'use client'

import Modal from '@codeverse/helios/modal'
import useUIStore from '../../Stores/useUIStore'
import Friends from './Friends/Friends'

export default function FriendsModal() {
  const { setFriendsModal, friendsModal } = useUIStore()

  return (
    <Modal
      width={'400px'}
      height={'400px'}
      isOpen={friendsModal}
      onClose={() => setFriendsModal(false)}
      allowEsc
      contentClassname="!rounded-2.5xl mx-4 z-3000 bg-white text-[#090e2b] overflow-y-scroll"
    >
      <Friends />
    </Modal>
  )
}
