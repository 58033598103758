'use client'
import { useQuery } from '@tanstack/react-query'
import fetchClientGql from '../fetch/fetchClientGql'
import { userPointsQuery } from './UserQueries'
import { UserScoreQuery } from './UserTypes'
import {
  getUserFollowers,
  getUserFollowings,
  getUserProfile,
  getUserPublicApps,
  getUserStarredProjects,
} from './UserFetch'
import Cookies from 'js-cookie'
import { useMemo } from 'react'
import { getAuthInfo } from '../getAuthInfo'
import { gql } from 'graphql-request'
import useAuth from '../../Hooks/Auth/useAuth'

export const useGetUserPoints = () => {
  const { currentUserId } = useAuth()
  return useQuery({
    queryKey: ['userPoints', currentUserId],
    queryFn: () => {
      return fetchClientGql().request(
        userPointsQuery()
      ) as Promise<UserScoreQuery>
    },
  })
}

export const useUserFollowers = (userId: string) => {
  return useQuery({
    queryKey: ['userFollowers', userId],
    queryFn: () => getUserFollowers(userId),
    enabled: !!userId,
  })
}

export const useUserFollowings = (userId: string) => {
  return useQuery({
    queryKey: ['userFollowings', userId],
    queryFn: () => getUserFollowings(userId),
    enabled: !!userId,
  })
}

export const useCurrentUser = () => {
  const currentUser = useMemo(() => {
    const auth = localStorage.getItem('auth')
    if (auth) {
      const { currentUserId, username } = JSON.parse(auth)
      return { currentUserId, username }
    } else {
      return { currentUserId: null, username: null }
    }
  }, [])
  return currentUser
}

export const useUserProfile = (username?: string) => {
  const { currentUserId } = getAuthInfo()
  return useQuery({
    queryKey: ['userProfile', username],
    queryFn: () =>
      fetchClientGql().request(gql`
      {
        user(username: "${username}") {
        id
        avatar {
          file
        }
        followingCount
        followerCount
        totalScore
      }
      }
      `),
    // queryFn: () => getUserProfile(username || currentUserId),
    enabled: !!username || !!currentUserId,
  }) as any
}

export const useUserPublicApps = (viewProfileUserId?: string) => {
  const { currentUserId } = getAuthInfo()
  const userId = viewProfileUserId || currentUserId
  return useQuery({
    queryKey: ['publicApps', userId],
    queryFn: () => getUserPublicApps(viewProfileUserId),
  })
}

export const useStarredGames = (viewProfileUserId?: string) => {
  const { currentUserId } = getAuthInfo()
  const username = viewProfileUserId || currentUserId
  return useQuery({
    queryKey: ['starredGames', username],
    queryFn: async () => {
      const response = await fetchClientGql().request(
        gql`{
            user(username:"${username}") {
            versionStars {
              project {
                shortCode
                user {
                  username
                  avatar {
                    file
                  }
                }
                playCount
                currentPublishedVersion {
                  id
                  name
                  screenshot
                  revisions {
                    kidscript
                  }
                }
              }

            }
          }
        }`
      )
      return response as any
    },
  })
}

export const useUserStarredProjects = (userId?: string) => {
  const { currentUserId } = useAuth()
  const id = userId || currentUserId
  return useQuery({
    queryKey: ['userStarredProjects', id],
    queryFn: () => getUserStarredProjects(id),
  })
}
