'use client'
import React, { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getNrdyParams } from '../PartnerAuth/getNrdyParams'
import Cookies from 'js-cookie'
import { createSession } from '../../Data/Auth/AuthFetch'
import { useAuthStore } from '@codeverse/studio-shared'
import { H } from '@highlight-run/next/client'
import { useRouter } from 'next/navigation'

const useAuthorizeAccount = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const {
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUserId,
    setAccessToken,
  } = useAuthStore()
  const authorizeAccountPartner = (
    handleAuthSuccess?: any,
    handleFail?: any
  ) => {
    const nrdyParams = getNrdyParams()

    return createSession(
      {
        nrdy_params: nrdyParams,
      },
      (response: any) => {
        queryClient.setQueryData(['user'], response)
        const auth = {
          accessToken: response.access_token.access_token,
          currentUserId: response.user.id,
          username: response.user.username,
        }
        setCurrentUserId(response.user.id)
        setAccessToken(response.access_token.access_token)
        H?.identify(response.user.username, {
          id: response.user.id,
          name: response.user.name,
          email: response.user.email,
          scope: response.user.scope,
        })

        localStorage.setItem('auth', JSON.stringify(auth))
        // Heap
        if (window.heap) {
          window.heap.resetIdentity()
          window.heap.identify(response?.referral_code)
        }

        if (typeof handleAuthSuccess === 'function') {
          handleAuthSuccess(response)
          router.push('/missions')
          setIsAuthenticated(true)
          // window.location.href = `/missions`
        }
      },
      handleFail
    )
  }

  const authorizeAccountLogin = (
    username: string,
    password: string,
    handleAuthSuccess: any,
    handleFail: any
  ) => {
    return createSession(
      {
        username,
        password,
      },
      (response: any) => {
        const { included } = response.data
        const [user, access_token] = included
        localStorage.setItem(
          'auth',
          JSON.stringify({
            accessToken: access_token,
            currentUserId: user.id,
            username: username,
          })
        )
        // queryClient.setQueryData(['user'], response)

        if (window.heap) {
          window.heap.resetIdentity()
          window.heap.identify(response?.referral_code)
        }

        if (typeof handleAuthSuccess === 'function') {
          handleAuthSuccess(response)
        }
      },
      handleFail
    )
  }

  return { authorizeAccountPartner, authorizeAccountLogin }
}

export default useAuthorizeAccount
