'use client'

import Button from '@codeverse/helios/button'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import CreateMenuDropDown from './CreateNewDropdown'
import { useUIStore } from 'apps/studio-shared/src/Stores'

export default function Name() {
  const { createNewMenu, setCreateNewMenu } = useUIStore()
  return (
    <div className="relative mr-4 hidden flex-none sm:hidden md:hidden lg:inline xl:inline 2xl:inline">
      <Button
        id="Home_CreateNew"
        className="ml-1 !pl-0"
        context="primary"
        size="small"
        icon={faPlus}
        iconBackgroundColor="#FFF"
        iconBackgroundOpacity="1"
        iconSide="left"
        iconWithCircle
        iconClassNames={'!text-neptune-darkest'}
        onClick={(e) => {
          e.stopPropagation()
          setCreateNewMenu(!createNewMenu)
        }}
      >
        Create New
      </Button>
      <CreateMenuDropDown />
    </div>
  )
}
