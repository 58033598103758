import React from 'react'
import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import LearningPathSkill from './LearningPathSkill'
import useModalDataStore from '../../../Stores/UseModalDataStore'
import { numberWithCommas } from 'apps/studio-shared/src/Util/formatNumberWithComma'

interface LearningPathProps {}

const LearningPath: React.FC<LearningPathProps> = () => {
  const { learningPath } = useModalDataStore()
  const pointsPillClassname = classNames(
    'bg-titan-darkalt flex font-bold items-center mr-3 rounded-[32px] text-lg text-white'
  )

  // const points = useMemo(() => {
  //   const reducer = (previousValue, currentValue) => {
  //     return previousValue + currentValue.points
  //   }
  //   return userData?.me?.curriculumScores.reduce(reducer, 0)
  // }, [userData])

  // const determineImage = () => {
  //   switch (learningPath?.learningPathId) {
  //     case '2e19aaea-17ee-4858-b178-69ed40afb512': // Creative Space
  //       return levelIconGreen
  //     case '7830d3b1-521c-4995-89cc-913c8850e0b0':
  //       return levelIconOrange
  //     case '1e87611d-1cfb-4d0a-ae0a-c8a7f852c9ae':
  //       return levelIconBlue
  //     case '84edd335-d40e-4169-be71-f65b53adbec9':
  //       return levelIconPink
  //     case '5e8c1e48-cd6b-465d-91cb-99465d56ea88': // Coding Genius
  //       return levelIconLightPurple
  //     case 'f458bcd8-7e31-42a3-841f-17e4eda97871': // Human Factors
  //       return levelIconYellow
  //     default:
  //       return levelIconPurple
  //   }
  // }

  // const image = React.useMemo(() => {
  //   return determineImage()
  // }, [learningPath])

  const Skills = React.useMemo(() => {
    return learningPath?.skills.map((skill) => {
      return <LearningPathSkill skill={skill} />
    })
  }, [learningPath])

  return (
    <div className="h-full overflow-y-scroll p-6">
      <div className="flex h-[98px] items-center justify-between">
        <div>
          <div className={pointsPillClassname} style={{ height: '50px' }}>
            <div className="mx-6">
              {numberWithCommas(learningPath?.currentTotalPoints)} Pts
            </div>
          </div>
        </div>

        <div className="font-jakarta-sans text-[32px] font-bold text-white">
          {learningPath?.name}
        </div>

        <div className="bg-moon-dark relative flex h-[50px] w-[136px] items-center justify-center rounded-[26px]">
          <div className="text-[18px] font-bold text-white">
            Level {learningPath?.currentLevel.position}
          </div>
        </div>
      </div>

      <div className="font-jakarta-sans text-moon px-[28px] text-base font-medium">
        {learningPath?.description}
      </div>

      <div className="bg-moon-dark rounded-4xl mt-6 h-auto min-h-[72px] w-full bg-opacity-30 px-8 py-8">
        {Skills}
      </div>
    </div>
  )
}

export default LearningPath
