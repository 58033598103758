'use client'

import { useEffect, useMemo, useState } from 'react'

import useMissionStepActions, {
  missionStepsToOptions,
} from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/useMissionStepActions'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import useRuntimeStore from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeStore'
import useKidScript from 'apps/studio-shared/src/Components/Studio/Hooks/useKidScript'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'

import { useParams } from 'next/navigation'
import { useQuery } from '@tanstack/react-query'

import { useDebounce } from '@codeverse/hooks'
import {
  useAllMissionStepInsertables,
  useMissionSteps,
} from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'

const MissionBuilderHookHandler = () => {
  const params = useParams()
  const {
    data: missionStepInsertablesData,
    isFetched: missionStepInsertablesFetched,
  } = useAllMissionStepInsertables((params.id as string) || '')
  const { data: missionStepData, isFetched: missionStepsFetched } =
    useMissionSteps(params.id as string)
  const {
    currentStep,
    missionStepPortalRef,
    setInitialReplayMissionSteps,
    initialReplayMissionSteps,
    setShowPortal,
  } = useMissionStore()
  const debouncedCurrentStep = useDebounce(currentStep, 1000)
  const { previewMissionMode } = useMissionBuilderStore()
  const { replayMissionStep } = useMissionStepActions()
  const { kidScriptSetup, editorSetup } = useRuntimeStore()
  const { editorRefs, missionBuilderRefs, setMissionBuilderRef } =
    useRuntimeRefsStore()
  const mainEditorRef = editorRefs['main']
  const mainMissionBuilderRef = missionBuilderRefs['main']

  const [builderRefSet, setBuilderRefSet] = useState(false)

  // const { handleRunKidScript } = useKidScript()

  // useEffect(() => {
  //   if (initialReplayMissionSteps) {
  //     replayMissionStep(debouncedCurrentStep, false)
  //   }
  // }, [initialReplayMissionSteps, debouncedCurrentStep])

  useEffect(() => {
    const mainEditorRef = editorRefs['main']
    if (
      currentStep &&
      mainEditorRef &&
      kidScriptSetup &&
      !initialReplayMissionSteps &&
      editorSetup &&
      missionStepsFetched
    ) {
      // Initial Replay Mission
      replayMissionStep(currentStep, true)
      setInitialReplayMissionSteps(true)
      // setShowPortal(true)
    }
  }, [
    currentStep,
    replayMissionStep,
    editorRefs,
    kidScriptSetup,
    editorSetup,
    missionStepsFetched,
  ])

  const missionStepInsertables = useMemo(() => {
    if (!missionStepInsertablesFetched) return null
    const missionStepInsertables =
      missionStepInsertablesData?.missions[0]?.missionSteps.sort(
        (a, b) => a.position - b.position
      )
    return missionStepInsertables
  }, [missionStepInsertablesData, missionStepInsertablesFetched])

  useEffect(() => {
    const editorRef = editorRefs['main']
    setBuilderRefSet(true)
    if (
      missionStepData &&
      missionStepData.length > 0 &&
      missionStepInsertablesFetched &&
      editorRef &&
      !builderRefSet
    ) {
      const missionBuilderRef = editorRef?.getMissionStepEditor(
        missionStepsToOptions(
          missionStepData,
          missionStepPortalRef,
          missionStepInsertables
        )
      )
      setMissionBuilderRef('main', missionBuilderRef)
    }
  }, [
    // setNewMissionStepEditor,
    missionStepData,
    editorRefs,
    missionStepInsertablesFetched,
    missionStepInsertables,
    builderRefSet,
  ])

  // useEffect(() => {
  //   if (
  //     initialReplayMissionSteps &&
  //     currentStep?.runnable &&
  //     previewMissionMode
  //   ) {
  //     handleRunKidScript()
  //   }
  // }, [initialReplayMissionSteps, currentStep, previewMissionMode])

  return null
}
export default MissionBuilderHookHandler
