import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import React, { useRef, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

const PortalWrapperModal = ({ children }: { children: any }) => {
  const portalRef = useRef(null)
  const { missionStepPortalRef } = useMissionStore()
  const div = missionStepPortalRef.current

  const hasDiv = useMemo(() => {
    //@ts-ignore
    portalRef.current = div
    return !!portalRef.current
  }, [portalRef, div])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     //@ts-ignore
  //     const div = window.newDiv
  //     console.log('found div to set portal ref to', div)
  //     if (div) {
  //       portalRef.current = div
  //     }
  //   }, 100)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [hasDiv])

  if (hasDiv) {
    return ReactDOM.createPortal(
      children,
      //@ts-ignore
      div
    )
  } else {
    return null
  }
}

export default PortalWrapperModal
