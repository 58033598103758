import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  StreamApp,
  FlatFeed,
  NotificationDropdown,
} from 'react-activity-feed-18'
// import axios from 'axios'
// import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import NotificationIcon from './NotificationIcon'
import ActivityContainer from '../Social/ActivityContainer'
import { motion } from 'framer-motion'

// import { ThemeContext } from '../context/ThemeContext'
// import NotificationIcon from '../atoms/NotificationIcon'
// import ActivityContainer from '../molecules/ActivityContainer'

const nova =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/codeverse-nova-white.png'
const topArrow = (
  <svg
    width="26px"
    height="8px"
    viewBox="0 0 26 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Path 2</title>
    <g
      id="Notifications/Feed"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Notifications-Infinite-Scroll-Copy"
        transform="translate(-1005.000000, -66.000000)"
        fill="#292E50"
      >
        <g id="Group-49-Copy" transform="translate(717.000000, 66.000000)">
          <path
            d="M288,0 C295.054808,0 296.473396,8 301.126824,8 C305.780252,8 306.945192,0 314,0 L288,0 Z"
            id="Path-2"
            transform="translate(301.000000, 4.000000) scale(1, -1) translate(-301.000000, -4.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  currentUser: any
  sessionToken: string
  streamToken: string
  setStreamToken: () => void
  followings: any
  onFollowClick: () => void
  onUnFollowClick: () => void
  onNotifierIconClick: () => void
  onUserNameClick: () => void
  isMobile: boolean
  ownProjectsList: any
}

const LoadingIndicator = (props) => {
  return (
    <div className="flex h-[400px] w-full items-center justify-center">
      <motion.img
        animate={{ rotate: 360 }}
        transition={{ duration: 0.5 }}
        src={nova}
        alt="Loading..."
        className="h-10 w-10"
      />
    </div>
  )
}

const useOutsideAlerter = (ref, onOutsideClick) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.contains(event.target)) {
        onOutsideClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const MobileHeader = (props) => {
  const mediaMatch = window.matchMedia('(max-width: 345px)')
  const smallMobile = mediaMatch.matches
  const mobileNovaStyles = {
    width: '40px',
    display: smallMobile ? 'none' : 'block',
  }
  return (
    <div
      className="mb-12 flex w-full flex-row justify-between !pb-16"
      style={{ padding: '4px' }}
    >
      <div
        className="flex h-40 w-40 items-center justify-start"
        style={mobileNovaStyles}
      >
        <img src={nova} alt="" className="opacity-50" width="40" height="40" />
      </div>
      <div className="col-span-2 text-center">
        <h5
          className="font-poppins-bold mb-0 py-8 pr-8 text-white"
          style={{ fontSize: '32px' }}
        >
          Notifications
        </h5>
      </div>
      <div className="flex items-center justify-end">
        <div
          className="bg-neptune-dark flex h-40 w-40 cursor-pointer items-center justify-center rounded-3xl bg-opacity-50"
          style={{ width: '40px' }}
          onClick={() => props.setIsOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>
    </div>
  )
}

const NotificationMenu = function NotificationMenu(props, ref) {
  const {
    currentUser,
    sessionToken,
    streamToken,
    setStreamToken,
    followings = [],
    onFollowClick,
    onUnFollowClick,
    onNotifierIconClick,
    onUserNameClick,
    isMobile = false,
    ownProjectsList = [],
    ...other
  } = props

  const menuRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isRead, setIsRead] = useState(false)
  const [followingList, setFollowingList] = useState(followings)
  const [followingListFetched, setFollowingListFetched] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const isFollowingsLoaded = useRef(false)
  //const isProjectsLoaded = useRef(false)

  useOutsideAlerter(menuRef, () => setIsOpen(false))

  // const {
  //   theme: { notifications },
  // } = useContext(ThemeContext)
  const notifications = {
    banner: 'bg-[#42476D] text-white text-center py-4',
    buttons: {
      outline:
        '!px-24 !py-4 rounded-4xl text-sm h-40 bg-gradient-to-r bg-transparent from-nebula to-sun !shadow-none align-middle',
    },
  }

  const getFollowings = () => {
    setIsFetching(true)
    // return axios
    //   .get(`${process.env.NX_API_URL}/users/${currentUser.id}/followings`, {
    //     headers: Object.assign(
    //       {},
    //       { Accept: 'application/vnd.api+json' },
    //       { 'content-type': 'application/vnd.api+json' }
    //     ),
    //     params: {
    //       current_user_id: currentUser.id,
    //       access_token: sessionToken,
    //     },
    //   })
    //   .then((response) => {
    //     setFollowingListFetched(true)
    //     setFollowingList(response.data.data)
    //   })
    //   .catch((error) => {
    //     setFollowingListFetched(true)
    //     //console.log(error)
    //   })
  }

  useEffect(() => {
    isFollowingsLoaded.current = true
    if (
      followingList.length < 1 &&
      isOpen &&
      !followingListFetched &&
      !isFetching
    ) {
      getFollowings()
    }
  }, [
    followingListFetched,
    followingList,
    setFollowingList,
    isOpen,
    isFetching,
  ])

  const listClasses = classNames(
    'absolute bg-[#292E50] bg-opacity-95 text-left text-white backdrop-blur',
    {
      hidden: !isOpen,
      'rounded-3xl shadow-lg': !isMobile,
    }
  )
  const notifierClasses = classNames('feed-module__button', { read: isRead })

  const listStyles = {
    width: isMobile ? '100vw' : '375px',
    height: isMobile ? '100vh' : '400px',
    top: isMobile ? '0' : '52px',
    right: isMobile ? '0' : '-50px',
    bottom: isMobile ? '0' : 'unset',
    left: isMobile ? '0' : 'unset',
    zIndex: 2000,
  }

  const arrowStyles = {
    height: '8px',
    width: '26px',
    top: '-8px',
    right: '57px',
  }

  return (
    <div
      className={classNames('min-w-10 ', isOpen && isMobile ? '' : 'relative')}
      ref={menuRef}
    >
      {streamToken && (
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          <StreamApp
            apiKey={'yz8wusyfmsez'}
            //@ts-ignore
            appId="1152053"
            token={streamToken}
          >
            <NotificationDropdown
              notify
              feedGroup="user_notifications"
              options={{ limit: 20 }}
              Paginator={false}
              LoadingIndicator={false}
              Notifier={false}
              className={notifierClasses}
              Icon={(props) => (
                <NotificationIcon
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setIsRead={setIsRead}
                  isMobile={isMobile}
                  onClick={onNotifierIconClick}
                  {...props}
                />
              )}
            />
            <div className={listClasses} style={listStyles}>
              {!isMobile && (
                <div
                  className="feed-module__arrow absolute"
                  style={arrowStyles}
                >
                  {topArrow}
                </div>
              )}
              <div
                className="feed-module__items font-poppins-regular overflow-scroll"
                style={{ height: '100%' }}
              >
                {isMobile && <MobileHeader setIsOpen={setIsOpen} />}
                {isOpen && (
                  <FlatFeed
                    notify
                    feedGroup="user_notifications"
                    options={{ limit: 20 }}
                    Placeholder={false}
                    LoadingIndicator={(props) => (
                      <LoadingIndicator {...props} />
                    )}
                    Activity={(props) => (
                      <ActivityContainer
                        feedType="notifications"
                        currentUser={currentUser}
                        sessionToken={sessionToken}
                        item={props.activity}
                        followingList={followingList}
                        setFollowingList={setFollowingList}
                        onFollowClick={onFollowClick}
                        onUnFollowClick={onUnFollowClick}
                        onUserNameClick={onUserNameClick}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        isMobile={isMobile}
                        theme={notifications}
                        ownProjectsList={ownProjectsList}
                        {...props}
                      />
                      // <div className="h-48 w-48">Hi</div>
                    )}
                  />
                )}
              </div>
            </div>
          </StreamApp>
        </motion.div>
      )}
    </div>
  )
}

export default NotificationMenu
