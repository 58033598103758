import React, { useMemo, useContext } from 'react'
import Image from 'next/image'
import { useResponsive, ResponsiveContext } from '@codeverse/hooks'
import Avatar from './Avatar'
// import Button from '../../Button'

import ProfileStat from './ProfileStat'
import Drawer from '../../Shared/Card/Drawer'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import classNames from 'classnames'
import useSession from 'apps/studio-shared/src/Data/User/useSession'
import { numberWithCommas } from 'apps/studio-shared/src/Util/formatNumberWithComma'
import determineAvatar from '../../Home/Nav/determineUserAvatar'
import { useUserPoints } from 'apps/studio-shared/src/Data/User/useUserPoints'

const PointsTitle = 'Pts'

interface PublicProfileProps {
  username: string
  pillProps: any
  isOpen: boolean
  avatarId?: string
  avatarSrc?: string
  withDrawer?: boolean
  button?: any
  handleClose?: any
  children: React.ReactNode
  userProfile: any
}

export const profile = {
  avatar: 'ml-0 flex items-center justify-center border-2 border-[#363B5F]',
  base: 'relative flex flex-col md:flex-row lg:flex-row items-center lg:items-start justify-start md:justify-center lg:justify-start w-full h-initial pt-0',
  container: 'w-full relative z-[9999]',
  username: 'text-white text-[30px] font-jakarta-sans font-semibold !mb-2',
  userInfoContainer: '',
  followButton: {
    base: 'w-full',
    container:
      'mt-5 md:mt-0 lg:mt-0 w-full md:w-auto lg:w-auto z-50 ml-auto h-full flex justify-end items-end grow',
    icon: 'mr-2',
  },
  stat: {
    container: 'bg-[#101432] px-6 py-3 rounded-3xl h-12 mb-2',
    sub: 'text-[#596087]',
    follow: 'text-[#DFE8F5] font-jakarta-sans font-semibold select-none',
  },
  background:
    'bg-gradient-to-b from-moon-lightalt2 to-moon-darkest absolute inset-0 rounded-t-[4px]',
}

const PublicProfile: React.FC<PublicProfileProps> = ({
  withDrawer,
  username,
  pillProps,
  avatarId,
  avatarSrc,
  isOpen,
  button,
  children,
  handleClose,
  userProfile,
}) => {
  const { isMobile } = useResponsive()
  const { setAvatarModal } = useUIStore()
  const { user } = useSession()
  const isCurrentUser = user?.referralCode === username
  const { coins, points } = useUserPoints()
  const Content: any = ({ children }) => {
    console.log('user', user)
    return (
      <div className={profile.container}>
        <div className={profile.base} style={{ height: 'fit-content' }}>
          {/* <div className={profile.background} /> */}
          <Avatar
            id="UserProfile_AvatarEdit"
            className={profile.avatar}
            size={'large'}
            src={userProfile?.user?.avatar?.file}
            handleClick={() => {
              setAvatarModal(true)
            }}
          />
          <div className="pl-4" style={{ zIndex: 1999 }}>
            <h2 className={profile.username}>{username}</h2>
            <div className="flex flex-row">
              <div
                className={classNames(
                  profile.stat.container,
                  'grid grid-cols-2 gap-4'
                )}
              >
                <div className={profile.stat.follow}>
                  {pillProps.following.value}{' '}
                  <span className={profile.stat.sub}>Following</span>
                </div>
                <div className={profile.stat.follow}>
                  {pillProps.followers.value}{' '}
                  <span className={profile.stat.sub}>Followers</span>
                </div>
                {/* <ProfileStat
                className={pillProps.following.className}
                onClick={pillProps.following.onClick}
                value={pillProps.following.value}
                title={'Following'}
              />
              <ProfileStat
                className={pillProps.followers.className}
                onClick={pillProps.followers.onClick}
                value={pillProps.followers.value}
                title={
                  pillProps.followers.value === 1 ? 'Follower' : 'Followers'
                }
              />
              <ProfileStat
                className={pillProps.points.className}
                onClick={pillProps.points.onClick}
                value={pillProps.points.value}
                title={isMobile ? 'Points' : PointsTitle}
              /> */}
              </div>
              <div className={classNames(profile.stat.container, 'ml-4 !p-1')}>
                <div
                  className={classNames(
                    profile.stat.follow,
                    'flex items-center justify-center'
                  )}
                >
                  <div
                    className={classNames(
                      'h-full rounded-3xl bg-[#1C2141] px-3 py-[10px] text-sm font-medium'
                    )}
                  >
                    Points
                  </div>
                  <span
                    className={classNames(
                      profile.stat.sub,
                      'pl-3 pr-[60px] text-[#DFE8F5]'
                    )}
                  >
                    {numberWithCommas(points)}
                  </span>
                </div>
              </div>
              <div className={classNames(profile.stat.container, 'ml-4 !p-1')}>
                <div
                  className={classNames(
                    profile.stat.follow,
                    'flex items-center justify-center'
                  )}
                >
                  <div
                    className={classNames(
                      'relative h-full rounded-3xl bg-[#1C2141] px-3 py-[10px] text-sm font-medium'
                    )}
                  >
                    <span className="">Rank</span>
                  </div>
                  <span
                    className={classNames(
                      profile.stat.sub,
                      'pl-3 pr-3 text-[#DFE8F5]'
                    )}
                  >
                    {user?.userRank?.rank?.name || 'Rank 1'}
                  </span>
                </div>
              </div>
              {isCurrentUser && (
                <div
                  className={classNames(profile.stat.container, 'ml-4 !p-1')}
                >
                  <div
                    className={classNames(
                      profile.stat.follow,
                      'flex items-center justify-center'
                    )}
                  >
                    <div
                      className={classNames(
                        'relative h-full rounded-3xl bg-[#1C2141] px-3 py-[10px] text-sm font-medium'
                      )}
                    >
                      <Image
                        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                        src={'/images/studio/coin.svg'}
                        alt="NovaCoin"
                        height={24}
                        width={24}
                      />
                      <span className="opacity-0">Po</span>
                    </div>
                    <span
                      className={classNames(
                        profile.stat.sub,
                        'pl-3 pr-[60px] text-[#DFE8F5]'
                      )}
                    >
                      {numberWithCommas(coins)}
                    </span>
                  </div>
                </div>
              )}
              {/* {isCurrentUser && (
                <div className="font-jakarta-sans flex min-w-[104px] items-center rounded-[20px] bg-[#101432] py-2 pl-2 pr-3 text-xs leading-4 text-white">
                  <Image
                    src={'/images/studio/coin.svg'}
                    alt="NovaCoin"
                    height={25}
                    width={24}
                  />
                  <span className="pl-2">{numberWithCommas(coins)}</span>
                </div>
              )} */}
            </div>
          </div>
          <div className={profile.followButton.container}>
            {typeof button === 'function' ? button() : button}
          </div>
        </div>
        <div className="my-6 h-auto w-full overflow-y-scroll rounded-[32px] bg-[#101432] px-[55px] py-8">
          {children}
        </div>
      </div>
    )
  }

  if (withDrawer) {
    return (
      <div className="z-[9999]">
        <Drawer
          allowEsc
          initialOpen
          isOpen={!!isOpen}
          handleClose={handleClose}
        >
          <Content>{children}</Content>
        </Drawer>
      </div>
    )
  }

  return (
    <div className="z-[9999]">
      <Content>{children}</Content>
    </div>
  )
}

export default PublicProfile
