'use client'

import { useMissionStepTips } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import Markdown from 'markdown-to-jsx'
import React, { useMemo } from 'react'
import MissionStepTip from './MissionStepTip'

interface MissionStepTipsProps {}

const MissionStepTips: React.FC<MissionStepTipsProps> = () => {
  const { missionStepTipsToCreate } = useMissionBuilderStore()
  const { currentStep } = useMissionStore()

  const { data: missionStepsTips, isFetched } = useMissionStepTips(
    currentStep?.id
  )
  const MissionStepTips = useMemo(() => {
    const stepTips =
      currentStep?.id && isFetched && missionStepsTips
        ? missionStepsTips
        : missionStepTipsToCreate
    if (!stepTips) return
    return stepTips?.map((missionStepTip: any, index: number) => {
      return <MissionStepTip index={index} content={missionStepTip.content} />
    })
  }, [missionStepTipsToCreate, missionStepsTips, currentStep, isFetched])

  return <div>{MissionStepTips}</div>
}

export default MissionStepTips
