'use client'

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

interface DraggingToolboxLibraryObjectProps {
  children: React.ReactNode
}

const DraggingToolboxLibraryObject: React.FC<
  DraggingToolboxLibraryObjectProps
> = ({ children }) => {
  if (!document.getElementById('portal')) {
    return null
  }
  //@ts-ignore
  return ReactDOM.createPortal(children, document.getElementById('portal'))
}

export default DraggingToolboxLibraryObject
