'use client'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import useMissionStore from '../../../Stores/useMissionStore'
import { animated, useSpring } from '@react-spring/web'
import useRuntimeStore from '../../Studio/Hooks/Runtime/useRuntimeStore'
import CurrentStep from './CurrentStep'
import Footer from './MissionStepCardFooter'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { usePathname } from 'next/navigation'

const background = 'new'

enum ValidBoilerplate {
  Code = 'code',
  Info = 'info',
}

const isValidStep = (currentStep: any) => {
  return Object.values(ValidBoilerplate).includes(currentStep?.boilerplate)
}

interface MissionStepDisplayProps {}

const MissionStepDisplay: React.FC<MissionStepDisplayProps> = () => {
  const pathname = usePathname()
  const [{ y, opacity }, api] = useSpring(() => ({ y: 700, opacity: 0 }))
  const { currentStep, guidedMissionMode, showPortal } = useMissionStore()
  const { kidscriptRunning } = useRuntimeStore()
  const { showMissionEditor } = useMissionBuilderStore()

  useEffect(() => {
    if (!showPortal) {
      api.start({ y: 700, opacity: 0 })
    } else {
      api.start({ y: 0, opacity: 1 })
    }
  }, [showPortal])

  const isMissionBuilder = pathname.includes('mission_builder')

  // const [width, setWidth] = useState(aspectRatio * window.innerHeight)
  const sideHeaderClassNames = classNames(
    '!w-[50px] duration-150 ease-in-out flex items-center justify-center rounded-tl-3xl transition-all border-r border-[#DFE8F5]',
    {
      'bg-titan-dark text-white': currentStep?.success_header,
      'bg-white text-[#8D98C6]': !currentStep?.success_header,
    }
  )

  const missionStepCardClassnames = classNames(
    'absolute bottom-0 items-stretch flex pointer-events-auto rounded-tl-3xl shadow-lg w-full z-[3001] mb-3',
    {
      hidden: !guidedMissionMode,
    }
  )

  const sideHeaderText = useMemo(() => {
    if (isValidStep(currentStep)) {
      switch (currentStep?.boilerplate) {
        case 'code':
          return currentStep?.side_bar_header
        case 'info':
          return currentStep?.side_bar_header
        default:
          return ''
      }
      // if ()
    }
  }, [currentStep])

  if (currentStep?.display !== 'fixed') return null

  if (!currentStep) return null

  if (showMissionEditor && isMissionBuilder) return null

  return (
    <animated.div
      id="MissionStepCardDisplay"
      className={missionStepCardClassnames}
      style={{ opacity, y }}
    >
      <div className={sideHeaderClassNames}>
        <div
          className="font-poppins-bold -rotate-90 transform text-center text-base tracking-wider"
          style={{ maxWidth: '373px', minWidth: '373px' }}
        >
          {sideHeaderText}
        </div>
      </div>
      <div className="flex-grow bg-white pt-4">
        <CurrentStep />
        <Footer classNameOverride="flex items-center border-t-[1px] border-opacity-[.12] border-white py-4" />
      </div>
    </animated.div>
  )
}

export default MissionStepDisplay
