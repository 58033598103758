'use client'

import { Tabs, TabsList, TabsTrigger } from '@codeverse/helios/tab'
import {
  useCurrentUser,
  useStarredGames,
  useUserProfile,
  useUserPublicApps,
} from 'apps/studio-shared/src/Data/User/UserDataHooks'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'
import PublishedGames from './PublishedGames'
import MyAchievements from './MyAchievements'
import StarredGames from './StarredGames'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { motion } from 'framer-motion'

export default function ProfileContent() {
  const { currentUserId, username: currentUserName } = useCurrentUser()
  const { profileTabState, setProfileTabState } = useUIStore()
  const params = useSearchParams()
  const username = params.get('username') as string
  const { data: userProfile } = useUserProfile(username)
  const { data: publicApps, isFetched } = useUserPublicApps(username)
  return (
    <div>
      <Tabs
        className="rounded-2.5xl mb-8 mt-0 h-full max-w-[432px]"
        //@ts-ignore
        onValueChange={(val: TabState) => {
          setProfileTabState(val)
        }}
        value={profileTabState}
      >
        <TabsList theme="dark" className=" grid w-full">
          <TabsTrigger
            id="UserProfile_Tab_Published"
            theme="darkLight"
            className="group relative"
            value="Published"
          >
            Published
          </TabsTrigger>

          <TabsTrigger
            id="UserProfile_Tab_Achievements"
            theme="darkLight"
            className="group"
            value="Achievements"
          >
            Achievements
          </TabsTrigger>
          <TabsTrigger
            id="UserProfile_Tab_Starred"
            theme="darkLight"
            className="group relative"
            value="Starred"
          >
            Starred Projects
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={!isFetched ? 'initial' : 'animate'}
        variants={{
          initial: {
            opacity: 0,
          },
          animate: {
            opacity: 1,
          },
        }}
        className=""
      >
        {profileTabState === 'Published' && <PublishedGames />}
        {profileTabState === 'Achievements' && <MyAchievements />}
        {profileTabState === 'Starred' && <StarredGames />}
      </motion.div>
    </div>
  )
}
