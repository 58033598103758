import React, { useEffect, useState, useMemo } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle, faTimes } from '@fortawesome/pro-solid-svg-icons'
import Button from '@codeverse/helios/button'

export default ({
  question,
  questionIdentifier,
  correctAnswer,
  selectedAnswer,
  setSelectedAnswer,
  submittedAnswer,
}: {
  question: string
  questionIdentifier: string
  correctAnswer: string
  selectedAnswer: string
  setSelectedAnswer: (questionIdentifier: string) => void
  submittedAnswer: boolean
}) => {
  const blur = useMemo(() => {
    if (selectedAnswer) {
      if (submittedAnswer && questionIdentifier === correctAnswer) {
        return false
      } else if (!submittedAnswer && questionIdentifier !== selectedAnswer) {
        return true
      } else if (submittedAnswer && questionIdentifier !== correctAnswer) {
        return true
      }
    }
    return false
  }, [submittedAnswer, questionIdentifier, selectedAnswer, correctAnswer])

  const quizQuestionBtnClassnames = classNames(
    '!flex !justify-center !items-start !flex-row !px-1',
    {
      'ring !ring-nebula ':
        questionIdentifier === selectedAnswer && !submittedAnswer,
      'ring !ring-titan-dark':
        submittedAnswer && questionIdentifier === correctAnswer,
      'ring !ring-mars-darkest ':
        questionIdentifier === selectedAnswer &&
        submittedAnswer &&
        correctAnswer !== selectedAnswer,
    }
  )
  const questionQuestionHeaderClassnames = classNames(
    'flex items-center justify-center w-full pr-3 pl-2 text-sm text-black',
    {
      active: questionIdentifier === selectedAnswer,
      'wrong-answer':
        submittedAnswer &&
        questionIdentifier === selectedAnswer &&
        selectedAnswer !== correctAnswer,
    }
  )

  // const icon = useMemo(() => {
  //   const questionBulletPointClassnames = classNames(
  //     'h-3 w-3 rounded-full bg-neptune-lightest flex ml-4.5 items-center transition-colors duration-300 ease-in-out',
  //     { '!bg-nebula': questionIdentifier === selectedAnswer }
  //   )
  //   if (submittedAnswer && questionIdentifier === correctAnswer) {
  //     return (
  //       <div className="ml-4.5 inline-flex items-center">
  //         <FontAwesomeIcon className="text-titan-dark" icon={faCheck} />
  //       </div>
  //     )
  //   } else if (
  //     submittedAnswer &&
  //     questionIdentifier === selectedAnswer &&
  //     selectedAnswer !== correctAnswer
  //   ) {
  //     return (
  //       <div className="ml-4.5 inline-flex items-center">
  //         <FontAwesomeIcon className="text-mars-darkest" icon={faTimes} />
  //       </div>
  //     )
  //   }
  //   return (
  //     <div className="inline-flex items-center">
  //       <div className={questionBulletPointClassnames} />
  //     </div>
  //   )
  // }, [submittedAnswer, questionIdentifier, selectedAnswer, correctAnswer])
  const icon = useMemo(() => {
    if (submittedAnswer && questionIdentifier === correctAnswer) {
      return faCheck
    } else if (
      submittedAnswer &&
      questionIdentifier === selectedAnswer &&
      selectedAnswer !== correctAnswer
    ) {
      return faTimes
    }
    return (
      // <div className="inline-flex items-center">
      //   {/* <div className={questionBulletPointClassnames} /> */}
      // </div>
      faCircle
    )
  }, [submittedAnswer, questionIdentifier, selectedAnswer, correctAnswer])

  return (
    <Button
      className={quizQuestionBtnClassnames}
      spanClassnames="flex flex-col w-full"
      context="secondary"
      size="small"
      icon={icon}
      iconWithCircle
      iconClassNames={
        questionIdentifier == selectedAnswer
          ? '!text-nebula'
          : '!text-neptune-lightest'
      }
      iconSide="left"
      onClick={() => {
        if (submittedAnswer) {
          return
        }
        setSelectedAnswer(questionIdentifier)
      }}
    >
      <div className={questionQuestionHeaderClassnames}>{question}</div>
    </Button>
  )
}
