'use client'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { useUIState } from '@codeverse-studio/containers/Context/UIStateContainer'
import { useOuterClick } from '@codeverse/hooks'
// import { useNavigate } from 'react-router-dom'
// import { ReactComponent as VTSmall } from './vtSmallIcon.svg'

import classNames from 'classnames'
// import useOuterClick from 'apps/studio/utils/useOuterClick'
import { useRouter } from 'next/navigation'
import {
  faArrowUpFromBracket,
  faCopy,
  faEllipsis,
  faFolderOpen,
  faICursor,
  faPlay,
  faRocketLaunch,
  faSquareUp,
} from '@fortawesome/pro-solid-svg-icons'
import DropdownItem from './helios-dropdown-item'
import { Variants, motion } from 'framer-motion'
// import { useSession } from '../../../../containers/Context/SessionContainer'
// import determineAvatar from '../../../../util/determineUserAvatar'

interface DropdownProps {
  children: React.ReactNode
  avatarId?: string
  avatarUrl?: string
  onClick?: any
  id?: string
  dropdownClassnames?: string
  dropdownButtonClassnames?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    avatarId,
    avatarUrl,
    children,
    onClick,
    id,
    dropdownClassnames,
    dropdownButtonClassnames,
    isOpen,
    setIsOpen,
  } = props
  // const isVTLogin = useMemo(() => {
  //   return localStorage.getItem('vtLogin') === 'true'
  // }, [])

  const handleLogout = () => {
    // logout()
    // setIsOpen(false)
    // const loginType = localStorage.getItem('loginType')
    // if (loginType === 'internal') {
    //   navigate('/login')
    // } else {
    //   window.location.replace('https://account.codeverse.com')
    // }
  }

  const handleLearningLab = () => {
    // window.location.assign('https://www.varsitytutors.com/learning-lab/')
  }

  const innerRef = useRef()

  const handleAvatarClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (typeof onClick === 'function') {
      setIsOpen(true)
      onClick()
    } else {
      setIsOpen(!isOpen)
    }
  }

  useOuterClick(innerRef, () => setIsOpen(false))

  return (
    <motion.div
      initial={'closed'}
      className={classNames(
        'absolute z-[9999] w-56 max-w-[160px] rounded-2xl bg-white p-2 shadow-lg',
        dropdownClassnames,
        {
          'pointer-events-none opacity-0': !isOpen,
          'opacity-100': isOpen,
        }
      )}
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        open: {
          clipPath: 'inset(0% 0% 0% 0% round 10px)',
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.7,
            delayChildren: 0.2,
            staggerChildren: 0.05,
          },
        },
        closed: {
          clipPath: 'inset(10% 50% 90% 50% round 10px)',
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.3,
          },
        },
      }}
      //@ts-ignore
      ref={innerRef}
    >
      {children}
    </motion.div>
  )
}

export default Dropdown
