'use client'
import Input from '@codeverse/helios/input'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect } from 'react'
import Template from './Template'
import { BLANK_PROJECT, PLATFORM, ACTION, SPORT } from './Templates'
import Button from '@codeverse/helios/button'
import useCreateNewProject from '../../Studio/Hooks/useCreateNewProject'
import Image from 'next/image'
import { useQueryClient } from '@tanstack/react-query'
import { useUIStore } from 'apps/studio-shared/src/Stores'

export default function NewProjectTemplate() {
  const [selectedTemplate, setSelectedTemplate] = React.useState<string | null>(
    'Platform'
  )
  const queryClient = useQueryClient()
  const { setNewProjectTemplateModal } = useUIStore()
  const state = queryClient.getQueriesData({
    queryKey: ['projects'],
  })
  const [projectName, setProjectName] = React.useState('')
  const [documentsToCreate, setDocumentsToCreate] =
    React.useState(BLANK_PROJECT)

  const createNewProject = useCreateNewProject()

  const [disabled, setDisabled] = React.useState(false)

  const handleCreateProject = () => {
    setDisabled(true)
    createNewProject(
      projectName,
      documentsToCreate[1],
      documentsToCreate[0],
      () => {
        setDisabled(false)
        setNewProjectTemplateModal(false)
      }
    )
  }

  useEffect(() => {
    if (selectedTemplate && projectName) {
      setDisabled(false)
    } else {
      // setDisabled(true)
    }
  }, [selectedTemplate])

  const totalProjects =
    state.length > 0 && state[1]?.length > 0
      ? //@ts-ignore
        state[1][1].pages[0][0].total_records
      : 0

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate === 'Blank') {
        setProjectName(`Project #${totalProjects}`)
      } else {
        setProjectName(`My ${selectedTemplate} Project #${totalProjects}`)
      }
    }

    switch (selectedTemplate) {
      case 'Platform':
        setDocumentsToCreate(PLATFORM)
        break
      case 'Action':
        setDocumentsToCreate(ACTION)
        break
      case 'Sport':
        setDocumentsToCreate(SPORT)
        break
      default:
        setDocumentsToCreate(BLANK_PROJECT)
        break
    }
  }, [selectedTemplate, state, totalProjects])

  return (
    <div className="flex h-full flex-col">
      <div className="px-6 pb-6 pt-6">
        <h5 className="font-jakarta-sans text-center text-[20px] font-bold">
          Creating New Project...
        </h5>

        <div>
          <p>Name your project</p>
          <div className="flex items-center pt-4">
            <div
              className="relative flex items-center justify-center rounded-lg bg-[#a6b1d6]"
              style={{
                height: '74px',
                width: '49px',
                minWidth: '49px',
                backgroundSize: 'cover',
              }}
            >
              <Image
                src="/images/home/nova-dark.svg"
                alt="blank"
                className="opacity-20"
                height={32}
                width={32}
              />
            </div>
            <div className="relative flex w-full pl-4">
              <Input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                className="w-full"
                size="regular"
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className="relative grow bg-[#e9eff8] p-6">
        <p>Choose project type</p>

        <div className="grid grid-cols-3 gap-4 pt-4">
          <Template
            type="Platform"
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
          <Template
            type="Action"
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
          <Template
            type="Sport"
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
          <Template
            type="Blank"
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </div>

        <div className="absolute bottom-6 right-6">
          <Button
            disabled={disabled}
            onClick={() => handleCreateProject()}
            context="primaryFlat"
            size="regular"
          >
            Start coding
          </Button>
        </div>
      </div>
    </div>
  )
}
