'use client'

import { useMemo, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Button from '@codeverse/helios/button'
import Input from '@codeverse/helios/input'
import { Tabs, TabsList, TabsTrigger } from '@codeverse/helios/tab'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons'

import {
  useGetFollowers,
  useGetFollowings,
  useMutateCreateFollowing,
  useMutateDeleteFollowing,
} from '../../../Data/Follow/FollowDataHooks'

import useUser from '../../../Data/User/useUser'
import useAuth from '../../../Hooks/Auth/useAuth'
import determineAvatar from '../../Home/Nav/determineUserAvatar'
import { motion } from 'framer-motion'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { usePathname, useRouter } from 'next/navigation'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import fetchClientGql from 'apps/studio/utils/fetch/fetchClientGql'
import { gql } from 'graphql-request'

type FollowTabState = 'followings' | 'followers'

export default function Friends() {
  const { currentUserId } = useAuth()
  const pathname = usePathname()
  const router = useRouter()
  const { data: user } = useUser(currentUserId, { enabled: !!currentUserId })
  const [tabState, setTabState] = useState<FollowTabState>('followings')
  const [disabled, setDisabled] = useState(false)
  const queryClient = useQueryClient()
  const { data: followers } = useGetFollowers(currentUserId)
  const { data: followings } = useGetFollowings(currentUserId)

  const { setProfileModal, setFriendsModal } = useUIStore()

  const createFollowingMutation = useMutateCreateFollowing()
  const deleteFollowingMutation = useMutateDeleteFollowing()

  const handleFollow = (username?: string) => {
    toast.dismiss()
    if (tabState === 'followings') {
      createFollowingMutation.mutate(
        {
          userName: username,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: ['userSocial'],
            })
            toast.success(`You are now following ${username}`, {
              position: 'bottom-center',
            })
          },
        }
      )
    } else {
      createFollowingMutation.mutate(
        {
          userName: username,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: ['userSocial'],
            })
            toast.success(`You are now following ${username}`, {
              position: 'bottom-center',
            })
          },
        }
      )
      // successToast('Followed')
    }
  }

  const handleUnfollow = (followingId?: string, username?: string) => {
    toast.dismiss()
    setDisabled(true)
    deleteFollowingMutation.mutate(
      {
        followingId: followingId,
      },
      {
        onSuccess: (data) => {
          setDisabled(false)
          queryClient.invalidateQueries({
            queryKey: ['userSocial'],
          })
          toast.success(`Unfollowed ${username}`, {
            position: 'bottom-center',
          })
        },
        onError: (error) => {
          setDisabled(false)
          toast.error(`Error`, {
            position: 'bottom-center',
          })
        },
      }
    )
  }

  const firstName = useMemo(() => {
    if (user) {
      return user.data.attributes.name.split(' ')[0]
    }
  }, [user])

  const userFollowings = useMemo(() => {
    return followings?.length
  }, [followings])

  const userFollowers = useMemo(() => {
    return followers?.length
  }, [followers])
  const userFollowerCards = useMemo(() => {
    return followers?.map((follower: any, index) => {
      const isFollowing = followings?.find(
        (following: any) => following.following.id === follower.user.id
      )
      return (
        <div
          key={index}
          className="rounded-2.5xl mb-2 flex flex-col bg-[#e9eff8] p-4 transition-all last:mb-0 hover:bg-[#e9eff8]"
        >
          <div className="flex flex-row items-center">
            <motion.img
              whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
              onClick={() => {
                setFriendsModal(false)
                setProfileModal(true)
                router.push(`${pathname}?username=${follower.user.username}`, {
                  scroll: false,
                })
              }}
              alt="avatar"
              className="cursor-pointer rounded-full"
              src={`https://api.codeverse.com/img/users/${follower.user.username}/thumbnail`}
              width={40}
              height={40}
            />
            <div className="flex w-full items-center justify-items-stretch pl-2">
              <span className="font-jakarta-sans font-semibold">
                {follower.user.username}
              </span>
              <div className="flex flex-1 justify-end">
                {isFollowing ? (
                  <Button
                    context="transparent"
                    size="small"
                    disabled={disabled}
                    onClick={() =>
                      handleUnfollow(isFollowing.id, follower.user.username)
                    }
                    className="ring-neptune-dark text-moon-darkest"
                  >
                    Following
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleFollow(follower.user.username)
                    }}
                    context="primaryFlat"
                    size="large"
                  >
                    Follow
                  </Button>
                )}
              </div>
            </div>
            {/* {following.follower.avatar_id} */}
          </div>
        </div>
      )
    })
  }, [followers, followings])

  const userFollowingsCards = useMemo(() => {
    return followings?.map((following: any, index) => {
      return (
        <div
          key={index}
          className="rounded-2.5xl mb-2 flex flex-col bg-[#e9eff8] p-4 transition-all last:mb-0 hover:bg-[#e9eff8]"
        >
          <div className="flex flex-row items-center">
            <motion.img
              whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
              onClick={() => {
                setFriendsModal(false)
                setProfileModal(true)
                router.push(
                  `${pathname}?username=${following.following.username}`,
                  {
                    scroll: false,
                  }
                )
              }}
              alt="avatar"
              className="cursor-pointer rounded-full"
              src={`https://api.codeverse.com/img/users/${following.following.username}/thumbnail`}
              width={40}
              height={40}
            />
            <div className="flex w-full items-center justify-items-stretch pl-2">
              <span className="font-jakarta-sans font-semibold">
                {following.following.username}
              </span>
              <div className="flex flex-1 justify-end">
                <Button
                  context="transparent"
                  size="small"
                  disabled={disabled}
                  onClick={() => {
                    handleUnfollow(following.id, following.following.username)
                  }}
                  className="ring-neptune-dark text-moon-darkest"
                >
                  Following
                </Button>
              </div>
            </div>
            {/* {following.following.avatar_id} */}
          </div>
        </div>
      )
    })
  }, [followings])

  return (
    <div className="h-auto">
      <div className="">
        <div className="flex flex-col items-center justify-center px-6 pt-6">
          <h3 className="mb-0 text-xl font-bold">{firstName}'s Friends</h3>

          <div className="w-full pt-4">
            <Tabs
              className="rounded-2.5xl mb-5 mt-0 h-full bg-[#dfe8f5]"
              //@ts-ignore
              onValueChange={(val: FollowTabState) => {
                setTabState(val)
              }}
              value={tabState}
            >
              <TabsList theme="light" className=" grid w-full">
                <TabsTrigger
                  theme="light"
                  className="group relative"
                  value="followings"
                >
                  {/* <FontAwesomeIcon
          className={cn('mr-1 group-hover:opacity-100', {
            'opacity-0': tabState !== 'home',
          })}
          icon={faHome}
          size="xs"
          color="#FF57D0"
        /> */}
                  {userFollowings} Following
                </TabsTrigger>

                <TabsTrigger theme="light" className="group" value="followers">
                  <>
                    {/* <Image
            className={cn('mr-1 group-hover:opacity-100', {
              'opacity-0': tabState !== 'projects',
            })}
            src="/images/home/icon-ide-projects.svg"
            width={10}
            height={10}
            alt="Icon Projects"
          /> */}
                    {userFollowers} Followers
                  </>
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>

        {tabState === 'followings' && (
          <motion.div
          // animate={tabState}
          // variants={{
          //   followings: {
          //     x: 0,
          //     display: 'block',
          //   },
          //   followers: {
          //     x: 100,
          //     display: 'none',
          //   },
          // }}
          >
            <p className="px-6 text-center font-medium">
              Follow someone new by typing in their username below.
            </p>

            <div className="mb-6 mt-5 flex w-full flex-row px-6 ">
              <Input
                onKeyUp={(evt) => {
                  if (evt.key === 'Enter') {
                    handleFollow()
                  }
                }}
                className=""
                size="regular"
              />
              <div>
                <FontAwesomeIcon
                  className="ml-2 cursor-pointer text-[40px] text-[#a6b1d6]"
                  icon={faCircleArrowRight}
                  size="2x"
                  onClick={() => handleFollow()}
                />
              </div>
            </div>

            <hr className="text-moon-light" />

            <div className="p-6">{userFollowingsCards}</div>
          </motion.div>
        )}
        {tabState === 'followers' && (
          <motion.div
            // animate={tabState}
            // variants={{
            //   followings: {
            //     x: 100,
            //     display: 'none',
            //   },
            //   followers: {
            //     x: 0,
            //     display: 'block',
            //   },
            // }}
            className="w-full px-6 pb-6"
          >
            {userFollowerCards}
          </motion.div>
        )}
      </div>
    </div>
  )
}
