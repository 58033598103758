import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { useMemo } from 'react'
import fetchClient from '../fetch/fetchClient'
import { getUserKeyrings } from './UserFetch'

export const useKeyrings = () => {
  const currentUserId = useMemo(() => {
    const auth = localStorage.getItem('auth')
    if (auth) {
      const { currentUserId } = JSON.parse(auth)
      return currentUserId
    } else {
      return null
    }
  }, [])

  const queryData = useQuery({
    queryKey: ['keyringGrants', currentUserId],
    queryFn: () => fetchClient(getUserKeyrings(currentUserId)),
  })
  return queryData
}
