import React, { useEffect } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-duotone-svg-icons'
import usePlatform from 'apps/studio-shared/src/Hooks/usePlatform'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  isOpen: boolean
  setIsOpen: () => void
  setIsRead: () => void
  onClick: () => void
  isMobile: boolean
}

const NotificationIcon = function NotificationIcon(props, ref) {
  const { isOpen, setIsOpen, setIsRead, isMobile, onClick, ...other } = props

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsRead(true)
      }, 1000)
    }
  }, [isOpen])

  const iconClasses = classNames(
    'bg-[#101432] cursor-pointer flex h-12 items-center justify-center relative rounded-3xl lg:h-10 lg:rounded-20 group border-white hover:border hover:border-opacity-30'
    // { 'bg-opacity-50': isMobile, 'bg-opacity-30': !isMobile }
  )

  return (
    <div
      className={iconClasses}
      style={{ width: isMobile ? '48px' : '40px' }}
      onClick={() => {
        onClick()
        setIsOpen(!isOpen)
      }}
    >
      <FontAwesomeIcon
        icon={faBell}
        className="group-hover:scale-110"
        size="lg"
        color="#ffffff"
        swapOpacity
      />
    </div>
  )
}

export default NotificationIcon
