import React from 'react'
import clsx from 'clsx'

const starPositions = [
  // Outer layer
  { top: '5%', left: '5%' },
  { top: '20%', left: '8%' },
  { top: '35%', left: '3%' },
  { top: '50%', left: '7%' },
  { top: '65%', left: '2%' },
  { top: '80%', left: '6%' },
  { top: '95%', left: '4%' },
  { top: '10%', right: '4%' },
  { top: '25%', right: '7%' },
  { top: '40%', right: '3%' },
  { top: '55%', right: '6%' },
  { top: '70%', right: '2%' },
  { top: '85%', right: '5%' },
  { top: '100%', right: '8%' },
  // Inner layer
  { top: '15%', left: '15%' },
  { top: '30%', left: '20%' },
  { top: '45%', left: '18%' },
  { top: '60%', left: '22%' },
  { top: '75%', left: '16%' },
  { top: '90%', left: '19%' },
  { top: '20%', right: '18%' },
  { top: '35%', right: '15%' },
  { top: '50%', right: '20%' },
  { top: '65%', right: '17%' },
  { top: '80%', right: '21%' },
  { top: '95%', right: '16%' },
]

const Sparkle = ({ color = 'white', size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80 0L88.2436 71.7564L160 80L88.2436 88.2436L80 160L71.7564 88.2436L0 80L71.7564 71.7564L80 0Z"
      fill={color}
    />
  </svg>
)

export const Starlight = () => {
  return (
    <div className="pointer-events-none fixed inset-0 z-[1]">
      {starPositions.map((position, idx) => (
        <div
          key={`sparkle-${idx}`}
          className={clsx('absolute', 'animate-twinkle')}
          style={{
            ...position,
            animationDelay: `${idx * 0.5}s`,
          }}
        >
          <Sparkle size={10} />
        </div>
      ))}
    </div>
  )
}
