'use client'
import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IconInputProps {
  value: string
  id?: string
  placeholder?: string
  onClick?: () => void
  onChange: any
  icon: any
  className?: string
  iconPosition?: string
  inputClassname?: string
  backgroundColor?: string
  iconClassname?: string
}

const IconInput: React.FC<IconInputProps> = ({
  value,
  onClick,
  onChange,
  placeholder,
  id,
  icon,
  className,
  inputClassname,
  iconPosition = 'left-3 top-5',
  backgroundColor,
  iconClassname = 'text-white',
}) => {
  const containerClassnames = classNames('relative', className)
  const inputClassnames = classNames(
    'bg-neptune-darkalt focus:outline-none focus:ring focus:ring-neptune font-poppins-medium h-10 pl-[38px] placeholder-moon pr-1 ring ring-neptune rounded-lg text-base text-moon w-full lg:ring-0 lg:ring-none',
    inputClassname
  )
  const iconClassnames = classNames('absolute', iconClassname, iconPosition)
  return (
    <div className={containerClassnames} id={id}>
      <input
        className={inputClassnames}
        //@ts-ignore
        style={Object.assign(
          {},
          backgroundColor && { backgroundColor: backgroundColor }
        )}
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        onChange={onChange}
      />
      <FontAwesomeIcon className={iconClassnames} icon={icon} />
    </div>
  )
}

export default IconInput
