'use client'
import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'

interface AvatarImageProps {
  avatar: any
}

export default function AvatarImage({ avatar }: AvatarImageProps) {
  const imageClassnames = classNames('h-[132px] m-auto rounded-full w-[132px] ')

  return (
    <Image
      fetchPriority="high"
      className={imageClassnames}
      src={avatar.file}
      alt={avatar.id}
      height={98}
      width={98}
    />
  )
}
