'use client'

import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@codeverse/hooks'
import { cva } from 'class-variance-authority'

const TabsListCn = cva(
  ['grid h-10 w-auto grid-flow-col gap-1 rounded-[20px] !bg-opacity-30'],
  {
    variants: {
      theme: {
        dark: 'bg-[#101432] text-white',
        darkLight: 'bg-[#101432] text-white',
        light: 'text-moon-darkest !bg-[#dfe8f5]',
      },
    },
  }
)

const TabsTriggerCn = cva(
  [
    'inline-flex items-center justify-center',
    'text-sm font-medium',
    'data-[state=active]:z-1000 rounded-[20px] px-3 py-1.5 transition-all',
    'font-jakarta-sans font-semibold disabled:pointer-events-none disabled:opacity-50',
    'min-w-[100px] data-[state=active]:bg-white',
    ' data-[state=active]:ring',
    'ring-inset hover:ring',
  ],
  {
    variants: {
      theme: {
        dark: 'data-[state=active]:!text-moon-darkest data-[state=active]:ring-neptune-dark hover:ring-neptune-dark bg-[#101432] text-white data-[state=inactive]:text-white',
        darkLight:
          'data-[state=active]:!text-moon-darkest hover:ring-neptune-dark bg-[#101432]  text-white data-[state=active]:bg-[#1C2141] data-[state=active]:!text-white data-[state=inactive]:text-white data-[state=active]:ring-white data-[state=active]:ring-opacity-[.08]',
        light:
          'data-[state=active]:!text-moon-darkest data-[state=inactive]:text-moon-darkest bg-[#dfe8f5] hover:ring-[#745bff] data-[state=active]:!bg-white data-[state=active]:ring-[#745bff]',
      },
    },
  }
)

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  Omit<React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>, 'theme'> & {
    theme?: 'dark' | 'light' | 'darkLight'
  }
>(({ className, theme, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(TabsListCn(), className)}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  Omit<
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    'theme'
  > & { theme?: 'dark' | 'light' | 'darkLight' }
>(({ className, theme, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={cn(
      TabsTriggerCn({
        theme: theme ?? 'dark',
      }),
      // 'dark:text-slate-200 dark:data-[state=active]:bg-slate-900 dark:data-[state=active]:text-slate-100',
      className
    )}
    {...props}
    ref={ref}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    className={cn('mt-2', className)}
    {...props}
    ref={ref}
  />
))

TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
