import { useQuery } from '@tanstack/react-query'
import { getAvatars, getAvatarsPublic } from './AvatarFetch'

export const useAvatars = () => {
  return useQuery({
    queryKey: ['avatars'],
    queryFn: () => getAvatars() as Promise<any[]>,
  })
}

export const useAvatarsPublic = () => {
  return useQuery({
    queryKey: ['avatars'],
    queryFn: async () => {
      return (await getAvatarsPublic()) as {
        avatars: Array<{
          id: string
          file: string
        }>
      }
    },
  })
}
