'use client'

import React, { useMemo } from 'react'

import {
  useBadgeAchievements,
  useBadges,
} from 'apps/studio-shared/src/Data/Achievements/BadgeDataHooks'
import { usePathname, useRouter } from 'next/navigation'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import useBadgeStore from 'apps/studio-shared/src/Stores/useBadgeStore'
import useAuth from 'apps/studio-shared/src/Hooks/Auth/useAuth'
import { set } from 'lodash'
import { motion } from 'framer-motion'

interface BadgeAchievementsProps {}

const BadgeAchievements: React.FC<BadgeAchievementsProps> = () => {
  const { data, isFetched } = useBadgeAchievements()
  const pathname = usePathname()
  const router = useRouter()
  const { username } = useAuth()
  const { setBadgeModal, setProfileModal, setProfileTabState } = useUIStore()
  const { setCurrentBadge } = useBadgeStore()
  const { data: badges } = useBadges()

  const mostRecent = useMemo(() => {
    return data?.me.badgeAchievements
      .sort((a, b) => {
        // Sort By Most Recent
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      .slice(0, 5)
  }, [data])

  const emptyBadges = useMemo(() => {
    let emptyBadges = []
    if (!isFetched) {
      //@ts-ignore
      emptyBadges = [null, null, null, null, null]
    } else {
      //@ts-ignore
      for (let i = 0; i < 5 - mostRecent?.length; i++) {
        //@ts-ignore
        emptyBadges.push(null)
      }
    }
    return emptyBadges
  }, [mostRecent, isFetched])

  const EmptyBadges = useMemo(() => {
    return emptyBadges?.map((badge, index) => {
      return (
        <div
          key={index}
          onClick={(e) => {
            e.stopPropagation()
            router.push(`${pathname}?username=${username}`)
            setProfileTabState('Achievements')
            setProfileModal(true)
          }}
        >
          <img
            src={'/images/home/empty-badge.png'}
            alt="BadgeDisplay"
            className="w-18 h-18 cursor-pointer transition-transform ease-in-out hover:scale-110"
          />
        </div>
      )
    })
  }, [emptyBadges])

  return (
    <div className="mt-6 grid grid-cols-3 gap-3">
      {mostRecent?.map((badge) => (
        <div
          onClick={(e) => {
            e.stopPropagation()
            const badgeToSet = badges?.find(
              (badgeToSearch: any) => badgeToSearch.id === badge.badge?.id
            )
            if (badgeToSet) {
              setCurrentBadge(badgeToSet)
              setBadgeModal(true)
            }
          }}
        >
          <img
            key={badge.id}
            alt="Badge"
            className="w-18 h-18 cursor-pointer transition-transform ease-in-out hover:scale-110"
            src={badge.badge.image}
          />
        </div>
      ))}
      {EmptyBadges}
      <motion.div
        id="ProfilePanel_ViewProfile"
        whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
        className="w-18 h-18 relative cursor-pointer"
        onClick={() => {
          router.push(`${pathname}?username=${username}`)
          setProfileTabState('Achievements')
          setProfileModal(true)
        }}
      >
        <img
          alt="ViewAll"
          className="w-18 h-18 absolute inset-0"
          src={'/images/home/ViewAllBadge.svg'}
        />
        <div className="font-jakarta-sans absolute inset-0 flex items-center justify-center text-center text-[10px] font-medium text-white">
          View <br /> Profile
        </div>
      </motion.div>
    </div>
  )
}

export default BadgeAchievements
