'use client'

import React, { useEffect } from 'react'
import Image from 'next/image'
import NovaCoin from './NovaCoin.svg'
import { useUserPoints } from '../../../Data/User/useUserPoints'
import { numberWithCommas } from '../../../Util/formatNumberWithComma'
import BadgeAchievements from './TopBadgeAchievements'
import AvatarFriends from './AvatarFriends'
import determineAvatar from '../Nav/determineUserAvatar'
import useUser from 'apps/studio-shared/src/Data/User/useUser'
import useSession from '../../Studio/Hooks/useSession'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenCircle } from '@fortawesome/pro-duotone-svg-icons'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { AnimatePresence, motion } from 'framer-motion'

interface ProfilePanelProps {}

const ProfilePanel: React.FC<ProfilePanelProps> = ({}) => {
  const imageClassnames = 'rounded-full'

  const { coins, points } = useUserPoints()

  const { user, isFetched } = useSession()

  const { setAvatarModal } = useUIStore()

  return (
    <div className="flex-col px-6 pb-6 pt-8">
      <div className="flex flex-col items-center">
        <div
          id="ProfilePanel_AvatarEdit"
          className="group relative"
          onClick={(e) => {
            e.stopPropagation()
            setAvatarModal(true)
          }}
        >
          <motion.div
            animate={!isFetched ? 'initial' : 'animate'}
            variants={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
            }}
            style={{
              height: 108,
              width: 108,
            }}
          >
            {user?.avatar.file && (
              <Image
                className={imageClassnames}
                src={user?.avatar.file}
                height={108}
                width={108}
                alt="hi"
                fetchPriority="high"
              />
            )}
            <div className="absolute right-6 top-2 text-[#A6B1D6] opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100">
              <FontAwesomeIcon
                style={{
                  //@ts-ignore
                  '--fa-primary-color': '#FFF',
                  '--fa-secondary-color': '#1c2141',
                  '--fa-secondary-opacity': 1,
                  '--fa-primary-opacity': 1,
                }}
                className="text-"
                icon={faPenCircle}
                size="xl"
              />
            </div>
          </motion.div>
        </div>
        <motion.h4
          initial={{ opacity: 0 }}
          animate={!isFetched ? 'initial' : 'animate'}
          variants={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
          }}
          className="font-jakarta-sans !mb-0 mt-2 font-medium text-white"
        >
          {user?.referralCode || 'No Referral Code'}
        </motion.h4>
        {/* <p className="font-roboto-mono text-[#8D98C6]">Explorer</p> */}
      </div>

      <div className="flex flex-row">
        <div className="font-jakarta-sans mt-7 flex min-w-[104px] items-center rounded-[20px] bg-[#101432] py-2 pl-2 pr-3 text-xs leading-4 text-white">
          <Image src={NovaCoin} alt="NovaCoin" height={25} width={24} />
          <span className="pl-2">{numberWithCommas(coins)}</span>
        </div>
        <div className="font-jakarta-sans ml-3 mt-7 flex min-w-[128px] items-center rounded-[20px] bg-[#101432] py-1 pl-1 pr-3 text-xs leading-4 text-white">
          <div className="rounded-[20px] bg-[#1c2141] p-2 text-xs">Points</div>
          <span className="py-2 pl-2">{numberWithCommas(points)}</span>
        </div>
      </div>

      <BadgeAchievements />

      <AvatarFriends />
    </div>
  )
}

export default ProfilePanel
