'use client'

import React from 'react'
import { Control, Controller } from 'react-hook-form'
import classNames from 'classnames'
import dynamic from 'next/dynamic'

import Input from '@codeverse/helios/input'
import { Select } from '@codeverse/helios/select'

import DifficultyLevel, {
  DifficultLevelOption,
} from './Mission/Form/DifficultyLevel'
import ImageFileUpload from './Mission/Form/ImageFileUpload'
import FormCheckbox from './MissionStep/Form/FormCheckbox'
import Category, { CategoryOption } from './Mission/Form/Category'

const MDEditor = dynamic(() => import('@uiw/react-md-editor'), { ssr: false })

export type MissionFormValues = {
  name: string
  position: number
  description: string
  coins: number
  minutes: number
  difficulty_level: DifficultLevelOption
  icon: string
  series: string
  published: boolean
  hidden: boolean
  guide_only: boolean
  category: CategoryOption
}

interface MissionFormProps {
  control: Control<MissionFormValues>
  type: 'edit' | 'new'
}

const seriesOptions = [
  { value: 'standard', label: 'Standard' },
  { value: 'bonus', label: 'Bonus' },
  { value: 'competition', label: 'Competition' },
]

export const headerClassname = classNames(
  'text-moon-darkest text-4 font-poppins-bold pb-1'
)

const MissionForm: React.FC<MissionFormProps> = ({ control, type }) => {
  const inputClassname = classNames('!py-1')
  const inputClassnameDescending = classNames('mt-1')
  const checkboxClassname = classNames(
    'col-span-2 checked:bg-white bg-no-repeat bg-center bg-contain bg-white'
  )

  const formCheckboxClassnames = classNames(
    'font-poppins-bold text-4 text-moon-darkest col-span-3 pt-1 pl-1'
  )

  return (
    <div>
      <div></div>
      <div className="mb-4">
        <div className={headerClassname}>Mission Title</div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input inputClassName={inputClassname} {...field} />
          )}
        />
      </div>
      {/* <Input
            className={inputClassnameDescending}
            {...register('position')}
            label="Position"
          /> */}
      <div className="mb-4" data-color-mode="light">
        <div className={headerClassname}>Description</div>
        <Controller
          name="description"
          control={control}
          render={({ field }) => <MDEditor preview="edit" {...field} />}
        />
      </div>
      <div className="mb-4">
        <div className={classNames(headerClassname, '!pb-0')}>
          Mission Cover
        </div>
        <p className="font-poppins-medium text-moon mb-2 text-xs">
          Artwork dimensions: 368 x 520 pixels. 600kb max
        </p>
        <Controller
          name="icon"
          control={control}
          render={({ field }) => {
            return (
              <ImageFileUpload
                theme="light"
                handleFile={field.onChange}
                value={field.value}
                handleReset={() => field.onChange(null)}
              />
            )
          }}
        />
      </div>
      <DifficultyLevel control={control} />
      <Category control={control} />
      <div className="mb-4">
        <div className={headerClassname}>Series</div>
        <Controller
          name="series"
          control={control}
          render={({ field }) => (
            <Select
              size="regular"
              defaultVal={
                seriesOptions.find((option) => field.value === option.value) ||
                seriesOptions[0]
              }
              onChange={(option) => field.onChange(option.value)}
              options={seriesOptions}
            />
          )}
        />
      </div>
      <div className="mb-4 grid grid-cols-2 gap-1 pb-1">
        <div>
          <div className={classNames(headerClassname, '!pb-0')}>Nova Coins</div>
          <p className="font-poppins-medium text-moon pb-2 text-xs">
            Coins awarded on completion
          </p>
          <Controller
            name="coins"
            control={control}
            render={({ field }) => (
              //@ts-ignore
              <Input
                type="number"
                min="1"
                inputClassName={inputClassname}
                // placeholder="Amount of coins"
                {...field}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <div className={classNames(headerClassname, '!pb-0')}>Minutes</div>
          <p className="font-poppins-medium text-moon pb-2 text-xs">
            Approx. time to complete
          </p>
          <Controller
            name="minutes"
            control={control}
            render={({ field }) => (
              //@ts-ignore
              <Input
                min="0"
                type="number"
                inputClassName={inputClassname}
                placeholder="30, 45, etc"
                {...field}
              />
            )}
          />
        </div>
      </div>
      <div className="mb-4 mt-4 grid grid-cols-12">
        <Controller
          name="published"
          control={control}
          render={({ field }) => (
            <FormCheckbox
              disabled={type === 'new'}
              classNameOverride="col-span-1"
              {...field}
            />
          )}
        />
        <label
          className={classNames(formCheckboxClassnames, {
            'text-moon-darkest text-opacity-50': type === 'new',
          })}
        >
          Published
        </label>
      </div>
      <div className="mt-1 grid grid-cols-12">
        <Controller
          name="hidden"
          control={control}
          render={({ field }) => (
            <FormCheckbox
              disabled={type === 'new'}
              classNameOverride="col-span-1"
              {...field}
            />
          )}
        />
        <label
          className={classNames(formCheckboxClassnames, {
            'text-moon-darkest text-opacity-50': type === 'new',
          })}
        >
          Hidden
        </label>
      </div>
      <div className="mt-1 grid grid-cols-12">
        <Controller
          name="guide_only"
          control={control}
          render={({ field }) => (
            <FormCheckbox classNameOverride="col-span-1" {...field} />
          )}
        />
        <label className={classNames(formCheckboxClassnames, 'pb-1')}>
          Guide Only
        </label>
      </div>
    </div>
  )
}

export default MissionForm
