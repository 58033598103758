'use client'

import Button from '@codeverse/helios/button'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import ArrowButton from './ArrowButton'
import Image from 'next/image'
import {
  useAvatars,
  useAvatarsPublic,
} from 'apps/studio-shared/src/Data/Avatar/AvatarDataHooks'
import AvatarImage from './AvatarImage'
import { after } from 'lodash'
import { getAvatarsPublic } from 'apps/studio-shared/src/Data/Avatar/AvatarFetch'
export default function AvatarSelector({
  initialSlide,
  currentSlide,
  setCurrentSlide,
  setInitialSlide,
}: {
  initialSlide: number
  setCurrentSlide: any
  currentSlide: any
  setInitialSlide: any
}) {
  const imageClassnames = classNames(
    'h-[132px] m-auto rounded-full w-[132px]',
    {}
  )
  const sliderEl = useRef(null)

  const { data: avatars } = useAvatarsPublic()

  const Avatars = useMemo(() => {
    return avatars?.avatars.map((avatar: any) => {
      return <AvatarImage avatar={avatar} key={avatar.id} />
    })
  }, [avatars])

  const sliderSettings = useMemo(() => {
    return {
      beforeChange: (current, next) => {
        setCurrentSlide(next)
      },
      // afterChange: (current, next) => {
      //   setSelectedAvatarId(
      // },
      arrows: true,
      autoplay: false,
      dots: false,
      focusOnSelect: true,
      initialSlide,
      infinite: true,
      nextArrow: <ArrowButton side="right" />,
      prevArrow: <ArrowButton side="left" />,
      slidesToScroll: 1,
      slidesToShow: 1,
      // swipe: editAvatar,
    }
  }, [])

  return (
    <div className="min-h-[132px]">
      {/* <Button context="secondary" className="" size="large">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button> */}
      <div style={{ width: '100%', height: '148px' }}>
        {initialSlide !== undefined && (
          <Slider ref={sliderEl} className="" {...sliderSettings}>
            {Avatars}
          </Slider>
        )}
      </div>
    </div>
  )
}
