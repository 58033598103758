// import React from 'react'
// import { v4 as uuidv4 } from 'uuid'
// import {
//   faArrowLeft,
//   faCaretRight,
//   faUnlockAlt,
// } from '@fortawesome/pro-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import CoinIcon from '@codeverse-studio/assets/icon/coin.svg'
// import { useBankBalance } from '@codeverse-studio/containers/Context/BankBalanceContainer'
// import { Button } from '@codeverse/helios'
// import { useUIState } from '@codeverse-studio/containers/Context/UIStateContainer'
// import { useMatch } from 'react-router'
// import { useRuntime } from '../useRuntime'
// import useGuidedMissions from '@codeverse-studio/containers/Hooks/useGuidedMissions'
// import { KidScriptStrategy } from '@kidscript/editor/dist/src/types/KidScriptStrategy'

import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useRuntimeStore from '../Hooks/Runtime/useRuntimeStore'
import { usePathname, useSelectedLayoutSegments } from 'next/navigation'

interface HeaderProps {
  resetFields: () => void
}

const Header: React.FC<HeaderProps> = ({ resetFields }) => {
  const { setShowToolbox } = useRuntimeStore()
  const pathname = usePathname()

  const isMissionBuilder = pathname.includes('/mission_builder')
  // const { state: bankBalanceState } = useBankBalance()
  // const { openUI, closeUI } = useUIState()
  // const {
  //   state: { missionInsertableToSave, updatedCurrentStep },
  //   setMissionInsertableToSave,
  //   replayMissionSteps,
  // } = useGuidedMissions()
  // const {
  //   state: RuntimeState,
  //   setToolboxMissionStepInsertable,
  //   setCurrentLibraryFilterObject,
  //   setShowToolbox,
  //   setCurrentToolboxObject,
  //   editorRef,
  // } = useRuntime()

  // const missionBuilderMatch = useMatch('/mission_builder/:missionId')

  // const { toolboxMissionStepInsertable } = RuntimeState

  // const handleCancel = () => {
  //   setShowToolbox(false)
  //   setToolboxMissionStepInsertable(false)
  //   setCurrentToolboxObject(null)
  //   setCurrentLibraryFilterObject(null)
  // }

  const handleClickEditor = () => {
    setShowToolbox(false)
    resetFields()
  }

  return (
    <div
      id="Header"
      className="relative flex h-[50px] items-center bg-white shadow-xl"
    >
      {/* <div
        id="Header_HideToolbox"
        className="bg-moon-lightest hover:ring-neptune ml-1 flex cursor-pointer items-center justify-center rounded-2xl px-3 py-1.5 shadow-sm transition-colors hover:ring"
        onClick={handleClickEditor}
      >
        <FontAwesomeIcon
          className="text-neptune-dark"
          icon={faArrowLeft}
          size={'lg'}
        />
        <span className="text-neptune-dark font-poppins-semibold ml-2">
          Editor
        </span>
      </div> */}
      {/* {!missionBuilderMatch && (
        <h4 className="mb-0 ml-10 flex-grow text-[19px]">
          Library
          <FontAwesomeIcon
            onClick={() => handleCancel()}
            className="mx-8"
            icon={faCaretRight}
            size={'sm'}
          />
          {subheader}
        </h4>
      )} */}
      {isMissionBuilder && (
        <div className="w-full" id="MissionInsertableToolboxHeader" />
      )}
      {/* {!missionBuilderMatch && (
        <div className="ml-auto flex items-end">
          {
            <div className="text-12 float-right mr-16 flex-none items-center justify-center">
              {showToolbox && (
                <Button
                  id="Header_UnlockCode"
                  className="text-moon-dark !px-12"
                  context={'secondary'}
                  size="small"
                  style={{
                    boxShadow: '#DDD 0px 0px 4px',
                    height: 28,
                  }}
                  onClick={() => openUI('unlockKeyring')}
                  icon={
                    <FontAwesomeIcon
                      className="text-neptune-light text-14 font-bold"
                      size="sm"
                      icon={faUnlockAlt}
                    />
                  }
                  iconCircleClassnames="h-[32px] pr-6"
                  iconWithCircle
                  iconSide="left"
                >
                  Enter Code
                </Button>
              )}
            </div>
          }
          {typeof bankBalanceState.bankBalance === 'number' && (
            <div className="float-right mr-8 flex-none items-center justify-center">
              <div className="text-18 leading-14 text-mars-darkalt relative flex flex-row items-center px-6 py-4 font-bold">
                <img className="mr-8 h-[26px]" alt="coin icon" src={CoinIcon} />
                {bankBalanceState.bankBalance}
              </div>
            </div>
          )}
        </div>
      )} */}
    </div>
  )
}

export default Header
