'use client'
import { useParams, useRouter, useSearchParams } from 'next/navigation'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import Button from '@codeverse/helios/button'
import Modal from '@codeverse/helios/modal'
import Portal from 'apps/studio/components/Shared/Portal'
import MissionForm, {
  MissionFormValues,
} from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionForm'
import useMission from 'apps/studio/hooks/data/Mission/useMission'
import { difficultLevelOptions } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/Mission/Form/DifficultyLevel'
import { useUpdateMissionMutation } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import { categoryOptions } from 'apps/studio-shared/src/Components/Missions/MissionBuilder/Mission/Form/Category'

export default function NamePage({ mission }: { mission: any }) {
  const router = useRouter()
  const params = useParams()

  const updateMission = useUpdateMissionMutation()
  const queryClient = useQueryClient()
  const [disabled, setDisabled] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<MissionFormValues>({
    defaultValues: {
      name: mission.name,
      position: mission.position,
      description: mission.description,
      coins: mission.coins,
      minutes: mission.minutes,
      difficulty_level: difficultLevelOptions.find(
        (option) => option.value === mission.difficulty_level
      ),
      icon: mission.icon,
      series: 'standard',
      published: mission.published,
      hidden: mission.hidden,
      guide_only: mission.guide_only,
      category: categoryOptions.find(
        (option) => option.value === mission.category
      ),
    },
  })

  const mutate = (data: any) => {
    setDisabled(true)
    updateMission.mutate(
      {
        id: params.id,
        ...data,
        difficulty_level: data.difficulty_level.value,
        category: data.category.value,
      },
      {
        onSuccess: (missionData: any) => {
          setDisabled(false)
          queryClient.invalidateQueries({
            queryKey: ['mission'],
          })
          router.push(`/mission_builder/${missionData.id}`)
        },
        onError: (error) => {
          setDisabled(false)
          console.error('Error updating mission', error)
        },
      }
    )
  }

  // Edit Mission Modal
  return (
    <Portal idToSearch="MissionBuilderMissionForm" debug={true}>
      <Modal
        onClose={() => router.push(`/mission_builder/${params.id}`)}
        isOpen
        id="CreateNewMissionModal"
      >
        <div className="z-2000 max-h-[764px] overflow-y-scroll px-6 pb-6">
          <div className="h-full">
            <div className="py-8 text-center">
              <h3>Edit Mission</h3>
            </div>
            <form onSubmit={handleSubmit(mutate)}>
              <MissionForm control={control} type="edit" />
              <div className="mt-4 grid grid-cols-2 gap-4">
                <Button
                  disabled={disabled}
                  type="submit"
                  context="primary"
                  size="small"
                >
                  Save Changes
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    router.push(`/mission_builder/${params.id}`)
                    // setShowNewMission(false)
                  }}
                  context="secondary"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Portal>
  )
}
