import { useQuery } from '@tanstack/react-query'
import fetchClientGql from '../fetch/fetchClientGql'
import { gql } from 'graphql-request'
import useAuth from '../../Hooks/Auth/useAuth'

export const useSkillsQuery = () => {
  const { currentUserId } = useAuth()
  return useQuery({
    queryKey: ['skillsQuery', currentUserId],
    queryFn: async () => {
      const response = await fetchClientGql().request(
        gql`
          {
            me {
              learningPathScores {
                learningPathId
                name
                currentTotalPoints
                guideOnly
                description
                currentLevel {
                  learningPathLevelId
                  position
                  pointsStart
                  pointsEnd
                  percentageComplete
                  requiredSkills {
                    skillId
                    name
                    level
                    pointsRequired
                  }
                }
                nextLevelPrerequisites {
                  learningPathLevelId
                  totalLearningPathPointsDeficit
                  deficientSkills {
                    skillId
                    name
                    pointsDeficit
                  }
                }
                skills {
                  skillId
                  name
                  currentPoints
                  currentLevel {
                    skillLevelId
                    position
                    pointsStart
                    pointsEnd
                  }
                }
              }
            }
          }
        `
      )
      return response
    },
  })
}
