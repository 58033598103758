'use client'

import React, { useEffect } from 'react'
import EditButton from '../../EditButton'
import {
  useParams,
  usePathname,
  useRouter,
  useSelectedLayoutSegments,
} from 'next/navigation'
import useMission from 'apps/studio/hooks/data/Mission/useMission'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import MissionSteps from './MissionSteps'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'

interface ViewMissionProps {}

const ViewMission: React.FC<ViewMissionProps> = () => {
  const router = useRouter()
  const params = useParams()
  const pathname = usePathname()
  const segments = useSelectedLayoutSegments()

  const { data: missionStepData, isFetched } = useMissionSteps(
    params.id as string
  )

  //@ts-ignore
  const { data: missionData } = useMission(params.id)
  // useEffect(() => {}, [mission])

  const labelClassname = classNames(
    'font-poppins-bold block text-[16px] text-white'
  )
  const valueClassname = classNames('font-poppins-regular text-white text-sm')

  const valueBaseClassname = 'font-poppins-medium text-14'
  const trueValueClassname = classNames(
    valueBaseClassname,
    'text-titan-dark !font-poppins-bold'
  )
  const falseValueClassname = classNames(valueBaseClassname, 'text-white')

  if (segments[0] === 'steps') {
    return null
  }

  if (!missionData?.attributes) return null

  return (
    <div>
      <div>
        <div className="flex flex-row items-center">
          <h3 className="!mb-0 !text-white">Mission Details</h3>
          <EditButton
            className="ml-auto"
            onClick={() => {
              router.push(`/mission_builder/${params.id}/edit`)
            }}
          />
        </div>
      </div>

      <div className="mt-6 grid grid-cols-3">
        <div className="pr-4">
          <img
            alt="MissionIcon"
            className="rounded-3xl"
            src={missionData?.attributes.icon}
          />
        </div>
        <div className="col-span-2 ml-3">
          <div className="grid grid-cols-2">
            <div>
              <label className={labelClassname}>Title</label>
              <span className={valueClassname}>
                {missionData?.attributes.name}
              </span>
            </div>
            <div>
              <label className={labelClassname}>Difficulty Level</label>
              <span className={valueClassname}>
                {missionData?.attributes.difficulty_level}
              </span>
            </div>
          </div>
          <div className="mt-1.5">
            <label className={labelClassname}>Description</label>
            {/* <MarkdownPreview theme="dark">
              {missionData?.attributes.description || ''}
            </MarkdownPreview> */}
          </div>
        </div>
      </div>

      <div className="bg-neptune-darkest mt-2 w-full rounded-[18px] bg-opacity-[24%] p-4">
        <div className="grid grid-cols-3">
          <div className={classNames(labelClassname, 'text-lg')}>
            <div>Published</div>
            <div
              className={
                missionData.attributes.published
                  ? trueValueClassname
                  : falseValueClassname
              }
            >
              {missionData.attributes.published ? 'Yes' : 'No'}
            </div>
          </div>
          <div className={classNames(labelClassname, 'text-lg')}>
            <div>Hidden</div>
            <div
              className={
                missionData.attributes.hidden
                  ? trueValueClassname
                  : falseValueClassname
              }
            >
              {missionData.attributes.hidden ? 'Yes' : 'No'}
            </div>
          </div>
          <div className={classNames(labelClassname, 'text-lg')}>
            <div>Guide Only</div>
            <div
              className={
                missionData.attributes.published
                  ? trueValueClassname
                  : falseValueClassname
              }
            >
              {missionData.attributes.guide_only ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-3">
          <div className={classNames(labelClassname, 'text-lg')}>
            <div>Nova Coins</div>
            <div
              className={classNames(
                valueBaseClassname,
                'flex flex-row items-center'
              )}
            >
              {/* <img className="mr-8 h-[15px]" alt="coin icon" src={CoinIcon} /> */}
              {missionData.attributes.coins}
            </div>
          </div>
          <div className={classNames(labelClassname, 'text-lg')}>
            <div>Minutes</div>
            <div className={valueBaseClassname}>
              {missionData.attributes.minutes}
            </div>
          </div>
          <div className={classNames(labelClassname, 'text-lg')}>
            <div>Series</div>
            <div className={valueBaseClassname}>
              {capitalize(missionData.attributes.series)}
            </div>
          </div>
        </div>
      </div>

      <hr className="text-moon-light mt-6" />

      {isFetched && (
        <MissionSteps
          //@ts-ignore
          missionStepData={missionStepData}
          missionId={params.id.toString()}
        />
      )}
    </div>
  )
}

export default ViewMission
