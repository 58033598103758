import {
  faArrowLeft,
  faBars,
  faBook,
  faBookOpen,
  faBookOpenCover,
  faCamera,
  faGrid2,
  faHexagon,
  faHexagonPlus,
  faHome,
  faToolbox,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useHover from '../../../../studio/hooks/util/useHover'
import { motion } from 'framer-motion'
import { useParams, useRouter } from 'next/navigation'
import FileSelector from './FileSelector'
import Button from '@codeverse/helios/button'
import useRuntimeStore from './Hooks/Runtime/useRuntimeStore'
import ToggleMissionButton from './ToggleMissionButton'
import { useState } from 'react'
import useMissionStore from '../../Stores/useMissionStore'
import useKidScript from './Hooks/useKidScript'
import { useUIStore } from '../../Stores'
import classNames from 'classnames'

const variant = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.2,
    },
  },
}

export default function EditorToolbar() {
  const { setPublishShareModal } = useUIStore()
  const {
    setKidScriptSetup,
    setShowToolbox,
    setShowCodeReference,
    showCodeReference,
    showToolbox,
    setKidscriptRunning,
  } = useRuntimeStore()
  const { handleMenuClick } = useKidScript()
  const { guidedMissionMode } = useMissionStore()
  const [hoverRef, isHovered] = useHover()
  const router = useRouter()

  const params = useParams()
  const { takeScreenshot } = useKidScript()

  return (
    <div className="flex h-12 w-full items-center bg-[#474c72]">
      <div
        id="Editor_Home"
        //@ts-ignore
        ref={hoverRef}
        onClick={() => {
          handleMenuClick()
          setShowToolbox(false)
          setShowCodeReference(false)
          setKidscriptRunning(false)
        }}
        className=" flex h-full w-12 cursor-pointer items-center justify-center hover:bg-[#596087]"
      >
        <motion.div
          initial="initial"
          animate={isHovered ? 'hover' : 'initial'}
          variants={variant}
        >
          <FontAwesomeIcon size="sm" className="text-white" icon={faHome} />
        </motion.div>
      </div>
      <FileSelector />
      <div className="ml-auto flex items-center">
        {/* {!guidedMissionMode && (
          <Button
            size="tiny"
            icon={faCamera}
            iconSide={'left'}
            iconClassNames="text-sm"
            className="mr-2 !rounded-[8px] !ring-0 hover:bg-[#596087]"
            context="transparent"
            onClick={() => {
              takeScreenshot(params.projectId as string)
            }}
          ></Button>
        )} */}
        {!guidedMissionMode && (
          <Button
            id="EditorToolbar_Publish"
            size="tiny"
            icon={faUpload}
            iconSide={'left'}
            iconClassNames="text-sm group-hover:scale-110 group-hover:transform"
            className="group mr-2 !rounded-[8px] !ring-0 duration-0 hover:bg-[#596087]"
            context="transparent"
            onClick={() => {
              setPublishShareModal(true)
            }}
          ></Button>
        )}
        {/* <Button
          size="tiny"
          icon={faHexagon}
          iconSide={'left'}
          iconClassNames="text-sm"
          className={classNames(
            'mr-2 !rounded-[8px] !ring-0 duration-0 hover:bg-[#596087]'
          )}
          context="transparent"
          onClick={() => {}}
        ></Button> */}
        <Button
          id="EditorToolbar_CodeReference"
          size="tiny"
          icon={faBookOpenCover}
          iconSide={'left'}
          iconClassNames="group-hover:scale-110 group-hover:transform duration-100 text-sm"
          className={classNames(
            'group mr-2 !rounded-[8px] !ring-0 duration-0 hover:bg-[#596087]',
            {
              'bg-[#596087]': showCodeReference,
            }
          )}
          context="transparent"
          onClick={() => {
            setShowToolbox(false)
            setShowCodeReference(!showCodeReference)
          }}
        >
          Reference
        </Button>
        <Button
          id="EditorToolbar_Toolbox"
          size="tiny"
          icon={faGrid2}
          iconSide={'left'}
          context="transparent"
          iconClassNames="group-hover:scale-110 group-hover:transform duration-100"
          className={classNames(
            'group mr-2 !rounded-[8px] !ring-0 duration-0 hover:bg-[#596087]',
            {
              'bg-[#596087]': showToolbox,
            }
          )}
          onClick={() => {
            setShowCodeReference(false)
            setShowToolbox(!showToolbox)
          }}
        >
          Objects
        </Button>

        {guidedMissionMode && <ToggleMissionButton />}
      </div>
    </div>
  )
}
