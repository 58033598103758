import Link from 'next/link'
import Step1ActionButtons from './Step1ActionButtons'
import Image from 'next/image'

interface Step1Props {}
export default function Step1({}: Step1Props) {
  return (
    <div>
      <div className="flex justify-center">
        <Image
          src="/images/login/cv-logo-nova.svg"
          width={56}
          height={56}
          alt={''}
        />
      </div>
      <div className="flex items-center justify-center">
        <h3 className="mt-4">Become a Creator!</h3>
      </div>
      <p className="mt-2 text-center text-base font-medium">
        Create your own games and play thousands more created by people just
        like you!
      </p>

      <Step1ActionButtons />

      <div className="font-jakarta-sans pt-2.5 text-center">
        <p
          className="font-medium"
          onClick={() => {
            window.location
          }}
        >
          Questions?{' '}
          <Link
            id="Login_FAQ"
            className="cursor-pointer font-semibold text-[#292E50] underline"
            href="https://www.codeverse.com/faq"
          >
            Read our FAQ
          </Link>
        </p>
      </div>
    </div>
  )
}
