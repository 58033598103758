import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React from 'react'

interface MethodProps {
  setCurrentMethod: any
  kidscriptMethod: any
  parameters: any
}

const Method: React.FC<MethodProps> = ({
  setCurrentMethod,
  kidscriptMethod,
  parameters,
}) => {
  const [hover, setHover] = React.useState(false)
  return (
    <li
      className="border-moon-light outline-moon-dark group relative grid cursor-pointer grid-cols-12 border-t bg-white p-6"
      onClick={() => {
        setCurrentMethod(kidscriptMethod)
        // setSelectedMethod()
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <div className="col-span-11 flex-grow rounded-lg p-2 text-black">
        <span className="font-sf-pro-text-bold">
          {kidscriptMethod.name}({parameters})
        </span>
        <div className="text-moon-dark">{kidscriptMethod.description}</div>
      </div>
      <div className="col-span-1 flex items-center">
        <div
          className="bg-moon-light text-neptune flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition-all group-hover:scale-125"
          onClick={() => {
            setCurrentMethod(kidscriptMethod)
            // setSelectedMethod()
          }}
        >
          <FontAwesomeIcon size="lg" icon={faChevronRight} />
        </div>
      </div>
    </li>
  )
}

export default Method
