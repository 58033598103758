import React, { useEffect, useMemo, useState } from 'react'
import { useAnimate } from 'framer-motion'
import BadgeAwardAnimation from './BadgeAwardAnimation'
import { useQuery } from '@tanstack/react-query'
import fetchClientGql from '../../Data/fetch/fetchClientGql'
import { gql } from 'graphql-request'
import { useUpdateBadgeAchievementViewed } from '../../Data/Achievements/BadgeDataHooks'
import useSession from '../../Data/User/useSession'

interface BadgeAwardContainerProps {}

const BadgeAwardContainer: React.FC<BadgeAwardContainerProps> = () => {
  const [scope, animate] = useAnimate()
  const [showBadgeAward, setShowBadgeAward] = useState(false)
  const updateBadgeViewedMutation = useUpdateBadgeAchievementViewed()
  const { user } = useSession()
  const { data: userBadges, refetch: refetchUserBadges } = useQuery({
    queryKey: ['userBadges', user?.id],
    queryFn: async () => {
      const response = await fetchClientGql().request(
        gql`
          {
            me {
              badgeAchievements {
                id
                viewed
                badge {
                  name
                  image
                }
              }
            }
          }
        `
      )
      return response as any
    },
  })

  const handleAnimationComplete = (badgeId: string) => {
    updateBadgeViewedMutation.mutate(
      {
        badgeAchievementId: badgeId,
      },
      {
        onSuccess: () => {
          refetchUserBadges()
        },
      }
    )
  }

  const unviewedBadges = useMemo(() => {
    return userBadges?.me?.badgeAchievements.filter(
      (badge) => badge.viewed === false
    )
  }, [userBadges])

  const RecentUnviewedBadge = useMemo(() => {
    const badge = unviewedBadges && unviewedBadges[unviewedBadges?.length - 1]
    if (badge) {
      // setShowBadgeAward(true)
      return (
        <div key={badge.id}>
          <BadgeAwardAnimation
            handleAnimationComplete={handleAnimationComplete}
            badge={badge}
            setShowBadgeAward={setShowBadgeAward}
          />
        </div>
      )
    }
    return null
  }, [unviewedBadges])
  if (!unviewedBadges) return null

  return <>{RecentUnviewedBadge}</>
}

export default BadgeAwardContainer
