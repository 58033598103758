import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import classNames from 'classnames'
import { useMatch } from 'react-router'
import useMissionStore from '../../../Stores/useMissionStore'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import { useParams, usePathname } from 'next/navigation'
import Button from '@codeverse/helios/button'
import EditorActionButtons from '../../Studio/EditorActionButtons'
import Progress from './MissionSteps/Progress'
import useMissionStepActions from '../MissionBuilder/MissionStep/useMissionStepActions'
import RunButton from '../../Studio/CodeReference/RunButton'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
// import ActionButtons from '../EditorOverlay/ActionButtons'
// import RunButton from './Steps/RunButton'

interface FooterProps {
  classNameOverride?: string
}

const Footer: React.FC<FooterProps> = ({ classNameOverride }) => {
  const { currentStep, setBlockNextStep, blockNextStep, currentMission } =
    useMissionStore()
  const { missionStepRunning, setMissionStepRunning } = useMissionBuilderStore()
  const params = useParams()
  const pathname = usePathname()
  const { data: missionSteps, isFetched } = useMissionSteps(currentMission?.id)
  const submitStep = { position: missionSteps?.length || 0 }
  const { handleNextStep, handleFinish, handlePrevStep } =
    useMissionStepActions()

  const isNewMissionStep = useMemo(() => {
    return pathname.includes('new')
  }, [])

  const isMissionStep = useMemo(() => {
    return !!params.stepId
  }, [params])

  const currentPosition = useMemo(() => {
    if (isNewMissionStep && missionSteps) {
      return missionSteps?.length + 1
    }
    return currentStep?.position + 1
  }, [currentStep])

  const totalSteps = useMemo(() => {
    if (isNewMissionStep && missionSteps) {
      return missionSteps.length + 1
    }
    return missionSteps?.length
  }, [missionSteps, isNewMissionStep])

  const totalCustomizeSteps = useMemo(() => {
    if (missionSteps && submitStep) {
      return missionSteps.length - submitStep.position
    } else {
      return 0
    }
  }, [currentStep, submitStep])

  const progressValue = useMemo(() => {
    if (!missionSteps) return 0
    if (isNewMissionStep) return 100
    return (currentPosition / missionSteps.length) * 100
  }, [currentStep, totalSteps, isNewMissionStep])

  const defaultFooterClassname =
    'flex items-center py-4 border-t-[1px] border-[#DFE8F5]'
  const footerClassnames = classNames(
    defaultFooterClassname,
    classNameOverride || 'w-full',
    {
      // !currentStep ||
      // (missionId &&
      //   // !previewMissionMode &&
      //   !currentStep?.validation_rules),
    }
  )

  if (currentStep?.success_header) {
    return (
      <div id="Footer" className={defaultFooterClassname}>
        <Button
          id="Footer_Back"
          className="z-2000 ml-4 mr-2 !h-12 !bg-[#E9EFF8] !px-4 text-[#090E2B] ring-opacity-0"
          context="transparent"
          size="regular"
          onClick={handlePrevStep}
          // hidden={!prevStep}
        >
          Back
        </Button>

        <Button
          id="Footer_Next"
          className="disabled z-2000 mr-6 !h-12 !border-none"
          context="primaryFlat"
          size="regular"
          onClick={handleFinish}
          disabled={blockNextStep}
          hidden={currentStep?.boilerplate === 'quiz'}
        >
          Finish
        </Button>
        <RunButton cx="ml-auto pr-4" size="large" />

        <EditorActionButtons bottomOverride="bottom-4" />
      </div>
    )
  }

  const handleNextStepFn = () => {
    if (isMissionStep) {
      return null
    }
    // setBlockNextStep(false)
    setBlockNextStep(true)
    setMissionStepRunning(false)
    handleNextStep()
  }

  return (
    <div id="MissionFooter" className={footerClassnames}>
      <Button
        id="MissionFooter_Back"
        className="z-2000 ml-6 !h-12 !bg-[#E9EFF8] !text-base text-[#090E2B] ring-opacity-0"
        context="transparent"
        size="regular"
        onClick={handlePrevStep}
        disabled={blockNextStep || currentStep?.position === 0}
        // hidden={!prevStep}
      >
        Back
      </Button>

      <div className="mx-6 flex w-full items-center justify-center pt-2">
        <div
          className={classNames(
            'flex h-12 max-w-[220px] flex-grow flex-col justify-center text-center text-white',
            {
              'mr-12': currentStep?.validation_target,
            }
          )}
        >
          <Progress value={progressValue} />

          <div className="font-jakarta-sans text-moon-darkest mt-1.5 select-none text-base font-medium">{`Step ${currentPosition} of ${totalSteps}`}</div>
        </div>
      </div>

      {currentStep?.runnable && (
        <>
          {!missionStepRunning && !currentStep?.run_automatically ? (
            <div className="mr-4">
              <RunButton
                // overrideClassName="h-12"
                runCallback={() => {
                  setMissionStepRunning(true)
                }}
              />
            </div>
          ) : (
            <Button
              id="MissionFooter_Next"
              className="z-2000 mr-6 !h-12 !border-none !text-base  text-[#090E2B]"
              context="success"
              size="regular"
              onClick={handleNextStepFn}
              disabled={blockNextStep}
              hidden={
                currentStep?.boilerplate === 'quiz' ||
                currentStep?.validation_rules
              }
            >
              Next
            </Button>
          )}
        </>
      )}
      {!currentStep?.runnable && (
        <>
          <Button
            id="Footer_Next"
            className="disabled z-2000 mr-6 !h-12 !border-none !text-base text-[#090E2B]"
            context="success"
            size="regular"
            onClick={handleNextStepFn}
            disabled={blockNextStep}
            hidden={
              currentStep?.boilerplate === 'quiz' ||
              currentStep?.validation_rules
            }
          >
            Next
          </Button>
        </>
      )}

      {/* {currentStep?.validation_rules && <ActionButtons />} */}
    </div>
  )
}

export default Footer
