import { useQuery } from '@tanstack/react-query'
import { MissionGQLQuery } from 'apps/studio-shared/src/Data'
import fetchClientGql from 'apps/studio-shared/src/Data/fetch/fetchClientGql'
import { gql } from 'graphql-request'
import { useRouter, useSearchParams } from 'next/navigation'

import Image from 'next/image'

import React from 'react'
import { MissionTabState } from './Missions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'
import { motion } from 'framer-motion'

interface MissionListProps {
  difficultyLevel: MissionTabState
}

export const getMissionDifficulty = (difficultyLevel: string) => {
  switch (difficultyLevel) {
    case 'BEGINNER':
      return 'Beginner'
    case 'INTERMEDIATE':
      return 'Intermediate'
    case 'ADVANCED':
      return 'Advanced'
    case 'PRO':
      return 'Pro'
    case 'EXPERT':
      return 'Expert'
    default:
      return 'Beginner'
  }
}

const variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index: number) => ({
    //use the custom value here
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index, //add delay according to the index
    },
  }),
}

export type Mission = {
  id: string
  name: string
  description: string
  difficultyLevel: 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED' | 'PRO' | 'EXPERT'
  icon: string
  coins: number
  points: number
  published: boolean
  hidden: boolean
  currentUserMissionAchievement: {
    id: string
    completed: boolean
    submitted: boolean
    failed: boolean
  }
}

const FoundationalMissionList: React.FC<MissionListProps> = ({
  // missions,
  difficultyLevel,
}) => {
  const searchParams = useSearchParams()
  const router = useRouter()
  const level = difficultyLevel.toUpperCase()
  //@ts-ignore
  const {
    data: missionsData,
  }: {
    data: { missions: Array<Mission> }
  } = useQuery({
    queryKey: ['missions', 'FOUNDATIONAL', level],
    // initialData: { missions },
    queryFn: () => {
      return fetchClientGql().request(
        gql`
          {
            missions(
              hidden: false
              onlyGuideMissions: false
              includeUnpublished: false
              difficultyLevel: ${level}
              category: FOUNDATIONAL
            ) {
              id
              name
              description
              difficultyLevel
              icon
              coins
              points
              position
              currentUserMissionAchievement {
                id
                completed
                submitted
              }
            }
          }
        `
      )
    },
  })

  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-6 rounded-2xl bg-[#090E2B] px-8 py-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4">
      {missionsData?.missions.map((mission, index) => {
        return (
          <motion.div
            id={`FoundationalMissionList_Mission_${mission.name}`}
            key={mission.id}
            onClick={() => {
              router.push('/missions/' + mission.id, { scroll: false })
            }}
            className="cursor-pointer rounded-2xl bg-[#090E2B] p-2 hover:bg-[#DFE8F5] hover:bg-opacity-[.08]"
            variants={variants}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
            custom={index}
          >
            <div
              className="relative aspect-[3/2] rounded-lg bg-white"
              style={{
                backgroundImage: `url(${mission.icon})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {mission?.currentUserMissionAchievement?.completed ? (
                <div className="flex h-full w-full items-center justify-center">
                  <FontAwesomeIcon
                    className="rounded-full text-[56px] ring ring-white"
                    size="2xl"
                    icon={faCheckCircle}
                    style={{
                      //@ts-ignore
                      '--fa-primary-color': '#fff',
                      '--fa-secondary-color': '#00CE94',
                      '--fa-secondary-opacity': '1',
                      '--fa-primary-opacity': '1',
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <h5 className="!font-jakarta-sans mb-0 mt-[10px] w-full overflow-hidden overflow-ellipsis whitespace-nowrap pr-4 text-sm font-medium text-white">
              {mission.name}
            </h5>
            <div className="flex flex-row items-center pt-1">
              <Image
                // className={novaClassnames}
                src="/images/missions/nova-purple.svg"
                alt="nova"
                width={20}
                height={20}
                className="pr-1"
              />
              <p className="text-xs text-[#A6B1D6]">
                {getMissionDifficulty(mission.difficultyLevel)}
              </p>
            </div>
          </motion.div>
        )
      })}
    </div>
  )
}

export default FoundationalMissionList
