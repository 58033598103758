'use client'
import React, { useEffect } from 'react'
import useRuntimeStore from './Runtime/useRuntimeStore'
import { useRuntimeRefsStore } from './Runtime/useRuntimeRefsStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import jsonLogic from 'json-logic-js'
import useMissionStepActions from '../../Missions/MissionBuilder/MissionStep/useMissionStepActions'

interface useAutomaticMissionProgressionProps {
  type: 'mission_builder' | 'guided_mission'
}

const useAutomaticMissionProgression: React.FC<
  useAutomaticMissionProgressionProps
> = ({ type }) => {
  const {
    currentStep,
    interpreterEvents,
    setInterpreterEvents,
    setGuidedMissionMode,
    resetInterpreterEvents,
    setKidScriptCompileData,
  } = useMissionStore()
  const { kidScriptSetup } = useRuntimeStore()
  const { editorRefs, interpreterRefs } = useRuntimeRefsStore()
  const interpreterRef = interpreterRefs.main
  const editorRef = editorRefs.main

  const { handleNextStep } = useMissionStepActions()

  // Interpreter Automatic Progression
  useEffect(() => {
    if (currentStep?.validation_target && kidScriptSetup && interpreterRef) {
      let handleExecuter: any = null
      switch (currentStep.validation_target) {
        case 'interpreter': {
          handleExecuter = (data) => {
            try {
              switch (type) {
                case 'mission_builder': {
                  setInterpreterEvents(data)
                  const result = jsonLogic.apply(
                    currentStep?.validation_rules,
                    data
                  )
                  if (result) {
                    // handleNextStep()
                    // setGuidedMissionMode(true)
                    // if (interpreterRef.getState() !== 'READY') {
                    //   interpreterRef.stop()
                    // }
                    // setShowPortal(true)
                    // setKidScriptRunning(false)
                  }
                  break
                }
                case 'guided_mission': {
                  const result = jsonLogic.apply(
                    currentStep?.validation_rules,
                    data
                  )
                  if (result) {
                    if (currentStep) {
                      handleNextStep()
                    }
                    setGuidedMissionMode(true)
                  }
                  break
                }
                default: {
                  break
                }
              }
            } catch (e) {
              console.log('error', e)
            }
          }
          console.log('setting up executer')
          interpreterRef.eventBus.on(
            'whenExecuter',
            'eventExecution',
            handleExecuter
          )
          interpreterRef.eventBus.on(
            'classExecuter',
            'methodExecution',
            handleExecuter
          )

          return () => {
            if (interpreterRef && kidScriptSetup && handleExecuter) {
              // interpreterRef.eventBus.off(
              //   'whenExecuter',
              //   'eventExecution',
              //   handleExecuter
              // )
              // interpreterRef.eventBus.off(
              //   'classExecuter',
              //   'methodExecution',
              //   handleExecuter
              // )
            }
          }
        }
      }
    }
  }, [setInterpreterEvents, kidScriptSetup, interpreterRef, currentStep])

  // KidScript Automatic Progression
  useEffect(() => {
    if (
      currentStep &&
      currentStep.validation_target === 'kidscript' &&
      editorRef
    ) {
      const handleCompile = (e) => {
        const ks = editorRef.getKidScript()
        //@ts-ignore
        const ksJSON = KidScript.parseDocuments(ks, KidScript.Version).toJSON()
        const result = jsonLogic.apply(currentStep?.validation_rules, ksJSON)
        if (result && result.length !== 0 && currentStep) {
          resetInterpreterEvents()
          handleNextStep()
          // if (currentStep.position >= lastStep) {
          //   if (missionBuilderMatch) {
          //     setShowPortal(false)
          //     setShowMissionEditor(true)
          //     setPreviewMissionMode(false)
          //     setCurrentStep(null)
          //     toast.success('Mission Complete!')
          //   } else {
          //     toggleShowMissionCompleteModal()
          //   }
          // } else {
          //   handleNextStep()
          //   setShowGuidedMission(true)
          // }
        }
      }
      editorRef.compiler.on('compiled', handleCompile)
      return () => {
        if (
          currentStep &&
          currentStep.validation_target === 'kidscript' &&
          editorRef
        ) {
          editorRef.compiler.off('compiled', handleCompile)
        }
      }
    }
  }, [editorRef, setKidScriptCompileData])

  // Set KidScript Compile Data
  useEffect(() => {
    if (editorRef) {
      const handleCompile = () => {
        const ks = editorRef.getKidScript()

        try {
          //@ts-ignore
          const ksJSON = KidScript.parseDocuments(
            ks,
            //@ts-ignore
            KidScript.Version
            //@ts-ignore
          ).toJSON()
          setKidScriptCompileData(ksJSON)
        } catch (e) {
          console.log('Error parsing KidScript JSON', e)
        }
      }
      editorRef.compiler.on('compiled', handleCompile)
      return () => {
        editorRef.compiler.on('compiled', handleCompile)
      }
    }
  }, [editorRef])
  return <div></div>
}

export default useAutomaticMissionProgression
