'use client'

import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons'
import useRuntimeStore from './Hooks/Runtime/useRuntimeStore'
import { usePathname } from 'next/navigation'

interface SavingIndicatorProps {}

const SavingIndicator: React.FC<SavingIndicatorProps> = () => {
  const { projectSaving, projectSaved, projectHasChanges } = useRuntimeStore()
  const pathname = usePathname()
  const saveIndicatorClassnames = classNames(
    'absolute bg-moon-dark bg-opacity-50 h-8 right-6 rounded-[20px] saving top-14 w-[90px]'
  )
  const saveText =
    'absolute left-[32px] font-poppins-semibold save-text text-[14px] top-[6px] text-white'

  // Don't show for mission builder
  if (pathname.includes('mission_builder')) {
    return null
  }

  if (!projectSaved && !projectSaving) {
    return (
      <div
        id="SavingIndicator"
        className={classNames(saveIndicatorClassnames, 'hidden !w-[124px]')}
      >
        <div className="bg-sun-darkest absolute left-1.5 top-1.5 flex h-[20px] w-[20px] min-w-[20px] items-center justify-center rounded-full bg-opacity-60">
          <FontAwesomeIcon
            className="text-white opacity-90"
            size="sm"
            icon={faExclamation}
          />
        </div>
        <div className={saveText + ' ' + 'ml-1'}>Not Saved</div>
      </div>
    )
  }

  if (projectSaving && !projectSaved) {
    return (
      <div className={saveIndicatorClassnames}>
        <motion.div
          className="project-saving-indicator absolute left-6 top-6 h-[20px] w-[20px]"
          animate={{ rotate: 360 }}
          style={{ backgroundImage: `url('/images/studio/save-nova.svg')` }}
          transition={{
            loop: Infinity,
            ease: 'linear',
            duration: 1,
          }}
        />
        <span className={saveText}>Saving</span>
      </div>
    )
  }

  return (
    <div id="SavingIndicator" className={saveIndicatorClassnames}>
      <div className="bg-titan-dark absolute left-1.5 top-1.5 flex h-[20px] w-[20px] min-w-[20px] items-center justify-center rounded-full bg-opacity-60">
        <FontAwesomeIcon
          className="text-titan opacity-90"
          size="sm"
          icon={faCheck}
        />
      </div>
      <div className={saveText + ' ' + 'ml-1'}>Saved</div>
    </div>
  )
}

export default SavingIndicator
