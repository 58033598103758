import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createFollowing,
  deleteFollowing,
  getUserFollowers,
  getUserFollowings,
} from './FollowFetch'

export const useMutateCreateFollowing = () => {
  const mutation = useMutation({
    mutationFn: ({ userName }: any) => {
      return createFollowing(userName)
    },
  })
  return mutation
}

export const useMutateDeleteFollowing = () => {
  const mutation = useMutation({
    mutationFn: ({ followingId }: any) => {
      return deleteFollowing(followingId)
    },
  })
  return mutation
}

export const useGetFollowers = (userId: string) => {
  return useQuery({
    queryKey: ['followers'],
    queryFn: () => getUserFollowers(userId),
  })
}

export const useGetFollowings = (userId: string) => {
  return useQuery({
    queryKey: ['followings'],
    queryFn: () => getUserFollowings(userId),
    enabled: !!userId,
  })
}
