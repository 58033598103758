'use client'
import React, { useCallback, useEffect, useState } from 'react'
import type { Editor } from '@kidscript/editor'

import fetchClient from 'apps/studio-shared/src/Data/fetch/fetchClient'
import { ProjectResponse } from 'apps/studio/app/projects/[projectId]/page'
import { MissionStep } from './types/MissionStep'
import useMissionStore from '../../Stores/useMissionStore'
import useMissionStepActions from './MissionBuilder/MissionStep/useMissionStepActions'
import { useParams } from 'next/navigation'
import useRuntimeStore from '../Studio/Hooks/Runtime/useRuntimeStore'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMission from 'apps/studio/hooks/data/Mission/useMission'
import { useMainEditorRef } from '../Studio/Hooks/useMainEditorRef'
import useKidScript from '../Studio/Hooks/useKidScript'
import { useMutateMissionAchievement } from '../../Data/Mission/MissionMutations'
import { useQueryClient } from '@tanstack/react-query'

declare global {
  interface Window {
    Editor: {
      Editor: typeof Editor
    }
    editor: Editor
    interpreter: any
  }
}

export function useInitializeGuidedMissions({
  type,
  missionAchievement,
  missionId,
}: {
  type: 'studio' | 'mission'
  missionAchievement?: any
  missionId?: any
}) {
  const { runKidScript } = useKidScript()
  const params = useParams()
  const {
    currentStep,
    setCurrentStep,
    setGuidedMissionMode,
    setShowMissionContent,
    setCurrentMission,
    initialReplayMissionSteps,
    setInitialReplayMissionSteps,
    missionStepPortalRef,
  } = useMissionStore()
  const mutateMissionAchievement = useMutateMissionAchievement()
  const queryClient = useQueryClient()
  const { kidScriptDependenciesFetched, editorSetup } = useRuntimeStore()
  const { data: missionSteps } = useMissionSteps(missionId)
  const { replayMissionStep, getEditorOption } = useMissionStepActions()
  const mainEditorRef = useMainEditorRef()
  const { data: missionData } = useMission(missionId)

  useEffect(() => {
    if (missionData) {
      setCurrentMission({ id: missionData.id, ...missionData?.attributes })
    }
  }, [missionData])

  useEffect(() => {
    if (
      type === 'studio' &&
      missionSteps &&
      missionAchievement &&
      kidScriptDependenciesFetched &&
      editorSetup &&
      mainEditorRef &&
      missionStepPortalRef.current &&
      !initialReplayMissionSteps
    ) {
      const currentStep = missionSteps.find(() => {})
      setGuidedMissionMode(true)
      setShowMissionContent(true)
      let missionStepToPlay: any
      if (missionAchievement) {
        if (!missionAchievement.missionStep) {
          setCurrentStep({
            id: missionSteps[0]?.id,
            ...missionSteps[0]?.attributes,
          })
          missionStepToPlay = missionSteps[0]
        } else {
          missionStepToPlay = missionSteps.find(
            (step) => step.id === missionAchievement.missionStep.id
          )
          if (!missionStepToPlay) {
            missionStepToPlay = missionSteps[0]
            setCurrentStep({
              id: missionSteps[0]?.id,
              ...missionSteps[0]?.attributes,
            })
          } else {
            setCurrentStep({
              id: missionStepToPlay?.id,
              ...missionStepToPlay?.attributes,
            })
          }
        }
      } else {
        missionStepToPlay = missionSteps[0]
        setCurrentStep({
          id: missionSteps[0].id,
          ...missionSteps[0]?.attributes,
        })
      }
      setTimeout(() => {
        try {
          replayMissionStep(
            { id: missionStepToPlay?.id, ...missionStepToPlay?.attributes },
            false,
            missionAchievement?.step_changes?.steps || []
          )
          if (missionStepToPlay?.attributes.run_automatically) {
            setTimeout(() => {
              runKidScript()
            }, 500)
          }
        } catch (e) {
          missionStepToPlay = missionSteps[0]
          setCurrentStep({
            id: missionSteps[0].id,
            ...missionSteps[0]?.attributes,
          })
          console.log('Error replaying mission step', e)
          replayMissionStep(
            { id: missionStepToPlay?.id, ...missionStepToPlay?.attributes },
            false,
            missionAchievement?.step_changes?.steps || []
          )
          mutateMissionAchievement.mutate(
            {
              //@ts-ignore
              missionStepId: missionStepToPlay?.id,
              missionAchievementId: missionAchievement?.id,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ['missionAchievement'],
                })
              },
            }
          )
        }
      }, 100)
      setInitialReplayMissionSteps(true)
    }
  }, [
    type,
    missionSteps,
    missionAchievement,
    kidScriptDependenciesFetched,
    editorSetup,
    missionStepPortalRef.current,
    mainEditorRef,
    replayMissionStep,
  ])

  // set guided mission step portal ref on mount
  useEffect(() => {
    const newDiv = document.createElement('div')
    newDiv.setAttribute('id', 'MissionStepPortal')

    missionStepPortalRef.current = newDiv
  }, [])

  useEffect(() => {
    return () => {
      setInitialReplayMissionSteps(false)
      missionStepPortalRef.current?.remove()
    }
  }, [])
}
