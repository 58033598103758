'use client'
import React, { useEffect, useState } from 'react'
import { cn } from '@codeverse/cn'
import useRuntimeStore from './Hooks/Runtime/useRuntimeStore'

export type KidScriptFileTypes = '/head.ks' | '/main.ks'

interface FileSelectorProps {}

const FileSelector: React.FC<FileSelectorProps> = () => {
  const [currentFile, setCurrentFile] = useState<KidScriptFileTypes>('/main.ks')
  const { setShowCodeReference, setShowToolbox } = useRuntimeStore()
  const isHead = currentFile === '/head.ks'
  const isMain = currentFile === '/main.ks'
  const { kidScriptSetup, editorSetup } = useRuntimeStore()

  const changeCurrentFile = (file: KidScriptFileTypes) => {
    const editor = window?.editor
    setShowCodeReference(false)
    setShowToolbox(false)
    if (file === '/main.ks') {
      window?.editor.switchDocument(1)
      setCurrentFile(file)
    } else {
      window?.editor.switchDocument(0)
      setCurrentFile(file)
    }
    // handleShowFileDropdown()
  }

  const handleDocumentSwitch = (e) => {
    switch (e.currentDocumentID) {
      case 0:
        setCurrentFile('/head.ks')
        break
      case 1:
        setCurrentFile('/main.ks')
        break
      default:
        console.log('Unsupported document id')
        break
    }
  }

  useEffect(() => {
    if (kidScriptSetup) {
      const editor = window?.editor
      if (editor && editor.documents) {
        editor.documents.on('switch', (e) => handleDocumentSwitch(e))
        return editor.documents.off('switch', (e) => handleDocumentSwitch(e))
      }
    }
  }, [kidScriptSetup])

  const fileTabClassnames = cn(
    'font-jakarta-sans 2xl:text-base xl:text-base lg:text-base text-[10px] flex h-full cursor-pointer items-center 2xl:px-6 xl:px-6 lg:px-3 px-2 text-white hover:bg-[#596087] justify-center border-b-4 border-r-0 border-l-0 border-neptune transition-all duration-150 ease-in select-none'
  )
  const mainTabClassnames = cn(fileTabClassnames, {
    'border-opacity-100': isMain,
    'border-opacity-0': !isMain,
  })
  const headTabClassnames = cn(fileTabClassnames, {
    'border-opacity-100': isHead,
    'border-opacity-0': !isHead,
  })
  return (
    <div className="grid h-full grid-cols-2">
      <div
        onClick={() => changeCurrentFile('/main.ks')}
        className={mainTabClassnames}
      >
        main.ks
      </div>
      <div
        onClick={() => changeCurrentFile('/head.ks')}
        className={headTabClassnames}
      >
        head.ks
      </div>
    </div>
  )
}

export default FileSelector
