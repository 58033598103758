import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useModalDataStore from '../../../../Stores/UseModalDataStore'
import { useUIStore } from '../../../../Stores'
import moment from 'moment-timezone'
import { motion } from 'framer-motion'
import useBadgeStore from 'apps/studio-shared/src/Stores/useBadgeStore'
import { useBadges } from 'apps/studio-shared/src/Data/Achievements/BadgeDataHooks'
import { variants } from '../PublishedGameCard'

interface BadgeDisplayProps {
  achievedBadge: any
  enableClick?: boolean
  disabled?: boolean
  index: number
}

const BadgeDisplay: React.FC<BadgeDisplayProps> = ({
  achievedBadge,
  enableClick,
  disabled,
  index,
}) => {
  const classnames = classNames('cursor-pointer', {
    'grayscale opacity-60': disabled,
  })
  const { setCurrentBadge } = useBadgeStore()
  const { setBadgeModal } = useUIStore()

  const { data: badges } = useBadges()

  return (
    <div>
      <motion.div
        id="BadgeDisplay"
        className={classnames}
        onClick={() => {
          if (enableClick) {
            const badgeToSet = badges.find(
              (badge: any) => badge.id === achievedBadge.id
            )
            setCurrentBadge(badgeToSet)
            setBadgeModal(true)
            // navigate(`/home/badges/${achievedBadge.id}`)
          }
        }}
        whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
        style={{
          backgroundImage: `url(${achievedBadge?.image})`,
          paddingTop: '100%',
        }}
        variants={variants}
        initial="initial"
        whileInView="animate"
        key={index}
        viewport={{
          once: true,
        }}
        custom={index}
      ></motion.div>
      <div className="pt-5">
        <p className="font-jakarta-sans text-sm font-medium text-white">
          {achievedBadge.name}
        </p>
        <p className="text-xs font-medium text-[#a6b1d6]">
          {moment(
            achievedBadge?.currentUserBadgeAchievement?.createdAt
          ).fromNow()}{' '}
        </p>
      </div>
    </div>
  )
}

export default BadgeDisplay
