import React from 'react'

interface MissionLayoutProps {
  children: any
}

const MissionLayout: React.FC<MissionLayoutProps> = ({ children }) => {
  return <>{children}</>
}

export default MissionLayout
