'use client'

import React from 'react'
import { HeliosPill } from '@codeverse/helios'
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/navigation'
import { motion } from 'framer-motion'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { variants } from './PublishedGameCard'

export default function AppCard({
  starredGame,
  index,
}: {
  starredGame: any
  index: number
}) {
  const router = useRouter()
  const { setProfileModal, setProfileTabState } = useUIStore()
  const handleUserClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setProfileTabState('Published')
    router.push(`/play?username=${starredGame.project.user.username}`, {
      scroll: false,
    })
    setProfileModal(true)
  }
  return (
    <motion.div
      key={index}
      className="flex cursor-pointer flex-col rounded-xl p-2 hover:bg-[#DFE8F5] hover:bg-opacity-5"
      variants={variants}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
      custom={index}
    >
      <div
        id="StarredGameCard_Open"
        className="relative cursor-pointer"
        onClick={() => {
          router.push(
            `/play/${starredGame.project.currentPublishedVersion.id}`,
            {
              scroll: false,
            }
          )
        }}
      >
        <img
          className="rounded-2xl"
          src={starredGame.project.currentPublishedVersion.screenshot}
          alt=""
        />
        <HeliosPill className="absolute left-2 top-2" context="dark">
          <FontAwesomeIcon
            icon={faPlayCircle}
            size="lg"
            className="pr-1 text-[#00ce94]"
          />
          {starredGame.project.playCount}
        </HeliosPill>
      </div>
      <div className="flex flex-row items-center pl-2 pt-3">
        <motion.img
          whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
          className="mr-2 h-10 w-10 cursor-pointer rounded-full border-2 border-white"
          src={starredGame.project.user.avatar.file}
          alt="image"
          onClick={handleUserClick}
        />
        <div className="">
          <div className="font-jakarta-sans max-w-[112px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-white">
            {starredGame.project.currentPublishedVersion.name}
          </div>
          <div
            className="font-jakarta-sans pt-.5 z-2000 max-w-[112px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-[#A6B1D6] transition-all hover:translate-x-1 hover:scale-110 hover:transform"
            onClick={handleUserClick}
          >
            {starredGame.project.user.username}
          </div>
        </div>
      </div>
    </motion.div>
  )
}
