'use client'
import IconInput from './IconInput'
import React from 'react'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'

interface SearchBoxProps {
  value: string
  handleSearch: any
}

const SearchBox: React.FC<SearchBoxProps> = ({ handleSearch, value }) => {
  return (
    <div id="SearchBox" className="relative mt-4 px-4">
      <IconInput
        inputClassname="!bg-white text-moon-dark h-14"
        value={value}
        onChange={handleSearch}
        icon={faSearch}
        placeholder="Search Anything"
        iconClassname="text-moon"
      />
    </div>
  )
}

export default SearchBox
