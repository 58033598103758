'use client'
import React from 'react'

import Modal from '@codeverse/helios/modal'
import useUIStore from '../../Stores/useUIStore'
import Friends from './Friends/Friends'
import LearningPath from './LearningPath/LearningPath'
import useModalDataStore from '../../Stores/UseModalDataStore'

export default function LearningPathModal() {
  const { setLearningPathModal, learningPathModal } = useUIStore()
  return (
    <Modal
      height={'700px'}
      width={'760px'}
      isOpen={learningPathModal}
      onClose={() => setLearningPathModal(false)}
      allowEsc
      contentClassname="!rounded-2.5xl mx-4 z-3000 !bg-moon-darkest text-white overflow-y-scroll"
    >
      <LearningPath />
    </Modal>
  )
}
