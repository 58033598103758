'use client'

import { useRouter, useSearchParams } from 'next/navigation'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import Cookies from 'js-cookie'

export interface LoginFormValues {
  username: string
  password: string
}

interface LoginFormProps {}
export default function LoginForm({}: LoginFormProps) {
  const router = useRouter()
  const searchParams = useSearchParams()
  const stepNumber = searchParams.get('step') || '1'
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue: setFormValue,
    getValues,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  useEffect(() => {
    localStorage.removeItem('auth')
  }, [])

  return (
    <div className="flex h-full flex-col items-center px-8 py-6">
      {stepNumber === '1' && <Step1 />}
      {stepNumber === '2' && (
        <Step2 router={router} getValues={getValues} control={control} />
      )}
      {stepNumber === '3' && (
        <Step3
          handleSubmit={handleSubmit}
          control={control}
          getValues={getValues}
          router={router}
        />
      )}
    </div>
  )
}
