import { faChevronRight, faSearch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Hammer from 'react-hammerjs-18'

import { useSpring, animated, useTransition } from '@react-spring/web'

import Breadcrumbs from '../Breadcrumbs'
import MethodParameter from './MethodParameter'

import { options } from '../ToolboxLibraryObject'

import useRuntimeStore from '../../Hooks/Runtime/useRuntimeStore'
import DraggingPortal from '../../DraggingPortal'
import Method from './Method'
import { useMainEditorRef } from '../../Hooks/useMainEditorRef'
import Button from '@codeverse/helios/button'
import IconInput from '../IconInput'

interface SelectedToolboxObjectProps {
  categoryFilterValue: string
  setCategoryValue: any
}

const tags = ['Character', 'Sprite']

const SelectedToolboxObject: React.FC<SelectedToolboxObjectProps> = ({
  categoryFilterValue,
  setCategoryValue,
}) => {
  const { currentToolboxObject, setCurrentEvent, setCurrentMethod } =
    useRuntimeStore()
  const editorRef = useMainEditorRef()
  const [searchTerm, setSearchTerm] = React.useState('')
  const container = useRef()
  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }))
  const [{ opacity }, opacityApi] = useSpring(() => ({ opacity: 1 }))

  const [panning, setPanning] = useState(false)
  const containerRef = useRef(null)

  const [selectedTab, setSelectedTab] = React.useState<
    'methods' | 'events' | 'defaults'
  >('methods')

  const containerClassnames = classNames(
    'col-span-1 flex flex-col relative rounded-2xl shadow-lg'
    // {
    //   absolute: panning,
    // }
  )

  const Tags = useMemo(() => {
    return tags.map((tag, index) => (
      <div
        className={classNames(
          'font-poppins-semibold text-neptune-dark text-sm uppercase',
          {
            'ml-2': index > 0,
          }
        )}
      >
        #{tag}
      </div>
    ))
  }, [tags])

  const kidscriptMethods = useMemo(() => {
    if (currentToolboxObject) {
      return currentToolboxObject.getMethods()
    }
  }, [currentToolboxObject])
  const kidscriptEvents = useMemo(() => {
    if (currentToolboxObject) {
      return currentToolboxObject.getEvents()
    }
  }, [currentToolboxObject])
  const kidscriptDefaults = useMemo(() => {
    if (currentToolboxObject) {
      return currentToolboxObject.getMethods()
    }
  }, [currentToolboxObject])

  const RenderEvents = useMemo(() => {
    if (kidscriptEvents) {
      // console.log('kidscriptEvents', kidscriptEvents)
      return Object.keys(kidscriptEvents).map((key) => {
        const kidscriptEvent = kidscriptEvents[key]
        if (kidscriptEvent.internal) return

        if (
          searchTerm?.length > 0 &&
          !kidscriptEvent.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return null
        }

        const parameters = kidscriptEvent.parameters.map((parameter, index) => {
          const id = `${kidscriptEvent}-${parameter.name}`
          return (
            <div className="relative inline">
              <span
                className="text-titan cursor-pointer"
                data-tip
                data-for={id}
              >
                {parameter.name}
              </span>
              <ReactTooltip
                className="!text-moon-darkest font-poppins-regular !z-[3000] !bg-white !opacity-100 shadow-xl"
                place="top"
                // effect="solid"
                id={id}
                delayHide={200}
                arrowColor="#FFF"
              >
                <div>{capitalize(parameter.description)}</div>
              </ReactTooltip>
              {index < kidscriptEvent.parameters.length - 1 ? ', ' : ''}
            </div>
          )
        })

        return (
          <li
            className="border-moon-light outline-moon-dark group relative grid cursor-pointer grid-cols-12 border-t bg-white px-6 py-7"
            onClick={() => {
              setCurrentEvent(kidscriptEvent)
              // setSelectedMethod()
            }}
          >
            <div className="col-span-11 flex-grow rounded-lg p-2 text-black">
              <span className="font-sf-pro-text-bold">
                {kidscriptEvent.name}({parameters})
              </span>
              <div className="text-moon-dark">{kidscriptEvent.description}</div>
            </div>
            <div className="col-span-1 flex items-center">
              <div className="bg-moon-light text-neptune flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition-all group-hover:scale-125">
                <FontAwesomeIcon size="lg" icon={faChevronRight} />
              </div>
            </div>
          </li>
        )
      })
    }
  }, [kidscriptEvents, searchTerm])

  // const RenderDefaults = useMemo(() => {
  //   if (kidscriptMethods) {
  //     return Object.keys(kidscriptMethods).map((key) => {
  //       const kidscriptMethod = kidscriptMethods[key]
  //       if (kidscriptMethod.internal) return

  //       const kidscriptHtml = KidScript.parseBlock(
  //         kidscriptMethod.getPreview()
  //       ).toHTML()

  //       if (
  //         searchTerm?.length > 0 &&
  //         !kidscriptMethod.name.includes(searchTerm)
  //       ) {
  //         return null
  //       }

  //       return (
  //         <div className="rounded-8 my-8 flex flex-shrink-0 cursor-pointer flex-row bg-white p-8">
  //           <div className="bg-moon-darkest rounded-8 flex-grow p-8">
  //             <pre
  //               className="kidscript-syntax"
  //               dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
  //             />
  //             {kidscriptMethod.description}
  //           </div>
  //         </div>
  //       )
  //     })
  //   }
  // }, [kidscriptMethods, searchTerm])

  const RenderMethods = useMemo(() => {
    if (kidscriptMethods) {
      return Object.keys(kidscriptMethods).map((key) => {
        const kidscriptMethod = kidscriptMethods[key]
        // console.log('kidscriptMethod', kidscriptMethod)
        if (kidscriptMethod.internal) return

        const kidscriptHtml = KidScript.parseBlock(
          kidscriptMethod.getPreview()
        ).toHTML()
        const kidscriptMethodName = kidscriptMethod.name

        if (
          searchTerm?.length > 0 &&
          !kidscriptMethod.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return null
        }

        const parameters = kidscriptMethod.parameters.map(
          (parameter, index) => {
            parameter.getConstant()
            return (
              <MethodParameter
                kidscriptMethodName={kidscriptMethodName}
                parameter={parameter}
                index={index}
                kidscriptMethod={kidscriptMethod}
              />
            )
          }
        )

        return (
          <Method
            setCurrentMethod={setCurrentMethod}
            kidscriptMethod={kidscriptMethod}
            parameters={parameters}
          />
        )
      })
    }
  }, [kidscriptMethods, searchTerm])

  if (!currentToolboxObject) return null

  const defaultTabClassnames =
    'flex justify-center font-poppins-semibold py-1 cursor-pointer'

  const methodsClassname = classNames(defaultTabClassnames, {
    'text-moon-darkest bg-white rounded-2xl ring ring-sun-darkest':
      selectedTab === 'methods',
    'text-moon': selectedTab !== 'methods',
  })
  const eventsClassname = classNames(defaultTabClassnames, {
    'text-moon-darkest bg-white rounded-2xl ring ring-sun-darkest':
      selectedTab === 'events',
    'text-moon': selectedTab !== 'events',
  })
  const defaultsClassname = classNames(defaultTabClassnames, {
    'text-moon-darkest bg-white rounded-2xl ring ring-sun-darkest':
      selectedTab === 'defaults',
    'text-moon': selectedTab !== 'defaults',
  })

  const handleImport = (object: any) => {
    const varName = editorRef?.documents.classNameToAvailableVariableName(
      object.name
    )
    let code = ''
    if (
      object.name === 'Timer' ||
      object.name === 'SoundEffects' ||
      object.name === 'Leaderboard'
    ) {
      code = `var ${varName} = new ${object.name}()`
    } else {
      code = `var ${varName} = new ${object.name}(50, 50)`
    }
    editorRef?.codeChanger.insert(code, [object.name])
    toast.success(`Imported ${object.name} as var ${varName}`, {
      position: 'bottom-center',
    })
  }

  const handlePan = (event, object) => {
    if (panning) {
      const { x, y } = event.center
      //@ts-ignore
      const { left } = document
        .getElementById('runtimeEnvironment')
        .getBoundingClientRect()
      const scale = x - (left - 50)
      opacityApi.start({ opacity: 1 - scale / 100 })
      api.start({
        //@ts-ignore
        x: x - containerRef.current.domElement.offsetWidth / 2,
        //@ts-ignore
        y: y - containerRef.current.domElement.offsetHeight / 2,
        immediate: true,
      })
      const { ratio } = window.game.renderer.scale
      if (event.isFinal) {
        setPanning(false)
        //@ts-ignore
        const { x: canvasX, y: canvasY } = document
          .getElementById('preview')
          .getBoundingClientRect()
        const ratio = window.game.renderer.scale.ratio
        const { x, y } = event.center
        const left = Math.round(
          //@ts-ignore
          editorRef.preview.renderer.scale.leftPositionPixelsToCoords(
            (x - canvasX) / ratio
          )
        )
        const top = Math.round(
          //@ts-ignore
          editorRef.preview.renderer.scale.topPositionPixelsToCoords(y / ratio)
        )
        addObject(event, object, left, top)
      }
    }
  }

  const handlePressUp = (event, object) => {
    setPanning(false)
  }

  const handlePress = (event, object) => {
    // setCurrentDragObject(object.name)
    setPanning(true)
  }

  const handleClick = (event, object) => {}

  const addObject = (event, object, left = 50, top = 50) => {
    //@ts-ignore
    const varName = editorRef.documents.classNameToAvailableVariableName(
      object.name
    )

    if (left >= 0 && top >= 0) {
      if (object.engine.instantiatable) {
        let code = ''
        if (
          object.name === 'Timer' ||
          object.name === 'SoundEffects' ||
          object.name === 'Leaderboard'
        ) {
          code = `var ${varName} = new ${object.name}()`
        } else {
          code = `var ${varName} = new ${object.name}(${left}, ${top})`
        }
        editorRef?.codeChanger.insert(code, [object.name])
      } else {
        editorRef?.codeChanger.insert(null, [object.name])
      }
      // if (typeof setDropProgressState === 'function') {
      //   setDropProgressState(
      //     Object.assign({}, dropProgressState, {
      //       [object.name]: {
      //         dropped: true,
      //         objectName: object.name,
      //         position: position,
      //       },
      //     })
      //   )
      // }
    }
    // setShowToolbox(false)
  }

  return (
    <div
      className="bg-moon-lightest absolute inset-0 z-[3333] overflow-y-scroll"
      // style={{ height: 'calc(100% - 50px)' }}
    >
      <Breadcrumbs
        setCategoryValue={setCategoryValue}
        categoryFilterValue={categoryFilterValue || 'All Objects'}
        setSearchValue={setSearchTerm}
      />

      <div
        className="rounded-8 mx-4 mt-4 p-6"
        style={{ backgroundColor: '#DDE9F4' }}
      >
        <div className="grid grid-cols-3 gap-6">
          <Hammer
            onPan={(event) => handlePan(event, currentToolboxObject)}
            onPanStart={(event) => handlePress(event, currentToolboxObject)}
            onPressUp={(event) => handlePressUp(event, currentToolboxObject)}
            onClick={(event) => handleClick(event, currentToolboxObject)}
            options={options}
            className={containerClassnames}
            style={{
              height: '0%',
              paddingTop: '130%',
              // x,
              // y,
              cursor: 'pointer',
              touchAction: 'none',
            }}
            ref={containerRef}
            key={currentToolboxObject.name}
            draggable
          >
            <div id="ToolboxLibraryObject" className="flex flex-auto">
              <div className="img">
                <div className="absolute inset-0 flex h-full flex-col items-center justify-end rounded-lg bg-white px-4">
                  {!panning && (
                    <img
                      className="mb-4 pt-3"
                      draggable={false}
                      style={{
                        maxWidth: '70%',
                        height: '70%',
                        userSelect: 'none',
                      }}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        currentToolboxObject?.getIcon()
                      )}`}
                      alt=""
                    />
                  )}
                  <Button
                    className="mb-4 w-full px-9"
                    size="small"
                    context="tertiary"
                    onClick={() => {
                      handleImport(currentToolboxObject)
                    }}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </div>
          </Hammer>
          <div className="col-span-2">
            <span className="font-poppins-bold text-moon-darkest text-[32px]">
              {currentToolboxObject.name}
            </span>
            <div className="mt-8 flex">{Tags}</div>

            <div
              className="font-poppins-medium mt-2 text-base"
              style={{ color: '#474C72' }}
            >
              {currentToolboxObject.description}
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-4.5xl mx-4 mb-6 mt-6 flex min-h-0 flex-grow flex-col bg-white py-4">
        <div className="bg-moon-light rounded-4.5xl mx-4 grid flex-shrink-0 grid-cols-2 p-2">
          <div
            className={methodsClassname}
            onClick={() => {
              setSelectedTab('methods')
              setSearchTerm('')
            }}
          >
            Methods
          </div>
          <div
            className={eventsClassname}
            onClick={() => {
              setSelectedTab('events')
              setSearchTerm('')
            }}
          >
            Events
          </div>
          {/* <div
            className={defaultsClassname}
            onClick={() => setSelectedTab('defaults')}
          >
            Defaults
          </div> */}
        </div>

        <div className="font-poppins-medium text-moon-darkest mb-4 mt-4 flex-shrink-0 px-4 text-sm">
          Explore all methods available to use with the{' '}
          {currentToolboxObject.name} object
        </div>

        <div className="flex-shrink-0 px-4">
          <div className="mb-4">
            <IconInput
              inputClassname="!bg-moon-lightest text-moon-dark !h-12"
              className="flex justify-center"
              iconPosition="left-3 top-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              icon={faSearch}
              placeholder="Search Anything"
              iconClassname="text-moon"
            />
          </div>
        </div>

        {selectedTab === 'methods' && <ul className="">{RenderMethods}</ul>}
        {selectedTab === 'events' && <ul className="">{RenderEvents}</ul>}
        {selectedTab === 'defaults' && <ul className="">{RenderMethods}</ul>}

        {panning && (
          <DraggingPortal>
            {
              <animated.div
                className="rounded-8 text-nebula-darkest"
                id="DraggingLibraryObject"
                style={{
                  x,
                  y,
                  height: containerRef.current
                    ? //@ts-ignore
                      containerRef.current.domElement.offsetHeight
                    : 0,
                  width: containerRef.current
                    ? //@ts-ignore
                      containerRef.current.domElement.offsetWidth
                    : 0,
                }}
              >
                <animated.div
                  className="absolute inset-0 rounded-lg bg-white"
                  style={{ opacity }}
                />
                <img
                  className="absolute left-1/2 top-1/2"
                  draggable={false}
                  style={{
                    maxWidth: '70%',
                    transform: 'translate(-50%, -63%)',
                    maxHeight: '96px',
                    userSelect: 'none',
                  }}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    currentToolboxObject.getIcon()
                  )}`}
                  alt=""
                />
              </animated.div>
            }
          </DraggingPortal>
        )}
      </div>
    </div>
  )
}

export default SelectedToolboxObject
