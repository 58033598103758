//@ts-nocheck
import { useEffect, useRef, useState } from 'react'

export function useStateCB(initialState) {
  const [state, setState] = useState(initialState)
  const stateRef = useRef(initialState)

  useEffect(() => {
    if (setState.callback) {
      setState.callback(state)
      setState.callback = null
    }
  }, [state])

  function newSetState(newState, callback) {
    if (callback) setState.callback = callback
    stateRef.current = newState
    setState(newState)
  }

  function getState() {
    return stateRef.current
  }

  return [getState, newSetState]
}

export function useSingleState(initialStateObj) {
  const [getState, setState] = useStateCB(initialStateObj)
  const stateObj = useRef({ ...initialStateObj }).current

  if (!stateObj._definedProperty) {
    Object.keys(stateObj).forEach((key) => {
      if (key) {
        Object.defineProperty(stateObj, key, {
          get() {
            return getState()[key]
          },
        })
      }
    })
    stateObj._definedProperty = true
  }

  function newSetState(partialStates, callback) {
    setState({ ...getState(), ...partialStates }, callback)
  }

  return [stateObj, newSetState]
}

export default useSingleState
