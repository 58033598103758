'use client'

import React, { useEffect } from 'react'
import useSWR from 'swr'

import { useRouter } from 'next/navigation'
import { useQuery } from '@tanstack/react-query'
import fetchGqlClient from 'apps/studio-shared/src/Data/fetch/fetchClientGql'
import { gql } from 'graphql-request'
import Cookies from 'js-cookie'

interface UserModel {
  id: string
  name: string
  scope: string
  yearsOld: number
  followerCount: number
  followingCount: number
  referralCode: string
  totalScore: number
  streamToken: string
  avatar: {
    id: string
    file: string
  }
  curriculumScores: {
    points: number
    skill: {
      id: string
    }
  }
  bankBalance: {
    coins: number
  }
  defaultOrganization: {
    id: string
    name: string
    status: string
    classification: string
    firstJourney: string
    referredBy: {
      id: string
      username: string
    }
  }
  keyringGrants: Array<{
    id: string
    libraryKeyring: {
      id: string
    }
  }>
}

type User = {
  isLoggedIn: boolean

  me: UserModel
}

export default function useSession({
  redirectTo = '/',
  redirectIfFound = false,
} = {}) {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}')
  const {
    data: user,
    isFetched,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['users', auth.currentUserId],
    queryFn: () =>
      fetchGqlClient()
        .request(
          gql`
            {
              me {
                id
                name
                scope
                yearsOld
                followerCount
                followingCount
                referralCode
                avatar {
                  id
                  file
                }
                totalScore
                curriculumScores {
                  points
                  skill {
                    id
                  }
                }
                bankBalance {
                  coins
                }
                keyringGrants {
                  id
                  libraryKeyring {
                    id
                  }
                }
                defaultOrganization {
                  id
                  name
                  status
                  classification
                  firstJourney
                  referredBy {
                    id
                    username
                  }
                  # subscriptions(
                  #   statuses: [
                  #     PENDING
                  #     TRIAL
                  #     ACTIVE
                  #     UPDATING_IN_BRAINTREE
                  #     ACTIVE
                  #   ]
                  #   perPage: 1
                  # ) {
                  #   id
                  #   startAt
                  #   status
                  #   subscriptionAddOns(perPage: 1) {
                  #     id
                  #     product
                  #   }
                  # }
                }
              }
            }
          `
        )
        .then((data: User) => {
          return data.me
        }),
  })

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // console.log('user', user)
    // if (!redirectTo || !user) return
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   router.push('/')
    // }
  }, [user, redirectIfFound, redirectTo])

  return { user, isFetched, refetchUser }
}
