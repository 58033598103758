'use client'

import React from 'react'
import { H } from '@highlight-run/next/client'
import Button from '@codeverse/helios/button'
import Input from '@codeverse/helios/input'
import Cookies from 'js-cookie'
import { useRouter, useSearchParams } from 'next/navigation'
import { FormEvent, FormEventHandler, useEffect, useRef, useState } from 'react'
import {
  Control,
  Controller,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
} from 'react-hook-form'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons'
import { LoginFormValues } from './LoginForm'
import { toast } from 'react-toastify'
import Link from 'next/link'

declare global {
  interface Window {
    heap: any
  }
}

interface Step2Props {
  router: any
  getValues: UseFormGetValues<LoginFormValues>
  control: Control<LoginFormValues>
  handleSubmit: UseFormHandleSubmit<LoginFormValues>
}

export default function Step3({
  router,
  getValues,
  control,
  handleSubmit,
}: Step2Props) {
  const [loading, setLoading] = useState(false)
  const loginRef = useRef<HTMLInputElement>(null)
  const searchParams = useSearchParams()

  useEffect(() => {
    const { username } = getValues()
    if (!username) {
      const next = searchParams.get('next')
      if (next) {
        router.push(`/login?step=2&next=${encodeURI(next)}`)
      } else {
        router.push(`/login?step=2`)
      }
    }
  })

  const login = async (loginFormData: LoginFormValues) => {
    setLoading(true)
    let data
    if (loginFormData.username.includes('@')) {
      data = {
        email: loginFormData.username,
        password: loginFormData.password,
      }
    } else {
      data = {
        username: loginFormData.username,
        password: loginFormData.password,
      }
    }
    await fetch('https://api.codeverse.com/sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      body: JSON.stringify({
        data: {
          attributes: data,
          type: 'sessions',
        },
      }),
    })
      .then(async (response) => {
        setLoading(false)
        if (response.status === 201) {
          const data = (await response.json()) as any
          const accessToken = data.included[1].attributes['access_token']
          const userId = data.included[0].id
          const username = data.included[0].attributes.username
          window.heap?.identify(username)

          H?.identify(data.included[0].attributes.username, {
            id: data.included[0].id,
            name: data.included[0].attributes.name,
            email: data.included[0].attributes.email,
            scope: data.included[0].attributes.scope,
          })

          window.heap?.addUserProperties({
            id: userId,
            name: data.included[0].attributes.name,
            email: data.included[0].attributes.email,
            scope: data.included[0].attributes.scope,
          })

          // localStorage.setItem(
          //   'auth',
          //   JSON.stringify({
          //     accessToken,
          //     currentUserId: userId,
          //   })
          // )
          localStorage.setItem(
            'auth',
            JSON.stringify({
              accessToken,
              currentUserId: userId,
              username: username,
            })
          )
          const next = searchParams.get('next')
          router.push(next || '/')
        } else if (response.status === 401 || response.status === 404) {
          toast.error(
            'Failed to log in, please check your username or password',
            {
              position: 'bottom-center',
            }
          )
        } else {
          toast.error(
            'System error: Contact support@codeverse.com for more help',
            {
              position: 'bottom-center',
            }
          )
        }
      })
      .catch((error) => {
        toast.error(
          'System error: Contact support@codeverse.com for more help',
          {
            position: 'bottom-center',
          }
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    if (loginRef?.current) {
      loginRef.current.focus()
    }
  }, [loginRef])

  return (
    <div className="flex w-full flex-col justify-center">
      <form onSubmit={handleSubmit(login)}>
        <div
          className="font-jakarta-sans cursor-pointer text-sm font-semibold text-[#090E2B]"
          onClick={() => router.back()}
        >
          <FontAwesomeIcon className="pr-2" size="lg" icon={faArrowLeft} /> Go
          back
        </div>
        <div className="mt-4 flex justify-center">
          <Image
            src="/images/login/cv-logo-nova.svg"
            width={56}
            height={56}
            alt={''}
          />
        </div>
        <div className="flex items-center justify-center">
          <h4 className="mt-4 text-center">Enter your password</h4>
        </div>

        <div className="">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                size="regular"
                {...field}
                inputRef={loginRef}
                placeholder="Password"
                type="password"
              />
            )}
          />
        </div>

        {/* <div className="font-jakarta-sans flex flex-row items-center pt-4 text-base font-medium">
          <input type="checkbox" className="mr-2 h-6 w-6" />
          <span className="text-[#292E50]">Keep me logged in</span>
        </div> */}

        <Button
          size="large"
          disabled={loading}
          // type="submit"
          className="mt-6 w-full"
          context="neptune"
        >
          Next
        </Button>

        <div className="font-jakarta-sans pt-4 text-center">
          <p className="font-medium">
            By clicking sign in you agree to the <br />
            <Link
              id="Login_TermsOfService"
              href="https://www.codeverse.com/terms-of-service"
              className="cursor-pointer font-semibold text-[#292E50] underline"
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              id="Login_PrivacyPolicy"
              href="https://www.codeverse.com/privacy-policy"
              className="cursor-pointer font-semibold text-[#292E50] underline"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </form>
    </div>
  )
}
