'use client'

import Modal from '@codeverse/helios/modal'
import { useWindowSize } from '@codeverse/hooks'
import {
  faBookSpells,
  faCircleArrowRight,
  faFile,
  faGamepadAlt,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useUIStore from 'apps/studio-shared/src/Stores/useUIStore'
import { useRouter } from 'next/navigation'
import Confetti from 'react-confetti'
import { toast } from 'react-toastify'
interface GuidedMissionsProps {}
const colors = [
  '#745bff',
  '#ffc745',
  '#17e898',
  '#56ade4',
  '#ff57d0',
  '#fd8e86',
  '#8d98c6',
]

export default function MissionCompleteModal() {
  const {
    showMissionCompleteModal,
    setShowMissionCompleteModal,
    setNewProjectTemplateModal,
  } = useUIStore()
  const { currentMission } = useMissionStore()
  const size = useWindowSize()
  const router = useRouter()
  const queryClient = useQueryClient()
  return (
    <Modal
      width={'400px'}
      isOpen={showMissionCompleteModal}
      onClose={(e) => {
        e.stopPropagation()
      }}
      confetti={() => {
        return (
          <Confetti
            className="z-[9999]"
            width={size.width}
            height={size.height}
            initialVelocityY={1}
            colors={colors}
          />
        )
      }}
    >
      <div className="px-5 pb-4 pt-6">
        <div className="text-center">
          <h4>Great coding!</h4>

          {currentMission?.category === 'foundational' ? (
            <p>
              You’ve mastered the basics of {currentMission?.name}. Where would
              you like to go next?
            </p>
          ) : (
            <p>
              You've completed {currentMission?.name}. Where would you like to
              go next?
            </p>
          )}
        </div>

        <div className="mt-4">
          <div
            id="MissionCompleteModal_GoToMissions"
            className="h-18 hover:ring-neptune cursor-pointer rounded-[20px] bg-[#8D98C6] bg-opacity-10 pl-3 pr-[19px] ring ring-[#E9EFF8]"
            onClick={() => {
              toast.dismiss()
              setShowMissionCompleteModal(false)
              router.push('/missions')
              queryClient.invalidateQueries({
                queryKey: ['missions'],
              })
            }}
          >
            <div className="flex h-full items-center">
              <div className="flex h-10 w-10 items-center justify-center rounded-xl bg-white text-[#FF8500]">
                <FontAwesomeIcon icon={faBookSpells} />
              </div>
              <div className="flex flex-col pl-4">
                <h5 className="!mb-0 text-sm">Missions</h5>
                <p className="pt-1 text-xs">
                  Follow guided instructions <br /> to create a game
                </p>
              </div>
              <div className="ml-auto">
                <FontAwesomeIcon
                  className="text-[32px] text-[#1248F3]"
                  icon={faCircleArrowRight}
                />
              </div>
            </div>
          </div>
          <div
            id="MissionCompleteModal_GoToPlay"
            className="h-18 hover:ring-neptune mt-2 cursor-pointer rounded-[20px] bg-[#8D98C6] bg-opacity-10 pl-3 pr-[19px] ring ring-[#E9EFF8]"
            onClick={() => {
              toast.dismiss()
              setShowMissionCompleteModal(false)
              router.push('/play')
            }}
          >
            <div className="flex h-full items-center">
              <div className="text-titan-dark flex h-10 w-10 items-center justify-center rounded-xl bg-white">
                <FontAwesomeIcon icon={faGamepadAlt} />
              </div>
              <div className="flex flex-col pl-4">
                <h5 className="!mb-0 text-sm">Play</h5>
                <p className="pt-1 text-xs">
                  Explore apps and games <br /> built by coders like you
                </p>
              </div>
              <div className="ml-auto">
                <FontAwesomeIcon
                  className="text-[32px] text-[#1248F3]"
                  icon={faCircleArrowRight}
                />
              </div>
            </div>
          </div>
          <div
            id="MissionCompleteModal_GoToNewProject"
            className="h-18 hover:ring-neptune mt-2 cursor-pointer rounded-[20px] bg-[#8D98C6] bg-opacity-10 pl-3 pr-[19px] ring ring-[#E9EFF8]"
            onClick={() => {
              toast.dismiss()
              setShowMissionCompleteModal(false)
              router.push('/projects')
              setNewProjectTemplateModal(true)
            }}
          >
            <div className="flex h-full items-center">
              <div className="text-earth-darkest flex h-10 w-10 items-center justify-center rounded-xl bg-white">
                <FontAwesomeIcon icon={faFile} />
              </div>
              <div className="flex flex-col pl-4">
                <h5 className="!mb-0 text-sm">New Project</h5>
                <p className="pt-1 text-xs">
                  Apply what you’ve learned <br /> to something new
                </p>
              </div>
              <div className="ml-auto">
                <FontAwesomeIcon
                  className="text-[32px] text-[#1248F3]"
                  icon={faCircleArrowRight}
                />
              </div>
            </div>
          </div>

          <p
            id="MissionCompleteModal_GoToHome"
            onClick={() => {
              setShowMissionCompleteModal(false)
              router.push('/')
              queryClient.invalidateQueries({
                queryKey: ['missions'],
              })
            }}
            className="!mb-0 cursor-pointer pt-6 text-center font-semibold text-[#090E2B] underline"
          >
            Go back to the homepage
          </p>
        </div>
      </div>
    </Modal>
  )
}
