'use client'
// import { Button, Select } from '@codeverse/helios'
import {
  faArrowLeft,
  faMap,
  faPlus,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import Button from '@codeverse/helios/button'
import { useRouter, useSearchParams } from 'next/navigation'
import { Select } from '@codeverse/helios/select'

interface MissionBuilderListHeaderProps {
  // showGuideMissions: boolean
  // setShowGuideMissions: React.Dispatch<React.SetStateAction<boolean>>
  // setShowNewMission: React.Dispatch<React.SetStateAction<boolean>>
  showGuideMissions: boolean
  setShowGuideMissions: React.Dispatch<React.SetStateAction<boolean>>
}

const filterOptions = [
  { value: 'normal', label: 'Normal Missions' },
  { value: 'guide', label: 'Guide Missions' },
]
const missionFilterOptions = [
  { value: 'guided_build', label: 'Guided Build Missions' },
  { value: 'foundational', label: 'Foundational Missions' },
]

const MissionBuilderListHeader: React.FC<MissionBuilderListHeaderProps> = ({
  showGuideMissions,
  setShowGuideMissions,
}) => {
  const router = useRouter()
  const searchParams = useSearchParams()

  const defaultValue = useMemo(() => {
    return showGuideMissions ? filterOptions[1] : filterOptions[0]
  }, [showGuideMissions])

  const missionDefaultValue = useMemo(() => {
    const category = searchParams.get('category')
    return (
      missionFilterOptions.find((option) => option.value === category) ||
      missionFilterOptions[0]
    )
  }, [searchParams])

  return (
    <>
      <div className="">
        <div className="block pb-3">
          <Button
            size="small"
            className="!h-8"
            context="neptune"
            onClick={() => router.push('/')}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" className="mr-2" />
            Exit
          </Button>
        </div>
      </div>
      <div className="pb-4.5 flex flex-row">
        <div className="flex flex-grow items-center">
          <div className="inline">
            <div
              className="h-[40px] w-[60px]"
              // style={{ backgroundImage: `url(${MapWrench})` }}
            />
          </div>
          <h2 className="!mb-0 ml-3 inline text-[36px] text-white">
            Mission Builder
          </h2>
        </div>
        <div className="ml-auto">
          {/* <Button
            className="mr-16"
            context="nebula"
            onClick={() => navigate('/home')}
          >
            Home
          </Button> */}
          <div className="flex">
            <div className="mr-4">
              {/* <Select
                className="min-w-[154px]"
                defaultVal={defaultValue}
                onChange={() => {
                  setShowGuideMissions(!showGuideMissions)
                }}
                size="regular"
                options={filterOptions}
              /> */}
            </div>
            <Select
              className="min-w-[154px]"
              defaultVal={missionDefaultValue}
              onChange={(option) => {
                const params = new URLSearchParams(searchParams)
                params.set('category', option.value)

                router.push(`/mission_builder?` + params.toString())
              }}
              size="regular"
              options={missionFilterOptions}
            />
          </div>
        </div>
        <div className="ml-4">
          <Button
            size="small"
            className="h-[48px]"
            onClick={(e) => {
              const params = new URLSearchParams(searchParams)
              router.push(`/mission_builder/new?` + params.toString())
            }}
            context="tertiary"
          >
            <FontAwesomeIcon className="mr-2" size="lg" icon={faPlus} />
            New Mission
          </Button>
        </div>
      </div>
    </>
  )
}

export default MissionBuilderListHeader
