'use client'
import {
  faCube,
  faCourtSport,
  faCrosshairs,
  faCirclePlus,
} from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

const getIcon = (type: string) => {
  switch (type) {
    case 'Platform':
      return faCube
    case 'Action':
      return faCrosshairs
    case 'Sport':
      return faCourtSport
    case 'Blank':
      return faCirclePlus
    default:
      return faCube
  }
}

export default function Template({
  type,
  setSelectedTemplate,
  selectedTemplate,
}: {
  type: string
  setSelectedTemplate: any
  selectedTemplate: string | null
}) {
  const icon = getIcon(type)

  return (
    <div
      className={classNames(
        'hover:ring-neptune-light flex h-[106px] w-[106px] cursor-pointer flex-col items-center justify-center rounded-2xl bg-white hover:ring',
        {
          'ring-[2px] ring-[#a594ff]': type === selectedTemplate,
        }
      )}
      onClick={() => {
        setSelectedTemplate(type)
      }}
    >
      <FontAwesomeIcon className="text-[#a594ff]" size="2xl" icon={icon} />
      <div className="pt-2 text-center text-sm font-medium">{type}</div>
    </div>
  )
}
