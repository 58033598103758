export type NRDY_PARAMS = any

export const getNrdyParams = () => {
  const nrdy_params: NRDY_PARAMS = JSON.parse(
    localStorage.getItem('nrdyParams') || '{}'
  )
  return nrdy_params
}

export const parseNrdyParams = (params: any) => {
  const nrdyParams: any = {}
  for (const value of params.entries()) {
    if (value[0].includes('nrdy_')) {
      nrdyParams[`${value[0]}`] = value[1]
    }
  }
  return nrdyParams
}

export const setNrdyParams = (nrdyParams: any) => {
  localStorage.setItem('nrdyParams', JSON.stringify(nrdyParams))
}
