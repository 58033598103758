'use client'

import React, { useState } from 'react'
import { Tabs, TabsList, TabsTrigger } from '@codeverse/helios/tab'

import FoundationalMissionList from './FoundationMissionList'
import GuidedGamesMissionList from './GuidedGamesMissionList'

interface MissionPageProps {}

export type MissionTabState =
  | 'Beginner'
  | 'Intermediate'
  | 'Advanced'
  | 'Pro'
  | 'Expert'

const MissionPage: React.FC<MissionPageProps> = ({}) => {
  const [tabState, setTabState] = useState<MissionTabState>('Beginner')

  return (
    <>
      <div>
        <Tabs
          className="rounded-2.5xl mb-8 mt-0 h-full"
          //@ts-ignore
          onValueChange={(val: FollowTabState) => {
            setTabState(val)
          }}
          value={tabState}
        >
          <TabsList theme="dark" className=" grid w-full">
            <TabsTrigger
              theme="darkLight"
              className="group relative"
              value="Beginner"
            >
              Beginner
            </TabsTrigger>

            <TabsTrigger
              theme="darkLight"
              className="group"
              value="Intermediate"
            >
              Intermediate
            </TabsTrigger>
            <TabsTrigger
              theme="darkLight"
              className="group relative"
              value="Advanced"
            >
              Advanced
            </TabsTrigger>

            <TabsTrigger theme="darkLight" className="group" value="Expert">
              Expert
            </TabsTrigger>
            <TabsTrigger theme="darkLight" className="group" value="Pro">
              Pro
            </TabsTrigger>
          </TabsList>
        </Tabs>
        {tabState === 'Beginner' && (
          <>
            <div className="mb-9 mt-0 flex flex-col">
              <h4 className="font-jakarta-sans mb-0 inline pb-1 text-2xl text-[20px] font-medium leading-[24px] text-white">
                Start here
              </h4>
              <p className="font-jakarta-sans inline text-base font-medium text-white opacity-70">
                Quickly see how to bring your ideas to life (1-3 min)
              </p>
            </div>

            <FoundationalMissionList difficultyLevel={tabState} />
          </>
        )}
      </div>
      <div className="pt-8">
        <div className="mb-6 mt-0 flex flex-col">
          <h4 className="font-jakarta-sans mb-0 inline pb-1 text-2xl text-[20px] font-medium leading-[24px] text-white">
            Build a Game
          </h4>
          <p className="font-jakarta-sans inline text-base text-white opacity-70">
            Step-by-step interactive guides for creating awesome games
          </p>
        </div>
        <GuidedGamesMissionList difficultyLevel={tabState} />
      </div>
    </>
  )
}

export default MissionPage
