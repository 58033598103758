import { SatelliteClient } from '@codeverse/satellite-client'
import Cookies from 'js-cookie'
import fetchClientGqlPublic from '../fetch/fetchClientGqlPublic'
import { gql } from 'graphql-request'

export const getAvatars = async () => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null

  return SatelliteClient.platform.get(`/avatars`, {
    query: {
      current_user_id: currentUserId,
    },
  })
}

export const getAvatarsPublic = async () => {
  return await fetchClientGqlPublic().request(gql`
    {
      avatars {
        id
        file
      }
    }
  `)
}
