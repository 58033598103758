import { gql } from 'graphql-request'

export const userPointsQuery = () => {
  return gql`
    {
      me {
        id
        curriculumScores {
          points
          skill {
            id
          }
        }
        bankBalance {
          coins
        }
      }
    }
  `
}
