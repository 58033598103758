import moment from 'moment-timezone'
const determineTimezone = () => {
  let timezone = ''
  const zonenameAbbr = moment.tz(moment.tz.guess()).zoneAbbr()
  switch (zonenameAbbr) {
    case 'CST':
      timezone = 'America/Chicago'
      break
    case 'MST':
      timezone = 'America/Denver'
      break
    case 'PST':
      timezone = 'America/Los_Angeles'
      break
    case 'EST':
      timezone = 'America/New_York'
      break
    default:
      timezone = 'America/Chicago'
      break
  }
  return timezone
}
export default determineTimezone
