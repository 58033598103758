import { VariantProps, cva } from 'class-variance-authority'

export const pillStyles = cva(
  ['font-jakarta-sans inline-flex items-center px-2 py-2 font-medium'],
  {
    variants: {
      size: {
        small: 'h-8 w-auto rounded-[20px] text-sm',
        large: 'h-10 w-auto rounded-[20px] text-lg',
      },
      context: {
        primary: 'bg-white text-black',
        secondary: 'bg-secondary-500 text-white',
        tertiary: 'bg-tertiary-500 text-white',
        success: 'bg-success-500 text-white',
        danger: 'bg-danger-500 text-white',
        warning: 'bg-warning-500 text-white',
        info: 'bg-info-500 text-white',
        light: 'bg-light-500 text-white',
        dark: 'border border-white/10 bg-[#1c2141] text-white',
        white: 'text-dark bg-white',
      },
    },
  }
)

export type ButtonVariantProps = VariantProps<typeof pillStyles>
