'use client'

import { useQuery } from '@tanstack/react-query'
import fetchClient from 'apps/studio-shared/src/Data/fetch/fetchClient'

export interface Mission {
  type: string
  id: string
  attributes: {
    name: string
    description: string
    points: number
    coins: number
    minutes: number
    difficulty_level: string
    hidden: boolean
    series: string
    icon: string
    icon_2x: string
    iconthumb: string
    iconthumb_2x: string
    icongrid: string
    icongrid_2x: string
    published: boolean
    guide_only: boolean
    required_multiple_choice_question_count: number
    category: string
    position: number
  }
  links: {
    self: string
  }
  relationships: {
    library_keyring: {
      links: {
        related: string
      }
      data: null
    }
  }
  meta: {
    deletable: boolean
    deleted_at: null | string
  }
}

const useMission = (id: string) => {
  const fetchMission = async (missionId: string) => {
    const response = await fetchClient({
      url: `/missions/${missionId}`,
      method: 'GET',
    })
    return response.data as Mission
  }

  return useQuery({
    queryKey: ['mission', id],
    queryFn: () => fetchMission(id),
    enabled: !!id,
  })
}

export default useMission
