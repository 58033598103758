import { Transition } from '@headlessui/react'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import Info from './MissionSteps/Info'
import Footer from './MissionStepCardFooter'
import CurrentStep from './CurrentStep'

interface PortalContentProps {}

const PortalContent: React.FC<PortalContentProps> = () => {
  const { currentStep } = useMissionStore()
  const { showMissionEditor } = useMissionBuilderStore()
  const { showPortal } = useMissionStore()

  const containerClassnames = classNames(
    'bg-white h-auto bg-gradient-to-b pt-6 rounded-r-3xl',
    {
      'rounded-3xl overflow-hidden': currentStep?.display === 'modal',
    }
  )

  return (
    <div>
      <Transition
        key={currentStep?.id}
        show={showPortal}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={containerClassnames}>
          <CurrentStep />
          <Footer />
        </div>
      </Transition>
    </div>
  )
}

export default PortalContent
