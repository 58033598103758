import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface KeyRingStore {
  currentKeyRing: any
  showKeyring: boolean
  setCurrentKeyRing: (currentKeyRing: any) => void
  resetKeyRing: () => void
  setShowKeyring: (showKeyring: boolean) => void
}

const useKeyRingStore = create<KeyRingStore>()(
  devtools(
    (set) => ({
      currentKeyRing: null,
      setCurrentKeyRing: (currentKeyRing: any) =>
        set((state) => ({
          currentKeyRing: currentKeyRing,
        })),
      resetKeyRing: () =>
        set((state) => ({
          currentKeyRing: null,
        })),
      showKeyring: false,
      setShowKeyring: (showKeyring: boolean) =>
        set((state) => ({ showKeyring: showKeyring })),
    }),
    {
      name: 'keyRing Store',
    }
  )
)

export default useKeyRingStore
