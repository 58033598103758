import React, { useEffect, useMemo, useState } from 'react'
import ToolboxLibraryObject from '../../../Studio/Toolbox/ToolboxLibraryObject'
import { useMissionStepInsertables } from 'apps/studio-shared/src/Data'
import useRuntimeStore from '../../../Studio/Hooks/Runtime/useRuntimeStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'

interface LibraryObjectsProps {}

const ValidObjectNames = ['LaunchTower', 'Rocket', 'LargePlatform']

const LibraryObjects: React.FC<LibraryObjectsProps> = () => {
  const [showToolbox, setShowToolbox] = useState(false)
  const [currentDragObject, setCurrentDragObject] = useState(null)

  const { currentStep, setBlockNextStep } = useMissionStore()
  const { missionInsertablesToCreate } = useMissionBuilderStore()

  const objects = useMemo(() => {
    //@ts-ignore
    return KidScript?.library.objects
  }, [])

  const { data: MissionStepInsertablesData } = useMissionStepInsertables(
    currentStep?.id
  )

  const [dropProgressState, setDropProgressState] = useState({})

  const allObjectsDragged = useMemo(() => {
    if (dropProgressState && Object.keys(dropProgressState).length > 0) {
      return Object.keys(dropProgressState).every(
        (val) => dropProgressState[val].dropped
      )
    }
    return false
  }, [dropProgressState])

  useEffect(() => {
    setTimeout(() => {
      setBlockNextStep(true)
    }, 100)
    return () => {
      setDropProgressState({})
    }
  }, [])

  useEffect(() => {
    if (allObjectsDragged) {
      setBlockNextStep(false)
    }
  }, [allObjectsDragged])

  const objectsToFilter = useMemo(() => {
    if (MissionStepInsertablesData) {
      return MissionStepInsertablesData.filter(
        (insertable) => insertable.display.toLowerCase() === 'object'
      ).map((insertable) => ({
        object: insertable.object,
        position: insertable.position,
        insertable: insertable,
      }))
    } else if (missionInsertablesToCreate?.length > 0) {
      return missionInsertablesToCreate
        .filter((insertable) => insertable.display.toLowerCase() === 'object')
        .map((insertable) => ({
          object: insertable.object,
          position: insertable.position,
          insertable: insertable,
        }))
    }
    return null
  }, [MissionStepInsertablesData])

  const filteredObjects = useMemo(() => {
    const filteredObjectsTemp = []
    const tempDropProgressState = {}
    Object.keys(objects).map((objectKeyName) => {
      const hasObject = objectsToFilter?.find(
        (obj) => obj.object === objectKeyName
      )
      if (
        objectsToFilter &&
        objects[objectKeyName].engine &&
        objects[objectKeyName].engine.importable &&
        hasObject
      ) {
        //@ts-ignore
        tempDropProgressState[objectKeyName] = {
          dropped: false,
          position: hasObject.position,
          objectName: objectKeyName,
        }
        //@ts-ignore
        filteredObjectsTemp.push({
          object: objects[objectKeyName],
          position: hasObject.position,
          objectName: objectKeyName,
          insertable: hasObject.insertable,
        })
      }
    })
    setDropProgressState(tempDropProgressState)
    //@ts-ignore
    return filteredObjectsTemp.sort((a, b) => a.position - b.position)
  }, [objects, objectsToFilter])

  useEffect(() => {
    if (filteredObjects && filteredObjects.length > 0) {
      setBlockNextStep(true)
    }
  }, [filteredObjects])

  if (filteredObjects.length === 0) {
    return null
  }

  return (
    <div className="mb-6">
      <div className="mx-6 rounded-2xl bg-[#8D98C6] bg-opacity-10 p-3">
        <div className="relative grid h-auto grid-cols-4 gap-4">
          {filteredObjects.map((object) => (
            <ToolboxLibraryObject
              showToolbox={showToolbox}
              setShowToolbox={setShowToolbox}
              //@ts-ignore
              key={object.object.name}
              //@ts-ignore
              object={object.object}
              //@ts-ignore
              insertable={object.insertable}
              //@ts-ignore
              position={object.position}
              setCurrentDragObject={setCurrentDragObject}
              currentDragObject={currentDragObject}
              dropProgressState={dropProgressState}
              setDropProgressState={setDropProgressState}
              type="mission"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LibraryObjects
