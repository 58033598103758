'use client'

import Markdown from '../../../Studio/CodeReference/MarkdownPreview'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'

export default function Body({ body }: { body: string | undefined }) {
  const mainEditorRef = useMainEditorRef()
  if (!body) return null
  return (
    <div className="mt-4 rounded-2xl bg-[#8D98C6] bg-opacity-10 p-4 text-sm text-black">
      <Markdown>{mainEditorRef?.applyTemplate(body || '')}</Markdown>
    </div>
  )
}
