'use client'

import Markdown from '../../../Studio/CodeReference/MarkdownPreview'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'

export default function Content({ content }: { content: string | undefined }) {
  const mainEditorRef = useMainEditorRef()
  if (!content) return null
  return (
    <div>
      <Markdown>{mainEditorRef?.applyTemplate(content || '')}</Markdown>
    </div>
  )
}
