import { useMissionStepInsertables } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import React, { useEffect, useMemo } from 'react'
import StepHeader from './StepHeader'
import { useRuntimeRefsStore } from '../../../Studio/Hooks/Runtime/useRuntimeRefsStore'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'
import { Markdown } from '@codeverse/helios/markdown'
import MissionStepTips from './MissionStepTips'
import KidScriptSnippet from './KidScriptSnippet'
import LibraryObjects from './LibraryObjects'
import { Card, CardBody } from '@codeverse/helios/card'
import MarkdownPreview from '../../../Studio/CodeReference/MarkdownPreview'
import Body from './Body'
import Content from './Content'
import Detail from './Detail'
import { usePathname } from 'next/navigation'

interface CodeProps {}

const Code: React.FC<CodeProps> = () => {
  // const { state: GuidedMissionState, setBlockNextStep } = useGuidedMissions()
  // const { editorRef } = useRuntime()
  const pathname = usePathname()
  const { missionInsertablesToCreate } = useMissionBuilderStore()
  const { currentStep, initialReplayMissionSteps, setBlockNextStep } =
    useMissionStore()

  const isMissionBuilder = pathname.includes('mission_builder')
  const { data: MissionStepInsertablesData } = useMissionStepInsertables(
    currentStep?.id
  )

  const mainEditorRef = useMainEditorRef()

  useEffect(() => {
    if (!isMissionBuilder) {
      setTimeout(() => {
        setBlockNextStep(false)
      }, 3000)
    } else {
      setBlockNextStep(false)
    }
  }, [])

  const showLibrary = useMemo(() => {
    const insertables = MissionStepInsertablesData || missionInsertablesToCreate
    return (
      insertables?.filter(
        (insertable: any) => insertable.display.toLowerCase() === 'object'
      ).length > 0
    )
  }, [currentStep, MissionStepInsertablesData])

  if (!initialReplayMissionSteps || !currentStep) return null

  return (
    <div className="select-none">
      <StepHeader
        title={mainEditorRef?.applyTemplate(currentStep.header) || ''}
        position={(currentStep?.position || 0) + 1}
      />

      <div className="px-6 pb-4">
        <Content content={currentStep?.content} />

        <Body body={currentStep?.body} />

        {currentStep.image && (
          <div className="mt-6 flex justify-center">
            <img
              src={currentStep.image}
              alt="MissionStepImage"
              className="aspect-auto max-h-[500px] max-w-[600px]	rounded-3xl"
            />
          </div>
        )}

        <Detail detail={currentStep?.detail} />

        <MissionStepTips />
      </div>

      {showLibrary && <LibraryObjects />}
      <KidScriptSnippet />
    </div>
  )
}

export default Code
