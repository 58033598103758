'use client'
import { useStarredGames } from 'apps/studio-shared/src/Data/User/UserDataHooks'
import { useSearchParams } from 'next/navigation'
import React from 'react'
import StarredGame from './StarredGame'
import times from 'lodash/times'
import EmptyCard from './EmptyCard'

export default function StarredGames() {
  const params = useSearchParams()
  const username = params.get('username') as string
  const { data: starredGames } = useStarredGames(username)

  return (
    <div className="grid grid-cols-6">
      {starredGames?.user?.versionStars.map((app: any, index: any) => {
        return <StarredGame index={index} starredGame={app} />
      })}
      {starredGames?.user?.versionStars?.length === 0 &&
        times(12).map((i) => {
          return <EmptyCard index={i} />
        })}
    </div>
  )
}
