'use client'
import {
  faBookSpells,
  faGamepadAlt,
  faHome,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from '@codeverse/cn'
import Image from 'next/image'
import AvatarIcon from '../AvatarIcon'
import NotificationMenu from './NotificationMenu'
import { TabsList, TabsTrigger } from '@codeverse/helios/tab'
import fetchApi from 'apps/studio/utils/fetch/fetchApi'
import Cookies from 'js-cookie'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import fetchClient from 'apps/studio-shared/src/Data/fetch/fetchClient'
import Button from '@codeverse/helios/button'

export default function Navbar({}) {
  return (
    <div className="mx-6 flex items-center">
      <TabsList
        className="hidden w-full shadow-lg lg:grid"
        style={{ backgroundColor: '#101432' }}
      >
        <TabsTrigger
          className="group relative"
          value="home"
          id="NavbarTab_Home"
        >
          {/* <FontAwesomeIcon
              className={cn('mr-1 group-hover:opacity-100', {
                'opacity-0': tabState !== 'home',
              })}
              icon={faHome}
              size="xs"
              color="#FF57D0"
            /> */}
          Home
        </TabsTrigger>

        <TabsTrigger className="group" value="projects" id="NavbarTab_Projects">
          <>
            {/* <Image
                className={cn('mr-1 group-hover:opacity-100', {
                  'opacity-0': tabState !== 'projects',
                })}
                src="/images/home/icon-ide-projects.svg"
                width={10}
                height={10}
                alt="Icon Projects"
              /> */}
            Projects
          </>
        </TabsTrigger>
        <TabsTrigger className="group" value="missions" id="NavbarTab_Missions">
          <>
            {/* <FontAwesomeIcon
                className={cn('mr-1 group-hover:opacity-100', {
                  'opacity-0': tabState !== 'missions',
                })}
                size="sm"
                icon={faBookSpells}
                color="#FF8500"
              /> */}
            Missions
          </>
        </TabsTrigger>
        <TabsTrigger className="group" value="play" id="NavbarTab_Play">
          <>
            {/* <FontAwesomeIcon
                className={cn('mr-1 group-hover:opacity-100', {
                  'opacity-0': tabState !== 'play',
                })}
                size="sm"
                icon={faGamepadAlt}
                color="#52BCFF"
              /> */}
            Play
          </>
        </TabsTrigger>
      </TabsList>
    </div>
  )
}
