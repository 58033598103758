import { faSquareUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Variants, motion } from 'framer-motion'
import React from 'react'

interface DropdownItemProps {
  text: string
  id?: string
  icon?: any
  isOpen?: boolean
  handleClick: (e: any) => void
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
}

const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  const { text, icon, handleClick, id, isOpen } = props

  const itemClassnames = classNames(
    'cursor-pointer font-medium font-jakarta-sans  py-2 text-moon-dark text-16 hover:bg-moon-light hover:bg-opacity-50 rounded-lg flex',
    {
      'pl-3': icon,
      'pl-2': !icon,
    }
  )

  return (
    <motion.div
      id={id}
      variants={itemVariants}
      className={itemClassnames}
      onClick={handleClick}
    >
      {icon && (
        <div className="mr-3 flex w-4 items-center justify-center">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <div>{text}</div>
    </motion.div>
  )
}

export default DropdownItem
