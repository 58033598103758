import capitalize from 'lodash/capitalize'
import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

interface MethodParameterProps {
  kidscriptMethodName: any
  parameter: any
  index: any
  kidscriptMethod: any
}

const MethodParameter: React.FC<MethodParameterProps> = ({
  kidscriptMethodName,
  parameter,
  index,
  kidscriptMethod,
}) => {
  const id = `${kidscriptMethodName}-${parameter.name}`

  return (
    <div className="relative inline">
      <span className="text-titan cursor-pointer" data-tip data-for={id}>
        {parameter.name}
      </span>
      <ReactTooltip
        className="!text-moon-darkest font-poppins-regular !z-[3000] !bg-white !opacity-100 shadow-xl"
        place="top"
        // effect="solid"
        id={id}
        arrowColor="#FFF"
        // backgroundColor="#FFF"
      >
        <div>{capitalize(parameter.description)}</div>
      </ReactTooltip>
      {index < kidscriptMethod.parameters.length - 1 ? ', ' : ''}
    </div>
  )
}

export default MethodParameter
