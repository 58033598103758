'use client'

import { useMemo, useState } from 'react'
import React from 'react'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import Button from '@codeverse/helios/button'
import { useUserMutation } from 'apps/studio/hooks/data/Users/Users'
import determineUserAvatarId from 'apps/studio-shared/src/Util/determineUserAvatarId'
import useAuth from 'apps/studio-shared/src/Hooks/Auth/useAuth'
import { useQueryClient } from '@tanstack/react-query'
import useUser from 'apps/studio-shared/src/Data/User/useUser'
import useSession from '../../Studio/Hooks/useSession'
import determineUserAvatarIndex from 'apps/studio-shared/src/Util/determineUserAvatarIndex'
import {
  useAvatars,
  useAvatarsPublic,
} from 'apps/studio-shared/src/Data/Avatar/AvatarDataHooks'
import AvatarSelectorContainer from './AvatarSelectorContainer'
import AvatarSelector from './AvatarSelector'

export default function AvatarModalContainer({
  initialSlide,
  currentSlide,
  setInitialSlide,
  setCurrentSlide,
}: {
  initialSlide: number
  currentSlide: number
  setInitialSlide: any
  setCurrentSlide: any
}) {
  const { currentUserId } = useAuth()
  const { setAvatarModal } = useUIStore()
  const { user, isFetched: isUserFetched } = useSession()

  // const [currentSlide, setCurrentSlide] = useState(
  //   determineUserAvatarIndex(user?.avatar?.id)
  // )
  const { data: avatars, isFetched } = useAvatarsPublic()

  const updateUserMutation = useUserMutation()
  const queryClient = useQueryClient()

  const avatarsMapping = useMemo(() => {
    if (avatars) {
      return avatars?.avatars?.map((ele, index) => ({
        index,
        id: ele.id,
      }))
    }
  }, [avatars])

  return (
    <form
      className="relative h-full overflow-y-scroll"
      onSubmit={(e) => {
        e.preventDefault()
        updateUserMutation.mutate(
          {
            id: currentUserId,
            avatarId: avatarsMapping?.find(
              (avatar) => avatar.index === currentSlide
            )?.id,
          },
          {
            onSuccess: () => {
              setAvatarModal(false)
              queryClient.invalidateQueries({
                queryKey: ['users', currentUserId],
              })
            },
          }
        )
      }}
    >
      <div className="w-full">
        <h4 className="text-center text-[20px]">Choose a new avatar</h4>
      </div>
      <div
        className=""
        style={
          {
            // maxHeight: 'calc(100% - 300px)',
          }
        }
      >
        {isFetched && isUserFetched && (
          <AvatarSelector
            initialSlide={initialSlide}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            setInitialSlide={setInitialSlide}
          />
        )}
      </div>

      <div className="px-4 pt-4">
        <Button context="primaryFlat" size="regular" className="w-full">
          Save
        </Button>
      </div>
    </form>
  )
}
