'use client'
import moment from 'moment-timezone'
import { useRouter } from 'next/navigation'
import React from 'react'
import classNames from 'classnames'
import { useQueryClient } from '@tanstack/react-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons'
import { variants } from './PublishedGameCard'
import { motion } from 'framer-motion'

interface PublishedGameCard {
  index: number
}

const PublishCard: React.FC<PublishedGameCard> = ({ index }) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  return (
    <motion.div
      className={classNames(
        'group relative rounded-xl p-2 ',
        'flex flex-initial flex-col'
      )}
      onClick={() => {
        queryClient
          .invalidateQueries({
            queryKey: ['projectDocuments'],
          })
          .then(() => {
            // router.push(`/play/${app.id}`)
          })
      }}
      variants={variants}
      initial="initial"
      whileInView="animate"
      key={index}
      viewport={{
        once: true,
      }}
      custom={index}
    >
      <div
        className="relative cursor-pointer rounded-lg"
        style={{
          height: 0,
          paddingTop: '150.85%',
          // backgroundImage: 'url(/images/home/cv-file-default.svg)',
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundSize: '50%',
        }}
      >
        {/* <HeliosPill className="absolute left-2 top-2" context="dark">
          <FontAwesomeIcon
            icon={faPlayCircle}
            size="lg"
            className="pr-1 text-[#00ce94]"
          />
          {app.play_count}
        </HeliosPill> */}
      </div>
    </motion.div>
  )
}

export default PublishCard
