import React, { useContext } from 'react'
import classNames from 'classnames'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Removes default styles (if true) so you can override with your own background styles
   */
  colored?: boolean
}

const card = {
  base: 'rounded-2xl shadow-sm',
  default: 'bg-white dark:bg-gray-800',
}

const Card = function Card(props: Props) {
  const { className, children, colored = false, ...other } = props

  const baseStyle = card.base
  const uncoloredStyle = card.default

  const cls = classNames(baseStyle, !colored && uncoloredStyle, className)

  return (
    <div className={cls} {...other}>
      {children}
    </div>
  )
}

export default Card
