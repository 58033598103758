'use client'

import Modal from '@codeverse/helios/modal'
import React, { useMemo } from 'react'
import { useUIStore } from '../../Stores'
import AvatarModalContainer from './Avatar/AvatarModalContainer'
import { useAvatars, useAvatarsPublic } from '../../Data/Avatar/AvatarDataHooks'
import AvatarSelectorContainer from './Avatar/AvatarSelectorContainer'
import useSession from '../Studio/Hooks/useSession'

interface AvatarModalProps {}

const AvatarModal: React.FC<AvatarModalProps> = () => {
  const { avatarModal, setAvatarModal } = useUIStore()
  const { data: avatars, isFetched } = useAvatarsPublic()
  const { user, isFetched: userIsFetched } = useSession()

  const avatarsMapping = useMemo(() => {
    if (avatars) {
      return avatars.avatars.map((ele, index) => ({
        index,
        id: ele.id,
      }))
    } else {
      return []
    }
  }, [avatars])

  return (
    <Modal
      allowEsc
      isOpen={avatarModal}
      onClose={() => {
        setAvatarModal(false)
      }}
      contentClassname="pt-[54px] pb-[26px]"
      width={'400px'}
    >
      {isFetched && userIsFetched && avatarsMapping.length > 0 && (
        <AvatarSelectorContainer avatarsMapping={avatarsMapping} />
      )}
    </Modal>
  )
}

export default AvatarModal
