'use client'

import Button from '@codeverse/helios/button'
import Input from '@codeverse/helios/input'
import Modal from '@codeverse/helios/modal'
import { useUIStore } from '../../Stores'
import useModalDataStore from '../../Stores/UseModalDataStore'
import { useEffect, useState } from 'react'
import { useMutateProject } from '../../Data/Projects/ProjectMutations'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export default function RenameProjectModal() {
  const { setRenameProjectModal, renameProjectModal } = useUIStore()
  const { currentProject, setCurrentProject } = useModalDataStore()
  const [projectName, setProjectName] = useState(currentProject?.name)
  const projectMutation = useMutateProject()
  const queryClient = useQueryClient()
  const [disabled, setDisabled] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setDisabled(true)
    projectMutation.mutate(
      {
        projectId: currentProject.id,
        data: {
          name: projectName,
        },
      },
      {
        onSuccess: () => {
          setDisabled(false)
          queryClient.invalidateQueries({ queryKey: ['projects'] })
          setRenameProjectModal(false)
          setCurrentProject(null)
          toast.success(`Project rename to ${projectName}`, {
            position: 'bottom-center',
          })
        },
        onError: () => {
          setDisabled(false)
          console.error('Error updating project name')
        },
      }
    )
  }

  useEffect(() => {
    setProjectName(currentProject?.name)
  }, [currentProject])

  return (
    <Modal
      width={'400px'}
      isOpen={renameProjectModal}
      onClose={(e) => {
        e.stopPropagation()
        setRenameProjectModal(false)
        setCurrentProject(null)
      }}
      allowEsc
      contentClassname="p-6"
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col items-center">
          <h4 className="!mb-0 text-[20px] text-[#090E2B]">Edit Name</h4>
          <p className="pt-4">Choose a new name for your project.</p>

          <Input
            className="mt-4  !h-10"
            size="regular"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />

          <Button
            disabled={disabled}
            className="mt-6 w-full"
            size="regular"
            context="primaryFlat"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}
