import React from 'react'
import classNames from 'classnames'
import { toNumber } from 'lodash'

let globalCssModule

export function setGlobalCssModule(cssModule: any) {
  globalCssModule = cssModule
}

export function mapToCssModules(className = '', cssModule = globalCssModule) {
  if (!cssModule) return className
  return className
    .split(' ')
    .map((c) => cssModule[c] || c)
    .join(' ')
}

type PropTypes = {
  children?: any
  bar?: boolean
  multi?: boolean
  tag?: any
  value: number
  max?: number
  animated?: boolean
  striped?: boolean
  color?: string
  className?: string
  barClassName?: string
  cssModule?: object
}

const Progress: React.FC<PropTypes> = (props) => {
  const {
    children,
    className,
    barClassName,
    cssModule,
    value = 0,
    max = 100,
    animated,
    striped,
    color,
    bar,
    multi,
    tag: Tag = 'div',
    ...attributes
  } = props

  const percent = (toNumber(value) / toNumber(max)) * 100

  const progressClasses = mapToCssModules(
    classNames(className, 'bg-moon-lightest rounded-2xl overflow-hidden'),
    cssModule
  )

  const progressBarClasses = mapToCssModules(
    classNames(
      'flex h-[10px] overflow-hidden text-12 bg-titan rounded-2xl',
      bar ? className || barClassName : barClassName,
      animated ? 'progress-bar-animated' : null,
      color ? `bg-${color}` : null,
      striped || animated ? 'progress-bar-striped' : null
    ),
    cssModule
  )

  const ProgressBar = multi ? (
    children
  ) : (
    <div
      className={progressBarClasses}
      style={{ width: `${percent}%` }}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={max}
      children={children}
    />
  )

  if (bar) {
    return ProgressBar
  }

  return (
    <Tag {...attributes} className={progressClasses} children={ProgressBar} />
  )
}

export default Progress
