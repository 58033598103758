'use client'
import Cookies from 'js-cookie'
import { H } from '@highlight-run/next/client'
import {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import useAuth from '../../Hooks/Auth/useAuth'
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation'
import useAuthStore from '../../Stores/useAuthStore'
import { parseNrdyParams, setNrdyParams } from '../PartnerAuth/getNrdyParams'
import useAuthorizeAccount from './useAuthorizeAccount'

type Props = {
  children: React.ReactNode
}

const Auth = ({ children }: Props) => {
  const searchParams = useSearchParams()
  const params = useParams()
  const {
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUserId,
    setAccessToken,
  } = useAuthStore()
  const { authorizeAccountPartner } = useAuthorizeAccount()
  const router = useRouter()
  const pathname = usePathname()

  const handlePartnerLogin = useCallback(() => {
    authorizeAccountPartner(
      () => {
        // localStorage.setItem('vtLogin', 'true')
        // console.log('Pushing to missions')
      },
      (e) => {
        console.log('handlePartnerLogin', e)
        router.push(`/login/partner?next=${decodeURI(pathname)}`)
      }
    )
  }, [])

  useLayoutEffect(() => {
    const nrdyParams = parseNrdyParams(searchParams)

    if (Object.keys(nrdyParams).length > 0) {
      localStorage.removeItem('auth')
      setNrdyParams(nrdyParams)
      console.log('handling partner auth')
      return handlePartnerLogin()
    }

    const auth = localStorage.getItem('auth')

    if (auth && !isAuthenticated) {
      console.log('Trying to auth')
      const { currentUserId, accessToken } = JSON.parse(auth)
      fetch(
        `https://api.codeverse.com/oauth/me?current_user_id=${currentUserId}&access_token=${accessToken}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
        }
      ).then(async (response) => {
        if (response.status === 200) {
          setCurrentUserId(currentUserId)
          setAccessToken(accessToken)
          setIsAuthenticated(true)
          const { data } = await response.json()
          H?.identify(data.attributes.username, {
            id: data.id,
            name: data.attributes.name,
            email: data.attributes.email,
            scope: data.attributes.scope,
          })
        } else {
          const next = pathname
          localStorage.setItem('auth', JSON.stringify(auth))
          window.heap?.resetIdentity()
          router.push(`/login?next=${next}`)
        }
      })
    } else {
      if (!isAuthenticated) {
        window.heap?.resetIdentity()
        router.push(`/login?next=${pathname}`)
      }
    }
  }, [])

  return !isAuthenticated ? (
    <div className="bg-moon-darkest font-poppins-bold absolute inset-0 flex items-center justify-center text-white  ">
      Loading...
    </div>
  ) : (
    <>{children}</>
  )
}

export default Auth
