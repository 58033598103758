'use client'

import { Editor } from '@kidscript/editor'
import { cn } from '@codeverse/cn'
import { useEffect, useRef, useState } from 'react'
import { ImperativePanelGroupHandle } from 'react-resizable-panels'
export const aspectRatio = 509 / 768
export const aspectRatioHeight = 768 / 509

interface GamePreviewProps {
  panelSize: number
  panelRef?: React.RefObject<ImperativePanelGroupHandle>
}
export default function GamePreview({ panelSize }: GamePreviewProps) {
  const [width, setWidth] = useState(1200)
  const [height, setHeight] = useState(900)
  const runtimeRef = useRef<HTMLDivElement>(null)
  const containerWidth = runtimeRef.current?.clientWidth || 0
  const containerHeight = runtimeRef.current?.clientHeight || 0

  useEffect(() => {
    //@ts-ignore
    const editor: Editor = document.getElementById('editor').editor

    if (editor) {
      //@ts-ignore
      editor.preview.renderer.scale.resize()
    }

    setWidth(aspectRatio * containerHeight)
    const heightToShow = containerWidth / aspectRatio

    if (heightToShow > containerHeight) {
      setHeight(containerHeight)
    } else if (heightToShow < containerHeight) {
      setHeight(heightToShow)
    }
  }, [containerWidth, panelSize, containerHeight])

  useEffect(() => {
    const handleResize = () => {
      // panelRef.current.setLayout([layout[0] + 1, layout[1] + 1])
      setWidth(aspectRatio * containerHeight)
      const heightToShow = containerWidth / aspectRatio

      if (heightToShow > containerHeight) {
        // console.log('heightToShow higher than container')
        setHeight(containerHeight)
      } else if (heightToShow < containerHeight) {
        // console.log('heightToShow lower than container')
        setHeight(heightToShow)
      } else {
        // console.log('doing nothing')
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [containerWidth, panelSize, containerHeight])

  const stageClassnames = cn('stage', 'absolute block h-full w-full')

  return (
    <div
      className="absolute right-0 top-0 flex h-full w-full items-center justify-center !bg-black"
      id="runtimeEnvironment"
      ref={runtimeRef}
    >
      <div
        className="bg-moon-darkest relative h-full"
        style={{
          height: `${height}px`,
          width: `${width}px`,
          maxHeight: '100%',
        }}
      >
        <div className={stageClassnames} id="stage" />
        <div
          id="preview"
          className="preview absolute inset-0 flex h-full w-full justify-center"
        ></div>
      </div>
    </div>
  )
}
