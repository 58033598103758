import { useRouter } from 'next/navigation'
import {
  useCreateNewProjectDocumentsMutation,
  useCreateNewProjectMutation,
} from '../../../Data/Projects/ProjectMutations'

import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { create } from 'lodash'
import { toast } from 'react-toastify'

const useCreateNewProject = () => {
  const router = useRouter()

  const createNewProjectMutation = useCreateNewProjectMutation()
  const createNewProjectDocumentsMutation =
    useCreateNewProjectDocumentsMutation()
  const queryClient = useQueryClient()
  const state = queryClient.getQueriesData({
    queryKey: ['projects'],
  })

  const createNewProject = useCallback(
    async (
      projectName?: string,
      mainKidScript?: string,
      headKidScript?: string,
      callback?: any
    ) => {
      //@ts-ignore
      const totalProjects =
        state.length > 0 && state[1]?.length > 0
          ? //@ts-ignore
            state[1][1].pages[0][0].total_records
          : 0
      const name =
        projectName ||
        (totalProjects === 0
          ? 'My First Project'
          : `Project #${totalProjects + 1}`)

      // setCreatingFirstProject(firstProject)

      return createNewProjectMutation.mutate(
        { name },
        {
          onSuccess: (projectData: any) => {
            createNewProjectDocumentsMutation.mutate(
              {
                firstProject: false,
                projectId: projectData.id,
                mainKidScript,
                headKidScript,
              },
              {
                onSuccess: () => {
                  router.push(`/projects/${projectData.id}`)
                  queryClient.invalidateQueries({
                    queryKey: ['projects'],
                  })
                },
                onError: (error) => {
                  toast.dismiss()
                  toast.error('Failed to create project')
                  console.error('Error creating project', error)
                  if (typeof callback === 'function') {
                    callback()
                  }
                },
              }
            )
          },
        }
      )
    },
    [state]
  )

  return createNewProject
}

export default useCreateNewProject
