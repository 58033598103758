'use client'

import React from 'react'
import { useUIStore } from '../../Stores'
import Modal from '@codeverse/helios/modal'
import NewProjectTemplate from './NewProjectTemplate/NewProjectTemplate'

export default function NewProjectTemplateModal() {
  const { newProjectTemplateModal, setNewProjectTemplateModal } = useUIStore()
  return (
    <Modal
      isOpen={newProjectTemplateModal}
      onClose={() => {
        setNewProjectTemplateModal(false)
      }}
      width={400}
      height={544}
    >
      <NewProjectTemplate />
    </Modal>
  )
}
