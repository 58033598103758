'use client'
import React, { useMemo, useState } from 'react'
import Header from './Header'
import FilterBar from './FilterBar'
import Breadcrumbs from './Breadcrumbs'
import useRuntimeStore from '../Hooks/Runtime/useRuntimeStore'
import classNames from 'classnames'
import SearchBox from './SearchBox'
import ToolboxLibrary from './ToolboxLibrary'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import SelectedToolboxObject from './Documentation/SelectedToolboxObject'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import { usePathname } from 'next/navigation'
import SelectedToolboxObjectValue from './Documentation/SelectedToolboxObjectValue'

interface ToolboxProps {}

const Toolbox: React.FC<ToolboxProps> = () => {
  const [categoryFilterValue, setCategoryFilterValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const pathname = usePathname()

  const isMissionBuilder = pathname.includes('/mission_builder')
  const {
    showToolbox,
    setShowToolbox,
    kidScriptSetup,
    toolboxLibraryFilter,
    kidScriptDependenciesFetched,
  } = useRuntimeStore()
  const { setCurrentLibraryFilterObject, libraryFilterObject } =
    useMissionBuilderStore()

  const isFetching = useIsFetching({
    queryKey: ['keyringGrants'],
  })

  const objects = useMemo(() => {
    if (kidScriptSetup && kidScriptDependenciesFetched) {
      //@ts-ignore
      return KidScript?.library.objects
    }
    return []
  }, [kidScriptSetup, kidScriptDependenciesFetched])

  const filteredObjects = useMemo(() => {
    let filteredObjectsTemp: Array<any> = []

    Object.keys(objects)
      .sort((a, b) => {
        const textA = a.toUpperCase()
        const textB = b.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      .map((objectKeyName) => {
        if (
          objects[objectKeyName].engine &&
          objects[objectKeyName].engine.importable
        ) {
          filteredObjectsTemp.push(objects[objectKeyName])
        }
      })

    if (!searchValue) {
      if (categoryFilterValue !== '') {
        filteredObjectsTemp = filteredObjectsTemp.filter((object) => {
          const objectCategory =
            (object.categoryName && object.categoryName.toLowerCase()) || ''
          // the special "Locked" category returns only locked objects
          if (categoryFilterValue === 'Locked') {
            return object?.ungrantedKeyring()
          }
          // all other categories filter by category name
          else {
            return object?.categoryName?.includes(categoryFilterValue)
          }
        })
      }
      return filteredObjectsTemp
    }
    if (searchValue) {
      filteredObjectsTemp = filteredObjectsTemp.filter((object) => {
        const objectName = object.name.toLowerCase()
        const searchLowerCased = searchValue.toLowerCase()
        const objectCategory =
          (object.categoryName && object.categoryName.toLowerCase()) || ''
        if (searchLowerCased === 'vehicle' && objectName === 'truck') {
          return true
        }
        return (
          objectName.includes(searchLowerCased) ||
          objectCategory === searchLowerCased
        )
      })
    }
    filteredObjectsTemp = filteredObjectsTemp.sort((a, b) => {
      const textA = a.name.toUpperCase()
      const textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
    return filteredObjectsTemp
  }, [searchValue, objects, categoryFilterValue])

  const resetFields = () => {
    setCategoryFilterValue('')
    setSearchValue('')
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }

  return (
    <div
      className={classNames('z-[9999] bg-white', {
        hidden: !showToolbox,
        'absolute inset-0 block': showToolbox,
      })}
    >
      {isMissionBuilder && <Header resetFields={resetFields} />}
      <div className="bg-moon-lightest relative h-full w-auto">
        <FilterBar
          categoryFilterValue={categoryFilterValue}
          setCategoryFilterValue={setCategoryFilterValue}
        />
        <div className="absolute inset-0 left-12 flex flex-col">
          <Breadcrumbs
            categoryFilterValue={categoryFilterValue || 'All Objects'}
            setSearchValue={setSearchValue}
            setCategoryValue={setCategoryFilterValue}
          />
          <div className="">
            <SearchBox
              value={searchValue}
              handleSearch={handleSearch}
              // handleClear={this.handleClear}
              // handleFocus={this.handleFocus}
              // handleBlur={this.handleBlur}
              // isFocused={isFocused}
            />
          </div>
          {!isFetching && (
            <ToolboxLibrary
              // setPanning={setPanning}
              // panning={panning}
              // setCurrentObject={setCurrentObject}
              categoryFilterValue={categoryFilterValue}
              objects={filteredObjects}
              // runtimeRef={runtimeRef}
              setShowToolbox={setShowToolbox}
              showToolbox={showToolbox}
              // toggleDrop={toggleDrop}
              // isDropping={isDropping}
            />
          )}
          <SelectedToolboxObject
            setCategoryValue={setCategoryFilterValue}
            categoryFilterValue={categoryFilterValue}
          />
          <SelectedToolboxObjectValue
            setSearchValue={setSearchValue}
            setCategoryValue={setCategoryFilterValue}
            categoryFilterValue={categoryFilterValue}
          />
        </div>
      </div>

      {
        <div
          className="z-2000 absolute inset-0"
          id="LibraryFilterEditorModal"
          style={{
            display:
              toolboxLibraryFilter && !!libraryFilterObject ? 'block' : 'none',
          }}
        />
      }
    </div>
  )
}

export default Toolbox
