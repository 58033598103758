'use client'

import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { faCheck, faPennant } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMissionStore from '../../../../Stores/useMissionStore'
import { useRuntimeRefsStore } from '../../../Studio/Hooks/Runtime/useRuntimeRefsStore'
import StepHeader from './StepHeader'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import { useParams, usePathname } from 'next/navigation'
import MissionStepTips from './MissionStepTips'
import { Card, CardBody } from '@codeverse/helios/card'
import MarkdownPreview from '../../../Studio/CodeReference/MarkdownPreview'
import Content from './Content'
import Markdown from '../../../Studio/CodeReference/MarkdownPreview'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'

export default ({}) => {
  const { currentStep, setCurrentStep, setBlockNextStep } = useMissionStore()
  const params = useParams()
  const pathname = usePathname()
  // const { state: missionState } = useGuidedMissions()
  // const { missionSteps } = useGuidedMissionsData()
  // const { editorRef } = useRuntime()
  const { editorRefs } = useRuntimeRefsStore()
  const { data: missionSteps } = useMissionSteps(params?.id as string)
  const editorRef = useMemo(() => {
    return editorRefs?.main
  }, [editorRefs])
  // const missionBuilderMatch = useMatch('/mission_builder/:id')
  // const { currentStep, initialReplayMissionSteps } = missionState

  // const { data: MissionStepInsertables } = useMissionStepTipInsertables(
  //   currentStep?.id
  // )

  // const showLibrary = useMemo(() => {
  //   return MissionStepInsertables?.length > 0
  // }, [currentStep, MissionStepInsertables])

  // if (
  //   !initialReplayMissionSteps &&
  //   !missionBuilderMatch &&
  //   currentStep.editable_symbols
  // )
  //   return null

  useEffect(() => {
    if (!isMissionBuilder) {
      setTimeout(() => {
        setBlockNextStep(false)
      }, 3000)
    } else {
      setBlockNextStep(false)
    }
  }, [])

  const isMissionBuilder = pathname.includes('mission_builder')

  if (currentStep.success_header) {
    return (
      <div className="ml-6 mr-8 mt-4 flex flex-row pb-[44px]">
        {currentStep?.image && (
          <div className="relative">
            <img
              className="inline-block rounded-[16px] bg-cover"
              src={currentStep?.image}
              style={{ height: '228px', width: '149px', minWidth: '149px' }}
              alt="MissionStepImage"
            />
            <div className="absolute inset-0 rounded-[16px] bg-[#292E50]/[.32]"></div>
            <div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="5x"
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform "
                style={{
                  //@ts-ignore
                  '--fa-primary-color': '#FFF',
                  '--fa-secondary-color': '#02be89',
                  '--fa-secondary-opacity': '1',
                }}
              />
            </div>
          </div>
        )}

        <div className="pl-4">
          <div className="flex pb-4">
            <div className="bg-titan-dark flex h-[64px] w-[64px] items-center justify-center rounded-full text-white">
              <FontAwesomeIcon className="pl-2" size="2x" icon={faPennant} />
            </div>
            <div className="font-poppins-bold text-moon-darkest ml-[20px] flex max-w-[220px] items-center text-[20px] leading-[28px]">
              {currentStep?.success_header}
            </div>
          </div>
          <Content content={currentStep?.content} />

          {/* {currentStep?.detail && (
            <div className="rounded-16 text-moon-darkest text-16 bg-white py-12 px-16">
              <MarkdownPreview
                fontClassName="font-poppins-regular text-14 leading-22"
                theme="light"
                type="detail"
              >
                {editorRef?.applyTemplate(currentStep.detail || '')}
              </MarkdownPreview>
            </div>
          )} */}

          <MissionStepTips />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <StepHeader
        title={editorRef?.applyTemplate(currentStep.header || '') || ''}
        position={currentStep?.position + 1}
      />

      <div className="select-none px-6 pb-4 text-base">
        {currentStep?.content && (
          <Markdown>
            {editorRef?.applyTemplate(currentStep.content || '')}
          </Markdown>
        )}

        {currentStep.image && (
          <div className="my-6 flex justify-center rounded-lg">
            <img
              src={currentStep.image}
              alt="MissionStepImage"
              className="aspect-auto max-h-[500px] max-w-[600px]	rounded-3xl"
            />
          </div>
        )}
        {currentStep.body && (
          <Card>
            <CardBody className="markdown">
              <Markdown children={currentStep.body || ''} />
            </CardBody>
          </Card>
        )}
        {currentStep?.detail && (
          <div className="text-moon-darkest mt-4 rounded-2xl bg-[#8D98C6] bg-opacity-10 px-4 py-3 text-base">
            <Markdown>
              {editorRef?.applyTemplate(currentStep?.detail || '')}
            </Markdown>
          </div>
        )}
        {/* {currentStep.audio_uri && (
            <div className="mt-32">
              <AudioPlayer src={currentStep.audio_uri} />
            </div>
          )} */}
        {/* {currentStep.video_uri && (
            <div className="mt-32">
              <ReactPlayer<
                className="react-player rounded-28 mx-auto aspect-video h-full w-full cursor-pointer overflow-hidden"
                url={currentStep.video_uri}
                width="100%"
                height="100%"
                controls
              />
            </div>
          )} */}
        <MissionStepTips />
      </div>

      {/* {showLibrary && <LibraryObjects />} */}
    </div>
  )
}
