'use client'

import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { MissionFormValues, headerClassname } from '../../MissionForm'
import { Select } from '@codeverse/helios/select'

interface DifficultyLevelProps {
  control: Control<MissionFormValues>
}

export type DifficultLevelOption = { value: LevelValue; label: LevelLabel }

export type LevelValue =
  | 'beginner'
  | 'intermediate'
  | 'advanced'
  | 'expert'
  | 'pro'
export type LevelLabel =
  | 'Beginner'
  | 'Intermediate'
  | 'Advanced'
  | 'Expert'
  | 'Pro'

export const difficultLevelOptions: Array<DifficultLevelOption> = [
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'advanced', label: 'Advanced' },
  { value: 'expert', label: 'Expert' },
  { value: 'pro', label: 'Pro' },
]

const DifficultyLevel: React.FC<DifficultyLevelProps> = ({ control }) => {
  return (
    <div className="mb-4">
      <div className={headerClassname}>Difficulty Level</div>
      <Controller
        name="difficulty_level"
        control={control}
        render={({ field }) => (
          <Select
            size="regular"
            context="light"
            defaultVal={
              difficultLevelOptions.find(
                (option) => field.value?.value === option.value
              ) || difficultLevelOptions[0]
            }
            onChange={(option) => field.onChange(option)}
            options={difficultLevelOptions}
          />
        )}
      />
    </div>
  )
}

export default DifficultyLevel
