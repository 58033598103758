import Hammer from 'react-hammerjs-18'
import {
  faArrowsUpDownLeftRight,
  faCheck,
  faDirections,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useMissionStepInsertables } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'

interface KidScriptSnippetProps {}

const options = {
  recognizers: {
    press: {
      time: 0,
      threshold: 0,
    },
  },
}

const CodeSnippet = ({ kidscript, insertable, index, length }) => {
  const editorRef = useMainEditorRef()
  const { currentStep, setBlockNextStep } = useMissionStore()
  //@ts-ignore
  const kidscriptHtml = KidScript.parseBlock(
    //@ts-ignore
    editorRef?.applyTemplate(kidscript)
  ).toHTML()

  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const [dropped, setDropped] = useState(false)

  const handleUnblock = () => {
    if (index === length - 1) {
      setBlockNextStep(false)
    }
  }

  const handlePressUp = () => {
    if (!editorRef) return
    editorRef.display.dragDrop.cancel()
    editorRef.applyMissionStepInsertable({
      ...insertable,
      lineAddress: insertable.line_address,
    })
    setBlockNextStep(false)
    handleUnblock()
    setDropped(true)
  }
  const handlePress = () => {
    if (!editorRef) return
    try {
      editorRef.display.dragDrop.start(
        editorRef.applyTemplate(kidscript),
        insertable.line_address
      )
      editorRef.focus()
    } catch (e) {
      console.log('HANDLE ERROR', e)
    }
  }
  const handleUnlock = () => {}
  const handlePan = (e) => {
    const { x, y } = e.center
    //@ts-ignore
    editorRef.display.dragDrop.update(x, y)
  }
  const handleClick = () => {}

  if (dropped)
    return (
      <div className="relative">
        <div className="absolute inset-0 left-1/2 top-1/2 z-[3001] flex h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-white">
          <FontAwesomeIcon
            className="text-titan-dark"
            size="2x"
            icon={faCheck}
          />
        </div>
        <div className="bg-moon-darkest border-titan-dark z-2000 relative max-h-[150px] flex-grow cursor-not-allowed overflow-y-scroll rounded-lg border-4 opacity-50">
          <div className="relative h-auto">
            <div className="bg-titan-dark border-titan-dark absolute bottom-[-4px] left-0 top-0 flex h-full w-10 items-center justify-center border">
              <FontAwesomeIcon
                className="!text-white"
                size="1x"
                icon={faArrowsUpDownLeftRight}
              />
            </div>
            <div className="pl-16">
              <pre
                className="kidscript-syntax py-4"
                dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
              />
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <Hammer
      onPan={(event) => handlePan(event)}
      onPanStart={(event) => handlePress()}
      onPanEnd={(event) => handlePressUp()}
      onPressUp={(event) => handlePressUp()}
      onClick={(event) => handleClick()}
      options={options}
      className="bg-moon-darkest border-titan-dark z-2000 relative mb-2 max-h-[150px] flex-grow cursor-pointer overflow-y-scroll rounded-lg border-4"
      onDrag={() => console.log('dragging')}
      draggable
    >
      <div>
        <div className="relative h-auto">
          <div className="bg-titan-dark border-titan-dark absolute bottom-0 left-0 top-0 flex h-full w-10 items-center justify-center overflow-y-scroll border">
            <FontAwesomeIcon
              className="!text-white"
              size="1x"
              icon={faArrowsUpDownLeftRight}
            />
          </div>
          <div className="pl-16">
            <pre
              className="kidscript-syntax py-4"
              dangerouslySetInnerHTML={{ __html: kidscriptHtml }}
            />
          </div>
        </div>
      </div>
    </Hammer>
  )
}

const KidScriptSnippet: React.FC<KidScriptSnippetProps> = () => {
  const { currentStep, setBlockNextStep } = useMissionStore()
  const { missionInsertablesToCreate } = useMissionBuilderStore()
  const { data: MissionStepInsertablesData } = useMissionStepInsertables(
    currentStep?.id
  )

  useEffect(() => {
    if (
      missionInsertablesToCreate?.length > 0 ||
      MissionStepInsertablesData?.length > 0
    ) {
      setBlockNextStep(true)
    }
  }, [MissionStepInsertablesData])

  const KidScriptSnippets = useMemo(() => {
    if (missionInsertablesToCreate && missionInsertablesToCreate.length > 0) {
      return missionInsertablesToCreate
        .filter((insertable) => insertable.display === 'snippet')
        .map((insertable, index) => {
          return (
            <div>
              <CodeSnippet
                insertable={insertable}
                kidscript={insertable.kidscript}
                index={index}
                length={missionInsertablesToCreate.length}
              ></CodeSnippet>
            </div>
          )
        })
    }
    if (MissionStepInsertablesData) {
      return MissionStepInsertablesData.filter(
        (insertable) => insertable.display === 'snippet'
      ).map((insertable, index) => {
        return (
          <div>
            <CodeSnippet
              insertable={insertable}
              kidscript={insertable.kidscript}
              index={index}
              length={MissionStepInsertablesData.length}
            ></CodeSnippet>
          </div>
        )
      })
    }
  }, [missionInsertablesToCreate, MissionStepInsertablesData])

  return <div className="px-6">{KidScriptSnippets}</div>
}

export default KidScriptSnippet
