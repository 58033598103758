import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

export const Meteors = ({ number }: { number?: number }) => {
  const meteors = new Array(number || 20).fill(true)
  const [showMeteors, setShowMeteors] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShowMeteors(true)
    }, 1000)
  }, [])
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={showMeteors ? 'animate' : 'initial'}
      variants={{
        initial: { opacity: 0 },
        animate: { opacity: 1 },
      }}
    >
      {meteors.map((el, idx) => (
        <span
          key={'meteor' + idx}
          className={clsx(
            'animate-meteor-effect absolute h-0.5 w-0.5 rounded-[9999px] bg-[#64748b] shadow-[0_0_0_1px_#ffffff10]',
            "before:absolute before:top-1/2 before:h-[1px] before:w-[50px] before:-translate-y-[50%] before:transform before:bg-gradient-to-r before:from-[#64748b] before:to-transparent before:content-['']"
          )}
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 2}s`,
            animationDuration: `${Math.random() * 3 + 2}s`,
          }}
        />
      ))}
    </motion.div>
  )
}
