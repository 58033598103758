'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'

import Tablist from './Tablist'
import { Tabs } from '@codeverse/helios/tab'
import AvatarIcon from '../AvatarIcon'
import NotificationMenu from './NotificationMenu'
import { usePathname } from 'next/navigation'
import CreateNew from './CreateNew/CreateNew'
import { motion, useTransform, useScroll } from 'framer-motion'
import useSession from '../../Studio/Hooks/useSession'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'
import { useStreamToken } from 'apps/studio-shared/src/Data/User/useStreamToken'
import { useUIStore } from 'apps/studio-shared/src/Stores'

interface INavbar {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>
}

const defaultTabState = (pathname: string) => {
  if (pathname === '/') {
    return 'home'
  } else if (pathname === '/projects') {
    return 'projects'
  } else if (pathname === '/play') {
    return 'play'
  } else if (pathname === '/missions') {
    return 'missions'
  } else {
    return 'home'
  }
}

export default function Navbar({ scrollRef }: INavbar) {
  const pathname = usePathname()
  const router = useRouter()
  const { setProfileModal } = useUIStore()
  const { user } = useSession()
  const { data } = useStreamToken()

  const { scrollY } = useScroll({
    container: scrollRef,
    layoutEffect: false,
  })
  const background = useTransform(
    scrollY,
    [0, 100],
    ['rgba(28, 33, 65, 0.0)', 'rgba(28, 33, 65, 0.88)']
  )

  const paddingTop = useTransform(scrollY, [0, 100], ['32px', '0px'])
  const paddingBottom = useTransform(scrollY, [0, 100], ['32px', '0px'])
  const backgroundOther = useTransform(
    scrollY,
    [0, 100],
    ['rgba(28, 33, 65, 1)', 'rgba(28, 33, 65, 0)']
  )

  const border = useTransform(
    scrollY,
    [0, 100],
    ['rgba(255, 255, 255, .10)', 'rgba(28, 33, 65, 0)']
  )

  const [tabState, setTabState] = useState(defaultTabState(pathname))

  useEffect(() => {
    if (pathname === '/') {
      setTabState('home')
    } else if (pathname === '/projects') {
      setTabState('projects')
    } else if (pathname === '/play') {
      setTabState('play')
    } else if (pathname === '/missions') {
      setTabState('missions')
    }
  }, [pathname])

  const handleNav = (tabState: string) => {
    if (tabState === 'home') {
      router.push('/')
      setTabState('home')
    } else if (tabState === 'projects') {
      router.push('/projects')
      setTabState('projects')
    } else if (tabState === 'play') {
      router.push('/play')
      setTabState('play')
    } else if (tabState === 'missions') {
      router.push('/missions')
      setTabState('missions')
    }
  }

  return (
    <motion.div
      className="sticky top-0 z-[9000] backdrop-blur-[6px]"
      style={{
        background,
      }}
    >
      <motion.div
        style={{
          paddingTop,
          paddingBottom,
        }}
        className="z-[9000] flex w-full justify-center"
      >
        <motion.div
          style={{
            background: backgroundOther,
            borderColor: border,
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
          className={classNames(
            'mx-5 flex max-w-[1200px] rounded-3xl px-8 py-6'
          )}
        >
          <div className="flex flex-none justify-center">
            <Image
              onClick={(e) => {
                router.push(`/`)
                scrollRef.current?.scrollTo(0, 0)
              }}
              className={'cursor-pointer overflow-hidden'}
              src="/images/home/codeverse-logo.svg"
              width={144}
              height={32}
              alt="Codeverse Logo Text"
            />
          </div>

          <div className="flex flex-grow items-center justify-center">
            <Tabs
              className="mt-0 h-full bg-contain bg-no-repeat lg:!bg-none"
              onValueChange={(val) => {
                setTabState(val)
                scrollRef.current?.scrollTo(0, 0)
                // handleNavigate(val)
                handleNav(val)
              }}
              value={tabState}
              style={
                {
                  // backgroundImage: `url(/images/mobile-panel-bg@2x.jpg)`,
                }
              }
            >
              <Tablist />
            </Tabs>
          </div>

          <CreateNew />

          <div className="flex-none">
            <NotificationMenu
              onNotifierIconClick={() => {}}
              streamToken={data?.data.meta.stream_token}
              currentUser={user}
              onUserNameClick={() => {
                setProfileModal(true)
                router.push(`${pathname}?username=${user?.referralCode}`)
              }}
            />
          </div>

          <div className="flex-none">
            <AvatarIcon />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
