'use client'
import React, { useEffect, useRef, useState } from 'react'
import { cn } from '@codeverse/cn'
import ProfilePanel from './ProfilePanel/ProfilePanel'
import Navbar from './Nav/Navbar'
import Button from '@codeverse/helios/button'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import Modals from '../Modals/Modals'
import classNames from 'classnames'
import { Meteors } from './Meteors'
import { Starlight } from './StarLight'
import useSession from '../Studio/Hooks/useSession'
import { useUIStore } from '../../Stores'
import BadgeAwardContainer from './BadgeAwardContainer'

interface HomeLayoutProps {
  children: React.ReactElement
}
const HomeLayout: React.FC<HomeLayoutProps> = ({ children }) => {
  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  // useEffect(() => {
  //   const updateMousePosition = (e: MouseEvent) => {
  //     setMousePosition({ x: e.clientX, y: e.clientY })
  //   }

  //   window.addEventListener('mousemove', updateMousePosition)

  //   return () => {
  //     window.removeEventListener('mousemove', updateMousePosition)
  //   }
  // }, [])
  const { setProfileModal } = useUIStore()
  const { user } = useSession()
  const pathname = usePathname()
  const router = useRouter()

  const scrollRef = useRef<HTMLDivElement>(null)

  const homeLayoutClassnames = cn(
    'relative h-full w-full overflow-hidden overflow-y-scroll',
    {
      'overflow-y-hidden': pathname.includes('/play/'),
    }
  )

  const baseClassname = 'rounded-3xl'

  const profileCn = cn(
    baseClassname,
    'bg-[#1C2141] cursor-pointer border-[1px] border-white border-opacity-10 w-[289px] flex-shrink-0',
    'sticky top-40 hidden lg:block xl:block 2xl:block',
    {
      // 'col-span-4': profileOpen,
      // 'col-span-2': !profileOpen,
    }
  )

  const contentCn = cn(
    baseClassname,
    'w-full lg:pl-12 md:pl-4 xl:pl-12 2xl:pl-12 pl-0 h-auto',
    // 'xl:max-w-[770px] lg:max-w-[512px] md:max-w-[512px] max-w-[361px] 2xl:max-w-[1002px]',
    'min-w-full sm:min-w-0 md:min-w-0 lg:min-w-0 xl:min-w-0 2xl:min-w-0 flex',
    {
      // 'col-span-8': profileOpen,
      // 'col-span-10': !profileOpen,
    }
  )

  return (
    <div
      className={homeLayoutClassnames}
      ref={scrollRef}
      id="scrollableDiv"
      style={{ background: '#101432' }}
    >
      {/* <div
        style={{
          background: `
          radial-gradient(
              circle 200px at ${mousePosition.x}px ${mousePosition.y}px, 
              rgba(173, 216, 230, 0.05), 
              rgba(173, 216, 230, 0.02) 30%, 
              rgba(173, 216, 230, 0.01) 60%,
              transparent 90%
            )
          `,
        }}
      > */}
      <div className="z-100 relative">
        <Navbar scrollRef={scrollRef} />

        <div className="z-2000 mb-4 flex w-full px-4 md:px-[16px] lg:px-[88px] xl:flex xl:justify-center xl:px-[88px] 2xl:px-[88px]">
          <div
            className={classNames(
              'w-full max-w-full',
              'flex flex-row justify-start',
              // 'sm:flex sm:justify-center',
              // 'md:flex md:justify-center',
              // 'lg:flex lg:justify-center',
              'xl:flex xl:max-w-[1100px] xl:justify-center',
              '2xl:flex 2xl:max-w-[1100px] 2xl:justify-center'
            )}
          >
            <div className="relative">
              <div
                onClick={() => {
                  router.push(`${pathname}?username=${user?.referralCode}`)
                  setProfileModal(true)
                }}
                className={cn(profileCn)}
              >
                <ProfilePanel />
              </div>
            </div>
            {/* <div className="absolute left-0 top-6">
              <div className={profileCn}>
                <ProfilePanel userName={userName} avatarId={avatarId} />
              </div>
            </div> */}
            <div className={contentCn}>{children}</div>
          </div>
        </div>
        <Modals />
      </div>
      <Meteors number={5} />
      <Starlight />
      <BadgeAwardContainer />
    </div>
    // </div>
  )
}

export default HomeLayout
