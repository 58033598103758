import classNames from 'classnames'
import React, { Ref } from 'react'
import { itemClassnames } from './SelectedToolboxObjectValue'
import { faPlay, faStop } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@codeverse/helios/button'

interface SoundConstantProps {
  value: string
  index: number
  soundRefs: React.MutableRefObject<Array<any>>
  library: string
  currentObject: any
}

const titlecaseToFilename = (name: string) => {
  const snakecase =
    name[0].toLowerCase() +
    name
      .slice(1, name.length)
      .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  // also add an underscore before any numbers
  return snakecase.replace(/([a-z])([0-9]+)/g, '$1_$2') + '.ogg'
}

const SoundConstant: React.FC<SoundConstantProps> = ({
  value,
  index,
  soundRefs,
  library,
  currentObject,
}) => {
  const [playingSound, setPlayingSound] = React.useState(false)
  const handlePlay = () => {
    setPlayingSound(true)
    const filename = titlecaseToFilename(value)
    const audioElement = new Audio(
      `https://explore.codeverse.com/kidscript/objects/${currentObject.name}/sounds/${library}/${filename}`
    )
    audioElement.playbackRate = 1
    audioElement.volume = 1
    audioElement.loop = false

    soundRefs.current.push(audioElement)

    audioElement.addEventListener('canplaythrough', (e) => {
      audioElement.play()
    })

    audioElement.addEventListener('ended', (e) => {
      setPlayingSound(false)
    })
    soundRefs.current[index] = new Audio()
  }

  const handleStop = () => {
    setPlayingSound(false)
  }

  return (
    <div
      className={classNames(itemClassnames, {
        'bg-moon': index % 2 === 0,
        'bg-moon-light': index % 2 !== 0,
      })}
    >
      <div className="px-18 py-[28px]">"{value}"</div>
      <div
        className="ml-auto mr-28"
        // dangerouslySetInnerHTML={{ __html: svg }}
      >
        {!playingSound && (
          <Button
            className="hover:ring-neptune-light !h-10 w-full pr-2 !shadow-none"
            size="small"
            context="neptune"
            onClick={() => {
              handlePlay()
            }}
            icon={faPlay}
            style={{ height: 'unset' }}
            iconWithCircle
            iconBackgroundOpacity="1"
            iconCircleClassnames=""
            iconSide="right"
            spanClassnames="pr-[6px]"
          >
            Preview
          </Button>
        )}
        {playingSound && (
          <Button
            className="hover:ring-neptune-light w-[108px] pr-2 opacity-50 !shadow-none"
            size="small"
            context="neptune"
            icon={faStop}
            style={{ height: 'unset' }}
            iconWithCircle
            iconBackgroundOpacity="1"
            iconCircleClassnames=""
            iconSide="right"
            spanClassnames="pr-[6px]"
            onClick={() => handleStop()}
          >
            Stop
          </Button>
        )}
      </div>
    </div>
  )
}

export default SoundConstant
