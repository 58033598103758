'use client'

import React from 'react'
import { useUIStore } from '../../Stores'
import AvatarModalContainer from './Avatar/AvatarModalContainer'
import Modal from '../../../../../libs/helios/modal/src/lib/helios-modal'
import PlayProjectModalContainer from './PlayProjectModal/PlayProjectModalContainer'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useRouter } from 'next/navigation'
import ProjectHeader from './PlayProjectModal/ProjectHeader'
import useModalDataStore from '../../Stores/UseModalDataStore'
import Button from '@codeverse/helios/button'
import useRuntimeStore from '../Studio/Hooks/Runtime/useRuntimeStore'
import useKidScript from '../Studio/Hooks/useKidScript'
import { useProjectDocuments } from '../../Data/Projects/ProjectDataHooks'

interface PlayProjectModalProps {}

const PlayProjectModal: React.FC<PlayProjectModalProps> = () => {
  const { playProjectModal, setPlayProjectModal } = useUIStore()
  const { setCurrentProject, currentProject } = useModalDataStore()
  const { runKidScript } = useKidScript()
  const router = useRouter()
  const { kidscriptRunning, kidScriptDependenciesFetched } = useRuntimeStore()
  const { data: documents, isFetched } = useProjectDocuments(currentProject?.id)
  return (
    playProjectModal && (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id="PlayPublicApp"
          className="fixed inset-0 z-[9999] flex h-full w-full justify-between p-10"
        >
          <div className="relative h-full w-full">
            <div className="flex h-full w-full justify-center rounded-[32px] bg-[#1C2141] px-10 py-6">
              <ProjectHeader />
              {isFetched && <PlayProjectModalContainer documents={documents} />}
              <Button
                id="PlayPublicApp_Restart"
                context="primaryFlat"
                size="small"
                className="ml-6 mr-14"
                icon={faRedo}
                // iconBackgroundColor="#cbd6eb"
                // iconBackgroundOpacity="1"
                iconSide="left"
                // iconWithCircle
                disabled={!kidScriptDependenciesFetched && !kidscriptRunning}
                onClick={() => {
                  runKidScript(
                    documents.map((doc: any) => doc.kidscript),
                    //@ts-ignore
                    KidScript.Version
                  )
                  // if (kidscriptDependenciesFetched) {
                  //   runKidscript()
                  //   createVersionPlayMutation.mutate({
                  //     id: publicApp?.versionId,
                  //   })
                  //   window.heap.track('versionPlayed', {
                  //     projectId: publicApp?.projectId,
                  //     versionId: publicApp?.versionId,
                  //     creator: publicApp?.username,
                  //   })
                  // }
                }}
              >
                <span className="font-bold">Restart</span>
              </Button>
            </div>
            <motion.div
              whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="z-2000 pointer-events-auto absolute right-4 top-4 hidden w-auto flex-shrink flex-row justify-center md:hidden lg:flex"
              onClick={() => {
                setPlayProjectModal(false)
                setCurrentProject(null)
              }}
            >
              {/* <div className={'mt-4 pr-0'}></div> */}

              <div
                id="PlayPublicApp_Close"
                className="pointer-events-auto flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-[#101432]"
              >
                <FontAwesomeIcon
                  className="text-white"
                  icon={faTimes}
                  size="lg"
                />
              </div>
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  )
}

export default PlayProjectModal
