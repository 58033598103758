'use client'

import { useEffect, useMemo } from 'react'
import { useGetUserPoints } from './UserDataHooks'

export const useUserPoints = () => {
  const { data, isFetched } = useGetUserPoints()

  const { coins, points } = useMemo(() => {
    if (isFetched) {
      const coins = data?.me.bankBalance?.coins || 0
      const points =
        data?.me.curriculumScores.reduce(
          (acc, score) => acc + score.points,
          0
        ) || 0
      return {
        coins,
        points,
      }
    } else {
      return {
        coins: 0,
        points: 0,
      }
    }
  }, [data, isFetched])

  return {
    coins,
    points,
  }
}
