'use client'

import Markdown from '../../../Studio/CodeReference/MarkdownPreview'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'

export default function Detail({ detail }: { detail: string | undefined }) {
  const mainEditorRef = useMainEditorRef()
  if (!detail) return null
  return (
    <div className="text-moon-darkest mt-4 rounded-2xl bg-[#8D98C6] bg-opacity-10 px-4 py-4 font-semibold">
      <Markdown
      // fontClassName="!font-jakarta-sans text-sm leading-[22px] font-bold"
      // type="detail"
      >
        {mainEditorRef?.applyTemplate(detail || '')}
      </Markdown>
    </div>
  )
}
