import classNames from 'classnames'
import { capitalize } from 'lodash'
import Markdown from 'markdown-to-jsx'
import React, { useMemo } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Breadcrumbs from '../Breadcrumbs'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoundParameter from './SoundParamater'
import MissionStepTip from '../../../Missions/Missions/MissionSteps/MissionStepTip'
import useRuntimeStore from '../../Hooks/Runtime/useRuntimeStore'
import { useMainEditorRef } from '../../Hooks/useMainEditorRef'
import { toast } from 'react-toastify'

interface SelectedToolboxObjectValueProps {
  categoryFilterValue: string
  setCategoryValue: any
  setSearchValue: any
}

export const itemClassnames = classNames(
  'flex font-jakarta-sans items-center justify-center'
)

const SelectedToolboxObjectValue: React.FC<SelectedToolboxObjectValueProps> = ({
  categoryFilterValue,
  setCategoryValue,
  setSearchValue,
}) => {
  const { currentMethod, currentEvent, currentToolboxObject, showToolbox } =
    useRuntimeStore()
  const editorRef = useMainEditorRef()

  const handleImport = (object: any) => {
    const varName = editorRef?.documents.classNameToAvailableVariableName(
      object.name
    )
    let code = ''
    if (
      object.name === 'Timer' ||
      object.name === 'SoundEffects' ||
      object.name === 'Leaderboard'
    ) {
      code = `var ${varName} = new ${object.name}()`
    } else {
      code = `var ${varName} = new ${object.name}(50, 50)`
    }
    editorRef?.codeChanger.insert(code, [object.name])
  }

  const containerClassnames = classNames(
    'col-span-1 flex flex-col relative rounded-lg shadow-lg'
    // {
    //   absolute: panning,
    // }
  )
  const currentObject = currentMethod || currentEvent

  const isEvent = !!currentEvent

  const parameters = useMemo(() => {
    return currentObject?.parameters.map((parameter, index) => {
      const id = `${currentObject?.name}-${parameter.name}`
      return (
        <div className="relative inline text-white">
          <span
            className={classNames('cursor-pointer font-mono text-white', id)}
          >
            {parameter.name}
          </span>
          <ReactTooltip
            className="!text-moon-darkest font-poppins-regular !z-[3000] !bg-white !opacity-100 shadow-xl"
            place="top"
            id={id}
            anchorSelect={`.${id}`}
            arrowColor="#FFF"
          >
            <div>{capitalize(parameter.className)}</div>
          </ReactTooltip>
          {index < currentObject.parameters.length - 1 ? ', ' : ''}
        </div>
      )
    })
  }, [currentObject])

  const parametersSection = useMemo(() => {
    if (currentToolboxObject) {
      const libraryObject = KidScript.library.getObject(
        currentToolboxObject?.name
      )

      if (KidScript.isKSSpriteLibraryObject(libraryObject)) {
        const spriteBuilder = KidScript.getSpriteBuilder(libraryObject)
        return currentObject?.parameters.map((parameter, index) => {
          const id = `${currentObject}-${parameter.name}`
          const isConstant = parameter.constant

          if (isConstant) {
            const constants = parameter.constant?.values.map((value, index) => {
              const svg = spriteBuilder.getLayerShapePreviewSVGString(
                40,
                40,
                parameter.constant?.name,
                value
              )
              return (
                <div
                  className={classNames(itemClassnames, {
                    'bg-moon': index % 2 === 0,
                    'bg-moon-light': index % 2 !== 0,
                  })}
                >
                  <div className="px-4.5 py-[28px]">"{value}"</div>
                  <div
                    className="ml-auto mr-7 overflow-hidden !rounded-lg"
                    dangerouslySetInnerHTML={{ __html: svg }}
                  ></div>
                </div>
              )
            })

            return (
              <div className="text-moon-darkest relative inline">
                <span
                  className="text-sun-darkest cursor-pointer font-mono"
                  data-tip
                  data-for={id}
                >
                  {parameter.name}
                </span>

                {isConstant && <p className="mt-1">Choose a Selection</p>}

                <div className="mt-2 overflow-hidden rounded-lg">
                  {constants}
                </div>
              </div>
            )
          } else {
            return (
              <div className="mt-1" key={parameter.name}>
                <div className="text-titan-dark">{parameter.name}</div>
                <p className="text-moon-dark">{parameter.description}</p>
              </div>
            )
          }
        })
      } else if (currentToolboxObject.engineName === 'Sound') {
        return currentObject?.parameters.map((parameter, index) => {
          return (
            <SoundParameter
              currentObject={currentToolboxObject}
              parameter={parameter}
              index={index}
              key={parameter.name}
            />
          )
        })
      }
    }
  }, [currentObject])

  const tips = useMemo(() => {
    return currentObject?.tips?.map((tip, index) => {
      return <MissionStepTip content={tip} index={index} />
    })
  }, [currentObject])

  const kidScriptHtml = useMemo(() => {
    if (!currentObject) return ''
    const parameters = currentObject?.parameters
      .map((parameter, index) => {
        return `┤${
          parameter.className === 'any'
            ? 'Variable'
            : capitalize(parameter.className)
        }|${parameter.name}├`
      })
      .join(', ')
    let kidScriptValue
    if (currentEvent) {
      kidScriptValue = `# listen to an event
      when ${currentToolboxObject.name.toLowerCase()}.${
        currentObject.name
      }(${parameters})\n  #foo\n end`
    } else {
      kidScriptValue = `# call a method
      ${currentToolboxObject.name.toLowerCase()}.${
        currentObject.name
      }(${parameters})`
    }
    //@ts-ignore
    return KidScript.parseBlock(kidScriptValue).toHTML()
  }, [currentToolboxObject, currentObject])

  if (!currentMethod && !currentEvent) {
    return null
  }

  return (
    <div className="bg-moon-lightest absolute inset-0 top-[1px] z-[3333] overflow-y-scroll">
      <Breadcrumbs
        setSearchValue={setSearchValue}
        setCategoryValue={setCategoryValue}
        categoryFilterValue={categoryFilterValue || 'All Objects'}
      />
      <div
        className="rounded-4.5xl mx-4 mt-4 p-6"
        style={{ backgroundColor: '#DDE9F4' }}
      >
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <span className="font-jakarta-sans text-moon-darkest text-[32px] font-bold">
              {currentObject.name}
            </span>
            <div className="font-poppins-medium text-moon-dark mt-1 text-base">
              {currentObject.description}
            </div>
            {/* <div className="mt-2">
              <div
                className={classNames(
                  'bg-moon-darkest font-jakarta-sans mx-0.5 mb-2 inline-block cursor-pointer rounded-[8px] p-2 text-base'
                )}
                onClick={() => {
                  toast.success('Copied Code', {
                    position: 'top-center',
                  })
                }}
                style={{
                  color: '#fff',
                }}
              >
                {isEvent && (
                  <div className="">
                    <span className="font-mono">when </span>
                    <span className="font-mono text-white">
                      {currentToolboxObject.name.toLowerCase()}.
                    </span>
                    <span className="text-sun-darkest font-mono">
                      {currentObject.name}
                      <span className="text-white">(</span>
                    </span>
                    {parameters}
                    <span className="font-mono text-white">)</span>
                  </div>
                )}
              </div>
            </div> */}
            <div className="kidscript-syntax bg-moon-darkest relative mb-4 mt-4 flex-grow rounded-lg px-4 py-2 font-mono">
              <div dangerouslySetInnerHTML={{ __html: kidScriptHtml }} />
            </div>
          </div>
        </div>
      </div>
      {currentObject.parameters && currentObject.parameters.length > 0 && (
        <div className="rounded-4.5xl mx-4 mt-4 bg-white p-4">
          <div
            className="rounded-2xl p-6"
            style={{ backgroundColor: '#DDE9F4' }}
          >
            <div className="font-jakarta-sans text-moon font-bold">
              <div>PARAMETERS</div>

              {parametersSection}
            </div>
          </div>
        </div>
      )}
      {currentObject.tips && currentObject.tips.length > 0 && (
        <div
          className="rounded-4.5xl mx-4 mt-4 p-4"
          style={{ backgroundColor: '#DDE9F4' }}
        >
          <div className="font-jakarta-sans text-moon font-bold">
            <div>TIPS</div>

            {tips}
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectedToolboxObjectValue
