import { useMutation, useQuery } from '@tanstack/react-query'
import fetchClientGql from '../fetch/fetchClientGql'
import { projectQuery } from './ProjectQueries'
import { ProjectResponse } from './ProjectTypes'
import {
  createProjectVersion,
  getProject,
  getProjectDocuments,
  getProjectVersions,
  updateProjectVersion,
} from './ProjectFetch'
import { gql } from 'graphql-request'

export const useProject = (projectId: string) => {
  return useQuery({
    queryKey: ['project', projectId],
    queryFn: () => {
      return getProject(projectId)
    },
    enabled: !!projectId,
    refetchOnMount: true,
  })
}

export const useProjectDocuments = (projectId: string) => {
  return useQuery({
    queryKey: ['projectDocuments', projectId],
    queryFn: () => {
      return getProjectDocuments(projectId)
    },
    enabled: !!projectId,
    refetchOnMount: true,
  })
}

export const useProjectVersions = (projectId: string, options?: any) => {
  return useQuery({
    queryKey: ['projectVersionId', projectId],
    queryFn: () => getProjectVersions(projectId),
    enabled: !!projectId,
    refetchOnMount: false,
    staleTime: Infinity,
    ...options,
  })
}

export const useMutateUpdateProjectVersion = () => {
  const mutation = useMutation({
    mutationFn: ({
      versionId,
      status,
      name,
      description,
    }: {
      versionId: string
      status: string
      name: string
      description: string
    }) => {
      return updateProjectVersion(versionId, status, name, description)
    },
  })
  return mutation
}

export const useMutateProjectVersion = () => {
  const mutation = useMutation({
    mutationFn: ({
      projectId,
      status,
      screenshot,
      name,
      description,
      version,
    }: {
      projectId: string
      status: string
      screenshot: string
      name: string
      description: string | null
      version: string
    }) => {
      return createProjectVersion({
        projectId,
        status,
        screenshot,
        name,
        description,
        version,
      })
    },
  })
  return mutation
}

export const useVersionPlayData = (
  { versionId }: { versionId: string },
  enabled: boolean
) => {
  return useQuery({
    queryKey: ['projectVersionQuery', versionId],
    queryFn: async () => {
      const response = await fetchClientGql().request(
        gql`
          {
            version(id: "${versionId}") {
              id
              name
              kidscriptVersion
              project {
                id
                shortCode
                playCount
              }
              referralCode
              userAvatar
              revisions {
                kidscript
                filename
              }
              description
              screenshot
              createdAt
            }
          }
        `
      )
      return response
    },
    enabled: enabled,
  })
}
