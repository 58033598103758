import Cookies from 'js-cookie'
import { useMutation, useQuery } from '@tanstack/react-query'
import fetchClient from 'apps/studio-shared/src/Data/fetch/fetchClient'
import { useMemo } from 'react'
import { FetchOptions } from 'apps/studio/utils/fetch/FetchOptions'
import { UserMutateParams } from './UserTypes'
import { omit } from 'lodash'
import useSession from '../../../../studio-shared/src/Data/User/useSession'

export const updateUser = (userData: UserMutateParams) => {
  const userId = userData.id
  const omittedData = omit(userData, ['id', 'avatarId'])
  return fetchClient({
    method: 'PUT',
    url: `/users/${userId}`,
    data: {
      data: Object.assign(
        {
          id: userId,
          type: 'users',
          attributes: {
            ...omittedData,
          },
        },
        userData.avatarId && {
          relationships: {
            avatar: {
              data: {
                type: 'avatars',
                id: userData.avatarId,
              },
            },
          },
        }
      ),
    },
  })
}

export const getUserFollowings = (userId: string) =>
  ({
    method: 'GET',
    url: `/users/${userId}/followings`,
  } as FetchOptions)

export const getUserPublicApps = (userId: string) =>
  ({
    method: 'GET',
    url: `/users/${userId}/public_apps`,
  } as FetchOptions)

export const getStreamToken = (userId: string) =>
  ({
    method: 'GET',
    url: `/users/${userId}`,
    query: {
      include_meta: {
        stream_token: true,
      },
    },
  } as FetchOptions)

export const useCurrentUserId = () => {
  const currentUserId = useMemo(() => {
    const auth = localStorage.getItem('auth')
    if (auth) {
      const { currentUserId } = JSON.parse(auth)
      return currentUserId
    } else {
      return null
    }
  }, [])

  const queryData = useQuery({
    queryKey: ['streamToken', currentUserId],
    queryFn: () => fetchClient(getStreamToken(currentUserId)),
  })
  return queryData
}

export const useUserMutation = () => {
  const { refetchUser } = useSession()
  const userMutation = useMutation({
    mutationFn: (userData: UserMutateParams) => {
      return updateUser(userData)
    },
    onSuccess: () => {
      refetchUser()
    },
  })

  return userMutation
}
