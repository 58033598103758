'use client'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classNames from 'classnames'
export default function ArrowButton({
  side = 'left',
  className,
  onClick,
}: {
  side?: 'left' | 'right'

  className?: string
  onClick?: any
}) {
  return (
    <div
      className={classNames(
        // className,
        'ring-neptune-light !text-neptune-dark z-2000 absolute top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full !bg-white ring ring-opacity-50 hover:ring-opacity-100',
        {
          'left-[62px]': side === 'left',
          'right-[62px]': side === 'right',
        }
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon
        className="z-2000 !text-neptune-dark relative"
        icon={side === 'left' ? faArrowLeft : faArrowRight}
        size="lg"
      />
    </div>
  )
}
