'use client'

import React from 'react'
import { useRuntimeRefsStore } from './Hooks/Runtime/useRuntimeRefsStore'
import { AnimatePresence, motion } from 'framer-motion'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faSquare } from '@fortawesome/pro-solid-svg-icons'
import useRuntimeStore from './Hooks/Runtime/useRuntimeStore'
import classNames from 'classnames'
import useMissionStore from '../../Stores/useMissionStore'
import useMissionBuilderStore from '../../Stores/useMissionBuilderStore'
import useKidScript from './Hooks/useKidScript'
import { useParams } from 'next/navigation'

interface EditorActionButtonsProps {
  bottomOverride?: string
}

interface ActionButtonsProps {}

export const RunButton = ({
  setRunning,
  runCallback,
  overrideClassName,
}: {
  setRunning?: any
  runCallback?: any
  overrideClassName?: string
}) => {
  const params = useParams()
  const { takeScreenshot } = useKidScript()
  const { editorRefs, interpreterRefs } = useRuntimeRefsStore()
  const { setKidscriptRunning } = useRuntimeStore()
  const { setShowMissionEditor } = useMissionBuilderStore()
  const { blockNextStep } = useMissionStore()
  const handleRunClick = async () => {
    const mainEditorRef = editorRefs.main
    const interpreterRef = interpreterRefs.main
    if (mainEditorRef === null) return
    try {
      const documents = mainEditorRef.getKidScript()
      //@ts-ignore
      await KidScript.onDependenciesResolved(
        documents,
        //@ts-ignore
        KidScript.Version,
        async () => {
          const instructions = mainEditorRef.getInstructionTree()
          if (instructions !== false) {
            const state = interpreterRef.getState()
            if (state === 'IDLE') return
            const promise = interpreterRef.execute(instructions)
            setRunning(true)
            setKidscriptRunning(true)
            setShowMissionEditor(false)
            await promise
              .then(
                (result) => {
                  if (!interpreterRef.isStopped()) {
                    // if (projectsMatch) {
                    //   takeScreenshot()
                    // }
                  }
                  takeScreenshot(params?.projectId as string)
                  typeof runCallback === 'function' && runCallback()
                  // dispatch(editorActions.disableEditMode());
                },
                (error) => {
                  console.log('error', error)
                }
              )
              .catch((error) => {
                console.log('error', error)
              })
          }
        }
      )
    } catch (e) {
      console.log('error:', e)
    }
  }
  return (
    <motion.div
      id="ActionButtons"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Button
        onClick={handleRunClick}
        className={classNames('flex !flex-col !pl-1.5', overrideClassName)}
        context="success"
        size="small"
        disabled={blockNextStep}
      >
        <div className="bg-titan-light pointer-events-auto mr-2 inline-flex h-8 w-8 items-center justify-center rounded-full bg-opacity-40 pl-2 pr-1.5">
          <FontAwesomeIcon size="sm" icon={faPlay} />
        </div>
        Run
      </Button>
    </motion.div>
  )
}
const StopButton = ({ setRunning }: { setRunning: any }) => {
  const { editorRefs, interpreterRefs } = useRuntimeRefsStore()
  const { setShowMissionEditor } = useMissionBuilderStore()
  const { blockNextStep } = useMissionStore()
  // const { setShowPortal } = useGuidedMissions()
  // const { interpreterRef } = useRuntime()

  const handleStopClick = () => {
    const interpreterRef = interpreterRefs.main
    if (interpreterRef?.getState() !== 'READY') {
      interpreterRef.stop()
    }
    // setShowPortal(true)
    setRunning(false)
    setShowMissionEditor(true)
  }
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Button
        onClick={handleStopClick}
        className="flex !flex-col"
        context="danger"
        size="small"
        disabled={blockNextStep}
      >
        <div className="bg-mars-light pointer-events-auto mr-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-opacity-40">
          <FontAwesomeIcon size="sm" icon={faSquare} />
        </div>
        Stop
      </Button>
    </motion.div>
  )
}

const EditorActionButtons: React.FC<EditorActionButtonsProps> = ({
  bottomOverride = 'bottom-6',
}: {
  bottomOverride?: string
}) => {
  const { kidscriptRunning, setKidscriptRunning } = useRuntimeStore()
  const { currentStep, guidedMissionMode } = useMissionStore()

  if (currentStep && guidedMissionMode) {
    return null
  }
  const className = classNames(
    'pointer-events-auto absolute right-6 z-[9999]',
    bottomOverride
  )
  console.log('kidscriptRunning', kidscriptRunning)
  return (
    <div className={className}>
      {kidscriptRunning ? (
        <AnimatePresence>
          <StopButton setRunning={setKidscriptRunning} />
        </AnimatePresence>
      ) : (
        <AnimatePresence>
          <RunButton setRunning={setKidscriptRunning} />
        </AnimatePresence>
      )}
    </div>
  )
}

export default EditorActionButtons
