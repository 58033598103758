'use client'

import EditorToolbar from './EditorToolbar'
import EditorOverlay from './EditorOverlay'
import MissionBuilderToolBar from '../Missions/MissionBuilder/MissionBuilderToolBar'

const EditorStyles: React.CSSProperties = {
  width: '100%',
  height: 'calc(100% - 48px)',
  position: 'absolute',
  bottom: 0,
  top: '48px',
  left: 0,
  opacity: 1,
}

interface EditorProps {
  mode?: 'activity_validator' | 'mission_builder' | 'studio' | 'query_builder'
}
export default function Editor({ mode }: EditorProps) {
  return (
    <>
      {mode === 'studio' && <EditorToolbar />}
      {mode === 'mission_builder' && <MissionBuilderToolBar />}
      <div className="editor relative" id="editor" style={EditorStyles}></div>
      {/* {mode === 'studio' && (
        <MenuBar
          showToolbox={showToolbox}
          setShowToolbox={setShowToolbox}
          saveCurrentDocuments={saveCurrentDocuments}
        />
      )} */}
      <EditorOverlay />
    </>
  )
}
