import React, { useContext } from 'react'
import classNames from 'classnames'

const avatar = {
  container: 'relative rounded-full flex justify-center items-center',
  base: 'rounded-full',
  size: {
    small: 'w-8 h-8',
    large: 'w-[112px] h-[112px]',
    extraLarge: 'w-2 h-2',
  },
}

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The size of the avatar
   */
  size?: 'large' | 'regular' | 'small'
  /**
   * Alternative text for the avatar image
   */
  alt?: string
  /**
   * The source for the avatar image
   */
  src: string
  onClick?: any
}

const Avatar = function Avatar(props, ref) {
  const { size = 'regular', src, alt, className, handleClick, ...other } = props

  const baseContainerStyle = avatar.container
  const baseStyle = avatar.base
  const sizeStyles = {
    small: avatar.size.small,
    large: avatar.size.large,
    extraLarge: avatar.size.extraLarge,
  }

  //@ts-ignore
  const cls = classNames(baseContainerStyle, sizeStyles[size], className, {
    'cursor-pointer': !!handleClick,
  })

  return (
    <div className={cls} {...other} onClick={handleClick}>
      <img
        className={baseStyle}
        src={
          src ||
          'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/blank-user.png'
        }
        alt={alt}
        // loading="lazy"
      />
      {/* <div
        className="absolute inset-0 rounded-full shadow-inner"
        aria-hidden="true"
      ></div> */}
    </div>
  )
}

export default Avatar
