import { useQuery } from '@tanstack/react-query'
import fetchClient from '../fetch/fetchClient'
import { getStreamToken } from './UserFetch'

const useUser = (currentUserId: string, { enabled }: any) =>
  useQuery({
    queryKey: ['user', currentUserId],
    queryFn: () => fetchClient(getStreamToken(currentUserId)),
    enabled: !!currentUserId,
  })

export default useUser
