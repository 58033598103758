import { Card } from '@codeverse/helios/card'
import classNames from 'classnames'
import LoginForm from './LoginForm'

export default function Login() {
  const CardClasses = classNames(
    'md:w-[400px] lg:w-[400px]',
    'min-h-[400px] w-full mx-6'
  )
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Card className={CardClasses}>
        <LoginForm />
      </Card>
    </div>
  )
}
