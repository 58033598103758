import { Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'

import { useMissionStepsMultipleChoice } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useQuizStateStore from 'apps/studio-shared/src/Stores/useQuizStateStore'
import { useParams, usePathname } from 'next/navigation'
import QuizQuestion from './QuizQuestion'
import Button from '@codeverse/helios/button'
import { useMutateMultipleChoiceAnswer } from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStepActions from '../../MissionBuilder/MissionStep/useMissionStepActions'

interface QuizProps {
  showSideHeader?: boolean
}

const Quiz: React.FC<QuizProps> = ({ showSideHeader = true }) => {
  const pathname = usePathname()

  const isMissionBuilder = pathname.includes('mission_builder')

  const { previewMissionMode, setPreviewMissionMode, setShowMissionEditor } =
    useMissionBuilderStore()
  const { currentStep, setSubmittedAnswer, submittedAnswer, setBlockNextStep } =
    useMissionStore()
  const { handleNextStep } = useMissionStepActions()
  const params = useParams()
  const { answer, answer_a, answer_b, answer_c, answer_d, question } =
    useQuizStateStore()

  const [currentMultipleChoiceQuestion, setCurrentMultipleChoiceQuestion] =
    useState<{
      id: string | null
      question: string
      answer_a: string
      answer_b: string
      answer_c: string
      answer_d: string
      answer: string
    }>({
      id: '',
      question: '',
      answer_a: '',
      answer_b: '',
      answer_c: '',
      answer_d: '',
      answer: '',
    })
  const [selectedAnswer, setSelectedAnswer] = useState('')

  const multipleChoiceAnswerMutation = useMutateMultipleChoiceAnswer()

  const { data: missionStepData } = useMissionStepsMultipleChoice(
    currentStep?.id
  )

  const missionId = params?.id as string

  useEffect(() => {
    if (missionStepData) {
      setCurrentMultipleChoiceQuestion(missionStepData[0])
    } else {
      setCurrentMultipleChoiceQuestion({
        id: null,
        question: question,
        answer_a: answer_a,
        answer_b: answer_b,
        answer_c: answer_c,
        answer_d: answer_d,
        answer: answer,
      })
    }
  }, [missionStepData])

  // useEffect(() => {
  //   console.log('currentMultipleChoiceQuestion', currentMultipleChoiceQuestion)
  // }, [currentMultipleChoiceQuestion])

  // useEffect(() => {
  //   console.log('guidedMissionState', guidedMissionState)
  // }, [guidedMissionState])

  // useEffect(() => {
  //   setBlockNextStep(true)
  // }, [currentStep])

  useEffect(() => {
    if (currentStep) {
      const currentQuizScore = JSON.parse(
        localStorage.getItem('currentQuizScore') || '{}'
      )
      if (
        currentQuizScore &&
        currentQuizScore[missionId] &&
        currentQuizScore[missionId][currentStep.id]
      ) {
        const currentQuiz =
          currentQuizScore[missionId] &&
          currentQuizScore[missionId][currentStep.id]
        if (currentQuiz.completed) {
          setBlockNextStep(false)
          setSubmittedAnswer(true)
          setSelectedAnswer(currentQuiz.answer)
        }
      }
    }
  }, [currentStep, missionId])

  const handleSubmit = () => {
    if (isMissionBuilder) {
      handleNextStep()
      return
    }
    multipleChoiceAnswerMutation.mutate(
      {
        multiple_choice_id: currentMultipleChoiceQuestion.id,
        answer: selectedAnswer,
      },
      {
        onSuccess: () => {
          setSubmittedAnswer(true)
          setBlockNextStep(false)

          const currentQuizScore = JSON.parse(
            localStorage.getItem('currentQuizScore') || '{}'
          )
          const newCurrentQuizScore = Object.assign({}, currentQuizScore)
          if (newCurrentQuizScore[missionId]) {
            newCurrentQuizScore[missionId][currentStep.id] = Object.assign(
              {},
              currentQuizScore[missionId][currentStep.id],
              {
                completed: true,
                correct:
                  currentMultipleChoiceQuestion.answer === selectedAnswer,
                answer: selectedAnswer,
              }
            )
            localStorage.setItem(
              'currentQuizScore',
              JSON.stringify(newCurrentQuizScore)
            )
          }
        },
      }
    )
  }

  if (!currentMultipleChoiceQuestion) {
    return
  }

  return (
    <div className="mx-auto min-w-[80%]" style={{}}>
      {currentStep.content}
      <div>
        {!showSideHeader && (
          <div className="bg-moon-darkest mb-0 mt-8 flex h-7 w-full items-center justify-center rounded-t-[24px] text-white">
            QUESTION
          </div>
        )}
        <div
          className={`quiz bg-moon-darkest after:bg-nebula after:content-[' '] pl-18 relative mt-8 flex w-full items-center rounded-3xl p-8 after:absolute after:left-0 after:top-0 after:h-full after:w-12 after:rounded-bl-3xl after:rounded-tl-3xl ${
            showSideHeader ? '' : 'top-header'
          }`}
        >
          {showSideHeader && (
            <div className="side-header font-poppins-bold bg-nebula z-1000 absolute left-[-12px] top-1/2 translate-y-[-50%] -rotate-90 transform text-base text-white">
              QUESTION
            </div>
          )}
          <div className="font-poppins-regular ml-8 flex min-h-[80px] items-center text-2xl text-white">
            {currentMultipleChoiceQuestion?.question}
          </div>
        </div>
      </div>
      <div className="spacing mt-6 flex items-center justify-center">
        <h6
          className="font-poppins-bold text-sm tracking-widest"
          style={{ color: 'white' }}
        >
          CHOOSE ONE
        </h6>
      </div>
      <div className="grid grid-cols-2 gap-3">
        {/* {currentStep} */}
        <QuizQuestion
          questionIdentifier={'a'}
          correctAnswer={currentMultipleChoiceQuestion?.answer}
          question={currentMultipleChoiceQuestion?.answer_a}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          submittedAnswer={submittedAnswer}
        />
        <QuizQuestion
          questionIdentifier={'b'}
          correctAnswer={currentMultipleChoiceQuestion?.answer}
          question={currentMultipleChoiceQuestion?.answer_b}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          submittedAnswer={submittedAnswer}
        />
        <QuizQuestion
          questionIdentifier={'c'}
          correctAnswer={currentMultipleChoiceQuestion?.answer}
          question={currentMultipleChoiceQuestion?.answer_c}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          submittedAnswer={submittedAnswer}
        />
        <QuizQuestion
          questionIdentifier={'d'}
          correctAnswer={currentMultipleChoiceQuestion?.answer}
          question={currentMultipleChoiceQuestion?.answer_d}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          submittedAnswer={submittedAnswer}
        />
      </div>

      <div className="mt-6 flex w-full justify-center">
        <Transition
          show={!!selectedAnswer && !submittedAnswer}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <Button
            size="regular"
            className="submit-button btn btn-status-success btn-sm px-12 opacity-100 transition-opacity duration-1000"
            context="nebula"
            onClick={handleSubmit}
          >
            <span>Submit</span>
          </Button>
        </Transition>
        {submittedAnswer &&
          currentMultipleChoiceQuestion?.answer &&
          selectedAnswer === currentMultipleChoiceQuestion?.answer && (
            <div className="font-poppins-semibold text-titan text-2xl">
              Correct!
            </div>
          )}
        {submittedAnswer &&
          currentMultipleChoiceQuestion?.answer &&
          selectedAnswer !== currentMultipleChoiceQuestion?.answer && (
            <div className="font-poppins-semibold text-2xl">Nice Try!</div>
          )}
      </div>
    </div>
  )
}

export default Quiz
