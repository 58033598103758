import { useOuterClick, useScrollTo } from '@codeverse/hooks'
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMission from 'apps/studio/hooks/data/Mission/useMission'
import { useParams, usePathname, useRouter } from 'next/navigation'
import React, { useEffect, useRef, useState } from 'react'
import useMissionStepActions from '../../MissionStep/useMissionStepActions'
import useUIStore from 'apps/studio-shared/src/Stores/useUIStore'
import useCreateQueryParams from 'apps/studio-shared/src/Hooks/useCreateQueryParams'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useQueryClient } from '@tanstack/react-query'

interface ActionButtonProps {
  currentStep: any
}

const ActionButton: React.FC<ActionButtonProps> = ({ currentStep }) => {
  const router = useRouter()
  const params = useParams()
  const pathname = usePathname()
  //@ts-ignore
  const { data: missionData } = useMission(params.id)
  const { replayMissionStep } = useMissionStepActions()
  const { setShowMissionEditor, setPreviewMissionMode } =
    useMissionBuilderStore()
  const [showActions, setShowActions] = useState(false)
  const [scrollToRef, setShouldScrollTo] = useScrollTo()
  const { setMissionStepDeleteConfirmationModal } = useUIStore()
  const queryClient = useQueryClient()

  const { setCurrentStep, setGuidedMissionMode } = useMissionStore()
  const createQueryParams = useCreateQueryParams()

  useOuterClick(scrollToRef, () => setShowActions(false))

  useEffect(() => {
    if (showActions && scrollToRef) {
      //@ts-ignore
      setShouldScrollTo(true)
    }
  }, [showActions, scrollToRef])

  const handleClick = () => {
    setShowActions(true)
  }
  return (
    <div className="relative flex items-center justify-end pr-1">
      <div
        className="bg-neptune ml-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
        onClick={() => handleClick()}
      >
        <FontAwesomeIcon icon={faEllipsis} />
      </div>

      {showActions && (
        <div
          //@ts-ignore
          ref={scrollToRef}
          className="z-2000 absolute right-8 top-3 w-[209px] rounded-xl bg-white p-2"
        >
          <div
            className="font-poppins-medium hover:bg-moon-lightest hover:text-moon-darkest text-18 text-moon mb-2 cursor-pointer rounded-lg px-2 py-2"
            onClick={() => {
              queryClient.invalidateQueries({
                queryKey: ['missionStep', currentStep.id],
              })
              router.push(
                `/mission_builder/${missionData?.id}/steps/${currentStep.id}`
              )
            }}
          >
            Edit
          </div>
          {/* <div
            className="font-poppins-medium hover:bg-moon-lightest hover:text-moon-darkest text-18 text-moon mb-2 cursor-pointer rounded-lg px-2 py-2"
            onClick={() => {
              // setCurrentStep(currentStep)
              setShowActions(false)
              setShowMissionEditor(false)
              setPreviewMissionMode(true)
              setGuidedMissionMode(true)
              // setShowMissionEditor(false)
              // setShowPortal(true)
              // setPreviewCurrentStep(true)
              replayMissionStep(currentStep, false, [])
            }}
          >
            Preview Step
          </div> */}
          <div
            className="font-poppins-medium hover:bg-moon-lightest hover:text-moon-darkest text-18 text-moon cursor-pointer rounded-lg px-2 py-2"
            onClick={() => {
              // openUI('confirmMissionStepDeletion')
              setShowActions(false)
              setMissionStepDeleteConfirmationModal(true)
              // setMissionStepToDelete(currentStep.id)
              router.push(
                pathname + '?' + createQueryParams('deleteStep', currentStep.id)
              )
            }}
          >
            Delete step
          </div>
        </div>
      )}
    </div>
  )
}

export default ActionButton
