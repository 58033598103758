import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dispatch, SetStateAction } from 'react'
import { useResponsive } from '@codeverse/hooks'

import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'

import ToolboxLibraryObject from './ToolboxLibraryObject'

interface ToolBoxLibraryProps {
  objects: Array<any>
  setShowToolbox: Dispatch<SetStateAction<boolean>>
  showToolbox: boolean
  categoryFilterValue: string
}

const ToolBoxLibrary: React.FC<ToolBoxLibraryProps> = ({
  objects,
  showToolbox,
  setShowToolbox,
  categoryFilterValue,
}) => {
  const [currentDragObject, setCurrentDragObject] = useState(null)
  const { isSmallToolbox } = useResponsive()
  const [tempObjects, setTempObjects] = useState<Array<any>>([])

  const [range, setRange] = useState({
    start: 0,
    end: 20,
  })

  useEffect(() => {
    const first = objects.slice(0, 20)
    setTempObjects(objects)
    setRange({
      start: 20,
      end: 40,
    })
  }, [objects, categoryFilterValue])

  const addNew = useCallback(() => {
    setTempObjects(tempObjects.concat(objects.slice(range.start, range.end)))
    setRange({
      start: range.start + 20,
      end: range.end + 20,
    })
  }, [objects, tempObjects, range])

  const objectsClassname = classNames(
    'relative grid h-auto gap-4 w-full px-4 pt-4 pb-8',
    {
      'grid-cols-4': !isSmallToolbox,
      'grid-cols-5': isSmallToolbox,
    }
  )

  // const Objects = useMemo(() => {
  //   if (!showToolbox) {
  //     return null
  //   }

  //   return (

  //   )
  // }, [tempObjects, showToolbox])

  const ref = useRef<any>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [categoryFilterValue, ref])

  return (
    <div
      id="ToolboxLibrary"
      className="overflow-y-scroll "
      style={{ height: '100%', WebkitOverflowScrolling: 'touch' }}
      ref={ref}
    >
      <InfiniteScroll
        key={categoryFilterValue}
        dataLength={tempObjects.length}
        next={() => {
          // addNew()
        }}
        hasMore={true}
        loader={<></>}
        scrollThreshold={'300px'}
        scrollableTarget={'ToolboxLibrary'}
        className={objectsClassname}
      >
        {tempObjects.map((object, index) => (
          <ToolboxLibraryObject
            showToolbox={showToolbox}
            setShowToolbox={setShowToolbox}
            key={index}
            object={object}
            setCurrentDragObject={setCurrentDragObject}
            currentDragObject={currentDragObject}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default ToolBoxLibrary
