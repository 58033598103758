import React, { useEffect } from 'react'
import useMissionStore from '../../../Stores/useMissionStore'
import MissionStepDisplay from './MissionStepCardDisplay'
import MissionCompleteModal from './Modals/MissionCompleteModal'
import { useWindowSize } from '@codeverse/hooks'
import { useUIStore } from 'apps/studio-shared/src/Stores'

import Confetti from 'react-confetti'
interface GuidedMissionsProps {}
const colors = [
  '#745bff',
  '#ffc745',
  '#17e898',
  '#56ade4',
  '#ff57d0',
  '#fd8e86',
  '#8d98c6',
]

const GuidedMissions: React.FC<GuidedMissionsProps> = () => {
  const { showPortal, guidedMissionMode, setShowPortal } = useMissionStore()
  const size = useWindowSize()
  const { showMissionCompleteModal } = useUIStore()
  // const { state, reset, setShowPortal, setShowGuidedMission } =
  //   useInitializeGuidedMissions()
  // const { missionAchievement } = useGuidedMissionsData()

  // const {
  //   customizeMode,
  //   showPortal,
  //   showGuidedMission,
  //   showMissionEditor,
  //   showMissionCompleteModal,
  // } = state
  // const {} = useUIState()
  // const size = useWindowSize()

  // useEffect(() => {
  //   return () => {
  //     // reset()
  //   }
  // }, [])

  useEffect(() => {
    if (!guidedMissionMode) {
      setShowPortal(false)
    }
  }, [guidedMissionMode])

  // useEffect(() => {
  //   if (missionAchievement) {
  //     if (missionAchievement.completed && !customizeMode) {
  //       setShowGuidedMission(false)
  //     } else {
  //       setShowGuidedMission(true)
  //     }
  //   }
  // }, [missionAchievement, customizeMode])

  return (
    <>
      <MissionStepDisplay />
      {/* <FullscreenMissionStepDisplay /> */}
      {/* <CanvasMissionStepDisplay /> */}
      <MissionCompleteModal />
      {/* {showPortal && (
        <MissionStepEditorPortal>
          <MissionStepEditorPortalContent />
        </MissionStepEditorPortal>
      )} */}
    </>
  )
}

export default GuidedMissions
