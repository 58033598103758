'use client'

import React, { useMemo, useState } from 'react'

import {
  useCurrentUser,
  useUserFollowers,
  useUserFollowings,
} from 'apps/studio-shared/src/Data/User/UserDataHooks'
import Image from 'next/image'
import { cn } from '@codeverse/hooks'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { motion } from 'framer-motion'
import useUser from 'apps/studio-shared/src/Data/User/useUser'
import determineAvatar from '../Nav/determineUserAvatar'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { useRouter } from 'next/navigation'
import { useQuery } from '@tanstack/react-query'
import fetchClientGql from 'apps/studio-shared/src/Data/fetch/fetchClientGql'
import { gql } from 'graphql-request'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import useAuth from 'apps/studio-shared/src/Hooks/Auth/useAuth'

export default function AvatarFriends() {
  const { setProfileModal } = useUIStore()
  const router = useRouter()

  const { currentUserId } = useAuth()
  const { setFriendsModal } = useUIStore()
  const { data: userData } = useUser(currentUserId, {
    enabled: true,
  })

  const { data: userFollowers, refetch: refetchFollowers } = useUserFollowers(
    userData?.data?.id
  )
  const { data: userFollowings, refetch: refetchFollowings } =
    useUserFollowings(userData?.data?.id)

  const [hover, setHover] = useState(null)

  const avatars = useMemo(() => {
    return userFollowings?.slice(0, 5).map((follower: any, index: any) => {
      const id = `follower-${follower.following.id}`
      const isUniqueHover = hover === follower.following.id
      const isHover = hover && hover !== follower.following.id
      return (
        <div key={id}>
          <motion.div
            id={`ProfileNav_UserAvatarIcon${index}`}
            key={follower.following.id}
            className={cn(
              'relative inline-block transform overflow-hidden rounded-full transition-all',
              'hover:z-[9999]',
              '!h-10 !w-10',
              'border-2 border-[#42476D]',
              'shadow-md',
              `my-anchor-element-${follower.following.id}`,
              {
                'z-[5] -translate-x-4': index === 0,
                'z-[4] -translate-x-6': index === 1,
                'z-[3] -translate-x-8': index === 2,
                'z-[2] -translate-x-10': index === 3,
                'z-[1] -translate-x-12': index === 4,
                '-translate-x-15 z-[6]': index === 5,
                'opacity-100': isUniqueHover,
                'opacity-10': isHover && !isUniqueHover,
              }
            )}
          >
            <motion.img
              alt="avatar"
              className="overflow-hidden rounded-full"
              src={`https://api.codeverse.com/img/users/${follower.following.username}/thumbnail`}
              onClick={(e) => {
                e.stopPropagation()
                setProfileModal(true)
                router.push(`/play?username=${follower.following?.username}`)
              }}
              onHoverStart={() => {
                setHover(follower.following.id)
              }}
              onHoverEnd={() => {
                setHover(null)
              }}
            />
          </motion.div>
          <ReactTooltip
            className="font-jakarta-sans !z-[3000] !rounded-[22px] !px-2 !py-1 text-xs font-medium !text-white !opacity-100 shadow-xl"
            place="bottom"
            // effect="solid"
            id={id}
            delayShow={250}
            anchorSelect={`.my-anchor-element-${follower.following.id}`}
            noArrow
          >
            {follower.following.username}
          </ReactTooltip>
        </div>
      )
    })
  }, [hover, userFollowings])

  return (
    <div className="mt-6 flex flex-col items-center justify-center rounded-xl bg-[#101432] bg-opacity-60 p-4">
      <div
        id="ProfilePanel_Friends"
        className="font-jakarta-sans grid grid-cols-2 gap-4 pb-4 text-xs font-medium"
        onClick={(e) => {
          e.stopPropagation()
          setFriendsModal(true)
        }}
      >
        <div className="text-white">
          {userFollowings?.length}{' '}
          <span className="text-[#596087]">Following</span>
        </div>
        <div className="text-white">
          {userFollowers?.length}{' '}
          <span className="text-[#596087]">Followers</span>
        </div>
      </div>
      <div className="flex min-h-[45px] w-full translate-x-2 transform">
        <div>
          <motion.div
            className={cn(
              'relative inline-block transform overflow-hidden rounded-full transition-all',
              'hover:z-[9999]',
              '!h-10 !w-10',
              'flex items-center justify-center overflow-hidden rounded-full ring ring-[#a594ff]',
              'z-[6] -translate-x-0 bg-[#101432] shadow-md',
              `my-anchor-element-add`
            )}
            onClick={(e) => {
              e.stopPropagation()
              setFriendsModal(true)
            }}
          >
            {/* <motion.div
              className="flex items-center justify-center overflow-hidden rounded-full ring ring-[#a594ff]"
              onClick={(e) => {
                e.stopPropagation()
                setFriendsModal(true)
              }}
            > */}
            <FontAwesomeIcon icon={faPlus} className="text-[#a594ff]" />
            {/* </motion.div> */}
          </motion.div>
          <ReactTooltip
            className="font-jakarta-sans !z-[3000] !rounded-[22px] !px-2 !py-1 text-xs font-medium !text-white !opacity-100 shadow-xl"
            place="bottom"
            // effect="solid"
            delayShow={250}
            anchorSelect={`.my-anchor-element-add`}
            noArrow
          >
            Add Friends
          </ReactTooltip>
        </div>
        {avatars}
      </div>
    </div>
  )
}
