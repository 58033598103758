import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { motion, useMotionValue } from 'framer-motion'
import classNames from 'classnames'
import { useSpring, animated, useTransition } from '@react-spring/web'
import Hammer from 'react-hammerjs-18'
import { useQueryClient } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { values } from 'lodash'
import useRuntimeStore from '../Hooks/Runtime/useRuntimeStore'
import DraggingPortal from '../DraggingPortal'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { useMainEditorRef } from '../Hooks/useMainEditorRef'
import useKeyRingStore from 'apps/studio-shared/src/Stores/useKeyringStore'

declare global {
  interface Window {
    game: {
      renderer: {
        scale: any
      }
    }
  }
}

interface ToolboxLibraryObjectProps {
  object: any
  showToolbox: boolean
  currentDragObject: any
  setCurrentDragObject: any
  setShowToolbox: Dispatch<SetStateAction<boolean>>
  dropProgressState?: any
  insertable?: any
  position?: number
  setDropProgressState?: Dispatch<SetStateAction<any>>
  type?: 'mission' | 'toolbox'
}

export const options = {
  recognizers: {
    press: {
      time: 251,
      threshold: 250,
    },
  },
}
const ToolboxLibraryObject: React.FC<ToolboxLibraryObjectProps> = ({
  object,
  setCurrentDragObject,
  currentDragObject,
  showToolbox,
  setShowToolbox,
  position,
  dropProgressState,
  insertable,
  setDropProgressState,
  type = 'toolbox',
}) => {
  const queryClient = useQueryClient()
  const { currentKeyRing, setCurrentKeyRing, setShowKeyring } =
    useKeyRingStore()
  const { toolboxLibraryFilter, setCurrentToolboxObject } = useRuntimeStore()
  const {
    setCurrentLibraryFilterObject,
    setMissionInsertableToSave,
    missionInsertableToSave,
    toolboxMissionStepInsertable,
  } = useMissionBuilderStore()
  const { currentStep } = useMissionStore()

  const [selected, setSelected] = useState(false)
  const [panning, setPanning] = useState(false)
  const objectX = useMotionValue(0)
  const objectY = useMotionValue(0)
  const containerRef = useRef<any>(null)
  const containerClassnames = classNames(
    '2xl:hover:scale-100 flex flex-col hover:ring hover:ring-earth hover:scale-105 relative rounded-lg shadow-lg transition-all ',
    {
      // 'w-[112px] h-[132px]': type === 'mission',
      // 'ring ring-earth-dark ring-opacity-40':
      //   missionInsertableToSave?.object === object.name,
    }
    // {
    //   absolute: panning,
    // }
  )
  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }))
  const [{ opacity }, opacityApi] = useSpring(() => ({ opacity: 1 }))
  const [showHiddenState, setShowHiddenState] = useState(false)
  const editorRef = useMainEditorRef()

  const debug = false

  const isLocked = useMemo(() => {
    return object.ungrantedKeyring()
  }, [object])

  useEffect(() => {
    if (debug) {
    }
  }, [debug, panning])

  const handlePressUp = (event, object) => {
    setPanning(false)
  }

  const handlePress = (event, object) => {
    setCurrentDragObject(object.name)
    setPanning(true)

    // if (toolboxLibraryFilter) {
    //   KidScript.library.getObject(object.name).ensureLoaded()
    // }
  }

  const handleUnlock = (keyringId: string) => {
    setCurrentKeyRing(keyringId)
    setShowKeyring(true)
  }

  const handlePan = (event, object) => {
    if (panning) {
      const { x, y } = event.center
      //@ts-ignore
      const { left } = document
        .getElementById('runtimeEnvironment')
        .getBoundingClientRect()
      const scale = x - (left - 50)
      opacityApi.start({ opacity: 1 - scale / 100 })
      api.start({
        x: x - containerRef.current.domElement.offsetWidth / 2,
        y: y - containerRef.current.domElement.offsetHeight / 2,
        immediate: true,
      })
      if (event.isFinal) {
        setPanning(false)
        //@ts-ignore
        const { x: canvasX, y: canvasY } = document
          .getElementById('preview')
          .getBoundingClientRect()
        const ratio = window.game.renderer.scale.ratio
        const { x, y } = event.center
        const left = Math.round(
          //@ts-ignore
          window.editor.preview.renderer.scale.leftPositionPixelsToCoords(
            (x - canvasX) / ratio
          )
        )
        const top = Math.round(
          //@ts-ignore
          window.editor.preview.renderer.scale.topPositionPixelsToCoords(
            y / ratio
          )
        )
        addObject(event, object, left, top)
        setShowToolbox(false)
      }
    }
  }

  const addObject = (event, object, left = 50, top = 50) => {
    if (!editorRef) return
    //@ts-ignore
    const varName = window.editor.documents.classNameToAvailableVariableName(
      object.name
    )

    if (left >= 0 && top >= 0) {
      if (object.engine.instantiatable) {
        let code = ''
        if (
          object.name === 'Timer' ||
          object.name === 'SoundEffects' ||
          object.name === 'Leaderboard'
        ) {
          code = `var ${varName} = new ${object.name}()`
        } else {
          code = `var ${varName} = new ${object.name}(${left}, ${top})`
        }
        if (currentStep && insertable) {
          const modifiedInsertable = {
            display: insertable.display,
            object: object.name,
            position: insertable.position,
            x: insertable.x,
            y: insertable.y,
            lineAddress: insertable.line_address,
          }
          editorRef.applyMissionStepInsertable(modifiedInsertable)
        } else {
          window.editor.codeChanger.insert(code, [object.name])
        }
      } else {
        // @ts-ignore
        window.editor.codeChanger.insert(null, [object.name])
      }
      if (typeof setDropProgressState === 'function') {
        setDropProgressState(
          Object.assign({}, dropProgressState, {
            [object.name]: {
              dropped: true,
              objectName: object.name,
              position: position,
            },
          })
        )
      }
    }
    setShowToolbox(false)
  }
  useEffect(() => {
    if (!showToolbox && panning && typeof setDropProgressState !== 'function') {
      setPanning(false)
    }
  }, [panning, showToolbox])

  useEffect(() => {
    if (currentDragObject !== object.name && panning) {
      setPanning(false)
    }
  }, [currentDragObject, object])

  const latestDroppedPosition = useMemo(() => {
    // get the latest dropped position
    if (typeof setDropProgressState === 'function') {
      const droppedPositions = values(dropProgressState)
        .filter((item) => item.dropped)
        .sort((a, b) => a.position - b.position)
      if (droppedPositions.length > 0) {
        return droppedPositions[droppedPositions.length - 1].position
      } else {
        return -1
      }
    }
  }, [dropProgressState])

  const currentDropState = useMemo(() => {
    if (dropProgressState) {
      return dropProgressState[object.name]
    }
  }, [dropProgressState])

  useEffect(() => {
    if (typeof setDropProgressState === 'function') {
      const currentIndex = values(dropProgressState)
        //@ts-ignore
        .sort((a, b) => a.position - b.position)
        .findIndex((item) => item.position === currentDropState.position)

      const showHidden =
        latestDroppedPosition < currentDropState.position &&
        currentDropState.position !== latestDroppedPosition + 1

      if (currentDropState && currentDropState.position !== 0 && showHidden) {
        setShowHiddenState(true)
      } else {
        setShowHiddenState(false)
      }
    }
  }, [currentDropState, latestDroppedPosition, dropProgressState])

  const handleClick = (event, object) => {
    if (toolboxMissionStepInsertable) {
      setSelected(true)
    } else if (toolboxLibraryFilter) {
      //@ts-ignore
      KidScript.library
        .getObject(object.name)
        .ensureLoaded()
        .then(() => {
          setCurrentLibraryFilterObject(object)
        })
    } else {
      if (!isLocked) {
        //@ts-ignore
        KidScript.library
          .getObject(object.name)
          .ensureLoaded()
          .then(() => {
            setCurrentToolboxObject(object)
          })
      }
    }
  }

  if (currentDropState?.dropped) {
    return (
      <div className="relative">
        <div
          className={classNames(containerClassnames, {
            'opacity-50': type === 'mission',
          })}
          style={{
            height: '0%',
            paddingTop: '130%',
            touchAction: 'none',
            opacity: '50%',
          }}
        >
          <div id="ToolboxLibraryObject" className="flex flex-auto">
            <div className="img">
              {!panning && (
                <div className="rounded-8 absolute inset-0 bg-white" />
              )}
              {!panning && (
                <img
                  className="absolute left-1/2 top-1/2"
                  draggable={false}
                  style={{
                    maxWidth: '70%',
                    transform: 'translate(-50%, -63%)',
                    maxHeight: '96px',
                    userSelect: 'none',
                  }}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    object.getIcon()
                  )}`}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="z-2000 absolute inset-0 left-1/2 top-1/2 flex h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-white">
            <FontAwesomeIcon
              className="text-titan-dark"
              size="2x"
              icon={faCheck}
            />
          </div>
        </div>
      </div>
    )
  }

  // const showHiddenState =  typeof setDropProgressState === 'function' &&
  // !currentDropState.dropped &&
  // currentDropState.position > latestDroppedPosition

  if (showHiddenState) {
    return (
      <div
        className={containerClassnames}
        style={{
          height: '0%',
          paddingTop: '130%',
          touchAction: 'none',
          opacity: '50%',
        }}
      >
        <div id="ToolboxLibraryObject" className="flex flex-auto">
          <div className="img">
            {!panning && (
              <div className="rounded-8 absolute inset-0 bg-white" />
            )}
            {!panning && (
              <img
                className="absolute left-1/2 top-1/2"
                draggable={false}
                style={{
                  maxWidth: '70%',
                  transform: 'translate(-50%, -63%)',
                  maxHeight: '96px',
                  userSelect: 'none',
                }}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  object.getIcon()
                )}`}
                alt=""
              />
            )}
          </div>

          {!panning && (
            <div>
              <div
                className="font-roboto-mono absolute bottom-3 left-1/2 mx-2 inline-block text-center text-[12px] font-medium text-black"
                style={{
                  maxWidth: '100%',
                  width: 'calc(100% - 16px)',
                  transform: 'translate(calc(-50% - 8px), 0%)',
                }}
              >
                {object.name}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  if (toolboxMissionStepInsertable) {
    return (
      <div
        className={containerClassnames}
        style={{
          height: '0%',
          paddingTop: '130%',
          cursor: 'pointer',
          touchAction: 'none',
          opacity: '100%',
        }}
        onClick={() => {
          setMissionInsertableToSave({
            object: object.name,
            kidscript: `import ${object.name}`,
            display: 'object',
          })
        }}
      >
        <div
          id="ToolboxLibraryObject"
          className="toolboxMissionStepInsertable flex flex-auto"
        >
          <div className="img">
            {!panning && (
              <div className="absolute inset-0 rounded-lg bg-white" />
            )}
            {missionInsertableToSave?.object === object.name && (
              <div className="bg-earth absolute inset-0 rounded-lg bg-opacity-50" />
            )}
            {!panning && (
              <img
                className="absolute left-1/2 top-1/2"
                draggable={false}
                style={{
                  maxWidth: '70%',
                  transform: 'translate(-50%, -63%)',
                  maxHeight: '96px',
                  userSelect: 'none',
                }}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  object.getIcon()
                )}`}
                alt=""
              />
            )}
          </div>

          {!panning && (
            <div>
              <div
                className="font-roboto-mono absolute bottom-3 left-1/2 mx-2 inline-block text-center text-[12px] font-medium text-black"
                style={{
                  maxWidth: '100%',
                  width: 'calc(100% - 16px)',
                  transform: 'translate(calc(-50% - 8px), 0%)',
                }}
              >
                {object.name}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Hammer
      onPan={(event: any) => handlePan(event, object)}
      onPanStart={(event: any) => handlePress(event, object)}
      onPressUp={(event: any) => handlePressUp(event, object)}
      onClick={(event: any) => handleClick(event, object)}
      options={options}
      className={containerClassnames}
      style={{
        height: '0%',
        paddingTop: '130%',
        cursor: 'pointer',
        touchAction: 'none',
        opacity: '100%',
      }}
      ref={containerRef}
      key={object.name}
      draggable
    >
      <div id="ToolboxLibraryObject" className="flex flex-auto">
        <div className="img">
          {!panning && <div className="absolute inset-0 rounded-lg bg-white" />}
          {panning && (
            <div
              className="absolute inset-0 rounded-lg"
              style={{ backgroundColor: '#DDE9F4' }}
            />
          )}
          {!panning && (
            <img
              className="absolute left-1/2 top-1/2"
              draggable={false}
              style={{
                maxWidth: '70%',
                transform: 'translate(-50%, -63%)',
                maxHeight: '96px',
                userSelect: 'none',
              }}
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                object.getIcon()
              )}`}
              alt=""
            />
          )}
        </div>

        {!panning && (
          <div>
            <div
              className="font-roboto-mono absolute bottom-3 left-1/2 mx-2 inline-block text-center text-[12px] font-medium text-black"
              style={{
                maxWidth: '100%',
                width: 'calc(100% - 16px)',
                transform: 'translate(calc(-50% - 8px), 0%)',
              }}
            >
              {object.name}
            </div>
          </div>
        )}

        {isLocked && (
          <div
            className="rounded-8 absolute bottom-0 left-0 right-0 top-0"
            style={{
              backgroundColor: 'rgba(62, 124, 249, 0.3)',
            }}
            onClick={() => handleUnlock(isLocked)}
          >
            {typeof object.keyringCost === 'number' && (
              <div className="mr-8 mt-8 flex flex-row-reverse">
                <div className="rounded-18 text-12 text-mars-darkalt flex flex-row items-center bg-white px-6 py-[2px] font-bold">
                  <img
                    alt="coin icon"
                    src={'/images/studio/coin.svg'}
                    className="mr-6 h-[12px]"
                  />
                  {object.keyringCost}
                </div>
              </div>
            )}

            <div
              className="bg-white"
              style={{
                left: '50%',
                top: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                backgroundImage: `url('/images/studio/locked.svg')`,
                backgroundSize: 40,
                backgroundPosition: 'center 45%',
                backgroundRepeat: 'no-repeat',
                borderRadius: 45,
                width: 90,
                height: 90,
                opacity: 0.9,
                position: 'absolute',
              }}
            ></div>
          </div>
        )}

        {panning && (
          <DraggingPortal>
            {
              <animated.div
                className="text-nebula-darkest rounded-lg"
                id="DraggingLibraryObject"
                style={{
                  x,
                  y,
                  height: containerRef.current
                    ? containerRef.current.domElement.offsetHeight
                    : 0,
                  width: containerRef.current
                    ? containerRef.current.domElement.offsetWidth
                    : 0,
                }}
              >
                <animated.div
                  className="absolute inset-0 rounded-lg bg-white"
                  style={{ opacity }}
                />
                <img
                  className="absolute left-1/2 top-1/2"
                  draggable={false}
                  style={{
                    maxWidth: '70%',
                    transform: 'translate(-50%, -63%)',
                    maxHeight: '96px',
                    userSelect: 'none',
                  }}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    object.getIcon()
                  )}`}
                  alt=""
                />
              </animated.div>
            }
          </DraggingPortal>
        )}
      </div>
    </Hammer>
  )
}

export default ToolboxLibraryObject
