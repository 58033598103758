'use client'

import useMissionStore from '../../Stores/useMissionStore'
import EditorActionButtons from './EditorActionButtons'
import useRuntimeStore from './Hooks/Runtime/useRuntimeStore'
import SavingIndicator from './SavingIndicator'

export default function EditorOverlay() {
  const { guidedMissionMode } = useMissionStore()
  return (
    <div
      id="EditorOverlay"
      className="z-2000 pointer-events-none absolute inset-0"
    >
      <div className="pointer-events-none relative h-full w-full">
        <SavingIndicator />
        {!guidedMissionMode && <EditorActionButtons />}
      </div>
    </div>
  )
}
