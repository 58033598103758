import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
// import { motion, useAnimate } from 'framer-motion'
import { animate } from 'framer-motion'

import classNames from 'classnames'
import Button from '../../../../../libs/helios/button/src'

interface BadgeAwardAnimationProps {
  badge: any
  handleAnimationComplete: any
  setShowBadgeAward: any
}

const BadgeAwardAnimation: React.FC<BadgeAwardAnimationProps> = ({
  badge,
  handleAnimationComplete,
  setShowBadgeAward,
}) => {
  const badgeRef = useRef<HTMLImageElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef<HTMLDivElement>(null)
  const badgeAnimationRef = useRef<HTMLDivElement>(null)

  const animationRef = useRef<ReturnType<typeof animate>>()

  const [animationStart, setAnimationStart] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)

  useEffect(() => {
    animationRef.current = animate(
      [
        // Fade in background
        [backgroundRef.current, { opacity: 1 }, { at: 0 }],
        // Set intiial position and rotate of badge
        [
          badgeRef.current,
          { y: -500, opacity: 0, rotateY: -360 },
          {
            at: 0,
          },
        ],
        // fade in header
        [
          headerRef.current,
          { opacity: 1 },
          {
            duration: 1.2,
            at: '1',
          },
        ],
        // Fade out badge and Rotate
        [
          badgeRef.current,
          { y: 0, opacity: 1, rotateY: 360 },
          { duration: 2, at: '.7' },
        ],
        [
          badgeRef.current,
          { y: -500, opacity: 1, rotateY: -360 },
          { duration: 2, at: '+2' },
        ],
        [headerRef.current, { opacity: 0 }, { duration: 0.7, at: '+.1' }],
        [backgroundRef.current, { opacity: 0 }, { duration: 0.7, at: '+.7' }],
      ],
      {
        onComplete: () => {
          setAnimationComplete(true)
        },
      }
    )
  }, [])

  useEffect(() => {
    if (animationComplete) {
      handleAnimationComplete(badge.id)
    }
  }, [animationComplete])

  useEffect(() => {
    if (
      badgeRef.current &&
      headerRef.current &&
      backgroundRef.current &&
      animationRef.current
    ) {
      setAnimationStart(true)
      animationRef.current.play()
    }
  }, [badgeRef, headerRef, backgroundRef, badge, animationRef.current])

  return (
    <div
      ref={backgroundRef}
      id="badgeBackground"
      className={classNames(
        'bg-moon-darkest absolute inset-0 z-[9999] bg-opacity-90 opacity-0',
        {
          'pointer-events-none': !animationStart,
        }
      )}
    >
      <div className="flex justify-center">
        <img
          style={{ opacity: 0 }}
          ref={badgeRef}
          id="badgeImage"
          src={badge.badge.image}
          height={250}
          width={250}
          className="mt-[180px]"
          alt={''}
        />
      </div>
      <div
        ref={headerRef}
        id="badgeHeader"
        style={{ opacity: 0 }}
        className="absolute bottom-0 mb-[72px] flex w-full flex-col items-center justify-center text-2xl text-white"
      >
        <h6>NEW BADGE ACHIEVEMENT:</h6>
        <h2 className="text-white">{badge.badge.name}</h2>

        <div>
          <Button
            onClick={() => {
              animationRef.current?.complete()
              setShowBadgeAward(false)
              handleAnimationComplete(badge.id)
            }}
            context={'secondary'}
            size="small"
            className="z-2000 !h-8 text-sm"
          >
            Skip
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BadgeAwardAnimation
