'use client'
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { displayOptions } from './Form/Display'
import Button from '@codeverse/helios/button'
import { documentOptions } from './Form/DocumentField'
import { useParams, useRouter } from 'next/navigation'
import {
  useMissionSteps,
  useMissionStepsMultipleChoice,
} from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import { KidScriptChanges, MissionStepFormValues } from './types'
import MissionStepForm from './MissionStepForm'
import useMissionStepValues from './useMissionStepValues'
import useMissionStepActions from './useMissionStepActions'
import {
  useMutateUpdateMultipleChoice,
  useUpdateMissionMutation,
  useUpdateMissionStepMutation,
} from 'apps/studio-shared/src/Data/Mission/MissionMutations'
import { dirtyValues } from './Form/dirtyValues'
import { LineAddress } from '@kidscript/editor/dist/src/types/LineAddress'
import { StepSymbolAddress } from '@kidscript/editor/dist/src/types/StepSymbolAddress'
import { useMainEditorRef } from '../../../Studio/Hooks/useMainEditorRef'
import { toast } from 'react-toastify'
import useSingleState from './Form/useSingleState'
import QuizForm from './Form/QuizForm'
import useRuntimeStore from '../../../Studio/Hooks/Runtime/useRuntimeStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { useQueryClient } from '@tanstack/react-query'
import useQuizStateStore from 'apps/studio-shared/src/Stores/useQuizStateStore'
interface EditMissionStepFormProps {
  initialCurrentStep: any
}

const EditMissionStepForm: React.FC<EditMissionStepFormProps> = ({
  initialCurrentStep,
}) => {
  const router = useRouter()
  const params = useParams()
  const queryClient = useQueryClient()

  const { setGuidedMissionMode } = useMissionStore()
  const { currentStep, setCurrentStep, setCurrentStepFormValue } =
    useMissionStore()
  const { setShowToolbox } = useRuntimeStore()
  const { resetMissionStepBuilderEditor } = useMissionStepActions()
  const { setCurrentLibraryFilterObject } = useMissionBuilderStore()
  const { setToolboxLibraryFilter } = useUIStore()

  const updateMissionStepMutation = useUpdateMissionStepMutation()
  const updateMultipleChoice = useMutateUpdateMultipleChoice()
  const missionSteps = useMissionSteps(params.id as string)
  const mainEditorRef = useMainEditorRef()
  const { data: MultipleChoiceData } = useMissionStepsMultipleChoice(
    currentStep?.id
  )

  const [disabled, setDisabled] = useState(false)

  const {
    question,
    answer,
    answer_a,
    answer_b,
    answer_c,
    answer_d,
    setAnswer,
    setQuestion,
    setAnswerA,
    setAnswerB,
    setAnswerC,
    setAnswerD,
  } = useQuizStateStore()

  useEffect(() => {
    return () => {
      resetMissionStepBuilderEditor()
    }
  }, [])

  useEffect(() => {
    if (initialCurrentStep && !currentStep) {
      // If the initialCurrentStep (fetched from the server component), update the current step
      setCurrentStep(initialCurrentStep)
    } else if (!currentStep && missionSteps.data && params) {
      // const foundCurrentStep = missionSteps.data.find(
      //   (step) => step.id === params.stepId
      // )
      // console.log('setting current step', foundCurrentStep)
      // setCurrentStep(
      //   Object.assign({}, foundCurrentStep?.attributes, {
      //     id: foundCurrentStep?.id,
      //   })
      // )
    }
    return () => {
      if (currentStep) {
        // setCurrentStep(null)
      }
    }
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      id: initialCurrentStep.id,
      boilerplate: initialCurrentStep.boilerplate,
      position: initialCurrentStep.position,
      header: initialCurrentStep.header,
      content: initialCurrentStep.content || '',
      body: initialCurrentStep.body || '',
      detail: initialCurrentStep.detail || '',
      side_bar_header: initialCurrentStep.side_bar_header,
      image: initialCurrentStep.image,
      option: false,
      line_address: initialCurrentStep.line_address,
      symbol_address: initialCurrentStep.symbol_address,
      kidscript: initialCurrentStep.kidscript,
      library: initialCurrentStep.library,
      success_header: initialCurrentStep.success_header,
      kidscript_changes: {
        documents:
          initialCurrentStep.kidscript_changes?.documents?.map((doc: any) => ({
            ...doc,
            changes: doc.changes.map((change: any) => ({
              ...change,
              id: uuidv4(),
            })),
          })) || [],
      },
      runnable: initialCurrentStep.runnable,
      run_automatically: initialCurrentStep.run_automatically,
      highlight: initialCurrentStep.highlight,
      editable_symbols: initialCurrentStep.editable_symbols,
      type_over: initialCurrentStep.type_over,
      video_uri: initialCurrentStep.video_uri,
      audio_uri: initialCurrentStep.audio_uri,
      validation_target: initialCurrentStep.validation_target,
      validation_rules: initialCurrentStep.validation_rules,
      button_type: initialCurrentStep.button_type,
      display: initialCurrentStep.display,
      document: initialCurrentStep.document,
    } as MissionStepFormValues,
  })

  // // Hook to update the current step values needed for mission steps replay
  useMissionStepValues({
    control,
    watch,
    getValues,
    initialCurrentStep,
  })

  // const formValues = useMemo(() => {
  //   return getValues()
  // }, [])

  const mutate = useCallback(
    (e) => {
      setDisabled(true)
      e.preventDefault()
      const values = getValues()

      const data = dirtyValues(dirtyFields, values) as MissionStepFormValues

      // if (originalBoilerplate !== values.boilerplate) {
      //   //delete some keys if the original boilerplate changed
      //   switch (values.boilerplate) {
      //     case 'quiz':
      //       data.body = null
      //       data.detail = null
      //       data.content = null
      //       break
      //     case 'code':
      //       data.kidscript = ''
      //       break
      //   }
      // }

      //@ts-ignore
      data.kidscript_changes = {
        //@ts-ignore
        documents: values.kidscript_changes?.documents.map((doc) => ({
          ...doc,
          changes: doc.changes.map((change: any) => {
            const newChange = Object.assign({}, { ...change })
            //@ts-ignore
            delete newChange.id
            return newChange
          }),
        })),
      }
      //@ts-ignore

      if (mainEditorRef) {
        data.highlight = mainEditorRef.highlight.getOptions()
        data.editable_symbols = mainEditorRef.editableSymbols.getOptions()
        data.type_over = mainEditorRef.typeOver.getOptions()

        const lineAddress =
          mainEditorRef.dynamicLineContent.getFirstLineAddress() as LineAddress
        const symbolAddress =
          mainEditorRef.dynamicSymbolContent.getFirstSymbolAddress() as StepSymbolAddress

        data.line_address = lineAddress
        data.symbol_address = symbolAddress

        const contentSymbolExists = !!lineAddress || !!symbolAddress

        if (
          (values.display === 'line' || values.display === 'modal') &&
          !contentSymbolExists
        ) {
          toast.error('Please select a Dynamic Content Line')
          setDisabled(false)
          return
        }

        data.validation_rules =
          typeof values.validation_rules === 'string'
            ? JSON.parse(values.validation_rules)
            : values.validation_rules
      }

      updateMissionStepMutation.mutate(
        {
          //@ts-ignore
          id: params.stepId,
          ...data,
        },
        {
          onSuccess: (data) => {
            setDisabled(false)
            if (values?.boilerplate === 'quiz') {
              updateMultipleChoice.mutate(
                {
                  question,
                  answer,
                  answer_a,
                  answer_b,
                  answer_c,
                  answer_d,
                  id: MultipleChoiceData[0].id,
                },
                {
                  onSuccess: () => {
                    setCurrentStep(null)
                    setGuidedMissionMode(false)
                    setShowToolbox(false)
                    setToolboxLibraryFilter(false)
                    setCurrentLibraryFilterObject(null)
                    queryClient.invalidateQueries({
                      queryKey: ['missionSteps'],
                    })
                    toast.success('Mission Step Updated')
                    setDisabled(false)
                    router.push(`/mission_builder/${params.id}`)
                  },
                  onError: (err) => {
                    console.error(err)
                  },
                }
              )
            } else {
              setCurrentStep(null)
              setGuidedMissionMode(false)
              setShowToolbox(false)
              setToolboxLibraryFilter(false)
              setCurrentLibraryFilterObject(null)
              queryClient.invalidateQueries({
                queryKey: ['missionSteps'],
              })
              setDisabled(false)
              toast.success('Mission Step Updated')
              router.push(`/mission_builder/${params.id}`)
            }

            // resetMissionInsertables()
            // setMissionEditorDisplayType('viewMission')
            // closeUI('showEditMission')
          },
        }
      )
    },
    [
      dirtyFields,
      getValues,
      dirtyValues,
      mainEditorRef,
      question,
      answer,
      answer_a,
      answer_b,
      answer_c,
      answer_d,
    ]
  )

  const [questionState, setState] = useSingleState({
    question: '',
    answerA: '',
    answerB: '',
    answerC: '',
    answerD: '',
    answer: 'a',
  })

  return (
    <form
      onSubmit={(e) => {
        mutate(e)
      }}
    >
      <h2 className="text-white">
        Editing Step{' '}
        <span className="text-sun-darkest">
          {initialCurrentStep.position + 1}
        </span>
      </h2>

      <MissionStepForm
        getValues={getValues}
        watch={watch}
        control={control}
        setValue={setValue}
        type="edit"
      />

      {currentStep?.boilerplate === 'quiz' && (
        <QuizForm
          questionState={questionState}
          setState={setState}
          type="edit"
          setValue={setValue}
          control={control}
        />
      )}

      <hr className="text-moon-light mt-4" />

      <div className="my-6 grid grid-cols-2 gap-4 pb-6">
        <Button
          disabled={disabled}
          size="regular"
          type="submit"
          context="success"
        >
          Save Changes
        </Button>
        <Button
          size="regular"
          onClick={(e) => {
            e.preventDefault()
            setCurrentStep(null)
            setGuidedMissionMode(false)
            queryClient.invalidateQueries({
              queryKey: ['missionSteps'],
            })
            router.push(`/mission_builder/${params.id}`)
            // resetMissionInsertables()
            // setShowToolbox(false)
            setToolboxLibraryFilter(false)
            // setCurrentLibraryFilterObject(null)
            // setMissionEditorDisplayType('viewMission')
          }}
          context="secondary"
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default EditMissionStepForm
