import React, { useContext } from 'react'
import classNames from 'classnames'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const cardBody = {
  base: 'px-3 py-3 mt-2 font-poppins-medium',
}
const CardBody = function CardBody(props: Props) {
  const { className, children, ...other } = props

  const baseStyle = cardBody.base

  const cls = classNames(baseStyle, className)

  return (
    <div className={cls} {...other}>
      {children}
    </div>
  )
}

export default CardBody
