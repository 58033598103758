'use client'

import Button from '@codeverse/helios/button'
import Modal from '@codeverse/helios/modal'
import { useUIStore } from '../../Stores'
import useModalDataStore from '../../Stores/UseModalDataStore'
import { useState } from 'react'
import { useMutateProject } from '../../Data/Projects/ProjectMutations'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export default function ArchiveProjectModal() {
  const { setArchiveProjectModal, archiveProjectModal } = useUIStore()
  const { currentProject, setCurrentProject } = useModalDataStore()
  const projectMutation = useMutateProject()
  const queryClient = useQueryClient()
  const [disabled, setDisabled] = useState(false)

  const handleArchive = () => {
    setDisabled(true)
    projectMutation.mutate(
      {
        projectId: currentProject.id,
        data: {
          archived: true,
        },
      },
      {
        onSuccess: () => {
          setDisabled(false)
          queryClient.invalidateQueries({ queryKey: ['projects'] })
          setArchiveProjectModal(false)
          setCurrentProject(null)
          toast.success(`Project "${currentProject.name}" archived`, {
            position: 'bottom-center',
          })
        },
        onError: () => {
          setDisabled(false)
          console.error('Error archiving project')
          toast.error('Failed to archive project', {
            position: 'bottom-center',
          })
        },
      }
    )
  }

  return (
    <Modal
      width={'400px'}
      isOpen={archiveProjectModal}
      onClose={(e) => {
        e.stopPropagation()
        setArchiveProjectModal(false)
        setCurrentProject(null)
      }}
      allowEsc
      contentClassname="p-6"
    >
      <div className="flex flex-col items-center">
        <h4 className="!mb-0 text-[20px] text-[#090E2B]">Archive Project</h4>
        <p className="pt-4 text-center">
          Are you sure you want to archive "{currentProject?.name}"?
          <br /> You can unarchive it later if needed.
        </p>

        <div className="mt-6 flex w-full gap-4">
          <Button
            className="flex-1"
            size="regular"
            context="secondary"
            onClick={() => setArchiveProjectModal(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            className="flex-1"
            size="regular"
            context="primaryFlat"
            onClick={handleArchive}
          >
            Archive
          </Button>
        </div>
      </div>
    </Modal>
  )
}
