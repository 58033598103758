//@ts-nocheck
import times from 'lodash/times'
import { useQuery } from '@tanstack/react-query'
import { useSkillsQuery } from 'apps/studio-shared/src/Data/Achievements/SkillsDataHooks'
import fetchClientGql from 'apps/studio-shared/src/Data/fetch/fetchClientGql'
import { gql } from 'graphql-request'
import React, { useMemo } from 'react'
import LearningPath from './Achievements/LearningPaths'
import BadgeDisplay from './Achievements/BadgeDisplay'
import { motion } from 'framer-motion'
interface AchievementsProps {}

const MyAchievements: React.FC<AchievementsProps> = ({}) => {
  const { data: skillsData, isFetched } = useSkillsQuery()
  const { data: badgesData } = useQuery({
    queryKey: ['badgesQuery'],
    queryFn: async () => {
      const response = await fetchClientGql().request(
        gql`
          {
            badges(firstBadgeOnly: true) {
              badgesInSeries {
                id
                name
                image
                position
                currentUserHasAchieved
                currentUserBadgeAchievement {
                  id
                  createdAt
                }
              }
            }
          }
        `
      )
      return response
    },
  })

  const LearningPaths = React.useMemo(() => {
    return skillsData?.me?.learningPathScores
      .filter((learningPath) => !learningPath.guideOnly)
      .map((learningPath, index) => {
        return (
          <LearningPath learningPath={learningPath} key={learningPath.name} />
        )
      })
  }, [skillsData])

  const achievedBadges = useMemo(() => {
    const achievedBadgeSeries = []
    badgesData?.badges?.map((badgesInSeries) => {
      let hasAchieved = false
      badgesInSeries?.badgesInSeries.map((badge) => {
        if (badge.currentUserHasAchieved) {
          hasAchieved = true
        }
      })

      if (hasAchieved) {
        achievedBadgeSeries.push(badgesInSeries)
      }
    })

    return achievedBadgeSeries
  }, [badgesData])

  const unachievedBadges = useMemo(() => {
    const unachievedBadgeSeries = []
    badgesData?.badges?.map((badgesInSeries) => {
      let hasAchieved = true
      badgesInSeries?.badgesInSeries.map((badge) => {
        if (!badge.currentUserHasAchieved) {
          hasAchieved = false
        }
      })

      if (!hasAchieved) {
        unachievedBadgeSeries.push(badgesInSeries)
      }
    })

    return unachievedBadgeSeries
  }, [badgesData])

  const AchievedBadges = useMemo(() => {
    return achievedBadges?.map((achievedBadge, index) => {
      let badgeToShow = null
      achievedBadge.badgesInSeries
        .sort((a, b) => a.position < b.position)
        .map((badge) => {
          if (badge.currentUserHasAchieved) {
            badgeToShow = badge
          }
        })

      return (
        <BadgeDisplay
          index={index}
          key={badgeToShow.id}
          achievedBadge={badgeToShow}
          enableClick
        />
      )
    })
  }, [achievedBadges])

  const UnAchievedBadges = useMemo(() => {
    const componentsToRender = []
    unachievedBadges?.map((unachievedBadge, index) => {
      const badgeToShow = null
      unachievedBadge.badgesInSeries
        .sort((a, b) => a.position < b.position)
        .map((badge) => {
          if (badge.position === 0 && !badge.currentUserHasAchieved) {
            componentsToRender.push(
              <BadgeDisplay
                key={badge.id}
                achievedBadge={badge}
                enableClick
                disabled
              />
            )
          }
        })
    })

    return componentsToRender.map((component) => component)
  }, [unachievedBadges])

  const emptyBadges = useMemo(() => {
    return times(21)
  }, [])

  const EmptyBadges = useMemo(() => {
    return emptyBadges?.map((badge, index) => {
      return (
        <div className="flex items-center justify-center" key={index}>
          <img
            src={'/images/home/empty-badge.png'}
            alt="BadgeDisplay"
            className="h-[153px] w-[153px] cursor-pointer transition-transform ease-in-out hover:scale-110"
          />
        </div>
      )
    })
  }, [emptyBadges])

  return (
    <motion.div
      className="px-0"
      style={
        {
          // opacity: profileTab === 'badges' ? 1 : 0,
          // display: profileTab === 'badges' ? 'grid' : 'none',
        }
      }
      initial={{
        opacity: 0,
      }}
      animate={!isFetched ? 'initial' : 'animate'}
      variants={{
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
      }}
    >
      <div className="bg-moon-dark mt-6 hidden h-auto min-h-[72px] w-full rounded-3xl bg-opacity-30 px-8 py-8 lg:inline-block">
        {LearningPaths}
      </div>
      <h3 className="mt-12 text-white">Achievement Badges</h3>
      <div className="grid grid-cols-2 gap-4 pt-6 md:grid-cols-5 lg:grid-cols-7">
        {AchievedBadges}
        {achievedBadges?.length === 0 && <>{EmptyBadges}</>}
        {/* {UnAchievedBadges} */}
      </div>
    </motion.div>
  )
}

export default MyAchievements
