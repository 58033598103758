'use client'

import FriendsModal from './FriendsModal'
import RenameProjectModal from './RenameProjectModal'
import ProfileModal from './ProfileModal'
import AvatarModal from './AvatarModal'
import NewProjectTemplateModal from './NewProjectTemplateModal'
import LearningPathModal from './LearningPathModal'
import PlayProjectModal from './PlayProjectModal'
import ArchiveProjectModal from './ArchiveProjectModal'

export default function Name() {
  return (
    <>
      <RenameProjectModal />
      <FriendsModal />
      <ProfileModal />
      <AvatarModal />
      <LearningPathModal />
      <NewProjectTemplateModal />
      <PlayProjectModal />
      <ArchiveProjectModal />
    </>
  )
}
