'use client'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useProjectDocuments } from 'apps/studio-shared/src/Data/Projects/ProjectDataHooks'
import useModalDataStore from 'apps/studio-shared/src/Stores/UseModalDataStore'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useInitializeRuntime } from '../../Studio/Hooks/useInitializeRuntime'

export default function PlayProjectModalContainer({ documents }: any) {
  const { currentProject } = useModalDataStore()
  const [showPreview, setShowPreview] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [setElements, setSetElements] = useState(false)
  const [stageElt, setStageElt] = useState<HTMLElement | null>(null)
  const [editorElt, setEditorElt] = useState<HTMLElement | null>(null)
  const [previewElt, setPreviewElt] = useState<HTMLElement | null>(null)
  const [runtimeContainer, setRuntimeContainer] = useState<HTMLElement | null>(
    null
  )

  const playButtonClassnames = classNames(
    'absolute cursor-pointer inset-0 z-[2001]',
    {
      // hidden: !kidscriptDependenciesFetched,
    }
  )

  const { setupEnvironment } = useInitializeRuntime({
    documents: documents,
    projectId: currentProject.id,
    saveDocuments: false,
    // enabled: isMounted,
    useEditor: false,
    //@ts-ignore
    kidscriptVersion: KidScript.Version,
    isMounted,
  })

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handlePlayClick = () => {
    //@ts-ignore
    setupEnvironment(KidScript.Version)
    setShowPreview(false)
  }

  return (
    <div
      className="rounded-4xl mb-18 pointer-events-auto relative aspect-[509/768] h-full overflow-hidden lg:mb-0 lg:px-0 lg:pb-0"
      id="runtimeEnvironment"
    >
      {showPreview && (
        <div
          id="PlayPublicApp_Play"
          className={playButtonClassnames}
          onClick={handlePlayClick}
          style={{
            backgroundImage: `url(${
              currentProject.screenshot || '/images/home/cv-file-default.svg'
            })`,
            backgroundSize: 'cover',
          }}
        >
          <div className="absolute left-1/2 top-1/2 h-[144px] w-[144px] -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white bg-opacity-30 p-[14px]">
            <div className="text-titan inset-0 flex h-full w-full items-center justify-center rounded-full bg-white">
              <FontAwesomeIcon icon={faPlay} size={'3x'} />
            </div>
          </div>
        </div>
      )}
      <style>{`
              canvas {
                  margin: 0 auto;
              }
          `}</style>
      <div className="z-2000 stage absolute inset-0 h-full" id="stage"></div>
      <div
        id="preview"
        className="preview absolute inset-0 flex h-full w-full justify-center"
      ></div>
    </div>
  )
}
