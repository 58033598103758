'use client'

import { faArrowLeft, faPlay, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'
import React from 'react'
import capitalize from 'lodash/capitalize'
import { numberWithCommas } from 'apps/studio-shared/src/Util/formatNumberWithComma'
import useModalDataStore from 'apps/studio-shared/src/Stores/UseModalDataStore'
import moment from 'moment-timezone'

interface ProjectHeaderProps {}

const faPaperPlaneStyle = {
  '--fa-primary-color': '#00CE94',
  '--fa-secondary-color': '#00CE94',
  '--fa-secondary-opacity': 0.6,
}

const ProjectHeader: React.FC<ProjectHeaderProps> = (
  {
    // name,
    // description,
    // publishedAt,
    // userAvatar,
    // userName,
    // playCount,
  }
) => {
  const router = useRouter()
  const { currentProject } = useModalDataStore()
  const projectHeaderClassnames = classNames(
    'bg-[#292E50] flex justify-end flex-col h-auto rounded-4xl w-auto z-2000 px-6 py-6',
    'min-w-[211px] max-w-[304px]'
  )

  const goBackClassnames = classNames(
    'block cursor-pointer font-poppins-semibold ml-6 pointer-events-auto text-white z-2000 lg:hidden mt-3'
  )

  return (
    <div
      className={classNames(
        'z-2000 pointer-events-auto mr-4 hidden flex-shrink-0 select-none flex-col md:hidden lg:flex'
      )}
    >
      <div className="ml-auto flex flex-1 flex-col items-end self-start">
        {/* {isOutsideStudio && (
          <div
            id="PlayPublicApp_PlayMore"
            className="block cursor-pointer font-poppins-semibold ml-6 mr-auto mt-48 pointer-events-auto text-white z-2000"
            onClick={() => navigate('/home/play')}
          >
            <FontAwesomeIcon
              className="mr-16 text-white"
              size="lg"
              icon={faArrowLeft}
            />
            Play more games
          </div>
        )} */}
        <div className={projectHeaderClassnames}>
          <div className="border-moon-dark grid h-auto w-full self-start">
            <h4 className="col-span-4 !mb-0 text-white">
              {currentProject.name}
            </h4>
            <div className="font-jakarta-sans font-regular text-moon mt-4 opacity-80">
              Last updated {moment(currentProject?.updated_at).fromNow()}
            </div>
            {/* <div className="flex items-center">
              <StarProjectButton />
            </div> */}
          </div>
          {/* 
          <div className="font-jakarta-sans font-regular text-moon ml-6 mt-4 opacity-80">
            Published {publishedAt}
          </div>
          <div className="font-jakarta-sans text-14 my-3 px-6 font-medium text-white"></div> */}

          {/* <div className="mx-6 mb-4 mt-auto flex flex-row text-white">
            <div>
              <AvatarIcon
                // onClick={() => navigate(`/r/${publicApp.username}`)}
                avatarUrl={userAvatar}
              />
            </div>
            <div className="ml-2 flex flex-col justify-center">
              <span className="font-jakarta-sans text-moon text-xs">
                Created By{' '}
              </span>
              <span className="font-jakarta-sans text-14 text-white">
                {userName}
              </span>
            </div>

            <div className="font-sf-pro-text-medium bg bg-moon-darkest ml-auto flex items-center rounded-[20px] p-2 text-white">
              <div className="bg-titan mr-1.5 flex h-6 w-6 items-center justify-center rounded-full">
                <FontAwesomeIcon
                  //@ts-ignore
                  style={faPaperPlaneStyle}
                  className="text-12 !text-moon-darkest font-bold"
                  size="xs"
                  icon={faPlay}
                />
              </div>
              {numberWithCommas(playCount)}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ProjectHeader
