import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface ModalDataStore {
  currentProject: any
  setCurrentProject: (currentProject: any) => void
  setLearningPath: (learningPath: any) => void
  learningPath: any
  currentBadgeId: any
  setCurrentBadgeId: (currentBadgeId: any) => void
  setCurrentProjectKidScript: (currentProjectKidScript: any) => void
  currentProjectKidScript: any
}

const useModalDataStore = create<ModalDataStore>()(
  devtools(
    (set) => ({
      currentProject: null,
      currentProjectKidScript: null,
      setCurrentBadgeId: (currentBadgeId) => set(() => ({ currentBadgeId })),
      currentBadgeId: null,
      setCurrentProject: (currentProject) => set(() => ({ currentProject })),
      setLearningPath: (learningPath) => set(() => ({ learningPath })),
      learningPath: null,
      setCurrentProjectKidScript: (currentProjectKidScript: Array<string>) =>
        set(() => ({ currentProjectKidScript })),
    }),
    {
      name: 'ModalDataStore',
    }
  )
)

export default useModalDataStore
