import React, { useMemo } from 'react'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { useUIStore } from '../../../../Stores'
import useModalDataStore from '../../../../Stores/UseModalDataStore'
import { numberWithCommas } from 'apps/studio-shared/src/Util/formatNumberWithComma'

interface LearningPathProps {
  learningPath: any
}

const LearningPath: React.FC<LearningPathProps> = ({ learningPath }) => {
  const { setLearningPath } = useModalDataStore()
  const { setLearningPathModal } = useUIStore()
  const determineImage = () => {
    switch (learningPath.learningPathId) {
      case '2e19aaea-17ee-4858-b178-69ed40afb512': // Creative Space
        return '/images/achievements/level-icon-green.png'
      case '7830d3b1-521c-4995-89cc-913c8850e0b0':
        return '/images/achievements/level-icon-orange.png'
      case '1e87611d-1cfb-4d0a-ae0a-c8a7f852c9ae':
        return '/images/achievements/level-icon-blue.png'
      case '84edd335-d40e-4169-be71-f65b53adbec9':
        return '/images/achievements/level-icon-pink.png'
      case '5e8c1e48-cd6b-465d-91cb-99465d56ea88': // Coding Genius
        return '/images/achievements/level-icon-light-purple.png'
      case 'f458bcd8-7e31-42a3-841f-17e4eda97871': // Human Factors
        return '/images/achievements/level-icon-yellow.png'
      default:
        return '/images/achievements/level-icon-purple.png'
    }
  }
  const determineColor = () => {
    switch (learningPath.learningPathId) {
      case '2e19aaea-17ee-4858-b178-69ed40afb512': // Creative Space
        return '#00CE94' // green
      case '7830d3b1-521c-4995-89cc-913c8850e0b0':
        return '#FDBA17'
      case '1e87611d-1cfb-4d0a-ae0a-c8a7f852c9ae':
        return '#3E7CF9'
      case '84edd335-d40e-4169-be71-f65b53adbec9':
        return '#FF57D0'
      case '5e8c1e48-cd6b-465d-91cb-99465d56ea88': // Coding Genius
        return '#745BFF' // purple
      case 'f458bcd8-7e31-42a3-841f-17e4eda97871': // Human Factors
        return '#FDBA17' // yellow
      default:
        return '#B305F8'
    }
  }

  const image = useMemo(() => {
    return determineImage()
  }, [learningPath])

  const color = useMemo(() => {
    return determineColor()
  }, [learningPath])

  const handleLearningPathClick = (e: any) => {
    e.stopPropagation()
    setLearningPath(learningPath)
    setLearningPathModal(true)
  }

  return (
    <div
      id="LearningPath"
      className="my-2 flex cursor-pointer items-center"
      onClick={handleLearningPathClick}
    >
      <div
        className="font-poppins-semibold text-base text-white"
        style={{ width: '188px' }}
      >
        {learningPath.name}
      </div>

      <div className="bg-moon-darkest relative float-left ml-3 flex h-6 flex-grow cursor-pointer rounded-2xl">
        <div
          className="relative float-left rounded-2xl"
          style={{
            width: `${learningPath.currentLevel.percentageComplete}%`,
            backgroundColor: color,
          }}
        >
          {learningPath.currentLevel.percentageComplete > 15 && (
            <div className="float-right mr-3 font-bold text-white">
              {numberWithCommas(learningPath.currentTotalPoints)}
            </div>
          )}
        </div>
      </div>

      <div
        className="bg-moon-darkest relative float-left ml-2 rounded-xl"
        style={{ height: '24px' }}
      >
        {/* <div className="relative -top-1.5 float-left h-8 w-8 text-center">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold">
            {learningPath.currentLevel.position}
          </div>
          <Image
            className=""
            src={image}
            alt={'LearningPathIcon'}
            height={32}
            width={32}
          />
        </div> */}
        <div className="px-4 py-1 text-xs font-medium text-[#A6B1D6]">
          LEVEL {learningPath.currentLevel.position}
        </div>
      </div>

      {/* <div className="bg-moon-dark ml-2  flex h-6 w-6 items-center justify-center rounded-full">
        <FontAwesomeIcon icon={faChevronRight} />
      </div> */}
    </div>
  )
}

export default LearningPath
