import React, { useContext } from 'react'
import classNames from 'classnames'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const cardTitle = {
  base: 'px-6 py-4 shadow-bottom font-poppins-semibold',
}

const CardTitle = function CardTitle(props: Props, ref: any) {
  const { className, children, ...other } = props

  const baseStyle = cardTitle.base

  const cls = classNames(baseStyle, className)

  return (
    <div className={cls} {...other}>
      {children}
    </div>
  )
}

export default CardTitle
