'use client'
import { useQuery } from '@tanstack/react-query'
import { useTable } from 'react-table'
import classNames from 'classnames'

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faPlus } from '@fortawesome/pro-solid-svg-icons'

// import StaticTableRow from '../Forms/StaticTableRow'
import DraggableTableRow from '../../Shared/DraggableTableRow'

// import ActionButton from './ActionButton'
// import { missionStepsToOptions } from '@codeverse-studio/containers/Hooks/useInitializeGuidedMissions'
import { toast } from 'react-toastify'
import { useMemo, useState } from 'react'
import Button from '@codeverse/helios/button'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import { MissionStep } from 'apps/studio/types/MissionStep'
import ActionButton from './ActionButton'
import { useParams, useRouter } from 'next/navigation'

interface MissionStepsProps {
  missionId: string
  missionStepData: Array<MissionStep> | null
}

const MissionSteps: React.FC<MissionStepsProps> = ({
  missionId,
  missionStepData,
}) => {
  const router = useRouter()
  const params = useParams()
  const [activeId, setActiveId] = useState<any>(null)
  const isFetched = true
  const items = useMemo(
    () => missionStepData?.map(({ id }) => id),
    [missionStepData]
  )
  const headers = useMemo(
    () => missionStepData?.map(({ id }) => id),
    [missionStepData]
  )
  const columns = useMemo(
    () => [
      {
        Header: 'STEP',
        Cell: ({ cell: { value }, row }) => {
          return <div className="font-poppins-semibold pl-2">{value + 1}</div>
        },
        accessor: 'attributes.position',
      },
      {
        Header: 'BOILERPLATE',
        Cell: ({ cell: { value }, row }) => {
          return <div>{value}</div>
        },
        accessor: 'attributes.boilerplate',
      },
      {
        Header: 'HEADER',
        accessor: 'attributes.header',
        minWidth: 100,
        Cell: ({ cell: { value }, row }) => {
          return <div className="w-[220px] truncate">{value}</div>
        },
      },
      {
        Header: '',
        Cell: ({ cell: { value }, row }) => {
          return (
            <ActionButton
              currentStep={{ id: row.original.id, ...row.original.attributes }}
            />
          )
        },
        accessor: 'actions',
      },
    ],
    [missionStepData]
  )

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  function handleDragStart(event) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event) {
    // const { active, over } = event
    // if (active.id !== over.id) {
    //   const oldIndex = missionStepData.findIndex((ms) => ms.id === active.id)
    //   const newIndex = missionStepData.findIndex((ms) => ms.id === over.id)
    //   const depsPreventingMove =
    //     missionEditorValidatorRef.current.getDependenciesPreventingMove(
    //       oldIndex,
    //       newIndex
    //     )
    //   try {
    //     console.log('attempting to move from', oldIndex, 'to', newIndex)
    //     console.log('missionEditorValidatorRef', missionEditorValidatorRef)
    //     console.log('deps preventing move', depsPreventingMove)
    //   } catch (e) {
    //     console.log('e', e)
    //     return
    //   }
    //   //
    //   // const newOptions = arrayMove(missionStepData, oldIndex, newIndex)
    //   // run mission step validations and reorder
    //   const missionStepEditor = editorRef.getMissionStepEditor(
    //     missionStepsToOptions(
    //       missionStepData,
    //       missionStepsToOptions,
    //       guidedMissionStepPortalRef?.current
    //     )
    //   )
    //   if (depsPreventingMove.length === 0) {
    //     missionStepEditor.moveStep(oldIndex, newIndex)
    //     // set new missions steps
    //     // mutateUpdateMissionStep.mutate(
    //     //   {
    //     //     id: missionStepData[oldIndex].id,
    //     //     position: newIndex,
    //     //   },
    //     //   {
    //     //     onSuccess: (data) => {
    //     // setNewMissionStepEditor(missionStepEditor)
    //     //       toast.success('Mission step position updated')
    //     //     },
    //     //   }
    //     // )
    //   }
    // }
    // setActiveId(null)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      data: missionStepData,
      headers,
      //@ts-ignore
      columns,
    })

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({ original }) => original.id === activeId)
    //@ts-ignore
    prepareRow(row)
    return row
  }, [activeId, rows, prepareRow])

  function handleDragCancel() {
    setActiveId(null)
  }

  return (
    <div className="py-4">
      <div className="mb-1.5 flex flex-row">
        <h4 className="text-white">Mission Steps</h4>
        <div className="ml-auto">
          <Button
            className="!pl-4 pr-6"
            size="small"
            context="nebula"
            onClick={(e) => {
              e.preventDefault()
              router.push(`/mission_builder/${params.id}/steps/new`)
              // setUpdatedCurrentStep(null)
              // setMissionEditorDisplayType('newMissionStep')
              // setMissionInsertableFormType('new')
            }}
          >
            <FontAwesomeIcon size="lg" icon={faPlus} className="mr-2" />
            New Step
          </Button>
        </div>
      </div>
      {isFetched && (
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <table
            className="w-full border-separate border-spacing-y-2"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup: any, index: any) => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps()
                return (
                  <tr key={index} {...rest}>
                    {headerGroup.headers.map((column: any) => {
                      const thClassname = classNames(
                        'font-poppins-bold text-moon-lightest text-12 text-left pb-2',
                        {
                          'pl-[38px]': column.Header === 'STEP',
                        }
                      )
                      const { key, ...columnRest } = column.getHeaderProps()
                      return (
                        <th
                          key={column.Header}
                          className={thClassname}
                          {...columnRest}
                        >
                          {column.render('Header')}
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              <SortableContext
                //@ts-ignore
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {rows?.map((row, i) => {
                  prepareRow(row)
                  return (
                    <DraggableTableRow
                      oneRow={rows.length === 1}
                      key={row.original.id}
                      row={row}
                      rowClassName=""
                    />
                  )
                })}
              </SortableContext>
            </tbody>
          </table>
          <DragOverlay>
            {activeId && (
              <table style={{ width: '100%' }}>
                <tbody>{/* <StaticTableRow row={selectedRow} /> */}</tbody>
              </table>
            )}
          </DragOverlay>
        </DndContext>
      )}
    </div>
  )
}

export default MissionSteps
