import { faMeteor } from '@fortawesome/pro-duotone-svg-icons'
import {
  faBuilding,
  faCube,
  faFutbol,
  faGem,
  faGlobe,
  faLeaf,
  faLock,
  faMountains,
  faPizzaSlice,
  faShapes,
  faToggleOn,
  faTools,
  faTruckPickup,
  faWalking,
  faWreath,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from '@codeverse/cn'
import React, { useMemo } from 'react'
import useRuntimeStore from '../Hooks/Runtime/useRuntimeStore'

export const CATEGORIES = [
  { name: '', icon: faGlobe },
  { name: 'Locked', icon: faLock },
  { name: 'Character', icon: faWalking },
  { name: 'Food', icon: faPizzaSlice },
  { name: 'Plant', icon: faLeaf },
  { name: 'Control', icon: faToggleOn },
  { name: 'Landscape', icon: faMountains },
  { name: 'Seasonal', icon: faWreath },
  { name: 'Shape', icon: faShapes },
  { name: 'Space', icon: faMeteor },
  { name: 'Sports', icon: faFutbol },
  { name: 'Thing', icon: faGem },
  { name: 'Vehicle', icon: faTruckPickup },
  { name: 'Material', icon: faCube },
  { name: 'Utility', icon: faTools },
  { name: 'Architecture', icon: faBuilding },
]

const Icon = (props) => {
  const { category, setCurrentFilter } = props

  switch (category.name) {
    case 'Locked':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{ opacity: 0.75, fontSize: '28px' }}
        />
      )
    case 'Character':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{ opacity: 0.75, fontSize: '28px' }}
        />
      )
    case 'Food':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{ opacity: 0.75, marginLeft: '3px', marginTop: '-3px' }}
        />
      )
    case 'Plant':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{ opacity: 0.75, fontSize: '22px' }}
        />
      )
    case 'Landscape':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{ opacity: 0.75, marginBottom: '4px', fontSize: '23px' }}
        />
      )
    case 'Control':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{
            opacity: 0.75,
            fontSize: '26px',
            ...{ '--fa-secondary-color': '#DDE9F4' },
          }}
        />
      )
    case 'Space':
      return (
        <FontAwesomeIcon
          icon={category.icon}
          style={{ opacity: 0.75, fontSize: '25px' }}
        />
      )
    default:
      return <FontAwesomeIcon icon={category.icon} style={{ opacity: 0.75 }} />
  }
}

type Category = {
  name: string
  icon: any
}

interface FilterBarProps {
  categoryFilterValue: string
  setCategoryFilterValue: (value: string) => void
}

const FilterBar: React.FC<FilterBarProps> = ({
  categoryFilterValue,
  setCategoryFilterValue,
}) => {
  const { currentToolboxObject, setCurrentEvent, setCurrentMethod } =
    useRuntimeStore()
  const Categories = useMemo(() => {
    return CATEGORIES.map((category: Category) => {
      const active = categoryFilterValue === category.name
      const iconClassnames = cn(
        'cursor-pointer duration-200 flex h-10 hover:bg-moon hover:bg-opacity-30 hover:text-moon-darkest items-center justify-center mt-[2px] relative rounded-full text-moon-dark transition-all w-10',
        {
          'bg-white': active,
          'hover:!bg-white': active,
        }
      )

      return (
        <div
          id="FilterBar_Category"
          onClick={() => {
            // setSearchValue('')
            setCategoryFilterValue(category.name)
            setCurrentEvent(null)
            setCurrentMethod(null)
          }}
          key={category.name}
          className={iconClassnames}
          style={{ fontSize: '24px' }}
        >
          <Icon category={category} />
        </div>
      )
    })
  }, [categoryFilterValue])

  return (
    <div
      id="FilterBar"
      className="fixed float-left h-full w-12"
      style={{ backgroundColor: '#DDE9F4' }}
    >
      <div className="w-100 mt-8 flex flex-col items-center justify-center">
        {Categories}
      </div>
    </div>
  )
}

export default FilterBar
