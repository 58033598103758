'use client'
import moment from 'moment-timezone'
import { useRouter } from 'next/navigation'
import React from 'react'
import classNames from 'classnames'
import { useQueryClient } from '@tanstack/react-query'
import { HeliosPill } from '@codeverse/helios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons'
import { motion } from 'framer-motion'

interface PublishedGameCard {
  app: any
  index: number
}

export const variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index: number) => ({
    //use the custom value here
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index, //add delay according to the index
    },
  }),
}

const PublishCard: React.FC<PublishedGameCard> = ({ app, index }) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  return (
    <motion.div
      className={classNames(
        'group relative cursor-pointer rounded-xl p-2 hover:bg-[#DFE8F5] hover:bg-opacity-5',
        'flex flex-initial flex-col'
      )}
      onClick={() => {
        queryClient
          .invalidateQueries({
            queryKey: ['projectDocuments'],
          })
          .then(() => {
            router.push(`/play/${app.id}`)
          })
      }}
      variants={variants}
      initial="initial"
      whileInView="animate"
      key={index}
      viewport={{
        once: true,
      }}
      custom={index}
    >
      <div
        className="relative cursor-pointer rounded-lg bg-[#363B5F]"
        style={{
          height: 0,
          paddingTop: '150.85%',
          backgroundImage: app.screenshot
            ? `url(${app.screenshot})`
            : 'url(/images/home/cv-file-default.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: app.screenshot ? 'cover' : '50%',
        }}
      >
        <HeliosPill className="absolute left-2 top-2" context="dark">
          <FontAwesomeIcon
            icon={faPlayCircle}
            size="lg"
            className="pr-1 text-[#00ce94]"
          />
          {app.play_count}
        </HeliosPill>
      </div>
      <div>
        <div className="font-jakarta-sans max-w-[128px] overflow-hidden text-ellipsis whitespace-nowrap pt-3 text-sm font-medium text-white">
          {app.name}
        </div>
        <div className="font-jakarta-sans pt-.5 max-w-[128px] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-[#A6B1D6]">
          {moment(app.created_at).fromNow()}
        </div>
      </div>
    </motion.div>
  )
}

export default PublishCard
