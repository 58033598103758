'use client'

import React, { useEffect, useMemo, useState } from 'react'
import Drawer from '../Shared/Card/Drawer'
import Profile from './Profile/Profile'
import useUser from '../../Data/User/useUser'
import {
  useCurrentUser,
  useUserFollowings,
  useUserProfile,
} from '../../Data/User/UserDataHooks'
import useUIStore from '../../Stores/useUIStore'
import ProfileContent from './Profile/ProfileContent'
import { useParams, useRouter, useSearchParams } from 'next/navigation'
import Button from '@codeverse/helios/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { toast } from 'react-toastify'
import {
  useMutateCreateFollowing,
  useMutateDeleteFollowing,
} from '../../Data/Follow/FollowDataHooks'
import { useQueryClient } from '@tanstack/react-query'

export default function ProfileModal() {
  const { profileModal, setProfileModal } = useUIStore()
  const params = useSearchParams()
  const [disabled, setDisabled] = useState(false)
  const username = params.get('username') as string
  const { currentUserId, username: currentUserName } = useCurrentUser()
  const { data: userProfile } = useUserProfile(username)
  const queryClient = useQueryClient()
  const {
    data: userFollowings,
    isFetched,
    refetch: refetchFollowings,
  } = useUserFollowings(currentUserName)

  const createFollowingMutation = useMutateCreateFollowing()
  const deleteFollowingMutation = useMutateDeleteFollowing()
  const router = useRouter()

  const [isFollowing, setIsFollowing] = useState(false)
  const [followingId, setFollowingId] = useState('')

  const isCurrentUser = useMemo(() => {
    return username === currentUserName
  }, [currentUserName, username])

  useEffect(() => {
    if (!profileModal) {
      setIsFollowing(false)
    }
  }, [profileModal])

  useEffect(() => {
    if (userFollowings) {
      const _thisFollowing = userFollowings.find(
        (following: any) => following.following.username === username
      )
      if (_thisFollowing) {
        setFollowingId(_thisFollowing.id)
        setIsFollowing(true)
      }
    }
  }, [userFollowings])

  useEffect(() => {
    if (username) {
      setProfileModal(true)
    } else {
      setProfileModal(false)
    }
  }, [username])

  const pillProps = useMemo(() => {
    return {
      following: {
        // value: userProfile ? userProfile.data.following_count : 0,
        value: userProfile?.user.followingCount || 0,
        onClick: () => {
          // dispatch(setMyFriendsTab('followers'))
          // dispatch(openModal('myFriends'))
          // updateMyFriendsTab('following')
          // navigate(friendsUrl)
          // if (isCurrentUser) {
          //   dispatch(setMyFriendsTab('following'))
          //   dispatch(openModal('myFriends'))
          // } else {
          //   dispatch(setMyFriendsTab('following'))
          //   dispatch(openModal('viewFriends'))
          // }
        },
        // className: isCurrentUser ? '' : '!cursor-default'
        className: '',
      },
      followers: {
        value: userProfile?.user.followerCount || 0,
        // value: 0,
        onClick: () => {
          // updateMyFriendsTab('followers')
          // navigate(friendsUrl)
        },
        // className: isCurrentUser ? '' : '!cursor-default'
        className: '',
      },
      points: {
        // value: totalPoints,
        value: 0,
        onClick: () => {},
        // className: isCurrentUser ? '' : '!cursor-default'
        className: '',
      },
    }
  }, [userProfile])

  const handleFollow = () => {
    toast.dismiss()
    createFollowingMutation.mutate(
      {
        userName: username,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ['followings'],
          })
          toast.success(`You are now following ${username}`, {
            position: 'bottom-center',
          })
          setIsFollowing(true)
        },
      }
    )
  }

  const handleUnfollow = (followingId?: string) => {
    toast.dismiss()
    setDisabled(true)
    deleteFollowingMutation.mutate(
      {
        followingId: followingId,
      },
      {
        onSuccess: (data) => {
          setDisabled(false)
          queryClient.invalidateQueries({
            queryKey: ['followings'],
          })
          setIsFollowing(false)
          toast.success(`Unfollowed ${username}`, {
            position: 'bottom-center',
          })
        },
        onError: (error) => {
          setDisabled(false)
          toast.error(`Error`, {
            position: 'bottom-center',
          })
        },
      }
    )
  }

  const SocialButtons = useMemo(() => {
    if (!isFetched) {
      return <div className="w-full md:w-[242px] lg:w-[242px]" />
    }
    return isFollowing ? (
      <Button
        id="ViewProfile_Following"
        size="large"
        className="mr-8 !h-14 px-8"
        spanClassnames="!text-xl font-jakarta-sans ring-neptune-dark text-white"
        context="transparent"
        onClick={() => {
          handleUnfollow(followingId)
        }}
      >
        Unfollow
      </Button>
    ) : (
      <Button
        id="ViewProfile_Follow"
        size="large"
        className="mr-8 !h-14 px-8"
        spanClassnames="!text-xl font-jakarta-sans"
        context="primaryFlat"
        onClick={() => {
          handleFollow()
        }}
      >
        Follow
      </Button>
    )
  }, [isFollowing, isFetched, handleFollow, handleUnfollow, userFollowings])

  // console.log('userData', userData)
  return (
    <Profile
      userProfile={userProfile}
      isOpen={profileModal}
      withDrawer
      pillProps={pillProps}
      username={username}
      handleClose={() => {
        setProfileModal(false)
      }}
      button={() => !isCurrentUser && SocialButtons}
    >
      <ProfileContent />
    </Profile>
  )
}
