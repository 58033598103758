'use client'

import React, { useEffect, useMemo, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
// import {
//   useResponsive,
//   ResponsiveContext,
// } from '../../../../../libs/hooks/src/index'

/**
 * Props for the Drawer component.
 * @interface DrawerProps
 */
interface DrawerProps {
  /**
   * Determines whether the Drawer is open or closed.
   * @type {boolean}
   */
  isOpen: boolean
  /**
   * Function to handle closing the Drawer.
   * @type {(e) => void}
   */
  handleClose: (e) => void
  /**
   * The content to be displayed inside the Drawer.
   * @type {any}
   */
  children: any
  /**
   * Determines whether the Drawer can be closed by pressing the escape key.
   * @type {boolean}
   * @default false
   */
  allowEsc?: boolean
  /**
   * Determines whether the Drawer is open or closed on initial render.
   * @type {boolean}
   * @default false
   */
  initialOpen?: boolean

  /**
   * Determines whether to hide the close button.
   * @type {boolean}
   * @default false
   */
  hideClose?: boolean
  /**
   * Custom close element
   * @type {function}
   */
  customClose?: () => React.ReactNode
}

const drawer = {
  base: '',
  shade:
    'fixed backdrop-blur backdrop-brightness-[30%] bg-[#101432] bg-opacity-[.08] inset-0 z-[9997]',
  closeButton:
    'text-white fixed bg-neptune-darkest bg-opacity-50 flex h-11 items-center justify-center right-4 md:right-6 lg:right-6 top-[52px] md:top-4 lg:top-4 w-12 rounded-full cursor-pointer z-[9999]',
  content: 'fixed inset-0 w-full z-[9998] pointer-events-none',
  contents:
    'pointer-events-auto bg-[#1C2141] rounded-t-4.5xl w-full absolute bottom-0 flex flex-row shadow-lg px-4 md:px-8 lg:px-8 pt-6 md:pt-[64px] lg:pt-[64px] xl:pt-[64px] 2xl:pt-[64px] text-white overflow-x-visible overflow-y-scroll h-auto',
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  handleClose,
  children,
  allowEsc,
  initialOpen,
  hideClose,
  customClose,
}) => {
  // const [initialSet, setInitial] = useState(false)

  // const { isMobile } = useResponsive()
  function handleEsc(e: KeyboardEvent) {
    if (
      e.key === 'Esc' ||
      (e.key === 'Escape' && typeof handleClose === 'function')
    ) {
      handleClose(e)
    }
  }

  useEffect(() => {
    if (allowEsc && isOpen) {
      document.addEventListener('keydown', handleEsc, { capture: true })
      return () => {
        document.removeEventListener('keydown', handleEsc)
      }
    }
  }, [allowEsc, isOpen, handleClose, handleEsc])

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="modal"
            initial={{ opacity: initialOpen ? 1 : 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
            className={drawer.shade}
            onClick={(e) => {
              e.stopPropagation()
              handleClose(e)
            }}
          />
        )}
      </AnimatePresence>
      {isOpen && !customClose && !hideClose && (
        <motion.div
          initial={{ opacity: initialOpen ? 1 : 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={drawer.closeButton}
          onClick={(e) => {
            if (typeof handleClose === 'function') {
              handleClose(e)
            }
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </motion.div>
      )}
      {isOpen && customClose && customClose()}
      {isOpen && (
        <motion.div
          initial={{ y: 800, opacity: 1 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, y: 800 }}
          transition={{ duration: 0.4, type: 'tween', ease: 'easeOut' }}
          className={drawer.content}
          // onClick={() => dispatch(updateMenu('projects'))}
        >
          <div
            className={drawer.contents}
            style={{
              height: false ? 'calc(100% - 116px)' : 'calc(100% - 71px)',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </motion.div>
      )}
    </>
  )
}

export default Drawer
