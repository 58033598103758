'use client'

import Button from '@codeverse/helios/button'
import Input from '@codeverse/helios/input'
import Cookies from 'js-cookie'
import { useRouter, useSearchParams } from 'next/navigation'
import { FormEvent, FormEventHandler, useEffect, useRef, useState } from 'react'
import { Control, Controller, useForm, UseFormGetValues } from 'react-hook-form'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons'
import { toast } from 'react-toastify'

interface Step2Props {
  router: any
  getValues: UseFormGetValues<LoginFormValues>
  control: Control<LoginFormValues>
}

interface LoginFormValues {
  username: string
  password: string
}

export default function Step2({ router, getValues, control }: Step2Props) {
  const [loading, setLoading] = useState(false)
  const loginRef = useRef<HTMLInputElement>(null)
  const searchParams = useSearchParams()

  useEffect(() => {
    if (loginRef?.current) {
      loginRef.current.focus()
    }
  }, [loginRef])

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const { username } = getValues()
        if (!username) {
          toast.dismiss()
          toast.error('Please enter a username/email', {
            position: 'bottom-center',
          })
        } else {
          const next = searchParams.get('next')
          if (next) {
            router.push(`/login?step=3&next=${encodeURI(next)}`)
          } else {
            router.push(`/login?step=3`)
          }
        }
      }}
    >
      <div className="flex w-full flex-col justify-center">
        <div
          className="font-jakarta-sans cursor-pointer text-sm font-semibold text-[#090E2B]"
          onClick={() => router.back()}
        >
          <FontAwesomeIcon className="pr-2" size="lg" icon={faArrowLeft} /> Go
          back
        </div>
        <div className="mt-4 flex justify-center">
          <Image
            src="/images/login/cv-logo-nova.svg"
            width={56}
            height={56}
            alt={''}
          />
        </div>
        <div className="flex items-center justify-center">
          <h4 className="mt-4 text-center">Sign in to your account</h4>
        </div>

        <p className="mt-2 text-center text-base font-medium">
          Log in with your username and password to play games and access the
          coding studio
        </p>

        <div className="mt-7">
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <Input
                size="regular"
                {...field}
                inputRef={loginRef}
                placeholder="Username/Email"
              />
            )}
          />
        </div>

        <Button
          size="large"
          disabled={loading}
          className="mt-7 w-full"
          context="neptune"
        >
          Next
        </Button>
      </div>
    </form>
  )
}
