import React from 'react'

interface StepHeaderProps {
  title: string
  position: number
}

const StepHeader: React.FC<StepHeaderProps> = ({ title, position }) => {
  return (
    <div className="pb-4.5 mb-4 flex flex-row border-b-[1px] border-[#DFE8F5]">
      <div className="font-jakarta-sans text-moon-darkest ml-4 flex h-10 w-10 items-center justify-center rounded-full bg-[#E9EFF8] text-2xl font-bold">
        {position}
      </div>
      <h4 className="font-jakarta-sans text-moon-darkest mb-0 ml-3 flex select-none items-center text-[20px] font-bold">
        {title}
      </h4>
    </div>
  )
}

export default StepHeader
