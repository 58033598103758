import { SatelliteClient } from '../../../../../libs/satellite-client/src'
import { start } from '../../Util/public-session-tracker'
import { CreateSessionArgs, NRDY_PARAMS } from './AuthTypes'
import determineTimezone from './determineTimezone'

export const createSession = (
  data: CreateSessionArgs,
  callback,
  handleError?
) => {
  return SatelliteClient.platform
    .post(`/sessions`, {
      data: {
        data: {
          attributes: {
            ...data,
          },
          type: 'sessions',
        },
      },
    })
    .then((response) => {
      if (data.slt && typeof callback === 'function') {
        callback(response)
      } else if (typeof callback === 'function') {
        callback(response)
        // TODO: Migrate other login to use this action
      }
    })
    .catch((error) => {
      if (typeof handleError === 'function') {
        handleError(error)
      }
      console.log('error', error)
    })
}

export const createPartnerRegistration = async (
  nrdy_params: NRDY_PARAMS,
  avatarId: string
) => {
  const organization_name = nrdy_params.nrdy_account_name
  const name = nrdy_params.nrdy_student_name

  if (
    nrdy_params.nrdy_student_name === '' ||
    nrdy_params.nrdy_student_name === null
  ) {
    nrdy_params.nrdy_student_name = 'User'
  }

  if (
    nrdy_params.nrdy_account_id === '' &&
    nrdy_params.nrdy_student_organization_id
  ) {
    nrdy_params.nrdy_account_id = null
    nrdy_params.nrdy_account_name = null
  }

  let sessionToken = localStorage.getItem('sessionToken')

  if (!sessionToken) {
    sessionToken = await start()
  }
  return SatelliteClient.platform.post('/homepage/registration', {
    headers: Object.assign(
      {},
      { Accept: 'application/json' },
      { 'Content-Type': 'application/json' }
    ),
    data: {
      nrdy_params: nrdy_params,
      organization_name: organization_name,
      name: name,
      avatar_id: avatarId,
      time_zone: determineTimezone(),
      public_session_id: localStorage.getItem('sessionToken'),
    },
    skipQueryPrefill: true,
    skipDeserialize: true,
  })
}
