import cn from 'classnames'
import { ButtonVariantProps, pillStyles } from './pill-styles'

interface PillProps {
  children: React.ReactNode
  className?: string
}

export function HeliosPill({
  size = 'small',
  context = 'primary',
  children,
  className,
}: PillProps & ButtonVariantProps) {
  return (
    <div className={cn(className, pillStyles({ size, context }))}>
      {children}
    </div>
  )
}

export default HeliosPill
