import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classNames from 'classnames'
import {
  faRectangleVerticalHistory,
  faBookSpells,
  faLayerGroup,
  faBadge,
  faUserFriends,
  faLockAlt,
} from '@fortawesome/pro-duotone-svg-icons'

import CreateMenuItem from './CreateMenuItem'

import { useUIStore } from 'apps/studio-shared/src/Stores'
import { usePathname, useRouter } from 'next/navigation'
import { useOuterClick } from '@codeverse/hooks'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons'
import { useQueryClient } from '@tanstack/react-query'
import useCreateNewProject from '../../../Studio/Hooks/useCreateNewProject'
import useAuth from 'apps/studio-shared/src/Hooks/Auth/useAuth'

interface CreateMenuDropDownProps {}

const CreateMenuDropDown: React.FC<CreateMenuDropDownProps> = () => {
  const router = useRouter()
  const [disabled, setDisabled] = useState(false)
  const createNewProject = useCreateNewProject()
  const pathname = usePathname()
  const { username } = useAuth()
  const {
    createNewMenu,
    setCreateNewMenu,
    setFriendsModal,
    setNewProjectTemplateModal,
    setProfileModal,
    setProfileTabState,
  } = useUIStore()
  const createMenuClassnames = classNames(
    'absolute bg-[#292E50] bg-opacity-90 ring-[1px] ring-white ring-opacity-10 create-menu-container left-[-115px] rounded-[32px] top-[54px] w-[320px] z-2000 border border-white border-opacity-[.08]'
  )

  useEffect(() => {
    setCreateNewMenu(false)
  }, [pathname])

  const innerRef = React.useRef(null)

  const totalProjects = 0

  const hideCreateMenu = () => {
    setCreateNewMenu(false)
  }

  useOuterClick(innerRef, () => setCreateNewMenu(false))

  return (
    <AnimatePresence>
      {createNewMenu && (
        <motion.div
          className={createMenuClassnames}
          initial={{ y: 30, opacity: 0.2 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          ref={innerRef}
          id="CreateMenuDropDown"
        >
          <style>{`.create-menu-container .create-menu__button:first-child span {
        padding-left: 0 !important;
        margin-left: 0 !important;
        display: flex;
        justify-content: center;
        }
        .create-menu-container .rounded-half {
          background-image: linear-gradient(90deg, #5225ee 0%, #8c00db 13%, #e0476c 25%, #db6c23 39%, #e0476c 56%, #8c00db 73%, #5225ee 87%, #8c00db 100%);
          background-size: 600% 100%;
          color: #060921;
          width: 48px;
          height: 48px;
        }
        .create-menu-container .pointer {
          position: absolute;
          top: -9px;
          left: 177px;
          width: 18px;
          height: 18px;
          background-color: #060921;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          border-radius: 4px 0 0 0;
        }`}</style>
          {/* <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            className="pointer"
          ></motion.div> */}
          <ul className="create-menu__list px-4 py-4">
            <CreateMenuItem
              label="Project"
              description="Create a new app or game with a blank canvas"
              icon={faRectangleVerticalHistory}
              primaryColor="#B305F8"
              menuChoice="project"
              disabled={disabled}
              handleClick={() => {
                hideCreateMenu()
                setNewProjectTemplateModal(true)
              }}
            />
            <CreateMenuItem
              label="Mission"
              description="Follow guided instructions to create a game"
              icon={faBookSpells}
              primaryColor="#FF8500"
              menuChoice="missions"
              locked={false}
              handleClick={() => {
                router.push('/missions')
                hideCreateMenu()
              }}
            />
            <CreateMenuItem
              label="Badge"
              description="Complete badges to build coding skills"
              icon={faBadgeCheck}
              primaryColor="#3e7cf9"
              menuChoice="badges"
              locked={false}
              handleClick={() => {
                router.push(`${pathname}?username=${username}`)
                setProfileTabState('Achievements')
                setProfileModal(true)
                hideCreateMenu()
              }}
            />
            <CreateMenuItem
              label="Friends"
              description="Follow other creators to share games"
              icon={faUserFriends}
              primaryColor="#00CE94"
              menuChoice="friends"
              locked={false}
              handleClick={() => {
                setFriendsModal(true)
                hideCreateMenu()
              }}
            />
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CreateMenuDropDown
