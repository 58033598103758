'use client'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface CreateMenuItemProps {
  label?: any
  description?: any
  icon?: any
  primaryColor?: any
  secondaryColor?: any
  menuChoice?: any
  locked?: any
  size?: any
  disabled?: boolean
  handleClick: any
}

const CreateMenuItem: React.FC<CreateMenuItemProps> = ({
  label,
  description,
  icon,
  primaryColor,
  secondaryColor,
  handleClick,
  menuChoice,
  disabled,
  locked,
  size,
}) => {
  const colorStyle = {
    color: primaryColor,
    fontSize: '1.5em',
    '--fa-primary-color': primaryColor,
    '--fa-secondary-color': secondaryColor || primaryColor,
  }

  return (
    <li
      id={`CreateMenuItem_${label}`}
      className="rounded-2.5xl group mb-2 h-[72px] w-[288px] cursor-pointer px-3 py-2 last:mb-0 hover:bg-[#363b5f]"
      onClick={!disabled ? handleClick : null}
    >
      <div className="flex items-center justify-center">
        <div className="flex h-10 w-10 items-center justify-center rounded-xl group-hover:bg-white">
          <FontAwesomeIcon style={colorStyle} icon={icon} />
        </div>
        <div className="pl-4">
          <h4 className="font-jakarta-sans font-semiald mb-[5px] text-sm leading-tight text-white">
            {label}
          </h4>
          <p className="font-jakarta-sans w-40 text-xs font-medium text-[#A6B1D6]">
            {description}
          </p>
        </div>

        <div className="ml-auto flex items-center justify-center">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#DFE8F5]">
            <FontAwesomeIcon className="text-black" icon={faArrowRight} />
          </div>
        </div>
      </div>
    </li>
  )
}

export default CreateMenuItem
