'use client'
import Button from '@codeverse/helios/button'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

export default function EditButton({ className, onClick }) {
  return (
    <div className={classNames(className)}>
      <Button
        context="secondary"
        size="small"
        className="flex"
        onClick={onClick}
      >
        {/* <div className="bg-moon-lightest mb-2 mr-3 mt-2 inline-flex h-8 w-8 items-center justify-center rounded-full">
          <FontAwesomeIcon className="text-moon" size="sm" icon={faPencil} />
        </div> */}
        Edit
      </Button>
    </div>
  )
}
