import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/pro-solid-svg-icons'
import useMissionStore from '../../Stores/useMissionStore'
import { useEffect } from 'react'

const ToggleMissionButton = ({}: {}) => {
  const { showMissionContent, setShowMissionContent, setShowPortal } =
    useMissionStore()

  useEffect(() => {
    if (showMissionContent) {
      setShowPortal(true)
    } else {
      setShowPortal(false)
    }
  }, [showMissionContent])

  return (
    <div className="inline-flex h-full items-center justify-center">
      <div
        onClick={() => setShowMissionContent(!showMissionContent)}
        className={twMerge(
          'font-jakarta-sans relative mr-4 inline-flex h-8 w-[91px] cursor-pointer select-none items-center rounded-lg border border-transparent bg-[#1c2141] font-medium text-[#dfe8f5] transition duration-200'
          // checked ? 'bg-cyan-500' : 'border-slate-500 bg-slate-700'
        )}
      >
        <motion.div
          initial={{
            width: '20px',
            x: showMissionContent ? 0 : 32,
          }}
          animate={{
            height: ['32px', '10px', '32px'],
            width: ['32px', '30px', '32px', '32px'],
            x: showMissionContent ? 59 : 0,
            backgroundColor: !showMissionContent ? '#8d98c6' : '#02be89',
          }}
          transition={{
            duration: 0.3,
            delay: 0.1,
          }}
          key={String(showMissionContent)}
          className={classNames(
            twMerge(
              'z-10 flex h-8 items-center justify-center rounded-lg shadow-md'
            )
            // {
            //   'bg-[#8d98c6]': !showMissionContent,
            //   'bg-titan-dark': showMissionContent,
            // }
          )}
        >
          <FontAwesomeIcon icon={faMap} />
        </motion.div>
        <motion.div
          initial={{
            // width: '20px',
            x: showMissionContent ? -22 : 12,
            scale: 1,
          }}
          animate={{
            height: ['32px', '10px', '32px'],
            // width: ['32px', '30px', '32px', '32px'],
            x: showMissionContent ? -22 : 12,
          }}
          transition={{
            duration: 0.3,
            delay: 0.1,
          }}
          key={'toggle'}
          className={classNames(twMerge('z-10 flex items-center'), {
            // 'bg-white': !showMissionContent,
            // 'bg-titan-dark': showMissionContent,
          })}
        >
          {showMissionContent ? 'Show' : 'Hide'}
        </motion.div>
      </div>
    </div>
  )
}

export default ToggleMissionButton
