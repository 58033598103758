'use client'
import Button from '@codeverse/helios/button'
import { faArrowLeft, faBars } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import FileSelector from 'apps/studio-shared/src/Components/Studio/FileSelector'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from '../../../Stores/useMissionStore'
import useMissionStepActions from './MissionStep/useMissionStepActions'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useRuntimeRefsStore } from 'apps/studio-shared/src/Components/Studio/Hooks/Runtime/useRuntimeRefsStore'
import useKidScript from 'apps/studio-shared/src/Components/Studio/Hooks/useKidScript'
import { useMissionSteps } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'

interface MissionBuilderToolBarProps {}

const MissionBuilderToolBar: React.FC<MissionBuilderToolBarProps> = () => {
  const router = useRouter()
  const { editorRefs } = useRuntimeRefsStore()
  const mainEditorRef = editorRefs['main']
  const { stopKidScript, runKidScript } = useKidScript()
  const {
    setGuidedMissionMode,
    guidedMissionMode,
    setCurrentStep,
    currentStep,
    setShowPortal,
    setBlockNextStep,
  } = useMissionStore()
  const { showMissionEditor, previewMissionMode, setShowMissionEditor } =
    useMissionBuilderStore()
  const { replayMissionStep } = useMissionStepActions()
  const params = useParams()
  const pathname = usePathname()
  const { data: missionSteps } = useMissionSteps(params.id as string)
  // const { currentStep, previewMissionMode, showMissionEditor } =
  //   missionState.get()

  const isMissionStep = useMemo(() => {
    return !!params?.stepId || pathname.includes('/new')
  }, [params, pathname])

  return (
    <div id="MenuBar" className="bg-moon-dark flex h-12 w-full items-center">
      <div
        id="MenuBar_Home"
        className="bg-moon-dark border-moon-darkest flex h-full min-w-[50px] cursor-pointer items-center justify-center border-r-[1px] border-opacity-50"
        onClick={() => {
          setShowMissionEditor(false)
          router.push('/mission_builder')
        }}
      >
        <FontAwesomeIcon className="text-white" icon={faBars} size={'2x'} />
      </div>

      <FileSelector />

      <div className="ml-auto mr-2">
        {guidedMissionMode && !showMissionEditor && (
          <Button
            id="MenuBar_OpenStepManager"
            style={{ height: '32px' }}
            className="mr-0 !pl-3 pr-3"
            context={'nebula'}
            size="tiny"
            onClick={() => {
              setShowMissionEditor(true)
              setGuidedMissionMode(false)
              setShowPortal(false)
              if (!isMissionStep) {
                setCurrentStep(null)
              }
              const mainEditorRef = editorRefs['main']
              if (mainEditorRef) {
                stopKidScript()
                mainEditorRef.reset()
                replayMissionStep(currentStep, true)
              }
              // if (mainEditorRef) {}
              // setShowMissionEditor(true)
              // setShowPortal(false)
              // setPreviewCurrentStep(false)
              // replayMissionSteps(currentStep, true, null, true)
            }}
          >
            Open Mission Editor
          </Button>
        )}
        {!isMissionStep && showMissionEditor && !guidedMissionMode && (
          <Button
            id="MenuBar_PreviewCurrentStep"
            className="mr-2 !pl-4 pr-4"
            context={'earthDark'}
            size="tiny"
            // onClick={() => setShowToolbox(!showToolbox)}
            onClick={() => {
              // setShowMissionEditor(false)
              // setGuidedMissionMode(true)
              mainEditorRef?.reset()
              setBlockNextStep(false)
              setGuidedMissionMode(true)
              setShowMissionEditor(false)
              if (missionSteps) {
                //@ts-ignore
                setCurrentStep({
                  //@ts-ignore
                  id: missionSteps[0]?.id || '',
                  //@ts-ignore
                  ...missionSteps[0].attributes,
                })

                setTimeout(() => {
                  //@ts-ignore
                  replayMissionStep(missionSteps[0].attributes, false, [])
                  if (
                    missionSteps &&
                    //@ts-ignore
                    missionSteps[0].attributes.run_automatically
                  ) {
                    runKidScript()
                  }
                }, 200)
              }
              // setPreviewCurrentStep(true)
              // setShowEditMission(false)
              setShowPortal(true)
              // setToolboxMissionStepInsertable(false)
            }}
          >
            Preview Mission
          </Button>
        )}
        <div id="MissionBuilderToolbar" />
      </div>
    </div>
  )
}

export default MissionBuilderToolBar
