'use client'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { Editor } from '@kidscript/editor'

import fetchClient from 'apps/studio-shared/src/Data/fetch/fetchClient'

import kidscriptVersionFormatter from '../Utils/kidscriptVersionFormatter'
import useRuntimeStore from './Runtime/useRuntimeStore'
import { useRuntimeRefsStore } from './Runtime/useRuntimeRefsStore'
import useSession from './useSession'
import { useKeyrings } from 'apps/studio-shared/src/Data/User/useKeyrings'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import { useMainEditorRef } from './useMainEditorRef'
import { usePathname } from 'next/navigation'
import { EditorOptions } from '@kidscript/editor/dist/src/types/EditorOptions'
import useKidScript from './useKidScript'
import useAuth from 'apps/studio-shared/src/Hooks/Auth/useAuth'
import useKeyRingStore from 'apps/studio-shared/src/Stores/useKeyringStore'

type SetupEnv = {
  editor: any
  interpreter: any
}

declare global {
  interface Window {
    Editor: {
      Editor: typeof Editor
    }
    editor: Editor
    interpreter: any
  }
}

export function useInitializeRuntime({
  documents,
  projectId,
  kidscriptVersion,
  saveDocuments = false,
  useEditor = true,
  versionId,
  isMission,
  isMounted,
}: {
  projectId?: string
  useEditor?: boolean
  saveDocuments?: boolean
  kidscriptVersion?: string
  documents: Array<{
    id: string
    filename: string
    kidscript: string
    createdAt: string
  }>
  versionId?: string
  isMission?: any
  isMounted?: any
}) {
  const pathname = usePathname()
  const { user, isFetched } = useSession()
  const { setEditorRef, setInterpreterRef } = useRuntimeRefsStore()
  const { currentKeyRing, setCurrentKeyRing, setShowKeyring } =
    useKeyRingStore()
  const {
    kidScriptSetup,
    scriptsLoaded,
    projectSaved,
    projectHasChanges,
    setEditorSetup,
    setKidScriptSetup,
    setProjectSaved,
    setProjectHasChanges,
    setDocuments,
    setKidScriptDependenciesFetched,
    setKidscriptRunning,
  } = useRuntimeStore()
  const { resetEditorRefs } = useRuntimeRefsStore()
  const { setCurrentStep, setGuidedMissionMode, guidedMissionMode } =
    useMissionStore()
  const { setPreviewMissionMode, setShowMissionEditor } =
    useMissionBuilderStore()
  const mainEditorRef = useMainEditorRef()

  const [environmentSetup, setEnvironmentSetup] = useState(false)
  const { data: keyringGrants } = useKeyrings()
  const { saveDocument } = useKidScript()

  const isMissionBuilder = pathname.includes('mission_builder')

  // Set User Keyrings in Library
  useEffect(() => {
    //@ts-ignore
    if (keyringGrants?.data && KidScript) {
      console.log('Runtime: Adding Keyrings')
      //@ts-ignore
      KidScript.library.addKeyrings(
        keyringGrants.data.map(
          (m: any) => m.relationships.library_keyring.data.id
        )
      )
    }
  }, [keyringGrants])

  // Sets up the environment for the component to run in
  const setupEnvironment = useCallback(
    async (version?: string) => {
      setEnvironmentSetup(true)

      if (environmentSetup) return

      setKidScriptSetup(true)

      //@ts-ignore
      if (!KidScript) {
        return
      }
      let editor: Editor

      //@ts-ignore
      return await KidScript.library.requireCatalog().then(async () => {
        //@ts-ignore
        return await KidScript.library
          .requireDependencies([
            {
              name: 'Game',
              version: 'current',
            },
          ])
          .then(() => {
            // setKidScript(kidscriptToSetup)
            // // For ToolBox Library
            // //@ts-ignore
            // setObjects(KidScript.library.objects)
            // //@ts-ignore

            // SETUP EDITOR
            if (useEditor) {
              //@ts-ignore
              // runtimeState.objects.set(JSON.stringify(KidScript.library.getObjects()) )
              const mainDocument = documents.find(
                (doc) => doc.filename === '/main.ks'
              )
              const headDocument = documents.find(
                (doc) => doc.filename === '/head.ks'
              )
              const ksVersions = kidscriptVersionFormatter(kidscriptVersion)
              window.editor = editor = new window.Editor.Editor(
                //@ts-ignore
                document.getElementById('editor'),
                //@ts-ignore
                document.getElementById('preview'),
                [headDocument?.kidscript || '', mainDocument?.kidscript || ''],
                `${ksVersions[0]}.${ksVersions[1]}.${ksVersions[2]}`,
                async (e) => {
                  // set new upgraded kidscript
                  // setKidScript(e.documents)
                  // create new project version with kidscript version
                  // createInitialKidScript2ProjectVersion(projectId)
                },
                Object.assign(
                  {},
                  (isMission || isMissionBuilder) && {
                    lineHeight: 30,
                  }
                )
              )
              // window?.editor.switchDocument(1)
              setEditorRef('main', editor)
              // setRef('editor', editor)
              // runtimeRefState.editorRefs.set([
              //   { identifier: 'editor', ref: editor },
              // ])
              editor.on('ready', () => {
                setEditorSetup(true)
                setKidScriptSetup(true)
                // setEditorRef(editor)
                if (isMissionBuilder) {
                  editor.setMode('mission_admin' as EditorOptions['mode'])
                }
              })

              setKidScriptDependenciesFetched(true)
            }

            setDocuments(documents)

            const auth = localStorage.getItem('auth')
            const { accessToken, currentUserId, username } = JSON.parse(
              auth || '{}'
            )

            //@ts-ignore
            const interpreter = (window.interpreter = new KidScript.Interpreter(
              //@ts-ignore
              Object.assign(
                {},
                {
                  type: useEditor ? 'edit' : 'play',
                  instantiate: {
                    Game: {
                      stage: document.getElementById('stage'),
                      runtimeContainer:
                        document.getElementById('runtimeEnvironment'),
                      // physicsStage: physicsStageElt,
                    },
                  },
                  currentUser: {
                    username: user?.referralCode,
                    id: user?.id,
                    accessToken: accessToken,
                  },
                },
                !useEditor && {
                  project: {
                    versionId: versionId,
                    projectId: projectId,
                  },
                }
              )
            ))
            //@ts-ignore
            window.game = interpreter.getPreInstantiated('game')
            setInterpreterRef('main', interpreter)
            if (useEditor && editor) {
              editor.attachInterpreter(interpreter)
              editor.on('keyringRequired', (e) => {
                setCurrentKeyRing(e.keyringId)
                setShowKeyring(true)
              })
              //@ts-ignore
              interpreter.on('stateChange', (e) => {
                if (e.toState === 'STARTING') {
                  editor.preview.hide()
                } else if (e.toState === 'READY') {
                  editor.preview.show()
                }
              })
              // document.getElementById('#canvas')
            }
            if (!useEditor) {
              //@ts-ignore
              const ksVersions = kidscriptVersionFormatter(version)
              const mainDocument = documents.find(
                (doc) => doc.filename === '/main.ks'
              )
              const headDocument = documents.find(
                (doc) => doc.filename === '/head.ks'
              )
              try {
                //@ts-ignore
                KidScript.onDependenciesResolved(
                  //@ts-ignore
                  [headDocument?.kidscript, mainDocument?.kidscript],
                  `${ksVersions[0]}.${ksVersions[1]}.${ksVersions[2]}`,
                  () => {
                    //@ts-ignore
                    const instructions = KidScript.parseDocuments(
                      //@ts-ignore
                      [headDocument?.kidscript, mainDocument?.kidscript],
                      `${ksVersions[0]}.${ksVersions[1]}.${ksVersions[2]}`
                    ).toInstructionTree()

                    //@ts-ignore
                    if (instructions !== false) {
                      const state = interpreter.getState()
                      if (state === 'IDLE') return
                      const promise = interpreter.execute(instructions)
                      promise
                        .then(
                          //@ts-ignore
                          (result) => {
                            setKidScriptDependenciesFetched(true)
                            setKidscriptRunning(true)
                          },
                          //@ts-ignore
                          (error) => {}
                        )
                        //@ts-ignore
                        .catch((exception) => {})
                    }
                  }
                )
              } catch (e) {
                console.log('DEP FETCH ERROR', e)
              }
            }
            return {
              editor,
              interpreter,
            } as SetupEnv
          })
          .catch((error: any) => {
            console.log('KidScript Setup Error:', error)
            return
          })
      })
    },
    [useEditor, kidScriptSetup, environmentSetup, guidedMissionMode]
  )

  const handleRuntimeUnmount = () => {
    setKidScriptSetup(false)
    setEnvironmentSetup(false)
    setKidScriptDependenciesFetched(false)
    setEditorSetup(false)
    if (useEditor) {
      console.log('Destroying editor')
      setInterpreterRef('main', null)
      setEditorRef('main', null)
      typeof window.editor?.destroy === 'function' && window.editor?.destroy()
      delete window.interpreter
      //@ts-ignore
      delete window.editor
    }
  }

  // Set up functions
  //

  const documentsSorted = useMemo(() => {
    return (
      documents?.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      }) || []
    )
  }, [documents])

  useEffect(() => {
    if (useEditor) {
      if (
        !kidScriptSetup &&
        documentsSorted.length > 0 &&
        isFetched &&
        isMounted
      ) {
        setupEnvironment()
      }
    }
  }, [documentsSorted, isFetched, isMounted])

  // useOnMountUnsafe(() => {
  //   setupEnvironment()
  // })

  // useEffect(() => {
  //   console.log('setting up environment')
  //   setupEnvironment()
  // }, [])

  useEffect(() => {
    let handleSave: any
    if (isMissionBuilder) return
    if (guidedMissionMode) return
    if (
      saveDocuments &&
      mainEditorRef &&
      documents.length === 2 &&
      kidScriptSetup
    ) {
      handleSave = async (e: any) => {
        setProjectHasChanges(true)
        let filename = '/main.ks'
        if (e.doc === 0) {
          filename = '/head.ks'
        }
        const document = documents
          // ?.filter((documents) => !documents.meta?.deleted_at)
          .find((document) => document.filename === filename)

        if (document && document.id && document.id !== '1') {
          // if (e.source === '') {
          //   // LogRocket.captureMessage(
          //   //   'Warning: Trying to overwrite populated original document with blank document'
          //   // )
          //   return new Promise((resolve) => {
          //     resolve('')
          //   })
          // }
          return await saveDocument(
            document.id,
            filename,
            e.source,
            projectId || ''
          ).then(() => {
            setProjectHasChanges(false)
            setProjectSaved(true)
          })
        }
      }
      if (typeof mainEditorRef.on === 'function') {
        mainEditorRef.on('saveRequired', handleSave)
      }

      return () => {
        if (mainEditorRef && handleSave) {
          mainEditorRef.off('saveRequired', handleSave)
        }
      }
    }
  }, [
    documents,
    kidScriptSetup,
    mainEditorRef,
    isMissionBuilder,
    guidedMissionMode,
  ])

  useEffect(() => {
    let handleCompile: any
    if (kidScriptSetup && projectSaved && !projectHasChanges && mainEditorRef) {
      handleCompile = () => {
        setProjectSaved(false)
        setProjectHasChanges(true)
      }
      mainEditorRef?.compiler.on('compiled', handleCompile)
    }
    return () => {
      if (window.editor && handleCompile && kidScriptSetup) {
        mainEditorRef?.compiler.off('compiled', handleCompile)
      }
    }
  }, [kidScriptSetup, projectSaved, projectHasChanges, mainEditorRef])

  // useEffect(() => {
  //   if (kidscriptSetup) {
  //     const objects = KidScript.library.getObjects()
  //     Object.keys(objects).forEach(async (objectName) => {
  //       if (objectName) {
  //         await objects[objectName]?.ensureLoaded()
  //         const methods = objects[objectName].getMethods()
  //         console.log(`${objectName} methods`, methods)
  //         const kidscriptTrainLine: Array<string> = []
  //         kidscriptTrainLine.push(`import ${objectName}`)
  //         kidscriptTrainLine.push(
  //           `var anyvariablename = new ${objectName}(0, 100)`
  //         )
  //         Object.keys(methods).forEach((methodName) => {
  //           if (methods[methodName].internal) return
  //           if (methods[methodName].parameters.length > 0) {
  //             const parameters: Array<string> = []
  //             const constantValues: Array<string> = []
  //             methods[methodName].parameters.map((parameter) => {
  //               const constants = parameter.getConstant()
  //               if (parameter) {
  //                 if (parameter.className === 'Number') {
  //                   parameters.push('100')
  //                 } else if (constants) {
  //                   constants.values.map((value) => {
  //                     constantValues.push(value)
  //                   })
  //                 }
  //               }
  //             })
  //           } else {
  //             kidscriptTrainLine.push(`${objectName}.${methodName}()`)
  //           }
  //         })
  //       }
  //     })
  //   }
  // }, [kidscriptSetup])
  useEffect(() => {
    return () => {
      if (kidScriptSetup) {
        handleRuntimeUnmount()
        setPreviewMissionMode(false)
        setShowMissionEditor(true)
        setGuidedMissionMode(false)
        setCurrentStep(null)
        resetEditorRefs()
      }
    }
  }, [kidScriptSetup])

  return { setupEnvironment }
}
