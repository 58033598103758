'use client'
import { useMissionStep } from 'apps/studio-shared/src/Data/Mission/MissionDataHooks'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import { useParams } from 'next/navigation'
import React from 'react'
import CurrentStep from '../CurrentStep'
import Footer from '../MissionStepCardFooter'

interface CanvasMissionStepProps {}

const CanvasMissionStep: React.FC<CanvasMissionStepProps> = () => {
  const { currentStep } = useMissionStore()
  const { previewMissionMode, showMissionEditor } = useMissionBuilderStore()
  const isCanvas = currentStep?.display === 'canvas'

  if (!isCanvas) return null

  if (showMissionEditor) return null

  return (
    <div className="z-2000 absolute right-0 top-0 h-full w-full bg-white px-4 py-4">
      <CurrentStep />
      <div className="absolute bottom-4 left-0 right-0">
        <Footer />
      </div>
    </div>
  )
}

export default CanvasMissionStep
