import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import useRuntimeStore from '../Hooks/Runtime/useRuntimeStore'

interface BreadcrumbsProps {
  categoryFilterValue: string
  setCategoryValue: any
  setSearchValue: any
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  categoryFilterValue,
  setCategoryValue,
  setSearchValue,
}) => {
  const {
    currentToolboxObject,
    currentMethod,
    currentEvent,
    setCurrentToolboxObject,
    setCurrentEvent,
    setCurrentMethod,
  } = useRuntimeStore()

  const currentToolboxMethodOrEventName = useMemo(() => {
    if (currentMethod) {
      return currentMethod.name
    }

    if (currentEvent) {
      return currentEvent.name
    }
    return null
  }, [currentMethod, currentEvent])

  return (
    <div className="pl-4 pt-4">
      <h4 className="font-poppins-semibold mb-0 ml-2.5 flex-grow text-[19px]">
        <span
          onClick={() => {
            setCurrentToolboxObject(null)
            setCurrentEvent(null)
            setCurrentMethod(null)
          }}
          className={classNames('cursor-pointer', {
            'opacity-50': !!currentToolboxObject,
          })}
        >
          {categoryFilterValue}
        </span>

        {!!currentToolboxObject && (
          <>
            <FontAwesomeIcon className="mx-2" icon={faCaretRight} size={'sm'} />
            <span
              className={classNames('cursor-pointer', {
                'opacity-50': !!currentToolboxMethodOrEventName,
              })}
              onClick={() => {
                setCurrentEvent(null)
                setCurrentMethod(null)
              }}
            >
              {currentToolboxObject?.name}
            </span>
          </>
        )}
        {!!currentToolboxMethodOrEventName && (
          <>
            <FontAwesomeIcon className="mx-2" icon={faCaretRight} size={'sm'} />
            <span>{currentToolboxMethodOrEventName}</span>
          </>
        )}
      </h4>
    </div>
  )
}

export default Breadcrumbs
