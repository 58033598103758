import { numberWithCommas } from 'apps/studio-shared/src/Util/formatNumberWithComma'
import React from 'react'

interface LearningPathSkillProps {
  skill: any
}

const determineImageAndColor = () => {
  const randomNumber = Math.floor(Math.random() * 5)
  switch (randomNumber) {
    case 0:
      return {
        color: '#B305F8',
      }
    case 1:
      return {
        color: '#FDBA17',
      }
    case 2:
      return {
        color: '#3E7CF9',
      }
    case 3:
      return {
        color: '#FF57D0',
      }
    case 4:
      return {
        color: '#745BFF',
      }
    case 5:
      return {
        color: '#FF8500',
      }
    default:
      return {
        color: '#B305F8',
      }
  }
}

const LearningPathSkill: React.FC<LearningPathSkillProps> = ({ skill }) => {
  const { color } = determineImageAndColor()

  const percentageComplete =
    (skill.currentPoints / skill.currentLevel.pointsEnd) * 100

  return (
    <div id="LearningPathSkill" className="my-4 flex" onClick={() => {}}>
      <div
        className="font-jakarta-sans flex items-center text-base font-semibold text-white"
        style={{ width: '182px' }}
      >
        {skill?.name}
      </div>

      <div
        className="bg-moon-darkest relative top-1 float-left rounded-[12px]"
        style={{ height: '24px', width: '32px' }}
      >
        <div className="relative -top-1 float-left h-8 w-8 text-center">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold">
            {skill?.currentLevel.position}
          </div>
          {/* <img className="h-32 w-32" src={icon} alt={'LearningPathIcon'} /> */}
        </div>
      </div>

      <div className="bg-moon-darkest relative top-1 float-left ml-2 flex h-6 flex-grow rounded-xl">
        <div
          className="relative float-left rounded-2xl"
          style={{
            width: `${percentageComplete}%`,
            backgroundColor: color,
          }}
        >
          {percentageComplete > 15 && (
            <div className="float-right mr-3 pl-3 text-base font-bold text-white">
              {numberWithCommas(skill?.currentPoints)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LearningPathSkill
