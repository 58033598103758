import React from 'react'
import classNames from 'classnames'
import { itemClassnames } from './SelectedToolboxObjectValue'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faStop } from '@fortawesome/pro-solid-svg-icons'
import SoundConstant from './SoundConstant'

interface SoundParameterProps {
  parameter: any
  index: number
  currentObject: any
}

const SoundParameter: React.FC<SoundParameterProps> = ({
  currentObject,
  parameter,
  index,
}) => {
  const id = `${currentObject}-${parameter.name}`
  const isConstant = parameter.constant
  const soundRefs = React.useRef<any>([])

  if (isConstant) {
    const library = parameter.constant.name.slice(
      0,
      parameter.constant.name.length - 7
    )
    const constants = parameter.constant?.values.map((value, index) => {
      return (
        <SoundConstant
          soundRefs={soundRefs}
          value={value}
          currentObject={currentObject}
          index={index}
          library={library}
        />
      )
    })

    return (
      <div className="text-moon-darkest relative inline">
        <span
          className="text-sun-darkest cursor-pointer font-mono"
          data-tip
          data-for={id}
        >
          {parameter.name}
        </span>

        {isConstant && <p className="mt-1">Choose a Selection</p>}

        <div className="mt-2 overflow-hidden rounded-lg">{constants}</div>
      </div>
    )
  } else {
    return (
      <div className="mt-1" key={parameter.name}>
        <div className="text-titan-dark">{parameter.name}</div>
        <p className="text-moon-dark">{parameter.description}</p>
      </div>
    )
  }
}

export default SoundParameter
