'use client'

// import { Button } from '@codeverse/helios'
// import Button from 'libs/helios/src/lib/Button'
// import Button from '@codeverse/helios/button'
import Button from '@codeverse/helios/button'
import { useRouter, useSearchParams } from 'next/navigation'

interface ActionButtonsProps {
  // handleNavigate: (step: number) => void
}
export default function ActionButtons({}: ActionButtonsProps) {
  const router = useRouter()
  const searchParams = useSearchParams()
  return (
    <div className="mt-7 flex w-full flex-col">
      <Button
        onClick={() => {
          const next = searchParams.get('next')
          if (next) {
            router.push(`/login/signup?step=1&next=${encodeURI(next)}`)
          } else {
            router.push(`/login/signup?step=1`)
          }
        }}
        size="large"
        className="!w-full"
        context="primaryFlat"
      >
        Join and start coding
      </Button>
      <Button
        size="large"
        className="mt-2 !w-full"
        context="gray"
        onClick={() => {
          const next = searchParams.get('next')
          if (next) {
            router.push(`/login?step=2&next=${encodeURI(next)}`)
          } else {
            router.push(`/login?step=2`)
          }
        }}
      >
        Sign In
      </Button>
    </div>
  )
}
