export const BLANK_PROJECT = ['', '']

export const PLATFORM = [
  '',
  `# import our objects
import Player
import DirectionalPad
import LargePlatform

# create our objects in the game
var large_platform = new LargePlatform(50, 90)
var directional_pad = new DirectionalPad(81.3, 88.7)
var player = new Player(10, 70)

# make the player bigger
player.setSize(150)

# make the dpad move the player right
when directional_pad.pressRight()
  player.walkRight()
end

# make the dpad move the player left
when directional_pad.pressLeft()
  player.walkLeft()
end

# make the dpad able to jump the character
when directional_pad.pressUp()
  player.jump()
end`,
]

export const ACTION = [
  '',
  `# Objects used in this game
import Apple
import BaseballBat
import Timer
import random

# A variable to hold the players score
var points = 0

# A baseball bat which can be picked up and dragged around
var bat = new BaseballBat(58, 83.3)
bat.setSize(209)
bat.setRotation(323)
bat.setDraggable(true)

# Use a timer to create lots of Apples
var t = new Timer()
when t.ends()
  # Generate a random position for the Apple
  var x = random.number(0, 100)
  var y = random.number(0, 10)
  
  # Create the falling Apple 
  var apple = new Apple(x, y)
  
  # Unfix the Apple, so it can fall
  apple.setFixed(false)
  
  # Code to execute when the bat strikes an apple
  when apple.collidesWith(bat)
    apple.explode()
    points = points + 1
  end
end

# Make the Timer repeat every second
t.setInterval(1)`,
]

export const SPORT = [
  '',
  `# This is a basic sports game which uses calculated position 
# and movement of objects

# Objects used in this game
import Ball
import Paddle
import Rectangle
import Leaderboard

# A variable to holds the players score
var points = 0

# Create a paddle which can be moved left and right
var paddle = new Paddle(50, 80)
paddle.setDraggableHorizontally(true)

# Initial speed and angle of the ball
var ball_speed = 0.5
var ball_angle = 15

# Create a ball and launch it towards the top wall
var ball = new Ball(50, 50)
ball.moveAtAngle(ball_speed, ball_angle)

# Add functionality to the ball which flips it's direction
# This code will be called when we need to make the ball bounce
when ball.triggers("bounce_x")
  ball_angle = -1 × ball_angle
  ball.moveAtAngle(ball_speed, ball_angle)
end

# Similar to the code above, but called when bouncing vertically
when ball.triggers("bounce_y")
  ball_angle = 180 - ball_angle
  # angle can not be less than -360
  if ball_angle greater than 360
    ball_angle = ball_angle - 360
  end
  ball.moveAtAngle(ball_speed, ball_angle)
end

# When we hit the ball with the paddle
when ball.collidesWith(paddle)
  # Increase the speed of the ball
  ball_speed = ball_speed + 0.1
  # Increase the users score
  points = points + 1
  # Call the vertical bounce code to flip the balls direction
  ball.trigger("bounce_y")
end

# Create walls for our ball to bounce off
var left_wall = new Rectangle(0, 50)
left_wall.setHeight(700)
left_wall.setWidth(100)

var right_wall = new Rectangle(100, 50)
right_wall.setHeight(700)
right_wall.setWidth(100)

var top_wall = new Rectangle(50, 0)
top_wall.setHeight(100)
top_wall.setWidth(600)

var bottom_wall = new Rectangle(50, 100)
bottom_wall.setHeight(100)
bottom_wall.setWidth(600)

# Flip the direction of the ball when it hits the top or sides
when ball.collidesWith(left_wall)
  ball.trigger("bounce_x")
end

when ball.collidesWith(right_wall)
  ball.trigger("bounce_x")
end

when ball.collidesWith(top_wall)
  ball.trigger("bounce_y")
end

# The Game ends when the ball hits the bottom wall
when ball.collidesWith(bottom_wall)
  # Display a leaderboard
  var lb = new Leaderboard()
  lb.saveScore(points)
end`,
]
