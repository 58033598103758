'use client'
import { useUserPublicApps } from 'apps/studio-shared/src/Data/User/UserDataHooks'
import { useSearchParams } from 'next/navigation'
import React from 'react'
import PublishedGameCard from './PublishedGameCard'

import times from 'lodash/times'
import EmptyCard from './EmptyCard'

export default function PublishedGames() {
  const params = useSearchParams()
  const username = params.get('username') as string
  const { data: publicApps } = useUserPublicApps(username)

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6">
      {publicApps?.map((app: any, index: number) => {
        return <PublishedGameCard index={index} app={app} />
      })}
      {publicApps?.length === 0 &&
        times(12).map((i) => {
          return <EmptyCard index={i} />
          return <EmptyCard index={i} />
        })}
    </div>
  )
}
