'use client'
import React, { useMemo } from 'react'
import useMissionStore from '../../../Stores/useMissionStore'
import Info from './MissionSteps/Info'
import Code from './MissionSteps/Code'
import Quiz from './MissionSteps/Quiz'

interface CurrentStepProps {}

const CurrentStep: React.FC<CurrentStepProps> = () => {
  const { currentStep } = useMissionStore()
  const CurrentStepComponent = useMemo(() => {
    switch (currentStep?.boilerplate) {
      //@ts-ignore
      // case 'insert':
      //   return <Insert />
      // //@ts-ignore
      // case 'image':
      //   return <Image />
      // //@ts-ignore
      // case 'video':
      //   return <Video />
      // //@ts-ignore
      // case 'example':
      //   return <Example />
      case 'quiz':
        return <Quiz />
      case 'code':
        return <Code />
      case 'info':
        return <Info />
      default:
        return <></>
    }
  }, [currentStep])

  return <div id="CurrentStep">{CurrentStepComponent}</div>
}

export default CurrentStep
