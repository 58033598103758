'use client'

import { useEffect, useMemo, useRef, useState } from 'react'

import {
  Panel,
  PanelGroup,
  PanelGroupStorage,
  PanelResizeHandle,
} from 'react-resizable-panels'

import { useInitializeRuntime } from '../../Studio/Hooks/useInitializeRuntime'
import GamePreview from 'apps/studio-shared/src/Components/Studio/GamePreview'
import Editor from 'apps/studio-shared/src/Components/Studio/Editor'
import MissionBuilderContainer from './Mission/MissionBuilderContainer'
import { useInitializeGuidedMissions } from '../useInitializeGuidedMissions'
import Missions from '../Missions/Missions'
import MissionBuilderHookHandler from 'apps/studio-shared/src/Components/Missions/MissionBuilder/MissionStep/MissionBuilderHookHandler'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'
import MissionStepEditorPortal from '../Missions/MissionStepPortal'
import MissionStepPortalContent from '../Missions/MissionStepPortalContent'
import Toolbox from '../../Studio/Toolbox/Toolbox'
import CanvasMissionStep from '../Missions/MissionSteps/CanvasMissionStep'
import useAutomaticMissionProgression from '../../Studio/Hooks/useAutomaticMissionProgression'
import useMissionBuilderStore from 'apps/studio-shared/src/Stores/useMissionBuilderStore'
import useMission from 'apps/studio/hooks/data/Mission/useMission'
import { useParams } from 'next/navigation'

interface Props {
  children: React.ReactNode
  defaultLayout?: any
}

export default function MissionBuilder({
  children,
  defaultLayout = [50, 50],
}: Props) {
  const [panelSize, setPanelSize] = useState(35)
  const params = useParams()
  const [isMounted, setIsMounted] = useState(false)

  const { showPortal, setCurrentMission } = useMissionStore()
  const { setShowMissionEditor } = useMissionBuilderStore()

  const { data: missionData } = useMission(params?.id as string)

  useEffect(() => {
    if (missionData) {
      setCurrentMission({ id: missionData.id, ...missionData?.attributes })
    }
  }, [missionData])

  // useMissionSteps(missionId, missionSteps)
  // useInitializeGuidedMissions({ missionSteps, missionAchievement })

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useInitializeRuntime({
    documents: [
      {
        id: '1',
        kidscript: '',
        filename: '/head.ks',
        createdAt: '',
      },
      {
        id: '2',
        kidscript: '',
        filename: '/main.ks',
        createdAt: '',
      },
    ],
    projectId: '123',
    saveDocuments: false,
    // enabled: isMounted,
    isMounted,
  })

  useAutomaticMissionProgression({ type: 'mission_builder' })

  useInitializeGuidedMissions({
    type: 'mission',
  })

  const onLayout = (sizes: number[]) => {
    document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`
  }

  useEffect(() => {
    setShowMissionEditor(true)
  }, [])

  return (
    <PanelGroup
      autoSaveId="missionBuilder"
      direction="horizontal"
      className="relative overflow-x-hidden"
      onLayout={onLayout}
    >
      <Panel className="relative" defaultSize={defaultLayout[0]} minSize={30}>
        <MissionBuilderHookHandler />
        <Editor mode="mission_builder" />
        <Missions />
        {/* {missionId && <MissionCard missionId={missionId} />} */}
        <Toolbox />
      </Panel>
      <PanelResizeHandle className="bg-moon-darkest z-2000 h-full w-[2px]" />
      <Panel
        onResize={(thing) => {
          setPanelSize(thing)
        }}
        className="relative"
        defaultSize={defaultLayout[1]}
        minSize={30}
      >
        <GamePreview panelSize={panelSize} />
        <div style={{ opacity: true ? '100%' : '0%' }}>
          <MissionBuilderContainer>{children}</MissionBuilderContainer>
          {/* <div id="KidScriptEditModal" /> */}
          {/* <div id="MissionStepTipCreate" /> */}
          {/* <div id="MissionStepInsertableKidScriptModal" /> */}
          {/* <div id="ManageMissionSkills" /> */}
        </div>
        <CanvasMissionStep />
        {showPortal && (
          <MissionStepEditorPortal>
            <MissionStepPortalContent />
          </MissionStepEditorPortal>
        )}
      </Panel>
    </PanelGroup>
  )
}
