'use client'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import Cookies from 'js-cookie'

// import { useUIState } from '@codeverse-studio/containers/Context/UIStateContainer'
// import { useOuterClick } from '@codeverse/helios'
// import { useNavigate } from 'react-router-dom'
// import { ReactComponent as VTSmall } from './vtSmallIcon.svg'

import classNames from 'classnames'
import useOuterClick from 'apps/studio/utils/useOuterClick'
import { usePathname, useRouter } from 'next/navigation'
import { useUIStore } from '../../Stores'
import useSession from '../../Data/User/useSession'
import determineAvatar from './Nav/determineUserAvatar'
import useUser from '../../Data/User/useUser'
import useAuth from '../../Hooks/Auth/useAuth'
import { Dropdown, DropdownItem } from '@codeverse/helios/dropdown'
import { faSquareUp } from '@fortawesome/pro-solid-svg-icons'
// import { useSession } from '../../../../containers/Context/SessionContainer'
// import determineAvatar from '../../../../util/determineUserAvatar'

interface AvatarIconProps {
  avatarId?: string
  avatarUrl?: string
  onClick?: any
}

const AvatarIcon: React.FC<AvatarIconProps> = ({
  avatarId,
  avatarUrl,
  onClick,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const { username } = useAuth()
  const pathname = usePathname()
  const { setProfileModal } = useUIStore()
  const { user, isFetched } = useSession()
  const router = useRouter()

  const isVTLogin = useMemo(() => {
    return localStorage.getItem('vtLogin') === 'true'
  }, [])

  const avatarStyle = useMemo(() => {
    if (avatarId) {
      return {
        backgroundImage: `url(${
          user?.avatar?.file ||
          'https://assets.codeverse.com/users/avatars/file_missing.svg'
        })`,
      }
    } else {
      return {
        backgroundImage: `url(${
          user?.avatar?.file ||
          'https://assets.codeverse.com/users/avatars/file_missing.svg'
        })`,
      }
    }
  }, [avatarId, user])

  const handleLogout = () => {
    window.heap?.resetIdentity()

    localStorage.removeItem('auth')
    router.push('/login')
  }

  const handleLearningLab = () => {
    // window.location.assign('https://www.varsitytutors.com/learning-lab/')
  }

  const profileMenuClassnames = classNames(
    'absolute bg-white right-0 rounded-lg shadow-lg top-10 transition-all w-56 z-2000',
    {
      'opacity-0 pointer-events-none': !showMenu,
      'opacity-100': showMenu,
    }
  )
  const itemClassnames = classNames(
    'border-b border-moon-dark border-opacity-20 cursor-pointer font-medium font-poppins-semibold last:border-none pl-4 py-2 text-moon-dark texy-16'
  )

  const innerRef = useRef()

  const dropdownClassnames = classNames(
    'absolute left-[-120px] top-10 transition-all  z-[9999]'
  )

  const handleAvatarClick = () => {
    if (typeof onClick === 'function') {
      onClick()
    } else {
      setShowMenu(!showMenu)
    }
  }

  useOuterClick(innerRef, () => setShowMenu(false))

  return (
    <div
      id="UserAvatarIcon"
      className="relative ml-4 h-10 w-10 cursor-pointer rounded-full border-2 border-white bg-cover"
      style={avatarStyle}
      onClick={() => handleAvatarClick()}
    >
      <Dropdown
        setIsOpen={setShowMenu}
        isOpen={showMenu}
        dropdownClassnames={classNames(dropdownClassnames)}
      >
        <DropdownItem
          id="ProfileIcon_ViewProfile"
          handleClick={() => {
            router.push(`${pathname}?username=${username}`)
            setProfileModal(true)
          }}
          text="View my profile"
          isOpen={showMenu}
        />
        {(user?.scope === 'ADMINISTRATOR' || user?.scope === 'GUIDE') && (
          <DropdownItem
            id="ProfileIcon_ViewProfile"
            handleClick={() => {
              router.push(`/mission_builder`)
            }}
            text="Mission Builder"
            isOpen={showMenu}
          />
        )}
        {/* {!isVTLogin && ( */}
        <DropdownItem
          id="ProfileIcon_Logout"
          handleClick={() => handleLogout()}
          text="Logout"
          isOpen={showMenu}
        />
        {/* )} */}
      </Dropdown>
    </div>
  )
}

export default AvatarIcon
