'use client'
import React, { useCallback, useMemo } from 'react'
import useMissionBuilderStore from '../../../../Stores/useMissionBuilderStore'
import classNames from 'classnames'
import Button from '@codeverse/helios/button'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useMissionSteps } from 'apps/studio-shared/src/Data'
import useMissionStore from 'apps/studio-shared/src/Stores/useMissionStore'

interface StepManagerProps {
  children: React.ReactNode
  setShowEditMission?: React.Dispatch<React.SetStateAction<boolean>>
}

const aspectRatio = 509 / 768

const MissionBuilderContainer: React.FC<StepManagerProps> = ({ children }) => {
  const pathname = usePathname()
  const params = useParams()
  const router = useRouter()

  const { setCurrentStep } = useMissionStore()

  const isSteps = useMemo(() => {
    return pathname.includes('/steps')
  }, [pathname])

  const { showMissionEditor, choosingInsertLine, pickLine } =
    useMissionBuilderStore()

  const editMissionClassnames = classNames(
    'z-3000 bg-neptune absolute right-0 top-0 mb-32 h-full w-full',
    {
      hidden: !showMissionEditor,
    }
  )

  const { data: missionSteps } = useMissionSteps(params?.id as string)

  const handleNextStep = useCallback(() => {
    const currentStepId = params?.stepId

    if (currentStepId && missionSteps) {
      const currentStepIndex = missionSteps?.findIndex(
        (step: any) => step?.id === currentStepId
      )
      setCurrentStep({
        // @ts-ignore
        ...missionSteps[currentStepIndex + 1].attributes,
        // @ts-ignore
        id: missionSteps[currentStepIndex + 1].data.id,
      })
      router.push(
        `/mission_builder/${params.id}/steps/${
          // @ts-ignore
          missionSteps[currentStepIndex + 1].id
        }`
      )
    }
  }, [missionSteps, pathname])

  // const hasNextStep = useMemo(() => {
  //   const currentStepId = params?.stepId
  //   if (currentStepId && missionSteps) {
  //     const currentStepIndex = missionSteps?.findIndex(
  //       (step: any) => step?.id === currentStepId
  //     )
  //     return currentStepIndex + 1 < missionSteps.length
  //   }
  //   return false
  // }, [missionSteps, pathname])

  return (
    <div className={editMissionClassnames}>
      <div className="h-full">
        <div className="bg-neptune-dark font-poppins-bold flex h-12 w-full items-center justify-center overflow-y-scroll text-white">
          <div className="flex">
            <div className="mr-2 pt-1">
              <svg
                width="36px"
                height="24px"
                viewBox="0 0 63 43"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Designs"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity="0.80375744"
                >
                  <g
                    id="Mission-Builder-Home"
                    transform="translate(-48.000000, -77.000000)"
                  >
                    <g id="Group-6" transform="translate(48.000000, 77.000000)">
                      <g id="Group-4" fill="#EEF6FD" opacity="0.5">
                        <polygon
                          id="Fill-1"
                          points="14.7619048 6.82121026e-13 29.5238095 4.89792054 29.5238095 34.2854438 14.7619048 29.3875232"
                        ></polygon>
                        <path
                          d="M0,6.55556052 L0,33.0609636 C0,33.467289 0.202326234,33.8471947 0.540309577,34.0750544 C0.878292612,34.3029133 1.30781868,34.3490471 1.68685519,34.1981988 L12.3015873,29.3875232 L12.3015873,0 L1.54692463,4.28568047 C0.614041447,4.65694285 0.00166629559,5.55551229 0,6.55556052 L0,6.55556052 Z M42.5988591,0.0887748098 L31.984127,4.89792054 L31.984127,34.2854438 L42.7387897,30.003589 C43.6729722,29.631804 44.2857143,28.7313243 44.2857143,27.7298832 L44.2857143,1.22448013 C44.2852991,0.818427216 44.0826689,0.438983781 43.7447674,0.211490678 C43.4068658,-0.0160100778 42.9776404,-0.0619740008 42.5988591,0.0887748098 Z"
                          id="Fill-3"
                        ></path>
                      </g>
                      <g
                        id="wrench"
                        transform="translate(25.714286, 5.714286)"
                        fillRule="nonzero"
                      >
                        <path
                          d="M17.0671604,2.06606275 C20.2125539,-1.07848366 24.8262099,-1.86007899 28.7015838,-0.275331271 L28.7015838,-0.275331271 L23.5008428,6.88004568 L24.0586271,10.2277405 L27.305328,10.7686982 L32.808324,5.26569833 C36.1610322,9.66784694 35.3045905,14.1335775 32.2188576,17.2193126 C30.7613675,18.6768037 28.9912026,19.6169028 27.1254283,20.0501446 C25.4079417,20.4489533 23.6090146,20.4174832 21.8985597,19.9626241 L21.8985597,19.9626241 L8.07294085,33.7882528 C7.02702226,34.8341721 5.65611418,35.3571429 4.28520045,35.3571429 C2.91428672,35.3571429 1.54337864,34.8341721 0.497460047,33.7882528 C-0.548458247,32.7423338 -1.07142857,31.3714251 -1.07142857,30.0005108 C-1.07142857,28.6295964 -0.548458247,27.2586878 0.497460047,26.2127687 L0.497460047,26.2127687 L14.3368448,12.373374 C13.8874059,10.6624836 13.857219,8.86138238 14.2544929,7.14394445 C14.6857482,5.27960283 15.6196347,3.51358939 17.0671604,2.06606275 Z"
                          id="Shape"
                          stroke="#292E57"
                          strokeWidth="2.14285714"
                          fill="#EEF6FD"
                        ></path>
                        <path
                          d="M4.28587009,31.6069808 C3.39859571,31.6069808 2.67873159,30.8871161 2.67873159,29.9998411 C2.67873159,29.1118965 3.39859571,28.3927015 4.28587009,28.3927015 C5.17314447,28.3927015 5.8930086,29.1118965 5.8930086,29.9998411 C5.8930086,30.8871161 5.17314447,31.6069808 4.28587009,31.6069808 Z"
                          id="Path"
                          fill="#FFFFFF"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            Mission Builder
          </div>

          {/* {isSteps && hasNextStep && (
            <div className="absolute right-4">
              <Button
                onClick={() => {
                  handleNextStep()
                }}
                size="tiny"
                context="secondary"
              >
                Next step
              </Button>
            </div>
          )} */}
        </div>
        <div
          className="bg-neptune relative h-auto w-full overflow-y-scroll p-8"
          style={{ height: `calc(100% - 48px)` }}
        >
          {children}
        </div>
        {(choosingInsertLine || pickLine) && (
          <div className="z-2000 pointer-events-auto absolute inset-0 flex">
            <div className="pointer-events-none relative flex h-full w-full items-center justify-center">
              <div className="bg-neptune-darkest absolute inset-0 bg-opacity-80" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MissionBuilderContainer
