'use client'

import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { MissionFormValues, headerClassname } from '../../MissionForm'
import { Select } from '@codeverse/helios/select'

interface CategoryProps {
  control: Control<MissionFormValues>
}

export type CategoryOption = { value: LevelValue; label: LevelLabel }

export type LevelValue = 'legacy' | 'foundational' | 'guided_build'
export type LevelLabel = 'Legacy' | 'Foundational' | 'Guided_build'

export const categoryOptions: Array<CategoryOption> = [
  { value: 'foundational', label: 'Foundational' },
  { value: 'guided_build', label: 'Guided_build' },
]

const Category: React.FC<CategoryProps> = ({ control }) => {
  return (
    <div className="mb-4">
      <div className={headerClassname}>Category</div>
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <Select
            size="regular"
            context="light"
            defaultVal={
              categoryOptions.find(
                (option) => field.value?.value === option.value
              ) || categoryOptions[0]
            }
            onChange={(option) => field.onChange(option)}
            options={categoryOptions}
          />
        )}
      />
    </div>
  )
}

export default Category
