'use client'

import React, { useMemo, useState } from 'react'
import AvatarSelector from './AvatarSelector'
import {
  useAvatars,
  useAvatarsPublic,
} from 'apps/studio-shared/src/Data/Avatar/AvatarDataHooks'
import AvatarModalContainer from './AvatarModalContainer'
import useSession from '../../Studio/Hooks/useSession'

export default function AvatarSelectorContainer({
  avatarsMapping,
}: {
  avatarsMapping: any
}) {
  const { data: avatars } = useAvatarsPublic()
  const { user } = useSession()

  const [initialSlide, setInitialSlide] = useState(
    avatarsMapping.findIndex((ele) => ele.id === user?.avatar?.id)
  )
  const [currentSlide, setCurrentSlide] = useState(
    avatarsMapping.findIndex((ele) => ele.id === user?.avatar?.id)
  )
  return (
    <AvatarModalContainer
      initialSlide={currentSlide}
      setCurrentSlide={setCurrentSlide}
      currentSlide={currentSlide}
      setInitialSlide={setInitialSlide}
    />
  )
}
